import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import LoadingButton from '../../../commonComponents/LoadingButton';
import useEventEnter from '../../../hooks/useEventEnter';
import CustomSelectReact from '../../CustomSelectReact';

const { useTranslation } = require('react-i18next');
const { default: useFilterOption } = require('./useFilter');

const FilterComponent = ({
  filters,
  handleDateFilter,
  handlerFilters,
  selectHandler,
  isLoading,
  handler,
  limit,
}) => {
  const { filterExpress, filterPayment, filterStatus } = useFilterOption();
  const { t } = useTranslation();
  const onGetDataOrderDriver = () => {
    handler({
      limit,
      page: 1,
      filters,
    });
  };

  useEventEnter(onGetDataOrderDriver);
  return (
    <div className="search-order flex flex-col mb-[35px] rtl items-start">
      <div className=" py-5 flex items-center gap-4 flex-wrap mb-4">
        <div className="flex">
          <input
            placeholder={t('phone')}
            className="filter-input"
            name="phone"
            value={filters.phone}
            onChange={handlerFilters}
          />
        </div>
        <div className="flex">
          <input
            placeholder={t('restaurant')}
            className="filter-input"
            name="restaurant"
            value={filters.restaurant}
            onChange={handlerFilters}
          />
        </div>
        <div className="flex">
          <input
            placeholder={t('order_number')}
            className="filter-input"
            name="order_number"
            value={filters.order_number}
            onChange={handlerFilters}
          />
        </div>
        <div className="flex">
          <input
            placeholder={t('customer')}
            className="filter-input"
            name="customer_name"
            value={filters.customer_name}
            onChange={handlerFilters}
          />
        </div>
        <div className="flex">
          <CustomSelectReact
            placeholder={t('isFreeExpress')}
            className="filter-select min-w-[200px]"
            options={filterExpress}
            value={filters.isFreeExpress}
            onChange={(e) => selectHandler(e, 'isFreeExpress')}
            isMulti
          />
        </div>
        <div className="flex filter-select min-w-[200px] ltr">
          <DateTimeRangePicker
            value={filters.date}
            onChange={handleDateFilter}
            disableClock
            format={'y-MM-dd'}
          />
        </div>
        <div className="flex">
          <CustomSelectReact
            placeholder={t('status')}
            className="filter-select min-w-[200px]"
            options={filterStatus}
            value={filters.status}
            onChange={(e) => selectHandler(e, 'status')}
            isMulti
          />
        </div>
        <div className="flex">
          <CustomSelectReact
            placeholder={t('payment')}
            className="filter-select min-w-[200px]"
            options={filterPayment}
            value={filters.payment}
            onChange={(e) => selectHandler(e, 'payment')}
            isMulti
          />
        </div>
        <div className="relative w-full">
          <div className="absolute right-0 ">
            <LoadingButton
              onClick={onGetDataOrderDriver}
              label={t('Apply Filter')}
              loading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterComponent;
