import React, { memo } from 'react';

const CardAnalysis = memo(({ dataAnalysis = 0, title }) => {
  return (
    <>
      <div className="card card--oil">
        <div className="card__count-container">
          <div className="card__count-text">
            <span className="card__count-text--big total">{dataAnalysis}</span>
            {title}
          </div>
        </div>
      </div>
    </>
  );
});
export default CardAnalysis;
