/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { memo, useEffect, useState } from 'react';
import { config } from '../../../config/Config';
import SearchDrivers from './SearchDrivers';

import { IconMapLoading } from '../../../constants/Icon';
import { CONST_STATUS } from '../../../constants/status';
import useMapTracking from '../../../hooks/useMapTracking';
import ButtonRefresh from '../../Atoms/ButtonRefresh';
import DriverCount from '../../Atoms/DriverCount';
import DriverCards from './DriverCards';
import DriverStatus from './DriverStatus';

const MapDrivers = () => {
  const [currentStatus, setCurrentStatus] = useState(CONST_STATUS.ONLINE);
  const [keywordSearch, setKeywordSearch] = useState('');

  const {
    handlerDriverId,
    driverId,
    onResetDriverFocus,
    drivers,
    polygon,
    orderId,
    setDrivers,
    boundaries,
    refSearchDriver,
    loadingDrivers,
    setLoadingDrivers,
  } = useMapTracking();

  const getDriver = async ({ findPolygon = [], bounds, filter = {}, keyword = '' }) => {
    if (loadingDrivers) return;
    try {
      setLoadingDrivers(true);
      const resp = await axios.post(
        config.API_URL.LOCATION.DRIVER,
        { filter, polygon: findPolygon, boundaries: bounds, keyword },
        {
          headers: config.headers,
        }
      );
      setDrivers(resp.data?.drivers || []);
    } catch (errors) {
    } finally {
      setLoadingDrivers(false);
    }
  };

  const handlerRefresh = async () => {
    if (driverId) {
      onResetDriverFocus();
    }
    refSearchDriver.current.value = '';
    await getDriver({
      findPolygon: polygon,
      filter: {
        status: currentStatus,
      },
    });
  };

  useEffect(() => {
    getDriver({
      bounds: driverId?._id ? {} : boundaries,
      findPolygon: polygon,
      keyword: keywordSearch,
      filter: {
        driver_id: driverId || null,
        status: currentStatus || CONST_STATUS.ONLINE,
        orderId,
      },
    });
  }, [driverId, currentStatus, orderId, polygon, boundaries, keywordSearch]);

  const handlerStatus = (status) => {
    handlerDriverId(null);
    refSearchDriver.current.value = '';
    setCurrentStatus(status);
  };
  return (
    <>
      <div className="card-drivers cards">
        <div className="w-full justify-center items-center h-22   flex bg-white pt-4 pb-6">
          <div className="h-12 w-full">{loadingDrivers && <IconMapLoading fill="red" />}</div>
        </div>

        <DriverCount />
        <SearchDrivers currentStatus={currentStatus} setKeywordSearch={setKeywordSearch} />
        <ButtonRefresh handlerRefresh={handlerRefresh} />
        <DriverStatus
          numberDriver={drivers?.length}
          currentStatus={currentStatus}
          setCurrentStatus={handlerStatus}
          showDriverNumber={loadingDrivers}
        />

        <DriverCards selectedTab={currentStatus} />
      </div>
    </>
  );
};

export default memo(MapDrivers);
