import axios from 'axios';
import { cloneDeep, debounce, isArray, isEmpty, isObject, isString } from 'lodash';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { config } from '../../../config/Config';
import { DataContext } from '../../../context/DataContext';
import { buildQueryString, parseQueryString } from '../../../helper/utils';
import { createRangeDate, newCreateRangeDate } from '../../../hooks/useDate';

export default function useNewOrder({
  isLoading,
  setIsLoading,
  currentPage,
  setCurrentPage,
  setData,
  setTotal,
  limit,
  setLimit,
}) {
  const filtersRef = useRef({});
  const [firstLoadPage, setFirstLoadPage] = useState(false);
  const { currentTimezone } = useContext(DataContext);

  const { search } = useLocation();
  const queryString = parseQueryString(search);
  useEffect(() => {
    if (!firstLoadPage) {
      if (!isEmpty(queryString?.filters)) {
        Object.entries(queryString?.filters)?.map(([key, value]) => {
          if (
            [
              'date',
              'isFreeExpress',
              'isDiscount',
              'status',
              'from_company',
              'driver_company',
              'payment',
            ].includes(key)
          ) {
            if (key === 'date') {
              return (filtersRef.current[key] = newCreateRangeDate(value));
            }
            filtersRef.current[key] = value;
          } else {
            filtersRef.current[key].value = value;
          }
        });
      }
      setFirstLoadPage(true);
    } else {
      getOrders(buildFilterOrderWithRef());
    }
  }, [firstLoadPage]);

  const buildFilterOrderWithRef = (newCurrentPage) => {
    const newFilter = {};
    Object.entries(filtersRef.current).map(([key, valueRef]) => {
      if (key === 'isDiscount') {
        return (newFilter[key] = valueRef);
      }
      if (isString(valueRef) || isArray(valueRef)) {
        return (newFilter[key] = valueRef);
      }
      if (
        (isString(valueRef?.value) && valueRef?.value.length > 0) ||
        (isArray(valueRef?.value) && valueRef.value.length > 0)
      ) {
        newFilter[key] = valueRef.value;
      }
    });
    const fullParamFilter = {
      filters: {
        ...newFilter,
      },
      limit: limit,
      page: newCurrentPage ? newCurrentPage : currentPage,
    };
    console.log('fullParamFilter', fullParamFilter);
    return fullParamFilter;
  };

  const handlePageChange = (nextPage) => {
    if (isLoading) return;
    if (Number(nextPage) === Number(currentPage)) return false;
    setCurrentPage(nextPage);
    const newFilters = {
      ...buildFilterOrderWithRef(),
      ...{ page: nextPage },
    };

    getOrders(newFilters);
  };

  const handleLimitChange = (newLimit) => {
    if (isLoading) return;
    setLimit(newLimit);

    const newFilters = {
      ...buildFilterOrderWithRef(),
      ...{ limit: newLimit },
      ...{ page: 1 },
    };

    getOrders(newFilters);
  };

  const buildNewQueryStringWithFilter = (filters) => {
    const cloneDeepFilters = cloneDeep(filters);

    let driver_company = [];
    if (
      isArray(cloneDeepFilters?.filters?.driver_company) &&
      cloneDeepFilters?.filters?.driver_company.length > 0
    ) {
      cloneDeepFilters?.filters?.driver_company.map((item) => driver_company.push(item.value));
      cloneDeepFilters.filters.driver_company = driver_company;
    }
    if (isObject(cloneDeepFilters?.filters?.isDiscount)) {
      cloneDeepFilters.filters.isDiscount = filters.filters.isDiscount.value;
    }
    if (cloneDeepFilters?.filters?.date) {
      cloneDeepFilters.filters.date = createRangeDate(filters?.filters?.date, currentTimezone);
    }
    return cloneDeepFilters;
  };

  async function getOrders(filters = {}) {
    if (isLoading === true) return;
    setIsLoading(true);

    const newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      `?${buildQueryString({ ...filters })}`;
    window.history.pushState({ path: newurl }, '', newurl);

    try {
      await axios
        .get(config.API_URL.DRIVERS.DRIVER_ORDER_DISCOUNT, {
          params: {
            ...buildNewQueryStringWithFilter(filters),
          },
          headers: config.headers,
        })
        .then(async (response) => {
          setData(response.data.data.results);
          if (response.data.data.results.length > 0) {
            setTotal(response.data.data.total);
          }
        });
      return;
    } catch (e) {
      console.log('Axios error: ', e);
    } finally {
      setIsLoading(false);
    }
  }

  const handlerGetOrder = useCallback(
    debounce((filters) => getOrders(filters), 500),
    [currentTimezone, isLoading]
  );

  return {
    handlerGetOrder,
    handlePageChange,
    filtersRef,
    buildFilterOrderWithRef,
    handleLimitChange,
    buildNewQueryStringWithFilter,
  };
}
