import axios from 'axios';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {useParams } from 'react-router';
import { config } from '../../config/Config';
import { DETAIL_TABS } from './constants';
import Driver from './Tab/Driver';
import Order from './Tab/Order';
import Partner from './Tab/Partner';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}


const CompanyDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [tab, setTab] = useState(null);
  const [companyName, setCompanyName] = useState('');

  const query = useQuery();

  const renderTransactionByType = () => {
    if (tab === DETAIL_TABS.DRIVER) {
      return <Driver tab={tab} companyName={companyName} />;
    }
    if (tab === DETAIL_TABS.PARTNER) {
      return <Partner tab={tab} companyName={companyName} />;
    }
    return <Order tab={tab} companyName={companyName} />;
  };

  const handleTab =  (type) => {
    setTab(type);
  };

  useEffect(() => {
     const type = query.get('tab');
    if (type) {
      setTab(type);
    }
    else {
      setTab(DETAIL_TABS.ORDER);
    }
   
  }, [query]);

  useEffect(() => {
    getCompanyInfo();
  }, []);

  const getCompanyInfo = async () => {
    try {
      const result = await axios.get(`${config.API_URL.DELIVERY_COMPANY.LIST}${id}`, {
        headers: config.headers,
      });

      const { status } = result;
      const { data } = result.data;

      if (status === 200) {
        setCompanyName(data?.name);
      }
    } catch (error) {
      console.log('error:', error);
    }
  };

  const renderTransactionsTab = useCallback(
    () => (
      <div className="flex items-center">
        <div>
          <h1 className="text-3xl m-8">
            اسم الشركة: <span className="font-bold text-3xl">{companyName}</span>
          </h1>
        </div>
        <div className="flex items-center gap-x-4 px-8 py-5">
          <button
            className={`tab-btn ${tab === DETAIL_TABS.ORDER ? 'active-tab' : ''}`}
            onClick={() => handleTab(DETAIL_TABS.ORDER)}
          >
            {t('orders')}
          </button>
          <button
            className={`tab-btn ${tab === DETAIL_TABS.DRIVER ? 'active-tab' : ''}`}
            onClick={() => handleTab(DETAIL_TABS.DRIVER)}
          >
            {t('drivers')}
          </button>
          <button
            className={`tab-btn ${tab === DETAIL_TABS.PARTNER ? 'active-tab' : ''}`}
            onClick={() => handleTab(DETAIL_TABS.PARTNER)}
          >
            {t('delivery_company.partner.title')}
          </button>
        </div>
      </div>
    ),
    [tab, companyName]
  );

  if (!tab) return null;
  return (
    <div className="rtl">
      {renderTransactionsTab()}
      {renderTransactionByType()}
    </div>
  );
};

export default CompanyDetail;
