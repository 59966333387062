import axios from 'axios';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { config } from '../../config/Config';

toast.configure();
export default function DriverDetails(props) {
  const { data, apiVersion, loadingConfig } = props;
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const [charge, setCharge] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [showCharge, setShowCharge] = useState(false);
  const [disableAdd, setDisableAdd] = useState(false);

  const handleMessage = (e) => {
    setMessage(e.target.value);
  };
  const handleCharge = (e) => {
    setCharge(e.target.value);
  };
  const sendMessage = async () => {
    if (message.length < 5) {
      alert(t('notification_must_have_minimum_5_letters'));
      return;
    }

    setShowMessage(false);
    setMessage('');
    await axios
      .post(
        `${config.API_URL.DRIVERS.SEND_MESSAGE}`,
        {
          title: t('admin'),
          message: message,
          driver_id: data._id,
        },
        { headers: config.headers }
      )
      .then(() =>
        toast(t('notification_has_been_successfully_sent'), {
          position: toast.POSITION.BOTTOM_LEFT,
        })
      );
  };

  console.log('🚀 ===== apiVersion', apiVersion);
  const sendCharge = async (e) => {
    if (charge.length < 1 || loadingConfig) return;
    let apiConfig = null;
    let amount = charge;
    let gift = 0;
    let chargeType;
    if (e === 'charge') {
      chargeType = true;
    } else {
      chargeType = false;
      amount = 0;
      gift = charge;
    }
    setDisableAdd(true);

    if (apiVersion === 'v1') {
      apiConfig = config;
      amount = charge;
    } else {
      apiConfig = config;
    }
    await axios
      .post(
        `${apiConfig.API_URL.TRANSACTIONS.CREATE_TRANSACTION}`,
        {
          driver_id: data._id,
          amount: amount,
          gift: gift,
          delivery_cost: '0.00',
          isGift: !chargeType,
          isCharge: chargeType,
        },
        { headers: apiConfig.headers }
      )
      .then(
        () =>
          toast(t('wallet_has_been_charged_successfully'), {
            position: toast.POSITION.BOTTOM_LEFT,
          }),
        setDisableAdd(false)
      );
    setCharge('');
  };

  const activate = async (id_number) => {
    await axios
      .patch(
        `${config.API_URL.DRIVERS.ACTIVATE_DRIVER}${id_number}`,
        {},
        { headers: config.headers }
      )
      .then((response) => {
        if (response.data.status === 200) {
          // console.log(response.data.message);
          props.toggleActivate(id_number, 'true');
        }
      })
      .catch((error) => {
        toast.error(
          t(`Active driver is not success. Errors: ${error.response?.data?.errors?.join(', ')} `),
          {
            position: toast.POSITION.BOTTOM_LEFT,
          }
        );
      });
  };
  const deactivate = async (id_number) => {
    if (!window.confirm('Do you want deactivate driver?')) return;
    await axios
      .patch(`${config.API_URL.DRIVERS.DEACTIVATE_DRIVER}${id_number}`, {}, config)
      .then((response) => {
        if (response.data.status === 200) {
          props.toggleActivate(id_number);
        }
      })
      .catch((error) => {
        console.log('🚀 ~ error', error);
        toast.error(
          t(
            `Deactivate driver is not success. Errors: ${error.response?.data?.errors?.join(', ')}`
          ),
          {
            position: toast.POSITION.BOTTOM_LEFT,
          }
        );
      });
  };

  const ready = async () => {
    await axios
      .patch(`${config.API_URL.DRIVERS.READY_DRIVER}${data._id}/available-status`, {}, config)
      .then((response) => {
        if (response.data.status === 200) {
          props.toggleReady(data.id_number, response.data.isReady);
        }
      })
      .catch((error) => {
        console.log('🚀 ~ error', error);
        toast.error(
          t(`Ready driver is not success. Errors: ${error.response?.data?.errors?.join(', ')}`),
          {
            position: toast.POSITION.BOTTOM_LEFT,
          }
        );
      });
  };
  const copy = async (data) => {
    navigator.clipboard.writeText(data.passKey.toUpperCase());
  };

  return (
    <div style={styles.container}>
      {!data.isActive ? (
        <button style={styles.activate} onClick={() => activate(data.id_number)}>
          {t('driver_account_suspended')}
        </button>
      ) : (
        <button style={styles.deactivate} onClick={() => deactivate(data.id_number)}>
          {t('account_deactivate')}
        </button>
      )}

      <Link to={{ pathname: `/drivers/edit/${data._id}`, data: data }}>
        <button style={styles.edit}>{t('edit_data')} </button>
      </Link>
      <Link to={{ pathname: `/drivers/${data._id}/transactions` }}>
        <button style={styles.edit}>{t('transactions')} </button>
      </Link>
      <Link to={{ pathname: `/drivers/${data._id}/orders` }}>
        <button style={styles.edit}>{t('orders')} </button>
      </Link>
      <button style={styles.chargeBtn} onClick={() => setShowCharge(!showCharge)}>
        {t('add_credit')}
      </button>

      {showCharge && (
        <div style={styles.messageHolder}>
          <input
            type="number"
            name="message"
            onChange={handleCharge}
            value={charge}
            style={styles.chargeInput}
            placeholder="0.00"
          />
          {disableAdd ? (
            <button style={[styles.add, { opacity: 0.5 }]}>{t('add')}</button>
          ) : (
            <div style={{ display: 'inline-block' }}>
              <button style={styles.addCharge} onClick={() => sendCharge('charge')}>
                {t('send_charge')}
              </button>
              <button style={styles.addGift} onClick={() => sendCharge('gift')}>
                {t('send_gift')}
              </button>
            </div>
          )}
        </div>
      )}

      <button style={styles.message} onClick={() => setShowMessage(!showMessage)}>
        {t('send_alert')}
      </button>
      {showMessage && (
        <div style={styles.messageHolder}>
          <input
            type="text"
            name="message"
            onChange={handleMessage}
            value={message}
            style={styles.messageInput}
            placeholder={`${t('message')}...`}
          />
          <button style={styles.send} onClick={sendMessage}>
            {t('send')}
          </button>
        </div>
      )}
      <button style={styles.copy} onClick={() => copy(data)}>
        {t('button_copy')}
      </button>
      {data?.isReady ? (
        <button style={styles.btnNotReady} onClick={ready}>
          {t('un_ready')}
        </button>
      ) : (
        <button style={styles.btnReady} onClick={ready}>
          {t('ready')}
        </button>
      )}
      {/* <ReCreate driverId={data._id} /> */}
    </div>
  );
}

const styles = {
  container: {
    padding: 20,
    backgroundColor: '#FCF5F2',
  },
  copy: {
    border: 0,
    backgroundColor: '#fafafa',
    padding: 8,
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 10,
    color: '#1C6F32',
    fontSize: 12,
    borderRadius: 5,
    cursor: 'pointer',
  },
  activate: {
    border: 0,
    backgroundColor: '#3BCE63',
    padding: 8,
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 10,
    color: '#1C6F32',
    fontSize: 12,
    borderRadius: 5,
    cursor: 'pointer',
  },
  deactivate: {
    border: 0,
    backgroundColor: '#342B40',
    padding: 8,
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 10,
    color: '#f2f2f2',
    fontSize: 12,
    borderRadius: 5,
    cursor: 'pointer',
  },
  edit: {
    border: 0,
    backgroundColor: '#E0EFF7',
    padding: 8,
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 10,
    color: '#342B40',
    fontSize: 12,
    borderRadius: 5,
    cursor: 'pointer',
  },
  chargeBtn: {
    border: 0,
    backgroundColor: '#D7A404',
    padding: 8,
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 10,
    color: '#6A5102',
    fontSize: 12,
    borderRadius: 5,
    cursor: 'pointer',
  },
  message: {
    border: 0,
    backgroundColor: '#34A5FA',
    padding: 8,
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 10,
    color: '#fff',
    fontSize: 12,
    borderRadius: 5,
    cursor: 'pointer',
  },
  send: {
    border: 0,
    backgroundColor: '#31C961',
    padding: 8,
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 10,
    color: '#fff',
    fontSize: 12,
    borderRadius: 5,
    cursor: 'pointer',
    marginRight: 10,
  },
  addCharge: {
    border: 0,
    backgroundColor: '#31C961',
    padding: 8,
    paddingLeft: 20,
    paddingRight: 20,
    color: '#fff',
    fontSize: 12,
    cursor: 'pointer',
  },
  addGift: {
    border: 0,
    backgroundColor: '#976EAD',
    padding: 8,
    paddingLeft: 20,
    paddingRight: 20,
    color: '#fff',
    fontSize: 12,
    borderRadius: 5,
    cursor: 'pointer',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    marginLeft: 20,
  },
  messageHolder: {
    display: 'inline-block',
  },
  messageInput: {
    border: 0,
    padding: 5,
    borderRadius: 5,
    paddingLeft: 10,
    paddingRight: 10,
    marginRight: 40,
  },
  chargeInput: {
    width: 80,
    border: 0,
    padding: 5,
    borderRadius: 5,
    paddingLeft: 10,
    paddingRight: 10,
    marginRight: 10,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    outline: 'none',
  },
  btnReady: {
    border: 0,
    backgroundColor: '#342B40',
    padding: 8,
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 10,
    color: '#f2f2f2',
    fontSize: 12,
    borderRadius: 5,
    cursor: 'pointer',
  },
  btnNotReady: {
    border: 0,
    padding: 8,
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 10,
    fontSize: 12,
    borderRadius: 5,
    cursor: 'pointer',
    backgroundColor: '#32BF84',
    color: '#6A5102',
  },
};
