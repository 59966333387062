/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { config, SETTING_KEY } from '../../config/Config';
toast.configure();

const Fee = ({ fee, setFee, setErrors, errors }) => {
  const { t } = useTranslation();

  const getSetting = async () => {
    const resp = await axios.get(config.API_URL.SETTING.GET, {
      params: {
        key: SETTING_KEY.FEE_TAX,
      },
      headers: config.headers,
    });
    const { data } = resp;
    const { setting } = data;
    setFee({
      fee: setting.fee || 0,
      tax: setting.tax || 0,
    });
  };

  useEffect(() => {
    getSetting();
  }, []);

  const handlerChange = (e) => {
    const newValue = {
      ...fee,
      [e.target.name]: e.target.value,
    };
    setFee(newValue);
    if (e.target.value <= 0) {
      setErrors({ ...errors, [e.target.name]: `The ${e.target.name} must be bigger than 0` });
      return false;
    }
    setErrors({});
  };

  return (
    <div className="setting">
      <h3>{t('citc_setting')}</h3>
      <div>
        <div className="row flex items-center gap-x-4">
          <label>{t('fee')}: </label>
          <input type="number" value={fee.fee} name="fee" onChange={handlerChange} />
        </div>
        {errors.fee && <p style={{ color: 'rgba(244, 91, 105, 1)' }}>{errors.fee}</p>}
      </div>

      <div>
        <div className="row flex items-center gap-x-4">
          <label>{t('tax')}: </label>
          <input type="number" value={fee.tax} name="tax" onChange={handlerChange} />
        </div>
        {errors.tax && <p style={{ color: 'rgba(244, 91, 105, 1)' }}>{errors.tax}</p>}
      </div>
    </div>
  );
};
export default Fee;
