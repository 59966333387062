import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Expandable(props) {
  const { t } = useTranslation();
  const { data } = props;

  return (
    <div style={styles.container}>
      <a
        href={`/orders/${data.order_number}`}
        title="Infomation invoice"
        without
        rel="noreferrer"
        style={styles.edit}
      >
        {t('view_detail_invoice')}
      </a>
    </div>
  );
}

const styles = {
  container: {
    padding: 20,
    backgroundColor: '#FCF5F2',
  },
  edit: {
    backgroundColor: '#E0EFF7',
    padding: 8,
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 10,
    color: '#342B40',
    fontSize: 12,
    borderRadius: 5,
    cursor: 'pointer',
    border: '1px solid #E0EFF7',
    textDecoration: 'none',
  },
};
