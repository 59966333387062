import React, { Fragment, memo, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';
import CompanyInput from '../../Atoms/CompanyInput/CompanyInput';
import { config } from '../../../config/Config';

const ImportExcel = memo(() => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [fromCompany, setFromCompany] = useState();

  const inputElement = useRef(null);

  useEffect(() => {
    if (!isOpen) return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const importExcel = async () => {
    setIsLoading(true);
    console.log(321);
    const formData = new FormData();

    formData.append('from_company', fromCompany);
    formData.append('driver_file', selectedFile);

    try {
      const response = await axios.post(config.API_URL.DRIVERS.IMPORT_EXCEl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response);
    } catch (error) {
      console.log('🚀 ~ file: ImportExcel.jsx ~ line 38 ~ importExcel ~ error', error);
    } finally {
      setIsLoading(false);
    }
  };

  const showModal = async () => {
    setIsOpen(true);
  };

  const closeModal = async () => {
    setIsOpen(false);
  };

  const handleUpload = async (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const onChangeCompany = async (selected) => {
    setFromCompany(selected.id);
  };

  return (
    <div>
      <button className="refresh-button flex" type="button" onClick={showModal}>
        {t('import_excel')}
      </button>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-[#00000065]" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-7xl h-[500px] overflow-x-hidden overflow-y-auto px-6 py-10 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h1"
                  className="font-bold text-2xl leading-6 text-gray-900 text-right"
                >
                  DRIVERS
                </Dialog.Title>
                <div className="mt-2">
                  <div className="w-1/2">
                    <div className="row">
                      <CompanyInput onChange={onChangeCompany} />
                    </div>
                    <div>
                      <label htmlFor="">Drivers CSV</label>
                      <input type="file" ref={inputElement || ''} onChange={handleUpload} />
                    </div>
                  </div>
                  <div className="flex w-full justify-end">
                    <button
                      className="refresh-button flex mt-20"
                      type="button"
                      onClick={importExcel}
                    >
                      {isLoading && (
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          width={16}
                          height={16}
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      )}
                      {t('import_excel')}
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
});

export default ImportExcel;
