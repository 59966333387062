import axios from 'axios';
import moment from 'moment';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from '../../../config/Config';
// import Select from '../../Forms/Select';
import AssignModal from '../AssignModal';
// import Collapsible from '../Collapse';

const ListOrder = memo(
  ({
    orders,
    setSelectedOrder,
    refreshList,
    handleStatusFilter,
    handleCityFilter,
    apiVersion,
    loadingConfig,
  }) => {
    const [assignModal, setAssignModal] = useState(false);
    const [currentOrder, setCurrentOrder] = useState({});
    const [status, setStatus] = useState({});
    const { t } = useTranslation();

    const [regions, setRegions] = useState([]);
    // const [cities, setCities] = useState([]);
    const [city, setCity] = useState(null);
    // const [region, setRegion] = useState(null);

    const filterStatus = [
      {
        key: 'new',
        name: t('new'),
      },
      {
        key: 'assigned',
        name: t('assigned'),
      },
    ];

    const handlerAssign = async (event, order) => {
      event.preventDefault();
      await setCurrentOrder(order);
      setAssignModal(true);
    };

    const assignToDriver = async () => {
      setAssignModal(false);
      refreshList();
    };

    const handleStatus = (event) => {
      let isChecked = event.target.checked;
      const newStatus = { ...status, [event.target.value]: isChecked };
      setStatus(newStatus);
      handleStatusFilter(newStatus);
    };

    const getRegions = async () => {
      const response = await axios.get(config.API_URL.CITC_LOOKUP.REGIONS);
      const { data = [] } = response;
      const res_regions = data.map((region) => {
        return {
          label: region.nameAr,
          value: region.id,
        };
      });
      setRegions(res_regions);
    };

    // const getCities = async (regionId) => {
    //   const response = await axios.get(config.API_URL.CITC_LOOKUP.CITIES, {
    //     params: { regionId },
    //   });
    //   const { data } = response;
    //   const res_cities = data.map((city) => {
    //     return {
    //       label: city.nameAr,
    //       value: city.id,
    //     };
    //   });
    //   setCities(res_cities);
    // };

    useEffect(() => {
      getRegions();
    }, []);

    // const selectRegion = (e) => {
    //   getCities(e.value);
    //   setRegion(e);
    // };

    // const selectCity = (e) => {
    //   setCity(e);
    //   handleCityFilter(e.value);
    // };

    const refreshListSearch = () => {
      setCity(null);
      refreshList();
    };

    return (
      <>
        {/* <Collapsible open={false} header="Filter"> */}
        <div className="flex filter flex-col px-6 gap-y-2">
          {filterStatus.map((status) => {
            return (
              <label key={status.key} className="checkbox-order">
                <input
                  type="checkbox"
                  value={status.key}
                  onChange={handleStatus}
                  checked={status[status.key]}
                />
                <span className="checkmark" />
                {status.name}
              </label>
            );
          })}
          {/* <div className="row direction-r">
              <label>{t('region')}</label>
              <Select
                id={3}
                model="region"
                selectHandler={selectRegion}
                data={regions}
                label={region?.label || null}
              />
            </div>
            <div className="row direction-r">
              <label>{t('city')} </label>
              <Select
                id={4}
                model="city"
                selectHandler={selectCity}
                data={cities}
                label={city?.label || null}
              />
            </div> */}
        </div>
        {/* </Collapsible> */}

        <div className="card-order">
          <div className="flex justify-end my-2">
            <button onClick={refreshListSearch} className="refresh-button">
              {t('refresh')}
            </button>
          </div>
          <nav>
            <ul>
              {orders.map((order) => {
                return (
                  <li key={order._id}>
                    <div className="content">
                      <div className="order-info" onClick={() => setSelectedOrder(order)}>
                        <div className="order-information">
                          <h1>{order.order_number}</h1>
                          <span className="restaurant">{order.restaurant_name}</span>
                          <span
                            className={`order-status ${!order.driver_id ? 'new' : 'inprogress'}`}
                          >
                            {!order.driver_id ? t('new') : t('in_progress')}
                          </span>
                        </div>
                        <div className="info-customer">
                          <h3>{order.customer_name}</h3>
                          <span>
                            {order.customer_address}, {order.customer_city}
                          </span>
                          <span>{order.driver_name}</span>
                          <span>{moment(order.created_at).format('LLL')}</span>
                        </div>
                      </div>
                      <div className="flex justify-end py-1">
                        <a
                          href={`/orders/${order.order_number}`}
                          className="button to-invoice"
                          target="_blank"
                          title="Infomation invoice"
                          without="true"
                          rel="noreferrer"
                        >
                          <svg
                            viewBox="0 0 24 24"
                            width="16"
                            height="16"
                            stroke="currentColor"
                            strokeWidth="2"
                            fill="none"
                          >
                            <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                            <polyline points="14 2 14 8 20 8"></polyline>
                            <line x1="16" y1="13" x2="8" y2="13"></line>
                            <line x1="16" y1="17" x2="8" y2="17"></line>
                            <polyline points="10 9 9 9 8 9"></polyline>
                          </svg>
                        </a>
                        <button
                          className="button assign-button"
                          onClick={(e) => handlerAssign(e, order)}
                        >
                          <svg
                            viewBox="0 0 24 24"
                            width="16"
                            height="16"
                            stroke="currentColor"
                            strokeWidth="2"
                            fill="none"
                          >
                            <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                            <circle cx="8.5" cy="7" r="4"></circle>
                            <line x1="20" y1="8" x2="20" y2="14"></line>
                            <line x1="23" y1="11" x2="17" y2="11"></line>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </nav>
          {assignModal && (
            <AssignModal
              order={currentOrder}
              assignModal={assignModal}
              closeModal={() => setAssignModal(false)}
              assignToDriver={assignToDriver}
              apiVersion={apiVersion}
              loadingConfig={loadingConfig}
            />
          )}
        </div>
      </>
    );
  }
);

export default ListOrder;
