import { memo } from 'react';
import ButtonRefresh from '../../../Atoms/ButtonRefresh';
import FilterOrders from '../FilterOrders';
import InputSearchOrder from '../InputSearchOrder';
import OrderCards from '../OrderCards/OrderCards';

const ListOrders = ({ handlerRefresh, apiVersion, loadingConfig }) => {
  return (
    <>
      <FilterOrders />
      <InputSearchOrder />
      <ButtonRefresh handlerRefresh={handlerRefresh} />
      <OrderCards
        handlerRefresh={handlerRefresh}
        apiVersion={apiVersion}
        loadingConfig={loadingConfig}
      />
    </>
  );
};

export default memo(ListOrders);
