/* eslint-disable no-const-assign */
import axios from 'axios';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { config, setToken } from '../../config/Config';
import '../../css/login.css';
import { handleMessage } from '../../helper/utils';

const Login = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isErrors = {};
    if (!email || email.length <= 0) {
      isErrors = {
        ...isErrors,
        email: t('email_not_empty'),
      };
    }
    if (!password || password.length <= 0) {
      isErrors = {
        ...isErrors,
        password: t('password_not_empty'),
      };
    }
    if (isErrors.email || isErrors.password) {
      setErrors(isErrors);
      return false;
    }
    try {
      const response = await axios.post(config.API_URL.AUTH.LOGIN, { email, password });
      const { data } = response;
      await setToken(data.token);
      if (response?.status === 200) {
        window.location.href = '/';
      } else {
      handleMessage('error', 'Username or password is incorrect');
      }   
    } catch (errors) {
      handleMessage('error', 'Username or password is incorrect');
    }
  };

  return (
    <>
      <div className="w-full h-full flex justify-center bg-red">
        <form className="login" onSubmit={handleSubmit} style={{ direction: 'rtl' }}>
          <input
            type="text"
            placeholder={t('email')}
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          {errors.email?.length > 0 && (
            <p style={{ color: 'rgba(244, 91, 105, 1)' }}>{errors.email}</p>
          )}
          <input
            type="password"
            placeholder={t('password')}
            name="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
          {errors.password?.length > 0 && (
            <p style={{ color: 'rgba(244, 91, 105, 1)' }}>{errors.password}</p>
          )}
          <button type="submit" onClick={handleSubmit}>
            {t('login_button')}
          </button>
        </form>
      </div>
    </>
  );
};

export default Login;
