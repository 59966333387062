import { isDate } from 'lodash';
import moment from 'moment';
import { optionTimezone } from '../../components/Setting/components/SelectTimeZone';
const convertTimeZone = (value) => {
  switch (value) {
    case '+07:00':
      return '0700';

    case '+03:00':
      return '0300';

    default:
      return '0300';
  }
};
export const createDate = (date, timeZone = optionTimezone[1]) => {
  if (isDate(date)) {
    const dateFormat = moment(date)
      .tz(timeZone?.timezone || 'Asia/Kuwait')
      .locale('en')
      .utcOffset(moment().utcOffset())
      .format('ddd MMM DD YYYY HH:mm:ss ZZ');
    const dateWithTimezoneFormat = `${dateFormat.split('+')[0]}GMT+${convertTimeZone(
      timeZone?.gmt
    )}`;

    return dateWithTimezoneFormat;
  }

  return date;
};

export const getUtcTime = (date) => {
  const newDate = isDate(date) ? date : new Date(date);
  return moment(newDate).valueOf() + moment().utcOffset() * 60 * 1000;
};
