/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { config, SETTING_KEY } from '../../config/Config';
toast.configure();

const ToggleSocket = memo(({ enableOrderSocket, setEnableOrderSocket }) => {
  const getSetting = async () => {
    const resp = await axios.get(config.API_URL.SETTING.GET, {
      params: {
        key: SETTING_KEY.ENABLE_ORDER_SOCKET,
      },
      headers: config.headers,
    });
    setEnableOrderSocket({
      status: resp?.data?.setting?.status || false,
    });
  };

  useEffect(() => {
    getSetting();
  }, []);

  const handlerChange = (e) => {
    const newValue = {
      [e.target.name]: e.target.checked,
    };
    setEnableOrderSocket(newValue);
  };

  return (
    <>
      <div className="setting">
        <h3>Enable Order Socket:</h3>
        <div className="row flex items-center gap-x-4">
          <label className="switch">
            <input
              checked={enableOrderSocket.status}
              type="checkbox"
              name="status"
              onChange={handlerChange}
            />
            <span className="slider round"></span>
          </label>
        </div>
      </div>
    </>
  );
});

export default ToggleSocket;
