import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useActionOrder from './useActionOrder';

export default function ActionOrder({
  selectedTab,
  setSelectedTab,
  refresh,
  setCurrentPage,
  buildNewQueryStringWithFilter,
  buildFilterOrderWithRef,
}) {
  const { exportSTCExcel, exportExcel, onChangeTab, isExportExcel, isExportStc } = useActionOrder({
    setSelectedTab,
    setCurrentPage,
    buildNewQueryStringWithFilter,
    buildFilterOrderWithRef,
  });
  const { t } = useTranslation();
  return (
    <div className="flex justify-between items-center">
      <div className="displayHolder">
        <div className="toggleLiveHolder toggleLiveHolderOrder">
          <Link to="/orders/live" className="live">
            <div>{t('live')}</div>
          </Link>
          <button
            className={`offline ${selectedTab === 'order' ? 'selectedDisplay' : ''}`}
            isActive={selectedTab === 'order'}
            onClick={() => onChangeTab('order')}
          >
            {t('hypothetical')}
          </button>
          <button
            className={`offline ${selectedTab === 'fake_order' ? 'selectedDisplay' : ''}`}
            isActive={selectedTab === 'fake_order'}
            onClick={() => onChangeTab('fake_order')}
          >
            {t('fake_order')}
          </button>
        </div>
        <span>{t('view_orders')}</span>
      </div>
      <div className="h-full flex items-center gap-x-4">
        <button className="refresh-button">
          <Link to="/list-discount-order">
            <div>{t('discount_order')}</div>
          </Link>
        </button>
        <button className="refresh-button" onClick={() => refresh()}>
          {t('refresh')}
        </button>
        <button
          disabled={isExportStc}
          className={`refresh-button direction-r ${
            isExportStc ? 'opacity-40 border-red-200' : 'opacity-100'
          }`}
          onClick={exportSTCExcel}
        >
          {t('export_to_STC')}
        </button>
        <button
          disabled={isExportExcel}
          className={`refresh-button  ${
            isExportExcel ? 'opacity-40 border-red-200' : 'opacity-100 '
          } `}
          onClick={exportExcel}
        >
          {t('export_excel_all_by_filter')}
        </button>
      </div>
    </div>
  );
}
