/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';

import { useTranslation } from 'react-i18next';
import { config } from '../../config/Config';
import '../../css/datatable.css';
import useSchema from './Schema.js';
import { useLocation } from 'react-router-dom';

const DriversFakerOrder = () => {
  const tableHeight = window.innerHeight - 400;
  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  const fakerOrderId = query.get('faker-order-id');

  const schema = useSchema();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const getDriverOfFakeOrder = async (regionId) => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${config.API_URL.ORDERS.GET_ORDERS_FAKE}${fakerOrderId}`, {
        headers: config.headers,
      });
      const { data } = response;
      setData(data?.drivers);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getDriverOfFakeOrder();
  }, []);

  const renderTable = useMemo(() => {
    return (
      <DataTable
        noDataComponent={<div style={{ padding: '24px' }}>{t('orders_no_records')}</div>}
        columns={schema}
        data={data}
        fixedHeader
        contextMessage={{ singular: t('item'), plural: t('items'), message: t('selected') }}
        fixedHeaderScrollHeight={tableHeight.toString() + 'px'}
        progressPending={isLoading}
        progressComponent={<div className="wait">{t('please_wait')}</div>}
        paginationComponentOptions={{
          rowsPerPageText: t('total_record'),
          rangeSeparatorText: 'من',
          noRowsPerPage: false,
          selectAllRowsItem: false,
        }}
        selectableRowSelected={(row) => row.isSelected}
        persistTableHead
        selectableRowsHighlight
        direction={'rtl'}
        customStyles={{
          headCells: {
            style: {
              fontWeight: 'bold',
              fontSize: 12,
              background: '#F9F9F9',
            },
          },
          rows: {
            style: {
              fontSize: 12,
            },
          },
          header: {
            style: { minHeight: 0 },
          },
          //
          table: { style: { borderBottom: '1px solid #0000001f' } },
        }}
      />
    );
  }, [schema, data, isLoading]);

  if (isLoading) return null;

  return <div>{renderTable}</div>;
};

export default DriversFakerOrder;
