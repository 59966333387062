import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { getToken } from '../../config/Config';

export const RouteLayout = ({
  layout: Layout,
  component: Component,
  isPrivate,
  isSetting,
  redirect,
  ...props
}) => {
  const isAuthenticated = getToken();
  if (!isAuthenticated && isPrivate) return <Redirect to={'/login'} />;

  return (
    <Route
      {...props}
      render={(ownProps) => {
        if (Layout)
          return (
            <Layout>
              <Component {...ownProps} />
            </Layout>
          );
        return <Component {...ownProps} />;
      }}
    />
  );
};

export default RouteLayout;
