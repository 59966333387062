/* eslint-disable no-unused-vars */
import { uniqueId } from 'lodash';
import { useState } from 'react';

export default function useFilter({
  setCurrentPage,
  handleGetDataTable,
  refFilters,
  buildFilterOrderWithRef,
}) {
  const [_, setIsRenderNewDate] = useState(uniqueId());

  const handlerFiltersInput = (e) => {
    const newFilter = {
      ...refFilters.current,
      [e.target.name]: e.target.value,
    };
    refFilters.current = newFilter;
  };

  const selectHandlerSelect = (e, key) => {
    const newFilters = {
      ...refFilters.current,
      [key]: e,
    };
    refFilters.current = newFilters;
    setIsRenderNewDate(uniqueId());
  };

  const handleDateFilter = (value, type) => {
    const newFilter = {
      ...refFilters.current,
      [type]: value,
    };
    setIsRenderNewDate(uniqueId());
    refFilters.current = newFilter;
  };

  const onApplyFilter = async () => {
    setCurrentPage(1);
    let currentPage = 1;
    handleGetDataTable(buildFilterOrderWithRef(currentPage));
  };

  return {
    filters: refFilters,
    handlerFiltersInput,
    selectHandlerSelect,
    handleDateFilter,
    onApplyFilter,
  };
}
