/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { config, SETTING_KEY } from '../../config/Config';
toast.configure();

const LimitDisplayOrder = ({ limitOrder, setLimitOrder }) => {
  const { t } = useTranslation();

  const getSetting = async () => {
    const resp = await axios.get(config.API_URL.SETTING.GET, {
      params: {
        key: SETTING_KEY.LIMIT_ORDER,
      },
      headers: config.headers,
    });
    const { data } = resp;
    const { setting } = data;
    setLimitOrder({
      value: setting.value || 0,
    });
  };

  useEffect(() => {
    getSetting();
  }, []);

  const handlerChange = (e) => {
    const newValue = {
      ...limitOrder,
      [e.target.name]: e.target.value,
    };
    setLimitOrder(newValue);
  };

  return (
    <div className="setting">
      <h3>{t('limit_display_order')}</h3>
      <div className="row flex items-center gap-x-4">
        <label>{t('limit_display_order')}: </label>
        <input type="text" value={limitOrder.value} name="value" onChange={handlerChange} />
      </div>
    </div>
  );
};
export default LimitDisplayOrder;
