import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { config } from '../../config/Config';
import '../../css/style.css';
import Select from '../Forms/Select';

toast.configure();

const CitcDriver = (props) => {
  const { id } = useParams();
  const [data, setData] = useState({});

  const [identities, setIdentities] = useState([]);
  const [carsTypes, setCarsTypes] = useState([]);
  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);

  const { t } = useTranslation();

  // States
  const [
    {
      carTypeLabel,
      // identityTypeLabel,
      regionLabel,
      cityLabel,
      // carTypeId,
      carNumber,
      // cityId,
      dateOfBirth,
      idNumber,
      identityTypeId,
      mobile,
      refrenceCode,
      // regionId,
      registrationDate,
      vehicleSequenceNumber,
    },
    setState,
  ] = useState({});

  const getDriver = async () => {
    const resp = await axios.get(config.API_URL.DRIVERS.CITC_DRIVER + id, {
      headers: config.headers,
    });
    await setData(resp.data?.data);
  };

  const getIdentityTypes = async () => {
    const response = await axios.get(config.API_URL.CITC_LOOKUP.IDENTITY_TYPES);
    const res_identities = response?.data.map((identity_type) => {
      if (data.identityTypeId === identity_type.id) {
        setState({
          ...setState,
          identityTypeLabel: identity_type.nameAr,
        });
      }
      return {
        label: identity_type.nameAr,
        value: identity_type.id,
      };
    });
    setIdentities(res_identities);
  };

  const getCarTypes = async () => {
    const response = await axios.get(config.API_URL.CITC_LOOKUP.CAR_TYPES);

    const res_cars_type = response?.data.map((car_type) => {
      if (data.carTypeId === car_type.id) {
        setState({
          ...setState,
          carTypeLabel: car_type.nameAr,
        });
      }
      return {
        label: car_type.nameAr,
        value: car_type.id,
      };
    });
    setCarsTypes(res_cars_type);
  };

  const getRegions = async () => {
    const response = await axios.get(config.API_URL.CITC_LOOKUP.REGIONS);
    const res_regions = response?.data.map((region) => {
      if (data.regionId === region.id) {
        console.log({
          ...setState,
          regionLabel: region.nameAr,
        });
        setState({
          ...setState,
          regionLabel: region.nameAr,
        });
      }
      return {
        label: region.nameAr,
        value: region.id,
      };
    });
    setRegions(res_regions);
  };

  // eslint-disable-next-line no-unused-vars
  const getCities = async (regionId) => {
    const response = await axios.get(config.API_URL.CITC_LOOKUP.CITIES, {
      params: { regionId },
    });
    const res_cities = response?.data.map((city) => {
      if (data.regionId === city.id) {
        setState({
          ...setState,
          cityLabel: city.nameAr,
        });
      }
      return {
        label: city.nameAr,
        value: city.id,
      };
    });
    setCities(res_cities);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    await getDriver();
    await getIdentityTypes();
    await getRegions();
    await getCarTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [selectValues, setSelectValues] = useState([]);
  console.log('🚀 ~ selectValues', selectValues);

  const updateInitial = async () => {
    await setState({
      carTypeId: data.carTypeId,
      carNumber: data.carNumber,
      cityId: data.cityId,
      dateOfBirth: data.dateOfBirth,
      idNumber: data.idNumber,
      identityTypeId: data.identityTypeId,
      mobile: data.mobile,
      refrenceCode: data.refrenceCode,
      regionId: data.regionId,
      registrationDate: data.registrationDate,
      vehicleSequenceNumber: data.vehicleSequenceNumber,
    });
  };

  const selectHandler = (e) => {
    setSelectValues((prevValues) => [...prevValues.filter((i) => i.id !== e.id), e]);
    if (e.id === 3 && e.value) {
      getCities(e.value);
    }
  };

  useEffect(() => {
    if (!data) return;
    updateInitial();
    const selectedData = [
      {
        value: data.identity_type_id,
        id: 1,
        model: 'IdentityTypeId',
      },
      { value: data.carTypeId, id: 2, model: 'carType' },
      { value: data.regionId, id: 3, model: 'region' },
      { value: data.cityId, id: 4, model: 'city' },
    ];

    // initialize select form
    selectedData.forEach((o, index) => {
      selectHandler(selectedData[index]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  console.log(regionLabel);
  return (
    <>
      {data && (
        <div className="new_driver_container">
          <h3>{t('edit_information')} </h3>
          <div className="row">
            <label> {t('phone')}</label>
            <input type="text" className="center" value={mobile} name="phone" readOnly />
          </div>
          <div className="row">
            <label> {t('id_number')}</label>
            <Select
              id={1}
              model="IdentityTypeId"
              readOnly
              data={identities}
              label={identityTypeId}
            />
          </div>
          <div className="row">
            <label> {t('national_id')}</label>
            <input type="text" className="center" value={idNumber} name="idNumber" readOnly />
          </div>
          <div className="row">
            <label> {t('birth_day')}</label>
            <input type="text" className="center" value={dateOfBirth} name="dateOfBirth" readOnly />
          </div>
          <div className="row">
            <label> {t('registrationDate')}</label>
            <input
              type="text"
              className="center"
              value={registrationDate}
              name="registrationDate"
              readOnly
            />
          </div>
          <div className="row">
            <label> {t('refrence_code')}</label>
            <input
              type="text"
              className="center"
              value={refrenceCode}
              name="dateOfBirth"
              readOnly
            />
          </div>
          <div className="row">
            <label>{t('type_of_the_vehicle')} </label>
            <Select id={2} model="carType" readOnly data={carsTypes} label={carTypeLabel} />
          </div>
          <div className="row">
            <label>{t('car_number')} </label>
            <input type="text" className="center" value={carNumber} name="carNumber" readOnly />
          </div>
          <div className="row">
            <label>{t('region')}</label>
            <Select id={3} model="region" readOnly data={regions} label={regionLabel} />
          </div>
          <div className="row">
            <label>{t('city')} </label>
            <Select id={4} model="city" readOnly data={cities} label={cityLabel} />
          </div>
          <div className="row">
            <label>{t('vehicle_sequence_number')}</label>
            <input
              type="text"
              className="center"
              value={vehicleSequenceNumber}
              name="vehicleSequenceNumber"
              readOnly
            />
          </div>
        </div>
      )}
    </>
  );
};
export default CitcDriver;
