import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from '../../../config/Config';

const fakeRegon = [
  {
    label: 'الدمام',
    value: 'vXChcFPkxcI=',
  },
  {
    label: 'الخبر',
    value: '7kRgMtOkdQE=',
  },
  {
    label: 'الهفوف',
    value: 'cABXRPle08s=',
  },
  {
    label: 'القطيف',
    value: 'i07AZC3Axw4=',
  },
  {
    label: 'الجبيل',
    value: 'rHYWCxiMFVY=',
  },
  {
    label: 'قريه',
    value: 'ovCU0jF3K78=',
  },
  {
    label: 'حفر الباطن',
    value: '3E29T521tWk=',
  },
  {
    label: 'الخفجي',
    value: 'y5zgJK9KaT0=',
  },
  {
    label: 'النعيرية',
    value: 'uUS+BzFxoC4=',
  },
  {
    label: 'رأس  تنورة',
    value: 'ZA3W9AKCPSk=',
  },
  {
    label: 'بقيق',
    value: 'kr7gPRhygSI=',
  },
  {
    label: 'الظهران',
    value: 'DkWZ7cZi+yc=',
  },
  {
    label: 'منفذ الرقعي',
    value: 'LTsD0V8q980=',
  },
  {
    label: 'جسر الملك فهد',
    value: 'yuZANuOO7l4=',
  },
  {
    label: 'سلوى',
    value: 'mlOYxJuUua8=',
  },
  {
    label: 'العديد',
    value: '+l2R99BiTXY=',
  },
  {
    label: 'بطحاء',
    value: 'XnEfmRzm+EY=',
  },
  {
    label: 'صفوى',
    value: 'FrYsF5UGGN4=',
  },
  {
    label: 'سيهات',
    value: 'w1V6rte0qXA=',
  },
  {
    label: 'تاروت',
    value: 'M3T52TB6kFM=',
  },
  {
    label: 'السعيرة',
    value: 'cixVoDzk+uY=',
  },
];
const useFilterOptionDriverNew = () => {
  const { t } = useTranslation();

  const [companies, setCompanies] = useState([]);
  const [cities, setCities] = useState(fakeRegon);

  const getCompanyList = async () => {
    const { data } = await axios.get(config.API_URL.DELIVERY_COMPANY.LIST);
    if (data && data?.success) {
      const terraformData = await data?.data?.map((company) => {
        return {
          label: company.name,
          value: company.name,
          id: company._id,
        };
      });
      setCompanies(terraformData);
    }
  };

  const getCities = async (regionId = 'rAy9UhMUw6Y=') => {
    const response = await axios.get(config.API_URL.CITC_LOOKUP.CITIES, {
      params: { regionId },
    });
    const { data } = response;

    const res_cities = data.map((city) => {
      return {
        label: city.nameAr,
        value: city.id,
      };
    });

    setCities(res_cities);
  };

  useEffect(() => {
    getCompanyList();
    getCities();
  }, []);

  const filterStatus = [
    {
      value: 'active',
      label: t('active'),
    },
    {
      value: 'deactivate',
      label: t('inactive'),
    },
  ];

  return {
    filterStatus,
    companies,
    cities,
  };
};
export default useFilterOptionDriverNew;
