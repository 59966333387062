import { useTranslation } from 'react-i18next';

export default function ActionButtons(props) {
  const {
    handleOpenModal,
    changeStatusToAccept,
    changeStatusToReject,
    data: selectedRowData,
  } = props;

  const { t } = useTranslation();

  return (
    <div style={styles.container}>
      <button
        style={styles.acceptBtn}
        onClick={() => {
          handleOpenModal(selectedRowData);
        }}
      >
        {t('driver_registration_order.detail')}
      </button>
      <button
        style={styles.showDetailBtn}
        onClick={() => {
          changeStatusToAccept(selectedRowData);
        }}
      >
        {t('driver_registration_order.accept')}
      </button>
      <button
        style={styles.rejectBtn}
        onClick={() => {
          changeStatusToReject(selectedRowData);
        }}
      >
        {t('driver_registration_order.reject')}
      </button>
    </div>
  );
}
const styles = {
  container: {
    padding: 20,
    backgroundColor: '#FCF5F2',
  },
  acceptBtn: {
    backgroundColor: '#E0EFF7',
    padding: 8,
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 10,
    color: '#342B40',
    fontSize: 12,
    borderRadius: 5,
    cursor: 'pointer',
    border: '1px solid #E0EFF7',
  },

  rejectBtn: {
    backgroundColor: '#C32E52',
    border: '1px solid #C32E52',
    padding: 8,
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 10,
    color: '#fff',
    fontSize: 12,
    borderRadius: 5,
    cursor: 'pointer',
  },

  showDetailBtn: {
    backgroundColor: '#9AF7C0',
    border: '1px solid #9AF7C0',
    padding: 8,
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 10,
    color: '#342B40',
    fontSize: 12,
    borderRadius: 5,
    cursor: 'pointer',
  },
};
