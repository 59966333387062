import { Slide, toast } from 'react-toastify';

export const buildQueryString = function (object) {
  if (Object.prototype.toString.call(object) !== '[object Object]') return '';
  let args = [];
  for (const key2 in object) {
    destructure(key2, object[key2]);
  }
  return args.join('&');
  function destructure(key2, value1) {
    if (Array.isArray(value1)) {
      for (let i = 0; i < value1.length; i++) {
        destructure(key2 + '[' + i + ']', value1[i]);
      }
    } else if (Object.prototype.toString.call(value1) === '[object Object]') {
      for (let i in value1) {
        destructure(key2 + '[' + i + ']', value1[i]);
      }
    } else
      args.push(
        encodeURIComponent(key2) +
          (value1 != null && value1 !== '' ? '=' + encodeURIComponent(value1) : '')
      );
  }
};

export const parseQueryString = (string) => {
  if (string === '' || string == null) return {};
  if (string.charAt(0) === '?') string = string.slice(1);
  let entries = string.split('&'),
    counters = {},
    data0 = {};
  for (let i = 0; i < entries.length; i++) {
    let entry = entries[i].split('=');
    let key5 = decodeURIComponent(entry[0]);
    let value2 = entry.length === 2 ? decodeURIComponent(entry[1]) : '';
    if (value2 === 'true') value2 = true;
    else if (value2 === 'false') value2 = false;
    let levels = key5.split(/\]\[?|\[/);
    let cursor = data0;
    if (key5.indexOf('[') > -1) levels.pop();
    for (let j0 = 0; j0 < levels.length; j0++) {
      let level = levels[j0],
        nextLevel = levels[j0 + 1];
      let isNumber = nextLevel === '' || !isNaN(parseInt(nextLevel, 10));
      if (level === '') {
        let key5 = levels.slice(0, j0).join();
        if (counters[key5] == null) {
          counters[key5] = Array.isArray(cursor) ? cursor.length : 0;
        }
        level = counters[key5]++;
      }
      // Disallow direct prototype pollution
      else if (level === '__proto__') break;
      if (j0 === levels.length - 1) cursor[level] = value2;
      else {
        // Read own properties exclusively to disallow indirect
        // prototype pollution
        let desc = Object.getOwnPropertyDescriptor(cursor, level);
        if (desc != null) desc = desc.value;
        if (desc == null) cursor[level] = desc = isNumber ? [] : {};
        cursor = desc;
      }
    }
  }
  return data0;
};

export const handleMessage = (type, message, customOption) => {
  const defaultOption = {
    position: toast.POSITION.BOTTOM_LEFT,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    transition: Slide,
  };
  if (type === 'success') {
    return toast.success(message, customOption ? customOption : defaultOption);
  }
  return toast.error(message, customOption ? customOption : defaultOption);
};

export const getShapeCoords = (shape) => {
  const path = shape.getPath();
  const coords = [];
  for (let i = 0; i < path.length; i++) {
    coords.push({
      lat: path.getAt(i).lat(),
      lng: path.getAt(i).lng(),
    });
  }
  return coords;
};

export const findCenter = (markers) => {
  let lat = 0;
  let lng = 0;

  for (let i = 0; i < markers.length; ++i) {
    lat += markers[i].lat;
    lng += markers[i].lng;
  }

  lat /= markers.length;
  lng /= markers.length;

  return { lat: lat, lng: lng };
};
