import React from 'react';
import MapTrackingContextProvider from '../../context/MapTrackingContext';
import MapTracking from './MapTracking';

export default function MapTrackingProvider() {
  return (
    <MapTrackingContextProvider>
      <MapTracking />
    </MapTrackingContextProvider>
  );
}
