import axios from 'axios';
import { useEffect, useState } from 'react';
import { config } from '../../../../config/Config';

const { useTranslation } = require('react-i18next');
const { TRANSACTION_TYPE, CREATED_BY_TYPE } = require('../../constants');

export const useFilterOptions = () => {
  const { t } = useTranslation();
  const [filterCompany, setFilterCompany] = useState([]);

  const transactionType = [
    {
      value: TRANSACTION_TYPE.CASH_BACK,
      label: 'CASH BACK',
    },
    {
      value: TRANSACTION_TYPE.FEE,
      label: 'FEE',
    },
    // {
    //   value: TRANSACTION_TYPE.GIFT_ORDER,
    //   label: 'GIFT ORDER',
    // },
    {
      value: TRANSACTION_TYPE.HOLDING,
      label: 'HOLDING',
    },
    {
      value: TRANSACTION_TYPE.TOP_UP,
      label: 'TOP UP',
    },
  ];
  const createdByType = [
    {
      value: CREATED_BY_TYPE.ADMIN,
      label: 'ADMIN',
    },
    {
      value: CREATED_BY_TYPE.DRIVER,
      label: 'DRIVER',
    },
  ];
  const filterTopUpStatus = [
    {
      value: 'SUCCESS',
      label: 'SUCCESS',
    },
    {
      value: 'PENDING',
      label: 'PENDING',
    },
    {
      value: 'FAILURE',
      label: 'FAILURE',
    },
  ];
  const filterOrderStatus = [
    {
      value: 'cancelled',
      label: t('cancelled'),
    },
    {
      value: 'deliveried',
      label: t('deliveried'),
    },
    {
      value: 'assigned',
      label: t('assigned'),
    },
    {
      value: 'new',
      label: t('new'),
    },
  ];
  const getCompanyDelivery = async (filter) => {
    try {
      const { data } = await axios.get(config.API_URL.DELIVERY_COMPANY.LIST);
      if (data?.success) {
        const dataFilterDelivery = data?.data.map((item) => {
          return { value: item?._id, label: item?.name };
        });
        setFilterCompany(dataFilterDelivery);
      }
    } catch (error) {
      console.log('Axios error', error);
    }
  };
  useEffect(() => {
    getCompanyDelivery();
  }, []);
  return { transactionType, createdByType, filterOrderStatus, filterTopUpStatus, filterCompany };
};
