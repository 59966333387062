import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { config } from '../../../config/Config';

import { useTranslation } from 'react-i18next';
import CardAnalysis from '../CardAnalysis';

const OrderCount = () => {
  const { t } = useTranslation();
  const [data, setData] = useState({});

  const getAnalysis = async () => {
    const { data } = await axios.get(config.API_URL.ANALYSIS.ORDER, {
      headers: config.headers,
    });

    if (data?.status) {
      const resp = data?.data;
      setData(resp);
    }
  };

  useEffect(() => {
    getAnalysis();
  }, []);

  return (
    <>
      <div className="content">
        <CardAnalysis title={t('orders')} dataAnalysis={data?.total?.[0]?.totalRecords || 0} />
        <CardAnalysis
          title={t('execute_orders')}
          dataAnalysis={data?.order_execute?.[0]?.totalRecords || 0}
        />
        <CardAnalysis
          title={t('canceled_orders')}
          dataAnalysis={data?.order_cancel?.[0]?.totalRecords || 0}
        />
        <CardAnalysis
          title={t('delivering_orders')}
          dataAnalysis={data?.order_delivering?.[0]?.totalRecords || 0}
        />
        <CardAnalysis
          title={t('new_orders')}
          dataAnalysis={data?.order_new?.[0]?.totalRecords || 0}
        />
      </div>
    </>
  );
};

export default OrderCount;
