/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import DataTable from 'react-data-table-component';
import { cloneDeepWith, debounce, isArray } from 'lodash';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import CsvDownload from 'react-json-to-csv';
import '../../css/datatable.css';
import useSchema from './Schema.js';
import OrderActions from './OrderActions';
import { config } from '../../config/Config';
import { useTranslation } from 'react-i18next';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';

import moment from 'moment';
import { useTabs } from 'react-headless-tabs';
import { useHistory } from 'react-router-dom';

import CustomSelectReact from '../CustomSelectReact';
import { isObject } from 'lodash';
import produce from 'immer';

export const buildQueryString = function (object) {
  if (Object.prototype.toString.call(object) !== '[object Object]') return '';
  let args = [];
  for (const key2 in object) {
    destructure(key2, object[key2]);
  }
  return args.join('&');
  function destructure(key2, value1) {
    if (Array.isArray(value1)) {
      for (let i = 0; i < value1.length; i++) {
        destructure(key2 + '[' + i + ']', value1[i]);
      }
    } else if (Object.prototype.toString.call(value1) === '[object Object]') {
      for (let i in value1) {
        destructure(key2 + '[' + i + ']', value1[i]);
      }
    } else
      args.push(
        encodeURIComponent(key2) +
          (value1 != null && value1 !== '' ? '=' + encodeURIComponent(value1) : '')
      );
  }
};

export const parseQueryString = (string) => {
  if (string === '' || string == null) return {};
  if (string.charAt(0) === '?') string = string.slice(1);
  let entries = string.split('&'),
    counters = {},
    data0 = {};
  for (let i = 0; i < entries.length; i++) {
    let entry = entries[i].split('=');
    let key5 = decodeURIComponent(entry[0]);
    let value2 = entry.length === 2 ? decodeURIComponent(entry[1]) : '';
    if (value2 === 'true') value2 = true;
    else if (value2 === 'false') value2 = false;
    let levels = key5.split(/\]\[?|\[/);
    let cursor = data0;
    if (key5.indexOf('[') > -1) levels.pop();
    for (let j0 = 0; j0 < levels.length; j0++) {
      let level = levels[j0],
        nextLevel = levels[j0 + 1];
      let isNumber = nextLevel === '' || !isNaN(parseInt(nextLevel, 10));
      if (level === '') {
        let key5 = levels.slice(0, j0).join();
        if (counters[key5] == null) {
          counters[key5] = Array.isArray(cursor) ? cursor.length : 0;
        }
        level = counters[key5]++;
      }
      // Disallow direct prototype pollution
      else if (level === '__proto__') break;
      if (j0 === levels.length - 1) cursor[level] = value2;
      else {
        // Read own properties exclusively to disallow indirect
        // prototype pollution
        let desc = Object.getOwnPropertyDescriptor(cursor, level);
        if (desc != null) desc = desc.value;
        if (desc == null) cursor[level] = desc = isNumber ? [] : {};
        cursor = desc;
      }
    }
  }

  return data0;
};

const FilterComponent = ({
  filterText,
  onFilter,
  onClear,
  filters,
  placeholder,
  handleStatusFilter,
  handleDateFilter,
  filterStatus,
  filterPayment,
  handlerFilters,
  selectHandler,
  filterExpress,
  filterCompany,
  filterDelivery,
  filterIsDiscount,
  t,
  isLoading,
}) => (
  <div className="search-order flex flex-col mb-10 rtl">
    <div className=" py-5 flex items-center gap-4 flex-wrap">
      <div className="flex">
        <input
          placeholder={t('customer')}
          className="filter-input"
          name="customer_name"
          value={filters.customer_name}
          onChange={handlerFilters}
        />
      </div>
      <div className="flex">
        <input
          placeholder={t('order_number')}
          className="filter-input"
          name="order_number"
          value={filters.order_number}
          onChange={handlerFilters}
        />
      </div>
      <div className="flex">
        <input
          placeholder={t('restaurant')}
          className="filter-input"
          name="restaurant"
          value={filters.restaurant}
          onChange={handlerFilters}
        />
      </div>
      <div className="flex">
        <input
          placeholder={t('phone')}
          className="filter-input"
          name="phone"
          value={filters.phone}
          onChange={handlerFilters}
        />
      </div>
      <div className="flex">
        <input
          placeholder={t('driver_name')}
          className="filter-input o"
          name="driver_name"
          value={filters.driver_name}
          onChange={handlerFilters}
        />
      </div>
      <div className="flex">
        <CustomSelectReact
          isDisabled={isLoading}
          placeholder={t('payment')}
          className="filter-select min-w-[200px]"
          options={filterPayment}
          value={filters.payment}
          onChange={(e) => selectHandler(e, 'payment')}
          isMulti
        />
      </div>
      <div className="flex">
        <CustomSelectReact
          isDisabled={isLoading}
          placeholder={t('isFreeExpress')}
          className="filter-select min-w-[200px]"
          options={filterExpress}
          value={filters.isFreeExpress}
          onChange={(e) => selectHandler(e, 'isFreeExpress')}
          isMulti
        />
      </div>
      <div className="flex">
        <CustomSelectReact
          isDisabled={isLoading}
          placeholder={t('is_show_discount')}
          className="filter-select min-w-[200px]"
          options={filterIsDiscount}
          value={filters.isDiscount}
          onChange={(e) => selectHandler(e, 'isDiscount')}
        />
      </div>
      <div className="flex">
        <CustomSelectReact
          isDisabled={isLoading}
          placeholder={t('status')}
          className="filter-select min-w-[200px]"
          options={filterStatus}
          value={filters.status}
          onChange={(e) => selectHandler(e, 'status')}
          isMulti
        />
      </div>
      <div className="flex">
        <CustomSelectReact
          isDisabled={isLoading}
          placeholder={t('company')}
          className="filter-select min-w-[200px]"
          options={filterCompany}
          value={filters.from_company}
          onChange={(e) => selectHandler(e, 'from_company')}
          isMulti
        />
      </div>
      <div className="flex">
        <CustomSelectReact
          isDisabled={isLoading}
          placeholder={t('delivery_company_filter')}
          className="filter-select min-w-[200px]"
          options={filterDelivery}
          value={filters.driver_company}
          onChange={(e) => selectHandler(e, 'driver_company')}
          isMulti
        />
      </div>
      <div className="input-select-wrapper ltr">
        <DateTimeRangePicker
          className="input-select-input-wrapper !border-0 w-[200px]"
          disabled={isLoading}
          value={filters.date}
          onChange={handleDateFilter}
          disableClock
          format={'y-MM-dd'}
        />
      </div>
    </div>
  </div>
);

const Orders = ({ apiVersion, loadingConfig, isSuperAdmin }) => {
  const tableHeight = window.innerHeight - 400;
  const [selectedTab, setSelectedTab] = useTabs(['order', 'fake_order']);
  const history = useHistory();

  const schema = useSchema(selectedTab === 'fake_order' ? true : false);
  const { t } = useTranslation();
  const filterStatus = [
    {
      value: 'cancelled',
      label: t('cancelled'),
    },
    {
      value: 'deliveried',
      label: t('deliveried'),
    },
    {
      value: 'assigned',
      label: t('assigned'),
    },
    {
      value: 'new',
      label: t('new'),
    },
  ];

  const filterPayment = [
    {
      value: 'online',
      label: t('online'),
    },
    {
      value: 'Cash',
      label: t('cash'),
    },
    {
      value: 'Point',
      label: t('point'),
    },
  ];
  const filterExpress = [
    {
      value: 'true',
      label: t('yes'),
    },
    {
      value: 'false',
      label: t('no'),
    },
  ];

  const filterIsDiscount = [
    {
      value: 'true',
      label: t('yes'),
    },
    {
      value: 'false',
      label: t('no'),
    },
    {
      value: '',
      label: t('all'),
    },
  ];

  const filterCompany = [
    {
      value: 'maeda',
      label: t('Maeda'),
    },
    {
      value: 'shukah',
      label: t('Shukah'),
    },
  ];

  const { search } = useLocation();
  const queryString = parseQueryString(search);

  const [isLoading, setIsLoading] = useState(false);
  const [filterDelivery, setFilterDelivery] = useState([]);

  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [data, setData] = useState([]);
  const [reasonsList, setReasonsList] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [firstTime, setFirstTime] = useState(true);
  const [total, setTotal] = useState(0);
  const [selected, setSelected] = useState([]);

  const [limit, setLimit] = useState(queryString.limit || 100);

  const [filters, setFilters] = useState({});
  const [isExport, setIsExport] = useState(false);

  const getOpsList = async (filter) => {
    const { data } = await axios.get(config.API_URL.DELIVERY_COMPANY.LIST);
    const dataFilterDelivery = data?.data.map((item) => {
      return { value: item?._id, label: item?.name };
    });

    setFilterDelivery(dataFilterDelivery);
  };

  useEffect(() => {
    getOpsList();
  }, []);

  const handleStatusFilter = (event) => {
    let isChecked = event.target.checked;
    const newFilter = {
      ...filters,
      [event.target.name]: { ...filters[event.target.name], [event.target.value]: isChecked },
    };
    setFilters(newFilter);
  };

  const handleDateFilter = (value) => {
    const newFilter = {
      ...filters,
      date: value,
    };
    setFilters(newFilter);
  };

  const selectHandler = (e, key) => {
    const newFilters = {
      ...filters,
      [key]: e,
    };
    setFilters(newFilters);
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.driver_id !== null,
      style: {
        backgroundColor: '#F8EECC',
      },
    },
    {
      when: (row) => row.isCanceled,
      style: {
        backgroundColor: '#F7DDDE',
      },
    },
    {
      when: (row) => row.isDelivered,
      style: {
        backgroundColor: '#D4E9D8',
      },
    },
  ];

  async function getOrders(filters = {}) {
    const cloneDeepFilters = cloneDeepWith(filters);
    let driver_company = [];
    if (
      isArray(cloneDeepFilters?.filters?.driver_company) &&
      cloneDeepFilters?.filters?.driver_company.length > 0
    ) {
      cloneDeepFilters?.filters?.driver_company.map((item) => driver_company.push(item.value));
      cloneDeepFilters.filters.driver_company = driver_company;
    }

    if (isObject(cloneDeepFilters?.filters?.isDiscount)) {
      cloneDeepFilters.filters.isDiscount = filters.filters.isDiscount.value;
    }

    const filter = parseQueryString(window.location.search);
    const { tab } = filter;

    try {
      await axios
        .get(
          `${
            tab !== 'fake_order'
              ? config.API_URL.ORDERS.GET_ORDERS_PAGE
              : config.API_URL.ORDERS.GET_ORDERS_FAKE
          }`,
          {
            params: {
              ...cloneDeepFilters,
            },

            headers: config.headers,
          }
        )
        .then(async (response) => {
          setData(response.data.data.results);
          if (response.data.data.results.length > 0) {
            setTotal(response.data.data.total);
          }
        });

      return;
    } catch (e) {
      console.log('Axios error: ', e);
    } finally {
      setIsLoading(false);
    }
  }

  async function getReason() {
    try {
      const reasons = await axios.get(`${config.API_URL.CITC_LOOKUP.REASONS}`);
      setReasonsList(reasons?.data || []);
      return;
    } catch (e) {
      console.log('Axios error: ', e);
    }
  }

  const handler = useCallback(
    debounce((filters) => getOrders(filters), 500),
    [selectedTab]
  );

  const handlePageChange = (nextPage) => {
    if (isLoading) return false;
    if (Number(nextPage) === Number(currentPage)) return false;
    const newFilters = {
      keyword: filterText,
      limit: limit,
      page: nextPage,
      filters,
      tab: selectedTab,
    };

    const newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      `?${buildQueryString({ ...newFilters })}`;
    window.history.pushState({ path: newurl }, '', newurl);
    setCurrentPage(nextPage);
    handler(newFilters, limit);
  };

  const unAssignOrder = (id_number) => {
    const index = data.findIndex((i) => i._id === id_number);
    const toggle = produce(data, (draft) => {
      draft[index].driver_id = null;
      delete draft[index].driver;
    });
    setData(toggle);
  };

  useEffect(async () => {
    if (isLoading) return false;
    await setIsLoading(true);

    if (firstTime) {
      const newFilters = parseQueryString(window.location.search);
      const { filters, limit = 100, page = 1, keyword, tab } = newFilters;
      setSelectedTab(tab);

      setFilters({
        ...filters,
      });
      setFirstTime(false);
      setLimit(limit || 100);
      setFilterText(keyword);
      setCurrentPage(page || 1);

      const newFilter = {
        limit: limit,
        page: currentPage,
        filters,
      };
      handler(newFilter);
    } else {
      const newFilters = {
        keyword: filterText,
        limit: limit,
        page: currentPage,
        filters,
        tab: selectedTab,
      };
      const newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        `?${buildQueryString({ ...newFilters })}`;

      window.history.pushState({ path: newurl }, '', newurl);
      handler(newFilters);
    }
  }, [handler, filterText, limit, filters, selectedTab]);

  useEffect(() => {
    getReason();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlerFilters = (e) => {
    const newFilter = {
      ...filters,
      [e.target.name]: e.target.value,
    };
    setFilters(newFilter);
  };

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
        filters={filters}
        placeholder={`${t('search')} ...`}
        handleStatusFilter={handleStatusFilter}
        handleDateFilter={handleDateFilter}
        filterStatus={filterStatus}
        filterPayment={filterPayment}
        filterCompany={filterCompany}
        handlerFilters={handlerFilters}
        selectHandler={selectHandler}
        filterExpress={filterExpress}
        filterDelivery={filterDelivery}
        filterIsDiscount={filterIsDiscount}
        t={t}
        isLoading={isLoading}
      />
    );
  }, [filterText, resetPaginationToggle, filters, filterDelivery, isLoading]);

  const refresh = () => {
    const newFilters = {
      keyword: filterText,
      limit: limit,
      page: currentPage,
      filters,
      tab: selectedTab,
    };
    getOrders(newFilters);
  };

  const exportSTCExcel = () => {
    setIsExport(true);
    try {
      axios
        .post(
          `${config.API_URL.ORDERS.EXPORT_STC_EXCEL}?${buildQueryString({
            filters: filters,
          })}`,
          {},
          {
            headers: {
              ...config.headers,
              'Content-Disposition': 'attachment; filename=template.xlsx',
              'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            responseType: 'arraybuffer',
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `order-STC-${moment().locale('en').format('MM-DD-YYYY')}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
        });
    } catch (errors) {
    } finally {
      setIsExport(false);
    }
  };

  const exportExcel = () => {
    setIsExport(true);
    try {
      axios
        .post(
          `${config.API_URL.ORDERS.EXPORT_EXCEL}?${buildQueryString({
            filters: filters,
          })}`,
          {},
          {
            headers: {
              ...config.headers,
              'Content-Disposition': 'attachment; filename=template.xlsx',
              'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            responseType: 'arraybuffer',
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `order-${moment().locale('en').format('MM-DD-YYYY')}.xlsx`);
          document.body.appendChild(link);
          link.click();
        });
    } catch (errors) {
    } finally {
      setIsExport(false);
    }
  };

  const onChangeTab = (tab) => {
    setCurrentPage(1);
    setSelectedTab(tab);

    //
  };
  const toggleLive = () => {
    return (
      <div className="flex justify-between items-center">
        <div className="displayHolder">
          <div className="toggleLiveHolder toggleLiveHolderOrder">
            <Link to="/orders/live" className="live">
              <div>{t('live')}</div>
            </Link>
            <button
              className={`offline ${selectedTab === 'order' ? 'selectedDisplay' : ''}`}
              isActive={selectedTab === 'order'}
              onClick={() => onChangeTab('order')}
            >
              {t('hypothetical')}
            </button>
            <button
              className={`offline ${selectedTab === 'fake_order' ? 'selectedDisplay' : ''}`}
              isActive={selectedTab === 'fake_order'}
              onClick={() => onChangeTab('fake_order')}
            >
              {t('fake_order')}
            </button>
          </div>
          <span>{t('view_orders')}</span>
        </div>
        <div className="h-full flex items-center gap-x-4">
          <button className="refresh-button">
            <Link to="/list-discount-order">
              <div>{t('discount_order')}</div>
            </Link>
          </button>
          <button className="refresh-button" onClick={() => refresh()}>
            {t('refresh')}
          </button>
          <button className="refresh-button direction-r" onClick={exportSTCExcel}>
            {t('export_to_STC')}
          </button>
          <button className="refresh-button" onClick={exportExcel}>
            {t('export_excel_all_by_filter')}
          </button>
        </div>
      </div>
    );
  };
  const onRowClicked = (data) => {
    if (selectedTab !== 'fake_order') return;
    history.push({
      pathname: 'faker-order/drivers',
      search: `?faker-order-id=${data?.order_number}`,
    });
  };

  return (
    <div>
      {selected.length > 0 && (
        <CsvDownload
          filename="orders.csv"
          data={selected}
          style={{
            position: 'absolute',
            top: 90,
            zIndex: 2,
            right: 30,
            borderWidth: 0,
            backgroundColor: '#fff',
            padding: 10,
            borderRadius: 5,
            cursor: 'pointer',
          }}
        >
          {t('loading_data')}
        </CsvDownload>
      )}

      <DataTable
        noDataComponent={<div style={{ padding: '24px' }}>{t('orders_no_records')}</div>}
        title={toggleLive()}
        columns={schema}
        data={data}
        fixedHeader
        pagination
        paginationDefaultPage={+currentPage}
        contextMessage={{ singular: t('item'), plural: t('items'), message: t('selected') }}
        fixedHeaderScrollHeight={tableHeight.toString() + 'px'}
        paginationRowsPerPageOptions={[100, 200, 400, 600]}
        onChangeRowsPerPage={(currentRowsPerPage) => setLimit(currentRowsPerPage)}
        paginationPerPage={+limit}
        paginationResetDefaultPage={resetPaginationToggle}
        progressPending={isLoading}
        paginationTotalRows={total}
        progressComponent={<div className="wait">{t('please_wait')}</div>}
        paginationServer
        subHeader
        onChangePage={handlePageChange}
        conditionalRowStyles={conditionalRowStyles}
        paginationComponentOptions={{
          rowsPerPageText: t('total_record'),
          rangeSeparatorText: t('from'),
          noRowsPerPage: false,
          selectAllRowsItem: false,
        }}
        subHeaderComponent={subHeaderComponentMemo}
        selectableRows
        onSelectedRowsChange={(row) => setSelected(row.selectedRows)}
        selectableRowsVisibleOnly
        persistTableHead
        selectableRowsHighlight
        direction={'rtl'}
        expandableRows={selectedTab === 'fake_order' ? false : true}
        expandableRowsHideExpander
        expandOnRowClicked
        onRowClicked={onRowClicked}
        expandableRowsComponent={
          <OrderActions
            data={(row) => row}
            reasonsList={reasonsList}
            apiVersion={apiVersion}
            loadingConfig={loadingConfig}
            unAssignOrder={unAssignOrder}
          />
        }
        customStyles={{
          headCells: {
            style: {
              fontWeight: 'bold',
              fontSize: 12,
              background: '#F9F9F9',
              justifyContent: 'flex-start',

              padding: 0,
            },
          },
          rows: {
            style: {
              cursor: 'pointer',
              fontSize: 12,
            },
          },
        }}
      />
    </div>
  );
};

export default Orders;
