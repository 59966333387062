import React, { useState } from 'react';
import { useDataTable } from '../../../hooks/useDataTable';
import DataTableOrderDiscount from './DataTableOrderDiscount/DataTableOrderDiscount';
import Filters from './Filters/Filters';
import useNewOrderDiscount from './useNewOrderDiscount';

export default function NewOrderDiscount() {
  const [isLoading, setIsLoading] = useState(false);

  const {
    data = [],
    setData,
    limit,
    setLimit,
    currentPage,
    setCurrentPage,
    total,
    setTotal,
  } = useDataTable({ isLoading, setIsLoading });
  const {
    handlePageChange,
    handlerGetOrder,
    filtersRef,
    buildFilterOrderWithRef,
    handleLimitChange,
  } = useNewOrderDiscount({
    isLoading,
    setIsLoading,
    currentPage,
    setCurrentPage,
    setData,
    setTotal,
    limit,
    setLimit,
  });

  return (
    <div>
      <div className="px-[20px] ">
        <Filters
          isLoading={isLoading}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          handlerGetOrder={handlerGetOrder}
          ref={filtersRef}
          buildFilterOrderWithRef={buildFilterOrderWithRef}
        />
      </div>
      <DataTableOrderDiscount
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        data={data}
        limit={limit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        total={total}
        handlePageChange={handlePageChange}
        setLimit={handleLimitChange}
      />
    </div>
  );
}
