import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDataTable } from '../../hooks/useDataTable';
import useLayout from '../../hooks/useLayout';
import ActionDriversNew from './ActionDriversNew';
import DataTableDrivers from './DataTableDrivers/DataTableDrivers';
import ExpandableDriversNew from './ExpandableDriversNew/ExpandableDriversNew';
import Filters from './Filters/Filters';
import NotificationModal from './NotificationModal';
import SendNotiSelect from './SendNotiSelect';
import useLogicDriverNew from './useLogicDriverNew';

export default function DriversNew() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const {
    data = [],
    setData,
    limit,
    setLimit,
    currentPage,
    setCurrentPage,
    total,
    setTotal,
  } = useDataTable({ isLoading, setIsLoading });
  const {
    selectedTab,
    selectedItem,
    setSelectedItem,
    handlePageChange,
    handleGetDataTable,
    filtersRef,
    buildFilterOrderWithRef,
    handleLimitChange,
    refresh,
    unAssignOrder,
    handleToggleModal,
    toggleModal,
    modalVisible,
    sendNotificationListDriver,
    getFilterObject,
    toggleBtnSendNotiDriver,
    onRowExpandToggled,
    selectedId,
    toggleActivate,
    toggleReady,
  } = useLogicDriverNew({
    isLoading,
    setIsLoading,
    currentPage,
    setCurrentPage,
    setData,
    setTotal,
    limit,
    setLimit,
    data,
  });
  const { height, refHeight } = useLayout();

  return (
    <div>
      <SendNotiSelect
        selectedItem={selectedItem}
        toggleBtnSendNotiDriver={toggleBtnSendNotiDriver}
      />
      <div className="px-[20px]" ref={refHeight}>
        <div className="pt-4 pb-8">
          <ActionDriversNew
            refresh={refresh}
            handleToggleModal={handleToggleModal}
            getFilterObject={getFilterObject}
          />
        </div>

        <Filters
          isLoading={isLoading}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          handleGetDataTable={handleGetDataTable}
          ref={filtersRef}
          buildFilterOrderWithRef={buildFilterOrderWithRef}
        />
      </div>
      <DataTableDrivers
        height={height}
        selectedTab={selectedTab}
        setSelectedItem={setSelectedItem}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        data={data}
        unAssignOrder={unAssignOrder}
        limit={limit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        total={total}
        handlePageChange={handlePageChange}
        setLimit={handleLimitChange}
        onRowExpandToggled={onRowExpandToggled}
        selectedId={selectedId}
        expandableRowsComponent={
          <ExpandableDriversNew
            toggleActivate={toggleActivate}
            data={(row) => row}
            toggleReady={toggleReady}
          />
        }
      />
      <NotificationModal
        visible={modalVisible}
        toggleModal={toggleModal}
        getFilterObject={getFilterObject}
        sendNotificationListDriver={sendNotificationListDriver}
        driverSelect={selectedItem}
      />
    </div>
  );
}
