import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { parseQueryString } from '../helper/utils';

export const useDataTable = ({ filters, isLoading }) => {
  const { search } = useLocation();
  const queryString = parseQueryString(search);
  const [limit, setLimit] = useState(queryString.limit || 100);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);

  return {
    limit,
    setLimit,
    currentPage,
    setCurrentPage,
    total,
    setTotal,
    data,
    setData,
  };
};
