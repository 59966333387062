/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import '../css/navbar.css';
import { useTranslation } from 'react-i18next';
import { config } from '../config/Config';
import BurgerButton from './BurgerButton';
import { useWindowSize } from '../constants/customHooks';
import {
  DeliveryCompanyIcon,
  DriverIcon,
  LogIcon,
  OpsIcon,
  OrderIcon,
  OtpIcon,
  PricingIcon,
  RecoveryIcon,
  SettingIcon,
} from '../constants/Icon';

const driverList = [
  {
    id: 1,
    name: 'drivers',
    path: '/drivers',
    state: 'drivers',
    superAdminOnly: false,
  },
  {
    id: 2,
    name: 'driver_registration_order.driver_registration_order',
    path: '/driver-registration',
    state: 'driver-registration',
    superAdminOnly: false,
  },
  {
    id: 3,
    name: 'new_driver',
    path: '/drivers/new',
    state: 'new_driver',
    superAdminOnly: false,
  },
  {
    id: 5,
    name: 'transactions',
    path: '/transactions',
    state: 'transactions',
    superAdminOnly: false,
  },
  {
    id: 12,
    name: 'location_drivers',
    path: '/map-tracking',
    state: 'map-tracking',
    superAdminOnly: false,
  },
];

const logMenu = [
  {
    id: 6,
    name: 'shukah.title',
    path: '/shukah-logs',
    state: 'shukah-logs',
    superAdminOnly: false,
  },
  {
    id: 7,
    name: 'odoo.title',
    path: '/odoo-logs',
    state: 'odoo',
    superAdminOnly: false,
  },
  {
    id: 8,
    name: 'hyperlog.title',
    path: '/hyperpay-logs',
    state: 'hyperpay',
    superAdminOnly: false,
  },
  {
    id: 9,
    name: 'citc_logs',
    path: '/citc-logs',
    state: 'citc',
    superAdminOnly: false,
  },
  {
    id: 10,
    name: 'http-logs',
    path: '/http-logs',
    state: 'http-logs',
    superAdminOnly: false,
  },
  {
    id: 11,
    name: 'payfort-transaction-logs',
    path: '/payfort-transaction-logs',
    state: 'payfort-transaction-logs',
    superAdminOnly: false,
  },
  {
    id: 18,
    name: 'Recovery file',
    path: '/syncFile',
    state: 'syncFile',
    superAdminOnly: false,
    icon: <RecoveryIcon />,
  },
];

const menuList = [
  {
    id: 0,
    name: 'orders',
    path: '/orders',
    state: 'orders',
    superAdminOnly: false,
    icon: <OrderIcon />,
  },
  {
    name: 'driver_management',
    data: driverList,
    allowExpand: true,
    icon: <DriverIcon />,
  },
  {
    id: 4,
    name: 'delivery_company.title',
    path: '/delivery-company',
    state: 'delivery-company',
    superAdminOnly: false,
    icon: <DeliveryCompanyIcon />,
  },
  {
    name: 'developer_tools',
    data: logMenu,
    allowExpand: true,
    icon: <LogIcon />,
  },
  {
    id: 13,
    name: 'ops_management.title',
    path: '/ops-management',
    state: 'ops-management',
    superAdminOnly: true,
    icon: <OpsIcon />,
  },
  {
    id: 14,
    name: 'pricing.title',
    path: '/pricing',
    state: 'pricing',
    superAdminOnly: false,
    icon: <PricingIcon />,
  },
  {
    id: 15,
    name: 'otp_code',
    path: '/otp',
    state: 'otp',
    superAdminOnly: false,
    icon: <OtpIcon />,
  },
  // {
  //   id: 16,
  //   name: 'Rating',
  //   path: '/rating',
  //   state: 'rating',
  //   superAdminOnly: false,
  //   icon: <RattingIcon />,
  // },
  // {
  //   id: 17,
  //   name: 'Dashboard',
  //   path: '/dashboard',
  //   state: 'dashboard',
  //   superAdminOnly: false,
  //   icon: <AnalyticIcon />,
  // },
  {
    id: 19,
    name: 'settings',
    path: '/settings',
    state: 'settings',
    superAdminOnly: false,
    icon: <SettingIcon />,
  },
];

export default function Navbar({ isSuperAdmin }) {
  const location = useLocation();
  const { t } = useTranslation();
  const path = location.pathname;
  const [active, setActive] = useState(null);
  const [express, setExpress] = useState(true);
  const [showOpsManagement, setShowOpsManagement] = useState(false);

  const logout = () => {
    localStorage.removeItem('accessToken');
    window.location.href = '/login';
  };

  const getStatus = async () => {
    const resp = await axios.get(
      'https://maeda.com.sa/express_connections/v2/status.php?key=maeda_express'
    );

    if (resp?.data?.isClosed) {
      setExpress(false);
    } else {
      setExpress(true);
    }
  };

  useEffect(async () => {
    getStatus();
  }, []);

  useEffect(() => {
    setShowOpsManagement(isSuperAdmin);
  }, [isSuperAdmin]);

  const sendToSlack = async (status) => {
    const message = {
      text: `Express delivery in by app have ${status ? 'closed' : 'opened'}`,
      type: 'mrkdwn',
      attachments: [
        {
          color: '#E01E5A',
          fields: [
            {
              title: 'Environment',
              value: 'Production',
              short: true,
            },
            {
              title: 'Driver',
              value: 'ALL DRIVER',
              short: true,
            },
          ],
        },
      ],
    };
    await axios.post(
      `${config.API_URL.DRIVERS.SEND_MESSAGE}sendToSlack`,
      {
        message,
      },
      {
        headers: config.headers,
      }
    );
  };

  const changeStatus = async () => {
    if (express) {
      await axios.get('https://maeda.com.sa/express_connections/v2/close.php?key=maeda_express');
      await getStatus();
    } else {
      await axios.get('https://maeda.com.sa/express_connections/v2/open.php?key=maeda_express');
      await getStatus();
    }
    sendToSlack(express);
  };

  return (
    <div>
      <nav className="navbar w-full bg-red">
        <BurgerButton
          active={active}
          path={path}
          setActive={setActive}
          t={t}
          showOpsManagement={showOpsManagement}
          logout={logout}
          menuList={menuList}
        />
        {showOpsManagement && (
          <div className={`activation ${!express && 'disableExpress'}`} onClick={changeStatus}>
            {express ? t('active') : t('inactive')}
          </div>
        )}
      </nav>
    </div>
  );
}
