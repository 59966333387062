import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import CsvDownload from 'react-json-to-csv';
import { useLocation, useParams } from 'react-router-dom';
import { config } from '../../../config/Config';
import { FORMAT_DATE, parseDateWithGmt } from '../../../helper/date/parseDateGmt';
import { buildQueryString, parseQueryString } from '../../../helper/utils';
import { useDate } from '../../../hooks/useDate';
import CustomSelectReact from '../../CustomSelectReact';
import OrderActions from '../../Orders/OrderActions';
import { DETAIL_TABS } from '../constants';
import LoadingButton from '../../../commonComponents/LoadingButton/LoadingButton';
import {
  CompareGmtAndCalcTimestamp,
  GetTimezone,
} from '../../../helper/date/getLocalGmtWithTimezone';
import { createDate } from '../../../helper/date/createDateWithTimezone';
import useEventEnter from '../../../hooks/useEventEnter';
import { isEmpty, uniqueId } from 'lodash';

const Order = ({ apiVersion, loadingConfig, companyName }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { search } = useLocation();
  const queryString = parseQueryString(search);

  const [orderList, setOrderList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(100);
  const [selected, setSelected] = useState([]);
  const [reasonsList, setReasonsList] = useState([]);
  const [filterText, setFilterText] = useState('');

  const [filters, setFilters] = useState({});
  const [oldFilters, setOldFilters] = useState({});

  const [parsingQuery, setParsingQuery] = useState(true);
  const [loading, setLoading] = useState(true);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const { currentTimezone } = useDate();

  const tableHeight = window.innerHeight - 445;

  const [isExportExcel, setIsExportExcel] = useState(false);
  const [date, setDate] = useState({ startDate: '', endDate: '', value: [] });
  const [newRender, setNewRender] = useState(uniqueId());

  const status = {
    success: t('successful'),
    canceled: t('canceled_orders'),
    waiting: t('waiting'),
    ongoing: t('ongoing'),
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setNewRender(uniqueId())
  };

  const onSetFilters = (newFilter) => {
    setCurrentPage(1);
    setFilters(newFilter);
  };

  useEffect(() => {
    if (queryString.page) {
      setCurrentPage(+queryString.page);
    }
    if (queryString.filters) {
      const filter = { ...queryString.filters };
      if (filter.fromDate) {
        filter.fromDate = +filter.fromDate;
      }
      if (filter.toDate) {
        filter.toDate = +filter.toDate;
      }
      setFilters(filter);
      setOldFilters({ filters: filter });
    }
    if (queryString.limit > 0) {
      setLimit(+queryString.limit);
    }
    setParsingQuery(false);
    getReason();
  }, []);

  const getOrders = async (_filter = {}) => {
    try {
      const newFilters = {
        filters: {
          ...filters,
          fromDate: filters?.fromDate
            ? new Date(createDate(+filters?.fromDate, currentTimezone)).getTime()
            : '',
          toDate: filters?.toDate
            ? new Date(createDate(+filters?.toDate, currentTimezone)).getTime()
            : '',
          date: date.value || '',
        },
        sort: -1,
        limit: limit,
        page: currentPage,
      };
      const filter = isEmpty(_filter) ? newFilters : _filter;
      setLoading(true);
      const result = await axios.get(`${config.API_URL.ORDERS.COMPANY}${id}`, {
        params: {
          ...filter,
        },
        headers: config.headers,
      });

      const { status } = result;
      const { results, total } = result.data.data;

      if (status === 200) {
        setTotal(total);
        setOrderList(results);
      }
      setLoading(false);
    } catch (error) {
      console.log(' [getOrders] error->', error);
    }
  };

  useEffect(() => {
    if (!parsingQuery) {
      const { settingGmt, localGmt } =  GetTimezone();

       const bodyRequest = {
        filters: {
          ...filters,
          fromDate: date.fromDate
            ? CompareGmtAndCalcTimestamp(
                settingGmt,
                localGmt,
                new Date(createDate(+date?.fromDate, currentTimezone)).getTime()
              )
            : null,
          toDate: date.toDate
            ? CompareGmtAndCalcTimestamp(
                settingGmt,
                localGmt,
                new Date(createDate(+date?.toDate, currentTimezone)).getTime()
              )
            : null,
          date: isEmpty(date.value) ? '' : date.value,
        },
        sort: -1,
        limit: limit,
        page: currentPage,
        tab: DETAIL_TABS.ORDER,
      };

      const newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        `?${buildQueryString({ ...bodyRequest })}`;

      window.history.pushState({ path: newurl }, '', newurl);
      getOrders(bodyRequest);
    }
  }, [limit, parsingQuery, newRender]);
  
  async function getReason() {
    try {
      const reasons = await axios.get(`${config.API_URL.CITC_LOOKUP.REASONS}`);
      setReasonsList(reasons?.data || []);
      return;
    } catch (e) {
      console.log('Axios error: ', e);
    }
  }

  const payments = {
    cash: {
      title: t('cash'),
    },
    card: {
      title: t('card'),
    },
    point: {
      title: t('table_point'),
    },
  };

  const filterStatus = [
    {
      value: 'cancelled',
      label: t('cancelled'),
    },
    {
      value: 'deliveried',
      label: t('deliveried'),
    },
    {
      value: 'assigned',
      label: t('assigned'),
    },
    {
      value: 'new',
      label: t('new'),
    },
  ];

  const filterPayment = [
    {
      value: 'Card',
      label: t('card'),
    },
    {
      value: 'Cash',
      label: t('cash'),
    },
    {
      value: 'Point',
      label: t('point'),
    },
  ];

  const filterExpress = [
    {
      value: 'true',
      label: t('yes'),
    },
    {
      value: 'false',
      label: t('no'),
    },
  ];

  const filterCompany = [
    {
      value: 'maeda',
      label: t('Maeda'),
    },
    {
      value: 'shukah',
      label: t('Shukah'),
    },
  ];

  const schema = useMemo(
    () => [
      {
        name: t('order'),
        selector: 'order_number',
        sortable: true,
        style: {
          padding: 0,
        },
      },
      {
        name: t('delegate'),
        selector: (row) =>
          `${row.driver_name !== null ? row.driver_name : t('waiting_for_acceptance')}`,
        sortable: true,
        width: '200px',
        style: {
          padding: 0,
        },
      },
      {
        name: t('company'),
        width: '130px',
        selector: 'from_company',
        sortable: true,
        wrap: true,
        style: {
          direction: 'ltr',
          justifyContent: 'flex-end',
          padding: 0,
        },
      },
      {
        name: t('restaurant'),
        selector: 'restaurant_name',
        sortable: true,
        wrap: true,
        width: '200px',
        style: {
          padding: 0,
        },
        cell: (row) => <span title={row.restaurant_city}>{row.restaurant_name}</span>,
      },
      {
        name: t('customer'),
        selector: 'customer_name',
        sortable: true,
        wrap: true,
        width: '200px',
        style: {
          padding: 0,
        },
        cell: (row) => <span title={row.customer_city}>{row.customer_name}</span>,
      },
      {
        name: t('phone_number'),
        selector: 'customer_phone',
        sortable: true,
        wrap: true,
        width: '130px',
        style: {
          padding: 0,
          direction: 'ltr',
          justifyContent: 'flex-end',
        },
      },
      {
        name: t('ordered_at'),
        // selector: (row) => `${moment(row.created_at).calendar('LL')}`,
        selector: (row) =>
          parseDateWithGmt({
            date: row.created_at,
            timezone: currentTimezone?.timezone,
          }),

        sortable: true,
        wrap: true,
        minWidth: '200px',
        style: {
          padding: 0,
        },
      },
      {
        name: t('order_time'),
        selector: (row) =>
          parseDateWithGmt({
            date: row.created_at,
            timezone: currentTimezone?.timezone,
            format: FORMAT_DATE.hms,
          }),
        sortable: true,
        wrap: true,
        minWidth: '150px',
        style: {
          direction: 'ltr',
          justifyContent: 'flex-end',
          padding: 0,
        },
      },
      {
        name: t('acceptance_time'),
        minWidth: '100px',
        selector: (row) =>
          parseDateWithGmt({
            date: row.assigned_at,
            timezone: currentTimezone?.timezone,
            format: FORMAT_DATE.hms,
          }),
        sortable: true,
        wrap: true,
        style: {
          direction: 'ltr',
          justifyContent: 'flex-end',
          padding: 0,
        },
      },
      {
        name: t('finish_time'),
        minWidth: '150px',
        selector: (row) =>
          parseDateWithGmt({
            date: row.delivery_time,
            timezone: currentTimezone?.timezone,
            format: FORMAT_DATE.hms,
          }),
        sortable: true,
        wrap: true,
        style: {
          direction: 'ltr',
          justifyContent: 'flex-end',
          padding: 0,
        },
      },
      {
        name: t('payment_method'),
        width: '130px',
        selector: (row) => {
          return `${
            row.payment_method === 'Cash'
              ? payments.cash.title
              : row.payment_method === 'Card' || row.payment_method === 'online'
              ? payments.card.title
              : row.payment_method === 'Point' && payments.point.title
          }`;
        },
        sortable: true,
        style: {
          padding: 0,
        },
      },
      {
        name: t('delivery_value'),
        selector: 'delivery_cost',
        sortable: true,
        width: '130px',
        style: {
          padding: 0,
        },
      },
      {
        name: t('free'),
        selector: (row) => `${row.isFreeExpress === true ? t('yes') : t('no')}`,
        sortable: true,
        width: '50px',
        style: {
          padding: 0,
        },
      },
      {
        name: t('status'),
        selector: (row) =>
          `${
            row.isDelivered
              ? status.success
              : row.isCanceled
              ? status.canceled
              : row.driver_name
              ? status.ongoing
              : status.waiting
          }`,
        sortable: true,
        width: '180px',
        style: {
          padding: 0,
        },
      },
    ],
    [orderList]
  );

  const handleGetDataByFilter = async () => {
    if (!parsingQuery) {
      setCurrentPage(1);
      const { settingGmt, localGmt } = await GetTimezone();

      const bodyRequest = {
        filters: {
          ...filters,
          fromDate: date.fromDate
            ? CompareGmtAndCalcTimestamp(
                settingGmt,
                localGmt,
                new Date(createDate(+date?.fromDate, currentTimezone)).getTime()
              )
            : null,
          toDate: date.toDate
            ? CompareGmtAndCalcTimestamp(
                settingGmt,
                localGmt,
                new Date(createDate(+date?.toDate, currentTimezone)).getTime()
              )
            : null,
          date: isEmpty(date.value) ? '' : date.value,
        },
        sort: -1,
        limit: limit,
        page: currentPage,
        tab: DETAIL_TABS.ORDER,
      };

      const newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        `?${buildQueryString({ ...bodyRequest })}`;

      window.history.pushState({ path: newurl }, '', newurl);

      onSetFilters(bodyRequest.filters);
      setOldFilters(bodyRequest);
      getOrders(bodyRequest);
    }
  };
  useEventEnter(handleGetDataByFilter);

  const refresh = () => {
    const newFilters = {
      keyword: filterText,
      limit: limit,
      page: currentPage,
      filters,
    };
    getOrders(newFilters);
  };

  const handleExportExcel = async () => {
    try {
      const newFilters = {
        filters: {
          ...oldFilters?.filters,
          date: filters.date,
        },
        sort: -1,
        limit: limit,
        page: currentPage,
      };

      setIsExportExcel(true);
      const result = await axios.post(
        `${config.API_URL.DELIVERY_COMPANY.LIST}${id}/orders/excel`,
        {},
        {
          params: newFilters,
          headers: {
            ...config.headers,
            'Content-Disposition': 'attachment; filename=template.xlsx',
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
          responseType: 'arraybuffer',
        }
      );
      if (result?.status === 200) {
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `${companyName}-orders-${moment().locale('en').format('MM-DD-YYYY')}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      }
      console.log('[handleExportExcel]newFilters', newFilters);

      setIsExportExcel(false);
    } catch (error) {
      console.log('error:', error);
      setIsExportExcel(false);
    }
  };

  const toggleLive = () => {
    return (
      <div className="h-full flex items-center flex-row-reverse">
        <button className="refresh-button" onClick={() => refresh()}>
          {t('refresh')}
        </button>
        <button
          type="button"
          disabled={isExportExcel}
          onClick={handleExportExcel}
          className={`refresh-button  ${
            isExportExcel ? 'opacity-40 border-red-200' : 'opacity-100 '
          } `}
        >
          {t('export_excel_all_by_filter')}
        </button>
      </div>
    );
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.driver_id !== null,
      style: {
        backgroundColor: '#F8EECC',
      },
    },
    {
      when: (row) => row.isCanceled,
      style: {
        backgroundColor: '#F7DDDE',
      },
    },
    {
      when: (row) => row.isDelivered,
      style: {
        backgroundColor: '#D4E9D8',
      },
    },
  ];

  const handlerFilters = (e) => {
    const newFilter = {
      ...filters,
      [e.target.name]: e.target.value,
    };
    onSetFilters(newFilter);
  };

  const handleStatusFilter = (event) => {
    let isChecked = event.target.checked;
    const newFilter = {
      ...filters,
      [event.target.name]: { ...filters[event.target.name], [event.target.value]: isChecked },
    };
    onSetFilters(newFilter);
  };

  const handleDateFilter = (value) => {
    setDate({
      startDate: value?.[0] || '',
      endDate: value?.[1] || '',
      value: value || '',
    });
  };

  const selectHandler = (e, key) => {
    const newFilters = {
      ...filters,
      [key]: e,
    };
    onSetFilters(newFilters);
  };

  const contextActions = useMemo(() => {
    if (selected.length === 0) return null;
    return (
      <CsvDownload
        filename="orders.csv"
        data={selected}
        style={{
          zIndex: 2,
          borderWidth: 0,
          backgroundColor: '#fff',
          padding: 10,
          borderRadius: 5,
          cursor: 'pointer',
        }}
      >
        {t('loading_data')}
      </CsvDownload>
    );
  }, [selected, orderList]);

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
        filters={filters}
        placeholder={`${t('search')} ...`}
        handleStatusFilter={handleStatusFilter}
        handleDateFilter={handleDateFilter}
        filterStatus={filterStatus}
        filterPayment={filterPayment}
        filterCompany={filterCompany}
        handlerFilters={handlerFilters}
        selectHandler={selectHandler}
        filterExpress={filterExpress}
        t={t}
        loading={loading}
        handleGetDataByFilter={handleGetDataByFilter}
        date={date}
      />
    );
  }, [filterText, filters, resetPaginationToggle, loading, date]);

  return (
    <div className="ltr">
      <DataTable
        noDataComponent={<div style={{ padding: '24px' }}>{t('orders_no_records')}</div>}
        title={toggleLive()}
        columns={schema}
        data={orderList}
        fixedHeader
        pagination
        paginationDefaultPage={+currentPage}
        contextActions={contextActions}
        contextMessage={{ singular: t('item'), plural: t('items'), message: t('selected') }}
        fixedHeaderScrollHeight={tableHeight.toString() + 'px'}
        paginationRowsPerPageOptions={[100, 200, 400, 600]}
        onChangeRowsPerPage={(currentRowsPerPage) => {
          setLimit(+currentRowsPerPage);
          setCurrentPage(1);
        }}
        paginationPerPage={+limit}
        progressPending={loading}
        paginationTotalRows={total}
        progressComponent={<div className="wait">{t('please_wait')}</div>}
        paginationServer
        onChangePage={handlePageChange}
        conditionalRowStyles={conditionalRowStyles}
        paginationComponentOptions={{
          rowsPerPageText: t('total_record'),
          rangeSeparatorText: t('from'),
          noRowsPerPage: false,
          selectAllRowsItem: false,
        }}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        selectableRows
        selectableRowSelected={(row) => row.isSelected}
        onSelectedRowsChange={(row) => setSelected(row.selectedRows)}
        selectableRowsVisibleOnly
        persistTableHead
        selectableRowsHighlight
        direction={'rtl'}
        expandableRows
        expandableRowsHideExpander
        expandOnRowClicked
        expandableRowsComponent={
          <OrderActions
            data={(row) => row}
            reasonsList={reasonsList}
            apiVersion={apiVersion}
            loadingConfig={loadingConfig}
          />
        }
        customStyles={{
          headCells: {
            style: {
              fontWeight: 'bold',
              fontSize: 12,
              background: '#F9F9F9',
              justifyContent: 'flex-start',

              padding: 0,
            },
          },
          rows: {
            style: {
              cursor: 'pointer',
              fontSize: 12,
            },
          },
        }}
      />
    </div>
  );
};

export default Order;

const FilterComponent = ({
  filterText,
  onFilter,
  onClear,
  filters,
  placeholder,
  handleStatusFilter,
  handleDateFilter,
  filterStatus,
  filterPayment,
  handlerFilters,
  selectHandler,
  filterExpress,
  filterCompany,
  t,
  loading,
  handleGetDataByFilter,
  date,
}) => (
  <div className="search-order flex flex-col mb-10 rtl">
    <div className="flex w-full gap-x-4">
      <div className="flex">
        <input
          placeholder={t('customer')}
          className="filter-input"
          name="customer_name"
          value={filters.customer_name}
          onChange={handlerFilters}
          disabled={loading}
        />
      </div>
      <div className="flex">
        <input
          placeholder={t('order_number')}
          className="filter-input"
          name="order_number"
          value={filters.order_number}
          onChange={handlerFilters}
          disabled={loading}
        />
      </div>
      <div className="flex">
        <input
          placeholder={t('restaurant')}
          className="filter-input"
          name="restaurant"
          value={filters.restaurant}
          onChange={handlerFilters}
          disabled={loading}
        />
      </div>
      <div className="flex">
        <input
          placeholder={t('phone')}
          className="filter-input"
          name="phone"
          value={filters.phone}
          onChange={handlerFilters}
          disabled={loading}
        />
      </div>
      <div className="flex">
        <input
          placeholder={t('driver_name')}
          className="filter-input"
          name="driver_name"
          value={filters.driver_name}
          onChange={handlerFilters}
          disabled={loading}
        />
      </div>
    </div>
    <div className="flex w-full items-center filter gap-x-4">
      <div className="flex">
        <CustomSelectReact
          placeholder={t('payment')}
          className="filter-select min-w-[200px]"
          options={filterPayment}
          value={filters.payment}
          onChange={(e) => selectHandler(e, 'payment')}
          isMulti
          isDisabled={loading}
        />
      </div>
      <div className="flex">
        <CustomSelectReact
          placeholder={t('isFreeExpress')}
          className="filter-select min-w-[200px]"
          options={filterExpress}
          value={filters.isFreeExpress}
          onChange={(e) => selectHandler(e, 'isFreeExpress')}
          isMulti
          isDisabled={loading}
        />
      </div>
      <div className="flex">
        <CustomSelectReact
          placeholder={t('status')}
          className="filter-select min-w-[200px]"
          options={filterStatus}
          value={filters.status}
          onChange={(e) => selectHandler(e, 'status')}
          isMulti
          isDisabled={loading}
        />
      </div>
      <div className="flex">
        <CustomSelectReact
          placeholder={t('company')}
          className="filter-select min-w-[200px]"
          options={filterCompany}
          value={filters.from_company}
          onChange={(e) => selectHandler(e, 'from_company')}
          isMulti
          isDisabled={loading}
        />
      </div>
      <div className="flex filter-select min-w-[200px] ltr">
        <DateTimeRangePicker
          value={date?.value?.length == 0 ? undefined : date?.value}
          onChange={handleDateFilter}
          disableClock
          format={'y-MM-dd'}
          disabled={loading}
        />
      </div>
    </div>
    <div className="relative w-full   mt-3 mb-6">
      <div className="absolute right-0  ">
        <LoadingButton onClick={handleGetDataByFilter} label={'Apply Filter'} loading={loading} />
      </div>
    </div>
  </div>
);