import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';
import { useCallback } from 'react';
import { getProfile } from '../api/profile';
import { config, SETTING_KEY } from '../config/Config';
import { getSettingTimezone } from '../hooks/useDate';

export const DataContext = createContext();
const DataContextProvider = (props) => {
  const [data, setData] = useState([]);
  const [profileAdmin, setProfileAdmin] = useState(null);
  const [currentTimezone, setCurrentTimezone] = useState();
  const [isOpenFetchDataWhenZoom, setOpenFetchDataWhenZoom] = useState(false);
  const [isLoadingFetchDataWhenZoom, setIsLoadingFetchDataWhenZoom] = useState(false);

  const onGetProfileAdmin = async () => {
    const data = await getProfile();
    setProfileAdmin(data);
  };

  const onGetSettingTimeZone = async () => {
    const settingTimezone = await getSettingTimezone();

    setCurrentTimezone(settingTimezone);
  };
  useEffect(() => {
    onGetSettingTimeZone();
  }, []);

  useEffect(() => {
    onGetProfileAdmin();
  }, []);

  function storeData(i) {
    setData(i);
  }

  const getSettingMap = useCallback(async () => {
    const resp = await axios.get(config.API_URL.SETTING.GET, {
      params: {
        key: SETTING_KEY.TOGGLE_ZOOM_MAP,
      },
      headers: config.headers,
    });
    const { data } = resp;
    if (data) {
      setOpenFetchDataWhenZoom(data?.setting?.isActive);
    }
  }, []);

  const onToggleSyncDataFilterMap = async () => {
    try {
      if (isLoadingFetchDataWhenZoom) return;
      setIsLoadingFetchDataWhenZoom(true);
      await axios.post(
        config.API_URL.SETTING.CREATE,
        {
          data: [
            {
              key: SETTING_KEY.TOGGLE_ZOOM_MAP,
              content: {
                isActive: !isOpenFetchDataWhenZoom,
              },
            },
          ],
        },
        {
          headers: config.headers,
        }
      );
      await getSettingMap();
      setIsLoadingFetchDataWhenZoom(false);
    } catch (error) {
      setIsLoadingFetchDataWhenZoom(false);
    } finally {
    }
  };

  useEffect(() => {
    getSettingMap();
  }, []);

  return (
    <DataContext.Provider
      value={{
        data,
        storeData,
        profileAdmin,
        currentTimezone,
        onGetSettingTimeZone,
        isOpenFetchDataWhenZoom,
        onToggleSyncDataFilterMap,
        isLoadingFetchDataWhenZoom,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};

export default DataContextProvider;
