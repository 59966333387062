import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDate } from '../../../hooks/useDate';
import { getDriverRegistrationOrderDetailAPI } from '../../../api/detailDriverRegistrationOrder/detailDriverRegistrationOrder.api';
import { IconClose, IconSpin } from '../../../constants/Icon';
import { FORMAT_DATE, parseDateWithGmt } from '../../../helper/date/parseDateGmt';
import { Dialog, Transition } from '@headlessui/react';

function DriverRegistrationDetailsModal({ isVisible, logId, handleCloseModal }) {
  const { t } = useTranslation();
  const { currentTimezone } = useDate();

  const [dataDetails, setDataDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getDriverRegistrationOrderDetail = useCallback(async () => {
    setIsLoading(true);

    try {
      if (!logId) return true;

      const { data: response } = await getDriverRegistrationOrderDetailAPI(logId);

      setDataDetails(response);
    } catch (error) {
      console.log('Error', error);
    } finally {
      setIsLoading(false);
    }
  }, [logId]);

  useEffect(() => {
    getDriverRegistrationOrderDetail();
  }, [getDriverRegistrationOrderDetail]);

  const renderFields = (fieldName, fieldData) => {
    if (fieldName)
      return (
        <>
          <p className="font-bold col-span-1">{t(fieldName)}</p>
          <p className="col-span-2">{fieldData ?? '-'}</p>
        </>
      );
  };

  const renderSelectedLogData = () => {
    if (isLoading) {
      return (
        <div className="inline-block w-full max-w-7xl h-[calc(100vh-200px)] overflow-x-hidden overflow-y-auto px-10 py-12 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
          <div className="flex items-center justify-center h-full w-full gap-x-4">
            <IconSpin />
            <p>{t('loading')}</p>
          </div>
        </div>
      );
    }

    return (
      <div className="inline-block w-full max-w-7xl h-full overflow-x-hidden overflow-y-hidden px-10 py-12 my-8 text-left align-middle transition-all duration-300 transform bg-white shadow-xl rounded-2xl">
        <div className="flex items-center justify-between gap-x-4">
          <div className="flex items-center gap-x-4">
            <span className="text-3xl font-bold">
              {t('driver_registration_order.driver_registration_order_details_id')}
            </span>
            <span className="text-3xl leading-6">{logId}</span>
          </div>
          <button type="button" onClick={() => handleCloseModal()}>
            <IconClose />
          </button>
        </div>

        <div className="my-10 pl-6 text-right flex flex-col gap-y-10 overflow-y-auto h-[calc(100vh-260px)] transition-all">
          <div className="grid grid-cols-3 gap-x-4 gap-y-3 mb-8">
            {renderFields('name', dataDetails?.name)}
            {renderFields('driver_registration_order.date_of_birth', dataDetails?.date_of_birth)}
            {renderFields('phone', dataDetails?.phone)}
            {renderFields('driver_registration_order.identity_type', dataDetails?.identity_type)}
            {renderFields('id_number', dataDetails?.id_number)}
            {renderFields('region', dataDetails?.region)}
            {renderFields('city', dataDetails?.city)}
            {renderFields('vehicle_sequence_number', dataDetails?.vehicle_sequence_number)}
            {renderFields(
              'ordered_at',
              parseDateWithGmt({
                date: dataDetails?.created_at,
                timezone: currentTimezone?.timezone,
                format: FORMAT_DATE.ymdhms,
              })
            )}
            {renderFields('status', dataDetails?.status)}
            {dataDetails?.id_accepted_driver &&
              renderFields('id_accepted_driver', dataDetails?.id_accepted_driver)}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Transition appear show={isVisible} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={handleCloseModal}>
        <div className="min-h-screen px-4 text-center rtl">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-[#00000065]" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            {renderSelectedLogData()}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
export default DriverRegistrationDetailsModal;
