import React from 'react';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';

export default function TableComponents({ ...rest }) {
  const { t } = useTranslation();

  return (
    <DataTable
      noDataComponent={<div style={{ padding: '24px' }}>{t('orders_no_records')}</div>}
      fixedHeader
      pagination
      paginationRowsPerPageOptions={[100, 200, 400, 600]}
      progressComponent={<div className="wait">{t('please_wait')}</div>}
      paginationServer
      subHeader
      paginationComponentOptions={{
        rowsPerPageText: t('total_record'),
        rangeSeparatorText: t('from'),
        noRowsPerPage: false,
        selectAllRowsItem: false,
      }}
      selectableRows
      selectableRowsVisibleOnly
      persistTableHead
      selectableRowsHighlight
      direction={'rtl'}
      expandableRowsHideExpander
      expandOnRowClicked
      customStyles={{
        headCells: {
          style: {
            fontWeight: 'bold',
            fontSize: 12,
            background: '#F9F9F9',
            justifyContent: 'flex-start',
            padding: 0,
          },
        },
        rows: {
          style: {
            cursor: 'pointer',
            fontSize: 12,
          },
        },
      }}
      {...rest}
    />
  );
}
