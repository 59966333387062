import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { config } from '../../config/Config';
import { Slide, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

toast.configure();
const NewPlan = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    price: Yup.number()
      .truncate()
      .positive(t('pricing.validate.type.price'))
      .required(t('pricing.validate.required.price')),
    gift: Yup.number().truncate().min(0, t('pricing.validate.type.gift')),
    planName: Yup.string().required(t('pricing.validate.required.planName')),
  });

  const formik = useFormik({
    initialValues: {
      planDescription: '',
      price: 0,
      gift: 0,
      planName: '',
      priority: 1,
      isRecommend: false,
    },
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validateOnChange: false,
  });

  const borderInputError = {
    border: '1px solid #E95F67',
  };

  const handleSubmit = async () => {
    setLoading(true);

    const finalizeData = {
      gift: formik.values.gift,
      title: formik.values.planName,
      description: formik.values.planDescription,
      price: formik.values.price,
      isRecommend: formik.values.isRecommend,
    };

    if (formik.values.isRecommend) {
      finalizeData.priority = +formik.values.priority;
    }

    try {
      console.log(finalizeData);
      const { data } = await axios.post(config.API_URL.PRICING.CREATE, finalizeData, {
        headers: config.headers,
      });
      if (data?.success) {
        handleMessage('success');
        window.history.back();
      } else {
        handleMessage('error', t('error'));
      }
      setLoading(false);
    } catch (error) {
      const errorList = error?.response?.data?.errors;
      if (errorList) {
        errorList.forEach((error) => handleMessage('error', error.msg));
      }
      setLoading(false);
    }
  };

  const handleMessage = (type = 'success', message = '') => {
    if (type === 'success') {
      return toast.success(t('pricing.register.success'), {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide,
      });
    }
    return toast.error(message, {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      transition: Slide,
    });
  };

  return (
    <div className="add-ops-board">
      <h3 className="title">{t('pricing.register.title')}</h3>
      <div className="ops-form">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit(e);
          }}
        >
          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <input
                id="planName"
                name="planName"
                type="text"
                style={formik.errors.planName && borderInputError}
                className="center rounded-md"
                onChange={formik.handleChange}
                value={formik.values.planName}
              />
              {formik.errors.planName && (
                <span className="mt-2 text-red rtl error-message">{formik.errors.planName}</span>
              )}
            </div>
            <span>{t('pricing.register.planName')}</span>
          </div>

          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <textarea
                id="planDescription"
                name="planDescription"
                cols="50"
                style={formik.errors.planDescription && borderInputError}
                className="center rounded-md"
                onChange={formik.handleChange}
                value={formik.values.planDescription}
              />
              {formik.errors.planDescription && (
                <span className="mt-2 text-red rtl error-message">
                  {formik.errors.planDescription}
                </span>
              )}
            </div>
            <span>{t('pricing.register.planDescription')}</span>
          </div>

          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <input
                id="price"
                name="price"
                type="number"
                style={formik.errors.price && borderInputError}
                className="center rounded-md"
                onChange={formik.handleChange}
                value={formik.values.price}
              />
              {formik.errors.price && (
                <span className="mt-2 text-red rtl error-message">{formik.errors.price}</span>
              )}
            </div>
            <span>{t('pricing.register.price')}</span>
          </div>

          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <input
                id="gift"
                name="gift"
                type="number"
                style={formik.errors.gift && borderInputError}
                className="center rounded-md"
                onChange={formik.handleChange}
                value={formik.values.gift}
              />
              {formik.errors.gift && (
                <span className="mt-2 text-red rtl error-message">{formik.errors.gift}</span>
              )}
            </div>
            <span>{t('pricing.register.gift')}</span>
          </div>
          <div className="row flex items-center gap-x-4 justify-between">
            <label className="switch">
              <input
                checked={formik.values.isRecommend}
                type="checkbox"
                id="isRecommend"
                name="isRecommend"
                onChange={formik.handleChange}
              />
              <span className="slider round"></span>
            </label>
            <span>{t('pricing.register.isRecommend')}</span>
          </div>
          {formik.values.isRecommend && (
            <div className="row flex items-center gap-x-4 justify-between">
              <select
                name="priority"
                id="priority"
                className="select-box"
                onChange={formik.handleChange}
                defaultValue={1}
              >
                <option value={1}>{t('pricing.priority.lowest')}</option>
                <option value={2}>{t('pricing.priority.normal')}</option>
                <option value={3}>{t('pricing.priority.highest')}</option>
              </select>
              <span>{t('pricing.register.priority')}</span>
            </div>
          )}
          <div className="">
            <button
              type="submit"
              className={`add-btn ${loading ? 'disabled' : ''}`}
              disabled={loading}
            >
              {t('pricing.register.submit')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewPlan;
