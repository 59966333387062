import React from 'react';
import { memo } from 'react';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import useSchema from './Schema';

function HttpLogsTable({
  logs,
  isLoaded,
  currentPage,
  selectedId,
  setSelectedId,
  curLimit,
  setCurLimit,
  setCurrentPage,
  total,
  handlePageChange,
  handleOpenModal,
}) {
  const schema = useSchema();
  const { t } = useTranslation();
  const tableHeight = window.innerHeight - 370;

  return (
    <DataTable
      noDataComponent={<div style={{ padding: '24px' }}>{t('orders_no_records')}</div>}
      // title={<RefreshData />}
      columns={schema}
      data={logs}
      fixedHeader
      contextMessage={{ singular: t('item'), plural: t('items'), message: t('selected') }}
      fixedHeaderScrollHeight={tableHeight.toString() + 'px'}
      progressPending={isLoaded}
      progressComponent={<div className="wait">{t('please_wait')}</div>}
      pagination
      paginationDefaultPage={+currentPage}
      onRowClicked={(row) => handleOpenModal(row?._id)}
      onSelectedRowsChange={(row) => setSelectedId(row.selectedRows)}
      paginationPerPage={Number(curLimit)}
      paginationRowsPerPageOptions={[100, 200, 400, 600]}
      onChangeRowsPerPage={(currentRowsPerPage) => {
        setCurLimit(currentRowsPerPage);
        setCurrentPage(1);
      }}
      paginationTotalRows={total}
      paginationServer
      onChangePage={handlePageChange}
      // subHeader
      conditionalRowStyles={conditionalRowStyles}
      paginationComponentOptions={{
        rowsPerPageText: t('total_record'),
        rangeSeparatorText: t('from'),
        noRowsPerPage: false,
        selectAllRowsItem: false,
      }}
      // subHeaderComponent={subHeaderComponentMemo}
      selectableRows
      selectableRowSelected={(row) => row.isSelected}
      selectableRowsVisibleOnly
      persistTableHead
      onRowExpandToggled={(toggleState, row) => [
        toggleState
          ? setSelectedId((p) => [...p, row._id])
          : setSelectedId(selectedId.filter((i) => i !== row._id)),
      ]}
      selectableRowsHighlight
      RowsHideExpander
      expandOnRowClicked
      direction={'rtl'}
      customStyles={{
        headCells: {
          style: {
            fontWeight: 'bold',
            fontSize: 12,
            background: '#F9F9F9',
            justifyContent: 'flex-start',
            padding: 0,
          },
        },
        rows: {
          style: {
            cursor: 'pointer',
            fontSize: 12,
          },
        },
        header: {
          style: {
            minHeight: 0,
          },
        },
      }}
    />
  );
}
export default memo(HttpLogsTable);

const conditionalRowStyles = [
  {
    when: (row) => Number(row.status) !== 200,
    style: {
      backgroundColor: '#F7DDDE',
    },
  },
  {
    when: (row) => Number(row.status) === 200,
    style: {
      backgroundColor: '#D4E9D8',
    },
  },
];
