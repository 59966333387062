/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { config, SETTING_KEY } from '../../config/Config';
toast.configure();

const ToggleHyperpay = memo(({ setHyperpay, hyperpay }) => {
  const { t } = useTranslation();

  const getSetting = async () => {
    const resp = await axios.get(config.API_URL.SETTING.GET, {
      params: {
        key: SETTING_KEY.HYPERPAY,
      },
      headers: config.headers,
    });
    const { data } = resp;
    const { setting } = data;
    setHyperpay({
      isEnable: setting.isEnable || false,
    });
  };

  useEffect(() => {
    getSetting();
  }, []);

  const handlerChange = (e) => {
    const newValue = {
      ...hyperpay,
      [e.target.name]: e.target.checked,
    };
    setHyperpay(newValue);
  };

  return (
    <>
      <div className="setting">
        <h3>{t('Enable Hyperpay')}:</h3>
        <div className="row flex items-center gap-x-4">
          <label className="switch">
            <input
              checked={hyperpay.isEnable}
              type="checkbox"
              name="isEnable"
              onChange={handlerChange}
            />
            <span className="slider round"></span>
          </label>
        </div>
      </div>
    </>
  );
});

export default ToggleHyperpay;
