import moment from 'moment';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { checkResultStatus } from '../../helper/regexHelper';

export default function useColumn({ logs }) {
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        name: t('id'),
        selector: '_id',
        sortable: true,
        minWidth: '200px',
      },
      //
      {
        name: t('transaction_id'),
        selector: (row) => row?.response?.merchantTransactionId,
        sortable: true,
        minWidth: '200px',
      },
      {
        name: t('amount'),
        selector: (row) => row?.response?.amount,
        sortable: true,
        minWidth: '150px',
      },
      //
      {
        name: t('plan_id'),
        selector: 'plan_id',
        sortable: true,
        minWidth: '200px',
      },
      {
        name: t('driver_id'),
        selector: 'driver_id',
        sortable: true,
        minWidth: '200px',
      },
      {
        name: t('driver_name'),
        selector: 'driver.name',
        sortable: true,
        minWidth: '200px',
      },
      {
        name: t('hyperlog.entity_id'),
        selector: 'entity_id',
        sortable: true,
        minWidth: '250px',
      },
      {
        name: t('status'),
        selector: (row) => checkResultStatus(row?.response?.result?.code),
        sortable: true,
      },
      {
        name: t('date'),
        selector: (row) =>
          `${row.created_at !== null ? moment(row.created_at).locale('ar').format('LL') : '...'}`,
        sortable: true,
        wrap: true,
        minWidth: '120px',
      },
      {
        name: t('date_time'),
        selector: (row) =>
          `${
            row.created_at !== null ? moment(row.created_at).locale('ar').format(' hh:mm A') : '...'
          }`,
        sortable: true,
        wrap: true,
        minWidth: '140px',
      },
    ],
    [logs]
  );
  return { columns };
}
