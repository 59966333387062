export const regexPattern = {
  success: {
    processed: {
      transactions: /^(000\.000\.|000\.100\.1|000\.[36])/,
      reviewed: /^(000\.400\.0[^3]|000\.400\.[0-1]{2}0)/,
    },
  },
  failure: {
    processed: {
      '3dSecureAndIntercard': /^(000\.400\.[1][0-9][1-9]|000\.400\.2)/,
      externalBankOrSimilarPayment: /^(800\.[17]00|800\.800\.[123])/,
      communicationErrors: /^(900\.[1234]00|000\.400\.030)/,
      systemErrors: /^(800\.[56]|999\.|600\.1|800\.800\.[84])/,
      asynchonousWorkflow: /^(100\.39[765])/,
      externalRiskSystems: /^(100\.400\.[0-3]|100\.38|100\.370\.100|100\.370\.11)/,
      addressValidation: /^(800\.400\.1)/,
      '3Dsecure': /^(800\.400\.2|100\.380\.4|100\.390)/,
      blacklistValidation: /^(100\.100\.701|800\.[32])/,
      riskValidation: /^(800\.1[123456]0)/,
      configurationValidation: /^(600\.[23]|500\.[12]|800\.121)/,
      registrationValidation: /^(100\.[13]50)/,
      jobValidation: /^(100\.250|100\.360)/,
      referenceValidation: /^(700\.[1345][05]0)/,
      formatValidation: /^(200\.[123]|100\.[53][07]|800\.900|100\.[69]00\.500)/,
      addressValidationSecond: /^(100\.800)/,
      contactValidation: /^(100\.[97]00)/,
      accountValidation: /^(100\.100|100.2[01])/,
      amountValidation: /^(100\.55)/,
      riskManagement: /^(100\.380\.[23]|100\.380\.101)/,
    },
  },
};

export const checkResult = (code) => {
  return (
    regexPattern.success.processed.transactions.test(code) ||
    regexPattern.success.processed.reviewed.test(code)
  );
};

export const checkRejectedTransaction = (code) => {
  let result = false;
  Object.keys(regexPattern.failure.processed).forEach((key) => {
    result = result || regexPattern.failure.processed[key].test(code);
  });
  return result;
};

export const STATUS_MAPPING = {
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
  PENDING: 'PENDING',
};

export const checkResultStatus = (code) => {
  let status = STATUS_MAPPING.PENDING;
  if (checkResult(code)) {
    status = STATUS_MAPPING.SUCCESS;
  } else if (checkRejectedTransaction(code)) {
    status = STATUS_MAPPING.FAILURE;
  }
  return status;
};
