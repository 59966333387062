/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { config, SETTING_KEY } from '../../config/Config';
toast.configure();

const TimeSetting = ({ timeSetting, setTimeSetting }) => {
  const { t } = useTranslation();

  const getSetting = async () => {
    const resp = await axios.get(config.API_URL.SETTING.GET, {
      params: {
        key: SETTING_KEY.TIME_TRACKING,
      },
      headers: config.headers,
    });
    const { data } = resp;
    const { setting } = data;
    setTimeSetting({
      time: setting.time || 0,
    });
  };

  useEffect(() => {
    getSetting();
  }, []);

  const handlerChange = (e) => {
    const newValue = {
      ...timeSetting,
      [e.target.name]: e.target.value,
    };
    setTimeSetting(newValue);
  };

  return (
    <div className="setting">
      <h3>{t('time_tracking')}</h3>
      <div className="row flex items-center gap-x-4">
        <label>{t('duration')}: </label>
        <input type="number" value={timeSetting.time} name="time" onChange={handlerChange} />
        <label className="pr-2">{t('hours')}</label>
      </div>
    </div>
  );
};
export default TimeSetting;
