// import driver from 'models/driver';
import moment from 'moment';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { renderAddedValue, renderGiftAmountValue } from '../../helper/Transaction/renderAddedValue';
import colBalance from './colBalance';
import { TRANSACTION_TYPE, TRANSACTION_VER } from './constants';

const useSchema = (version, transactionType, tax, newRenderColumns) => {
  const { t } = useTranslation();

  const renderTransactionType = (type) => {
    switch (type) {
      case TRANSACTION_TYPE.FEE:
        return (
          <span data-tag="allowRowEvents" className="text-red-600">
            {t('FEE')}
          </span>
        );
      case TRANSACTION_TYPE.CASH_BACK:
        return (
          <span data-tag="allowRowEvents" className="text-blue-600">
            {t('CASH BACK')}
          </span>
        );
      case TRANSACTION_TYPE.GIFT_ORDER:
        return (
          <span data-tag="allowRowEvents" className="text-pink-600">
            {t('GIFT ORDER')}
          </span>
        );
      case TRANSACTION_TYPE.HOLDING:
        return (
          <span data-tag="allowRowEvents" className="text-yellow-600">
            {t('HOLDING')}
          </span>
        );
      case TRANSACTION_TYPE.TOP_UP:
        return (
          <span data-tag="allowRowEvents" className="text-green-600">
            {t('TOP UP')}
          </span>
        );
      default:
        return '-';
    }
  };

  const renderPaymentMethod = (method) => {
    if (!method) return 'online';
    return method;
  };

  const columns = useMemo(() => {
    let columns;

    if (version === TRANSACTION_VER.NEW) {
      if (transactionType === TRANSACTION_TYPE.TOP_UP) {
        columns = [
          {
            name: t('transaction_id'),
            selector: '_id',
            sortable: true,
            minWidth: '200px',
          },
          {
            name: t('transaction.previous_balance'),
            minWidth: '200px',
            cell: (row) => colBalance(row, 'previous'),
          },
          {
            name: t('transaction.remaining_balance'),
            minWidth: '200px',
            cell: (row) => colBalance(row, 'remaining'),
          },

          {
            name: t('delegate'),
            sortable: true,
            minWidth: '260px',
            cell: (row) => <span>{row?.driver?.name}</span>,
          },
          {
            name: t('payment_method'),
            selector: 'payment_method',
            sortable: true,
            width: '140px',
            cell: (row) => renderPaymentMethod(row?.payment_method),
          },
          {
            name: t('transaction_type'),
            selector: 'type',
            sortable: true,
            width: '140px',
            cell: (row) => <p className="font-bold">{renderTransactionType(row?.type)}</p>,
          },
          {
            name: t('amount'),
            selector: 'amount',
            sortable: true,
            cell: (row) => (
              <span data-tag="allowRowEvents" className="ltr">
                {row?.amount?.toFixed(2) ?? '0.00'}
              </span>
            ),
          },
          {
            name: t('gift_amount'),
            cell: (row) => (
              <span data-tag="allowRowEvents" className="ltr">
                {renderGiftAmountValue(row?.transactionBalanceSub)}
              </span>
            ),
          },
          {
            name: t('hyper_pay_log_id'),
            selector: 'hyper_pay_log_id',
            sortable: true,
            minWidth: '200px',
          },
          {
            name: t('hyper_pay_checkout_id'),
            selector: 'hyper_pay_checkout_id',
            sortable: true,
            minWidth: '360px',
          },
          {
            name: t('plan_id'),
            selector: 'plan_id',
            sortable: true,
            minWidth: '200px',
          },
          {
            name: t('created_by'),
            selector: 'created_by',
            sortable: true,
            cell: (row) => (
              <span data-tag="allowRowEvents">
                {row?.created_by?.role
                  ? `${row?.created_by?.role} - ${row?.created_by?.name}`
                  : `${row?.created_by?.name} - ${row?.created_by?._id}`}
              </span>
            ),
            minWidth: '280px',
          },
          {
            name: t('date'),
            selector: (row) =>
              `${
                row.created_at !== null ? moment(row.created_at).locale('ar').format('LL') : '...'
              }`,
            sortable: true,
            wrap: true,
            minWidth: '120px',
          },
          {
            name: t('date_time'),
            selector: (row) =>
              `${
                row.created_at !== null
                  ? moment(row.created_at).locale('ar').format(' hh:mm A')
                  : '...'
              }`,
            sortable: true,
            wrap: true,
            minWidth: '120px',
          },
        ];
        return columns;
      }

      if (
        transactionType === TRANSACTION_TYPE.HOLDING ||
        transactionType === TRANSACTION_TYPE.GIFT_ORDER ||
        transactionType === TRANSACTION_TYPE.CASH_BACK ||
        transactionType === TRANSACTION_TYPE.FEE
      ) {
        columns = [
          {
            name: t('delegate'),
            sortable: true,
            minWidth: '260px',
            cell: (row) => <span>{row?.driver?.name}</span>,
          },
          {
            name: t('order_number'),
            selector: 'order_number',
            sortable: true,
          },
          {
            name: t('transaction.previous_balance'),
            minWidth: '200px',
            cell: (row) => colBalance(row, 'previous'),
          },
          {
            name: t('transaction.remaining_balance'),
            minWidth: '200px',
            cell: (row) => colBalance(row, 'remaining'),
          },
          {
            name: t('payment_method'),
            selector: 'payment_method',
            sortable: true,
            width: '140px',
            cell: (row) => renderPaymentMethod(row?.payment_method),
          },
          {
            name: t('transaction_type'),
            selector: 'type',
            sortable: true,
            width: '140px',
            cell: (row) => <p className="font-bold">{renderTransactionType(row?.type)}</p>,
          },

          ...(transactionType !== TRANSACTION_TYPE.GIFT_ORDER
            ? [
                {
                  name: t('amount'),
                  selector: 'amount',
                  sortable: true,
                  cell: (row) => <span className="ltr">{row?.amount?.toFixed(2) ?? '0.00'}</span>,
                },
                {
                  name: t('gift_transaction'),
                  selector: 'gift',
                  sortable: true,
                  cell: (row) => <span className="ltr">{row?.gift?.toFixed(2) ?? '0.00'}</span>,
                },
                {
                  name: t('delivery_cost'),
                  selector: 'delivery_cost',
                  sortable: true,
                  cell: (row) => (
                    <span className="ltr">{row?.delivery_cost?.toFixed(2) ?? '0.00'}</span>
                  ),
                },
                {
                  name: t('fee'),
                  selector: 'fee',
                  sortable: true,
                  cell: (row) => <span className="ltr">{row?.fee?.toFixed(2) ?? '0.00'}</span>,
                },
                {
                  name: t('tax'),
                  selector: 'tax',
                  sortable: true,
                  cell: (row) => renderAddedValue(transactionType, row, tax),
                },
              ]
            : []),

          {
            name: t('created_by'),
            selector: 'created_by',
            sortable: true,
            cell: (row) => (
              <span data-tag="allowRowEvents">
                {row?.created_by?.role
                  ? `${row?.created_by?.role} - ${row?.created_by?.name}`
                  : `${row?.created_by?.name} - ${row?.created_by?._id}`}
              </span>
            ),
            minWidth: '280px',
          },
          {
            name: t('date'),
            selector: (row) =>
              `${
                row.created_at !== null ? moment(row.created_at).locale('ar').format('LL') : '...'
              }`,
            sortable: true,
            wrap: true,
            minWidth: '120px',
          },
          {
            name: t('date_time'),
            selector: (row) =>
              `${
                row.created_at !== null
                  ? moment(row.created_at).locale('ar').format(' hh:mm A')
                  : '...'
              }`,
            sortable: true,
            wrap: true,
            minWidth: '120px',
          },
        ];
        return columns;
      }

      columns = [
        // {
        //   name: 'order_number',
        //   selector: 'order_number',
        //   sortable: true,
        //   minWidth: '120px',
        // },

        {
          name: t('delegate'),
          sortable: true,
          minWidth: '260px',
          cell: (row) => <span>{row?.driver?.name}</span>,
        },

        {
          name: t('payment_method'),
          selector: 'payment_method',
          sortable: true,
          width: '140px',
          cell: (row) => renderPaymentMethod(row?.payment_method),
        },
        {
          name: t('transaction.previous_balance'),
          minWidth: '200px',
          cell: (row) => colBalance(row, 'previous'),
        },
        {
          name: t('transaction.remaining_balance'),
          minWidth: '200px',
          cell: (row) => colBalance(row, 'remaining'),
        },
        {
          name: t('gift_transaction'),
          selector: 'gift',
          sortable: true,
          cell: (row) => <span className="ltr">{row?.gift?.toFixed(2) ?? '0.00'}</span>,
        },
        {
          name: t('transaction_type'),
          selector: 'type',
          sortable: true,
          width: '140px',
          cell: (row) => <p className="font-bold">{renderTransactionType(row?.type)}</p>,
        },
        // {
        //   name: 'amount',
        //   selector: 'amount',
        //   sortable: true,
        //   cell: (row) => <span className="ltr">{row?.amount?.toFixed(2) ?? '0.00'}</span>,
        // },
        // {
        //   name: 'gift',
        //   selector: 'gift',
        //   sortable: true,
        //   cell: (row) => <span className="ltr">{row?.gift?.toFixed(2) ?? '0.00'}</span>,
        // },
        // {
        //   name: 'delivery_cost',
        //   selector: 'delivery_cost',
        //   sortable: true,
        //   cell: (row) => <span className="ltr">{row?.delivery_cost?.toFixed(2) ?? '0.00'}</span>,
        // },
        // {
        //   name: 'fee',
        //   selector: 'fee',
        //   sortable: true,
        //   cell: (row) => <span className="ltr">{row?.fee?.toFixed(2) ?? '0.00'}</span>,
        // },
        // {
        //   name: 'tax',
        //   selector: 'tax',
        //   sortable: true,
        //   cell: (row) => <span className="ltr">{row?.tax?.toFixed(2) ?? '0.00'}</span>,
        // },
        // {
        //   name: 'hyper_pay_log_id',
        //   selector: 'hyper_pay_log_id',
        //   sortable: true,
        //   minWidth: '200px',
        // },
        // {
        //   name: 'hyper_pay_checkout_id',
        //   selector: 'hyper_pay_checkout_id',
        //   sortable: true,
        //   minWidth: '360px',
        // },
        // {
        //   name: 'plan_id',
        //   selector: 'plan_id',
        //   sortable: true,
        //   minWidth: '200px',
        // },
        {
          name: t('created_by'),
          selector: 'created_by',
          sortable: true,
          cell: (row) => (
            <span data-tag="allowRowEvents">
              {row?.created_by?.role
                ? `${row?.created_by?.role} - ${row?.created_by?.name}`
                : `${row?.created_by?.name} - ${row?.created_by?._id}`}
            </span>
          ),
          minWidth: '280px',
        },
        {
          name: t('date'),
          selector: (row) =>
            `${row.created_at !== null ? moment(row.created_at).locale('ar').format('LL') : '...'}`,
          sortable: true,
          wrap: true,
          minWidth: '120px',
        },
        {
          name: t('date_time'),
          selector: (row) =>
            `${
              row.created_at !== null
                ? moment(row.created_at).locale('ar').format(' hh:mm A')
                : '...'
            }`,
          sortable: true,
          wrap: true,
          minWidth: '120px',
        },
      ];
      return columns;
    }

    columns = [
      {
        name: t('order_number'),
        selector: 'order_number',
        sortable: true,
      },
      {
        name: t('by'),
        selector: (row) =>
          `${row.charge_by_ad?.firstname || ' - '} ${row.charge_by_ad?.lastname || ' - '}`,
        sortable: true,
      },
      {
        name: t('delegate'),
        selector: 'driver_name',
        sortable: true,
      },
      {
        name: t('payment_method'),
        selector: 'payment_method',
        sortable: true,
        width: '140px',
        cell: (row) => renderPaymentMethod(row?.payment_method),
      },
      {
        name: t('value'),
        selector: (row) => `${row.amount.toFixed(2)}`,
        sortable: true,
        width: '100px',
      },
      {
        name: t('balance'),
        selector: (row) => `${row?.new_balance?.toFixed(2)}`,
        sortable: true,
        width: '100px',
        style: {
          direction: 'ltr',
        },
      },
      {
        name: t('old_balance'),
        selector: (row) => `${row?.previous_balance?.toFixed(2)}`,
        sortable: true,
        width: '100px',
        style: {
          direction: 'ltr',
        },
      },
      {
        name: t('operation'),
        selector: (row) =>
          `${row.isFee ? t('table_fee') : row.isCharge ? t('shipping_value') : t('not_charge')}`,
        sortable: true,
        wrap: true,
        width: '200px',
      },
      {
        name: t('date'),
        selector: (row) =>
          `${row.created_at !== null ? moment(row.created_at).locale('ar').format('LL') : '...'}`,
        sortable: true,
        wrap: true,
        width: '300px',
      },

      {
        name: t('date_time'),
        selector: (row) =>
          `${
            row.created_at !== null ? moment(row.created_at).locale('ar').format(' hh:mm A') : '...'
          }`,
        sortable: true,
        wrap: true,
        width: '300px',
      },
    ];
    return columns;
  }, [version, tax, newRenderColumns]);
  return columns;
};

export default useSchema;
