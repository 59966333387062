import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import useSchema from '../Schema';

const Timeout = ({
  logs,
  conditionalRowStyles,
  handlePageChange,
  toggleActivate,
  isLoaded,
  total,
  setLimit,
  limit,
  logType,
  handleToggleModal,
  paginationDefaultPage,
}) => {
  const schema = useSchema({ logType });
  const { t } = useTranslation();

  const tableHeight = window.innerHeight - 440;
  const [selected, setSelected] = useState([]);

  const BtnSetLogId = ({ data }) => {
    return (
      <button
        type="button"
        className="px-3 py-3 bg-green-500 m-2 rounded-md"
        onClick={() => handleToggleModal(data?._id)}
      >
        {t('citc_more')}
      </button>
    );
  };

  return (
    <>
      <DataTable
        noHeader
        noDataComponent={<div style={{ padding: '24px' }}>{t('orders_no_records')}</div>}
        columns={schema}
        data={logs}
        fixedHeader
        contextMessage={{ singular: t('item'), plural: t('items'), message: t('selected') }}
        fixedHeaderScrollHeight={tableHeight.toString() + 'px'}
        progressPending={isLoaded}
        progressComponent={<div className="wait">{t('please_wait')}</div>}
        pagination
        paginationDefaultPage={paginationDefaultPage}
        onSelectedRowsChange={(row) => setSelected(row.selectedRows)}
        paginationPerPage={limit}
        paginationRowsPerPageOptions={[100, 200, 400, 600]}
        onChangeRowsPerPage={(currentRowsPerPage) => {
          setLimit(currentRowsPerPage);
          handlePageChange(1);
        }}
        paginationTotalRows={total}
        paginationServer
        onChangePage={handlePageChange}
        conditionalRowStyles={conditionalRowStyles}
        paginationComponentOptions={{
          rowsPerPageText: t('total_record'),
          rangeSeparatorText: t('from'),
          noRowsPerPage: false,
          selectAllRowsItem: false,
        }}
        selectableRows
        selectableRowSelected={(row) => row.isSelected}
        selectableRowsVisibleOnly
        persistTableHead
        onRowExpandToggled={(toggleState, row) => [
          toggleState
            ? setSelected((p) => [...p, row._id])
            : setSelected(selected.filter((i) => i !== row._id)),
        ]}
        expandableRowsComponent={<BtnSetLogId data={(row) => row} />}
        selectableRowsHighlight
        expandableRows
        expandableRowsHideExpander
        expandOnRowClicked
        direction={'rtl'}
        customStyles={{
          headCells: {
            style: {
              fontWeight: 'bold',
              fontSize: 12,
              background: '#F9F9F9',
              justifyContent: 'flex-start',
            },
          },
          rows: {
            style: {
              cursor: 'pointer',
              fontSize: 12,
            },
          },
        }}
      />
    </>
  );
};

export default Timeout;
