import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DropdownInput from '../DropdownInput';
import { config, SETTING_KEY } from '../../config/Config';

const ConfigAPIVersionMobile = ({ apiVerMobile, setApiVerMobile }) => {
  const { t } = useTranslation();
  const [defaultVersion, setDefaultVersion] = useState(null);

  const version = [
    {
      value: 'v1',
      label: t('v1'),
    },
    {
      value: 'v2',
      label: t('v2'),
    },
  ];

  const handleSelectVersion = (value) => {
    const newFilters = {
      ...apiVerMobile,
      version: value.value,
    };
    setApiVerMobile(newFilters);
  };

  const getSetting = async () => {
    const resp = await axios.get(config.API_URL.SETTING.GET, {
      params: {
        key: SETTING_KEY.API_VER_MOBILE,
      },
      headers: config.headers,
    });
    const { data } = resp;
    const { setting } = data;
    if (setting) {
      const configVersion = {
        version: setting.version,
      };
      const filtered = version.filter((ver) => ver.value === setting.version)?.[0];
      setDefaultVersion(filtered);
      handleSelectVersion(configVersion);
    }
  };

  useEffect(() => {
    getSetting();
  }, []);

  return (
    <div className="setting">
      <h3>{t('express_version')}</h3>
      <div className="row flex items-center gap-x-4">
        <label>{t('version')}: </label>
        <DropdownInput
          textAlign="right"
          placeholder={t('version')}
          options={version}
          handleSelect={handleSelectVersion}
          defaultSelectedValue={defaultVersion}
          hasDefault={false}
        />
      </div>
    </div>
  );
};

export default ConfigAPIVersionMobile;
