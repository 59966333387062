import moment from 'moment';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
const renderFields = (text) => {
  if (!text) return '-';
  return text;
};
const useColumnsOtp = () => {
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        name: t('driver_name'),
        minWidth: '200px',
        cell: (row) => renderFields(row?.drivers?.name),
      },
      {
        name: t('otp_code'),
        minWidth: '200px',
        cell: (row) => renderFields(row?.passKey),
      },
      {
        name: t('phone'),
        minWidth: '200px',
        cell: (row) => renderFields(row?.phone),
      },
      {
        name: t('time_create'),
        selector: (row) =>
          `${
            row.created_at !== null
              ? moment(row.created_at).locale('ar').format('LL ~ hh:mm A')
              : '...'
          }`,
        sortable: true,
        width: '300px',
        wrap: true,
        style: {
          padding: 0,
        },
      },
      {
        name: t('expired'),
        selector: (row) =>
          `${
            row.created_at !== null
              ? moment(row.expired_at).locale('ar').format('LL ~ hh:mm A')
              : '...'
          }`,
        sortable: true,
        width: '300px',
        wrap: true,
        style: {
          padding: 0,
        },
      },
    ],
    []
  );
  return columns;
};
export default useColumnsOtp;
