import React, { useCallback, useEffect, useMemo, useState } from 'react';
import '../../css/pricing.css';
import '../../css/datatable.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { config } from '../../config/Config';
import DataTable from 'react-data-table-component';
import { debounce } from 'lodash';
import { buildQueryString } from '../Orders/Orders';
import { useTranslation } from 'react-i18next';
import produce from 'immer';
import { Slide, toast } from 'react-toastify';

toast.configure();

const ExpandableRow = ({ toggleActivate, data, refresh }) => {
  const { t } = useTranslation();

  const handleMessage = (type = 'success', message = '') => {
    if (type === 'success') {
      return toast.success(message, {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide,
      });
    }
    return toast.error(message, {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      transition: Slide,
    });
  };

  const activate = async (id_number) => {
    await axios
      .patch(
        `${config.API_URL.PRICING.PLAN_DETAIL}${id_number}/change-status`,
        { isActive: true },
        { headers: config.headers }
      )
      .then((response) => {
        if (response.status === 200) {
          handleMessage('success', `${t('pricing.action.notification.activate')} - ${data?.title}`);
          toggleActivate(id_number, 'true');
        }
      })
      .catch((error) => {
        const errorList = error?.response?.data?.errors;
        if (errorList) {
          errorList.forEach((error) => handleMessage('error', error?.msg));
        }
      });
  };
  const deactivate = async (id_number) => {
    if (!window.confirm('Do you want to deactivate this plan?')) return;
    await axios
      .patch(
        `${config.API_URL.PRICING.PLAN_DETAIL}${id_number}/change-status`,
        { isActive: false },
        config
      )
      .then((response) => {
        if (response.status === 200) {
          handleMessage(
            'success',
            `${t('pricing.action.notification.deactivate')} - ${data?.title}`
          );
          toggleActivate(id_number);
        }
      })
      .catch((error) => {
        const errorList = error?.response?.data?.errors;
        if (errorList) {
          errorList.forEach((error) => handleMessage('error', error?.msg));
        }
      });
  };

  return (
    <div className="flex items-center gap-x-4 expand">
      <Link to={`/pricing/edit/${data?._id}`}>
        <button type="button" className="edit-btn disable">
          {t('pricing.action.edit.title')}
        </button>
      </Link>
      {!data.isActive ? (
        <button type="button" className="toggle-btn active" onClick={() => activate(data._id)}>
          {t('pricing.action.activate')}
        </button>
      ) : (
        <button type="button" className="toggle-btn disable" onClick={() => deactivate(data._id)}>
          {t('pricing.action.deactivate')}
        </button>
      )}
    </div>
  );
};

const Pricing = () => {
  const tableHeight = window.innerHeight - 400;
  const { t } = useTranslation();

  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [data, setData] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  const [selected, setSelected] = useState([]);
  const [limit, setLimit] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [curFilters, setCurFilters] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const filter = {
      limit,
      page: currentPage,
    };
    setCurFilters(filter);
    getOpsList(filter);
  }, [limit, currentPage]);

  const getOpsList = async (filter) => {
    const newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      `?${buildQueryString({ ...filter })}`;
    window.history.replaceState({ path: newurl }, '', newurl);

    const { status, data } = await axios.get(config.API_URL.PRICING.LIST, {
      params: { ...filter },
      headers: config.headers,
    });
    setIsLoading(false);
    setData(data?.data);
    setTotal(data?.total);
  };

  const conditionalRowStyles = [
    {
      when: (row) => selectedId.includes(row._id),
      style: {
        backgroundColor: '#C32E52',
        color: '#fff',
      },
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handler = useCallback(() => debounce((filters) => getOpsList(filters), 500), []);

  const refresh = () => {
    const newFilters = {
      filters: {
        ...curFilters,
      },
      limit: limit,
      page: currentPage,
    };
    getOpsList(newFilters);
  };

  const RefreshData = () => {
    return (
      <div className="flex justify-end">
        <Link to="/pricing/new">
          <button type="button" className="refresh-button">
            {t('pricing.register.title')}
          </button>
        </Link>
        <div className="h-full flex items-center gap-x-4" onClick={() => refresh()}>
          <button className="refresh-button"> {t('refresh')} </button>
        </div>
      </div>
    );
  };

  const handlePageChange = (nextPage) => {
    if (isLoading) return false;
    if (Number(nextPage) === Number(currentPage)) return false;

    const newFilters = {
      limit: limit,
      page: nextPage,
    };
    const newUrl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      `?${buildQueryString({ ...newFilters })}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
    setCurrentPage(nextPage);
    handler(newFilters);
  };

  const toggleActivate = (_id) => {
    const index = data.findIndex((i) => i._id === _id);
    const toggle = produce(data, (draft) => {
      draft[index].isActive = !draft[index].isActive;
    });
    setData(toggle);
  };

  const schema = useMemo(
    () => [
      {
        name: t('id'),
        selector: '_id',
        omit: false,
        minWidth: '230px',
      },
      {
        name: t('pricing.register.planName'),
        selector: 'title',
        omit: false,
        maxWidth: '250px',
        // cell: (row) => '',
      },
      {
        name: t('pricing.register.planDescription'),
        selector: 'description',
        omit: false,
        // cell: (row) => '',
      },
      {
        name: t('pricing.register.price'),
        selector: 'price',
        width: '150px',
        omit: false,
        cell: (row) => (row?.price ? `${row?.price} ﷼` : ''),
      },
      {
        name: t('pricing.register.gift'),
        selector: 'gift',
        width: '150px',
        omit: false,
        cell: (row) => (row?.gift ? `${row?.gift} ﷼` : ''),
      },
      {
        name: t('pricing.register.isRecommend'),
        selector: 'isRecommend',
        omit: false,
        width: '150px',
        cell: (row) => (
          <>
            {row?.isRecommend ? (
              <span className={`text-green ${selectedId.includes(row?._id) ? 'selected' : ''}`}>
                {t('pricing.status.true')}
              </span>
            ) : (
              <span className={`text-red ${selectedId.includes(row?._id) ? 'selected' : ''}`}>
                {t('pricing.status.false')}
              </span>
            )}
          </>
        ),
      },
      {
        name: t('pricing.register.priority'),
        selector: 'priority',
        omit: false,
        width: '150px',
        cell: (row) =>
          row?.priority === 1
            ? t('pricing.priority.lowest')
            : row?.priority === 2
            ? t('pricing.priority.normal')
            : row?.priority === 3
            ? t('pricing.priority.highest')
            : '',
      },
      {
        name: t('status'),
        selector: 'status',
        omit: false,
        width: '150px',
        cell: (row) => (
          <>
            {row?.isActive ? (
              <span className={`text-green ${selectedId.includes(row?._id) ? 'selected' : ''}`}>
                {t('pricing.status.activated')}
              </span>
            ) : (
              <span className={`text-red ${selectedId.includes(row?._id) ? 'selected' : ''}`}>
                {t('pricing.status.deactivated')}
              </span>
            )}
          </>
        ),
      },
    ],
    [isLoading, selectedId]
  );

  return (
    <div>
      <DataTable
        noDataComponent={<div style={{ padding: '24px' }}>{t('orders_no_records')}</div>}
        title={<RefreshData />}
        columns={schema}
        data={data}
        fixedHeader
        pagination
        contextMessage={{ singular: t('item'), plural: t('items'), message: t('selected') }}
        fixedHeaderScrollHeight={tableHeight.toString() + 'px'}
        onSelectedRowsChange={(row) => setSelected(row.selectedRows)}
        paginationPerPage={Number(limit)}
        paginationRowsPerPageOptions={[20, 50, 100, 200]}
        onChangeRowsPerPage={(currentRowsPerPage) => {
          setLimit(currentRowsPerPage);
          setCurrentPage(1);
        }}
        paginationResetDefaultPage={resetPaginationToggle}
        progressPending={isLoading}
        paginationTotalRows={total}
        paginationServer
        paginationDefaultPage={currentPage}
        onChangePage={handlePageChange}
        progressComponent={<div className="wait">{t('please_wait')}</div>}
        subHeader
        onRowExpandToggled={(toggleState, row) => [
          toggleState
            ? setSelectedId((p) => [...p, row._id])
            : setSelectedId(selectedId.filter((i) => i !== row._id)),
        ]}
        expandableRowsComponent={
          <ExpandableRow toggleActivate={toggleActivate} data={(row) => row} refresh={refresh} />
        }
        conditionalRowStyles={conditionalRowStyles}
        paginationComponentOptions={{
          rowsPerPageText: t('total_record'),
          rangeSeparatorText: 'من',
          noRowsPerPage: false,
          selectAllRowsItem: false,
        }}
        // subHeaderComponent={subHeaderComponentMemo}
        selectableRows
        selectableRowSelected={(row) => row.isSelected}
        selectableRowsVisibleOnly
        persistTableHead
        selectableRowsHighlight
        expandableRows
        expandableRowsHideExpander
        expandOnRowClicked
        direction={'rtl'}
        customStyles={{
          headCells: {
            style: {
              fontWeight: 'bold',
              fontSize: 12,
              background: '#F9F9F9',
            },
          },
          rows: {
            style: {
              fontSize: 12,
            },
          },
        }}
      />
    </div>
  );
};

export default Pricing;
