import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../../commonComponents/LoadingButton';
import { config } from '../../config/Config';
import { IconClose, IconSpin } from '../../constants/Icon';
import { handleToastMessage } from '../../helper/toast';
import { renderAddedValue } from '../../helper/Transaction/renderAddedValue';
import { TRANSACTION_TYPE } from './constants';
export const PAYMENT_OPTION = {
  VISA: 'VISA',
  MASTERCARD: 'MASTERCARD',
};

export default function TransactionDetail({ visible, id, toggleModal, tax }) {
  const [transaction, setTransaction] = useState(null);
  const [isRefund, setIsRefund] = useState(false);
  const [isShowButtonRefund, setShowButtonRefund] = useState(true);

  const showButtonRefund = () => {
    if (
      transaction.status === 'SUCCESS' &&
      transaction?.payfort_information?.response_code === '14000' &&
      !transaction?.isRefunded &&
      transaction.type === 'TOP_UP' &&
      [PAYMENT_OPTION.VISA, PAYMENT_OPTION.MASTERCARD].includes(
        transaction?.payfort_information?.payment_option
      )
    ) {
      return true;
    }
    return false;
  };

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  const onRefund = async () => {
    if (isRefund) return;
    setIsRefund(true);

    try {
      await axios.post(
        `${config.API_URL.TRANSACTIONS.REFUND(transaction?._id)}`,
        {},
        {
          headers: config.headers,
        }
      );
      handleToastMessage('success', 'Refund success');
      setIsRefund(false);
      setShowButtonRefund(false);
    } catch (error) {
      const message = error?.response?.data?.message;
      handleToastMessage('failed', `Refund failed: ${message}`);
      setIsRefund(false);
    }
  };

  const getDetailLog = async () => {
    setIsLoading(true);
    try {
      const resp = await axios.get(`${config.API_URL.TRANSACTIONS.GET_ALL_TRANSACTIONS}${id}`, {
        headers: config.headers,
      });
      setTransaction(resp.data);
    } catch (e) {
      console.log('error', e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getDetailLog();
    }
  }, [id]);

  function closeModal() {
    toggleModal(false);
  }

  useEffect(() => {
    if (visible) {
    }
  }, [visible]);

  const renderFields = (field) => {
    if (field) return field;
    return '-';
  };

  const renderTransactionDetail = () => {
    if (isLoading) {
      return (
        <div className="border-r-2 border-gray-200 inline-block w-full max-w-5xl h-[calc(100vh-200px)] overflow-x-hidden overflow-y-auto px-10 py-12  text-left align-middle transition-all transform bg-white  rounded-l-2xl ">
          <div className="flex items-center justify-center h-full w-full gap-x-4">
            <IconSpin />
            <p>{t('loading')}</p>
          </div>
        </div>
      );
    }
    return (
      <div className="w-full max-w-5xl h-full overflow-x-hidden  px-10 py-12  text-left align-middle transition-all duration-300 transform bg-white rounded-l-2xl">
        <div className="flex items-center justify-between gap-x-4">
          <div className="flex items-center gap-x-4">
            <span className="text-3xl font-bold">Transaction ID</span>
            <span className="text-3xl leading-6">{id}</span>
          </div>
          <button type="button" onClick={closeModal}>
            <IconClose />
          </button>
        </div>
        {showButtonRefund() && isShowButtonRefund && (
          <div>
            <LoadingButton label="Refund" loading={isRefund} onClick={onRefund} />
          </div>
        )}
        {(transaction?.isRefunded || !isShowButtonRefund) && (
          <div className="w-[110px] ml-12 h-[35px] relative justify-center items-center flex focus:outline-none border px-12 py-2.5 rounded-md text-black">
            Refunded
          </div>
        )}

        <div className="my-10 text-right flex flex-col gap-y-10  h-[calc(100vh-260px)] transition-all">
          {transaction?.type === TRANSACTION_TYPE.HOLDING ||
            transaction?.type === TRANSACTION_TYPE.GIFT_ORDER ||
            transaction?.type === TRANSACTION_TYPE.CASH_BACK ||
            (transaction?.type === TRANSACTION_TYPE.FEE && (
              <div className="grid grid-cols-3 gap-x-4 gap-y-3">
                <p className="col-span-1 font-bold text-2xl">{t('order_number')}</p>
                <p className="col-span-2">{renderFields(transaction?.order_number)}</p>
              </div>
            ))}
          <div>
            <p className="col-span-1 font-bold text-2xl mb-2">{t('delegate')}</p>
            <div className="grid grid-cols-3 gap-x-4">
              <p className="font-bold col-span-1">{t('driver_id')}</p>
              <p className="col-span-2">{renderFields(transaction?.driver?._id)}</p>
              <p className="font-bold col-span-1">{t('name')}</p>
              <p className="col-span-2">{renderFields(transaction?.driver?.name)}</p>
              <p className="font-bold col-span-1">{t('phone')}</p>
              <p className="col-span-2">{renderFields(transaction?.driver?.phone)}</p>
              <p className="font-bold col-span-1">{t('id_number')}</p>
              <p className="col-span-2">{renderFields(transaction?.driver?.id_number)}</p>
              <p className="font-bold col-span-1">{t('region')}</p>
              <p className="col-span-2">{renderFields(transaction?.driver?.region)}</p>
              <p className="font-bold col-span-1">{t('city')}</p>
              <p className="col-span-2">{renderFields(transaction?.driver?.city)}</p>
              <p className="font-bold col-span-1">{t('car_number')}</p>
              <p className="col-span-2">{renderFields(transaction?.driver?.car_number)}</p>
              <p className="font-bold col-span-1">{t('type_of_the_vehicle')}</p>
              <p className="col-span-2">{renderFields(transaction?.driver?.car_type)}</p>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-x-4 gap-y-3">
            <p className="col-span-1 font-bold text-2xl">{t('transaction_type')}</p>
            <p className="col-span-2">{renderFields(transaction?.type)}</p>
          </div>
          <div className="grid grid-cols-3 gap-x-4 gap-y-3">
            <p className="col-span-1 font-bold text-2xl">{t('amount')}</p>
            <p className="col-span-2 ltr">{renderFields(transaction?.amount?.toFixed(2))}</p>
          </div>
          {(transaction?.type === TRANSACTION_TYPE.HOLDING ||
            transaction?.type === TRANSACTION_TYPE.GIFT_ORDER ||
            transaction?.type === TRANSACTION_TYPE.CASH_BACK ||
            transaction?.type === TRANSACTION_TYPE.FEE) && (
            <>
              <div className="grid grid-cols-3 gap-x-4 gap-y-3">
                <p className="col-span-1 font-bold text-2xl">{t('gift')}</p>
                <p className="col-span-2">{renderFields(transaction?.gift?.toFixed(2))}</p>
              </div>
              <div className="grid grid-cols-3 gap-x-4 gap-y-3">
                <p className="col-span-1 font-bold text-2xl">{t('delivery_cost')}</p>
                <p className="col-span-2">{renderFields(transaction?.delivery_cost?.toFixed(2))}</p>
              </div>
              <div className="grid grid-cols-3 gap-x-4 gap-y-3">
                <p className="col-span-1 font-bold text-2xl">{t('fee')}</p>
                <p className="col-span-2">{renderFields(transaction?.fee?.toFixed(2))}</p>
              </div>
              <div className="grid grid-cols-3 gap-x-4 gap-y-3">
                <p className="col-span-1 font-bold text-2xl">{t('tax')}</p>
                <p className="col-span-2">
                  {renderFields(renderAddedValue(transaction?.type, transaction, tax))}
                </p>
              </div>
            </>
          )}
          <div>
            <p className="col-span-1 font-bold text-2xl mb-2">{t('transaction.real_balance')}</p>
            <div className="grid grid-cols-3 gap-x-4">
              <p className="font-bold col-span-1">{t('transaction.balance_id')}</p>
              <p className="col-span-2">
                {renderFields(transaction?.transactionBalanceMain?.balance_id)}
              </p>
              <p className="font-bold col-span-1">{t('transaction.previous_balance')}</p>
              <p className="col-span-2">
                {renderFields(transaction?.transactionBalanceMain?.previous_balance.toFixed(2))}
              </p>
              <p className="font-bold col-span-1">{t('transaction.remaining_balance')}</p>
              <p className="col-span-2">
                {renderFields(transaction?.transactionBalanceMain?.remaining_balance.toFixed(2))}
              </p>
            </div>
          </div>
          <div>
            <p className="col-span-1 font-bold text-2xl mb-2">{t('transaction.gift_balance')}</p>
            <div className="grid grid-cols-3 gap-x-4">
              <p className="font-bold col-span-1">{t('transaction.balance_id')}</p>
              <p className="col-span-2">
                {renderFields(transaction?.transactionBalanceSub?.balance_id)}
              </p>
              <p className="font-bold col-span-1">{t('transaction.previous_balance')}</p>
              <p className="col-span-2">
                {renderFields(transaction?.transactionBalanceSub?.previous_balance.toFixed(2))}
              </p>
              <p className="font-bold col-span-1">{t('transaction.remaining_balance')}</p>
              <p className="col-span-2">
                {renderFields(transaction?.transactionBalanceSub?.remaining_balance.toFixed(2))}
              </p>
            </div>
          </div>
          <div>
            <p className="col-span-1 font-bold text-2xl mb-2">{t('created_by')}</p>
            <div className="grid grid-cols-3 gap-x-4">
              <p className="font-bold col-span-1">{t('account_type')}</p>
              <p className="col-span-2">{renderFields(transaction?.created_by?.role)}</p>
              <p className="font-bold col-span-1">{t('account_id')}</p>
              <p className="col-span-2">{renderFields(transaction?.created_by?.id)}</p>
            </div>
          </div>
          {transaction?.type === TRANSACTION_TYPE.TOP_UP && (
            <div>
              <p className="col-span-1 font-bold text-2xl mb-2">{t('Hyperpay')}</p>
              <div className="grid grid-cols-3 gap-x-4 gap-y-3">
                <p className="col-span-1 font-bold">{t('transaction.hyper_pay_log_id')}</p>
                <p className="col-span-2">{renderFields(transaction?.hyper_pay_log_id)}</p>
              </div>
              <div className="grid grid-cols-3 gap-x-4 gap-y-3">
                <p className="col-span-1 font-bold">{t('transaction.hyper_pay_checkout_id')}</p>
                <p className="col-span-2">{renderFields(transaction?.hyper_pay_checkout_id)}</p>
              </div>
              <div className="grid grid-cols-3 gap-x-4 gap-y-3">
                <p className="col-span-1 font-bold">{t('plan_id')}</p>
                <p className="col-span-2">{renderFields(transaction?.plan_id)}</p>
              </div>
            </div>
          )}
          <div className="grid grid-cols-3 gap-x-4 gap-y-3">
            <p className="col-span-1 font-bold text-2xl">{t('date')}</p>
            <p className="col-span-2">
              {transaction.created_at !== null
                ? moment(transaction.created_at).locale('ar').format('LLLL')
                : '-'}
            </p>
          </div>
          {transaction?.order_number && (
            <div className="  w-full mt-10 mb-10">
              <a
                href={`/orders/${transaction.order_number}`}
                title="Infomation invoice"
                without
                rel="noreferrer"
              >
                <button type="button" className="btn-primary rounded-md px-3 py-4">
                  {t('view_detail_invoice')}
                </button>
              </a>
            </div>
          )}
        </div>
      </div>
    );
  };
  return (
    <div
      className={`${
        visible ? 'translate-x-[0px]' : 'translate-x-[-500px]'
      } w-[500px] pb-10 bg-white  absolute left-0 h-full duration-300	 ease-in-out  border-2 border-gray-200  rounded-l-2xl pt-8`}
    >
      {renderTransactionDetail()}
    </div>
  );
}
