/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import { isEmpty } from 'lodash';
import { memo, useEffect } from 'react';
import { useTabs } from 'react-headless-tabs';
import { config } from '../../../config/Config';
import { IconMapLoading } from '../../../constants/Icon';
import useMapTracking from '../../../hooks/useMapTracking';

import TabContent from './TabContent';
import TabOrders from './TabOrders';

const MapOrders = memo(({ apiVersion, loadingConfig }) => {
  const [selectedTab, setSelectedTab] = useTabs(['order_list', 'dashboard']);
  const {
    setOrders,
    orderFocus,
    setOrderFocus: setSelectedOrder,
    polygon,
    boundaries,
    refKeywordSearch,
    onResetDriverFocus,
    loadingOrders,
    setLoadingOrders,
    filtersOrder,
    onResetFilterOrder,
    driverId,
    driverFocus,
  } = useMapTracking();

  const getOrder = async ({ ...props }) => {
    try {
      if (loadingOrders) return;

      setLoadingOrders(true);
      const resp = await axios.post(
        config.API_URL.LOCATION.ORDER,
        {
          ...props,
        },
        {
          headers: config.headers,
        }
      );
      const { data } = resp;
      const { orders = [] } = data;
      setOrders(orders);
    } catch (errors) {
    } finally {
      setLoadingOrders(false);
    }
  };
  const buildFilterOrder = ({ boundaries, polygon, filtersOrder, orderFocus, driverId }) => {
    let filters = {
      filter: {
        order_id: orderFocus?._id || null,
        driver_id: driverFocus?.hasOrder ? driverId : null,
        ...filtersOrder,
      },
    };
    if (orderFocus || driverId) {
      console.log('1');

      return filters;
    }
    filters.boundaries = boundaries;
    if (!isEmpty(polygon)) {
      console.log('2');
      filters.polygon = polygon;
      return filters;
    }
    console.log('3');
    return filters;
  };

  const handlerRefresh = async () => {
    onResetDriverFocus();
    refKeywordSearch.current.value = '';
    setSelectedOrder(null);
    onResetFilterOrder();
  };

  useEffect(() => {
    const filters = buildFilterOrder({ boundaries, polygon, filtersOrder, orderFocus, driverId });

    filterOrder(filters);
  }, [boundaries, polygon, filtersOrder, orderFocus, driverId]);

  const filterOrder = async (filters) => {
    await getOrder(filters);
  };

  return (
    <>
      <div className="card-orders cards">
        <div className="w-full justify-center items-center h-16   flex bg-white pt-4">
          <div className="h-full w-full">{loadingOrders && <IconMapLoading fill="red" />}</div>
        </div>

        <TabOrders setSelectedTab={setSelectedTab} selectedTab={selectedTab} />
        <TabContent
          selectedTab={selectedTab}
          handlerRefresh={handlerRefresh}
          apiVersion={apiVersion}
          loadingConfig={loadingConfig}
        />
      </div>
    </>
  );
});

export default MapOrders;
