import { useTranslation } from 'react-i18next';
import { config } from '../../../config/Config';
import { buildQueryString } from '../../Orders/Orders';
import ImportExcel from './ImportExcel';
import SyncOdoo from './SyncOdoo';
import SyncShukah from './SyncShukah';

export default function ActionDriversNew({ handleToggleModal, refresh, getFilterObject }) {
  const { t } = useTranslation();

  return (
    <div className="flex justify-end">
      <div className="h-full flex items-center gap-x-4" onClick={() => handleToggleModal()}>
        <button className="refresh-button"> {t('send_noti.send_notification')} </button>
      </div>
      <div className="h-full flex items-center gap-x-4" onClick={() => refresh()}>
        <button className="refresh-button"> {t('refresh')} </button>
      </div>
      <a
        href={`${config.API_URL.DRIVERS.EXPORT_EXCEL}?${buildQueryString({
          ...getFilterObject(),
        })}`}
        rel="noreferrer"
        target="_blank"
        className="refresh-button"
      >
        {t('export_excel_all_by_filter')}
      </a>

      <ImportExcel />
      <SyncOdoo />
      <SyncShukah />
    </div>
  );
}
