/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from '../../../config/Config';
import { Dialog, Transition } from '@headlessui/react';
import { IconClose, IconSpin } from '../../../constants/Icon';

const HttpLogDetailsModal = ({ logId, isModalVisible, handleCloseModal }) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [log, setLog] = useState({});

  const getDetailLog = async () => {
    setIsLoading(true);
    try {
      const resp = await axios.get(`${config.API_URL.HTTP_LOGS.DETAIL(logId)}`, {
        headers: config.headers,
      });
      setLog(resp.data.data);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDetailLog();
  }, []);

  const renderSelectedLogData = () => {
    if (isLoading) {
      return (
        <div className="inline-block w-full max-w-7xl h-[calc(100vh-200px)] overflow-x-hidden overflow-y-auto px-10 py-12 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
          <div className="flex items-center justify-center h-full w-full gap-x-4">
            <IconSpin />
            <p>{t('loading')}</p>
          </div>
        </div>
      );
    }

    return (
      <div className="inline-block w-full max-w-7xl h-full overflow-x-hidden overflow-y-hidden px-10 py-12 my-8 text-left align-middle transition-all duration-300 transform bg-white shadow-xl rounded-2xl">
        <div className="flex items-center justify-between gap-x-4">
          <div className="flex items-center gap-x-4">
            <span className="text-3xl font-bold">Log ID</span>
            <span className="text-3xl leading-6">{logId}</span>
          </div>
          <button type="button" onClick={() => handleCloseModal()}>
            <IconClose />
          </button>
        </div>

        <div className="my-10 pl-6 text-right flex flex-col gap-y-10 overflow-y-auto h-[calc(100vh-260px)] transition-all">
          <div className="rtl">
            <table id="table">
              <tbody>
                <tr>
                  <td>{t('route')}</td>
                  <td>{log.url}</td>
                </tr>
                <tr>
                  <td>{t('method')}</td>
                  <td>{log.method}</td>
                </tr>
                <tr>
                  <td>{t('type')} </td>
                  <td>{log.type}</td>
                </tr>
                <tr>
                  <td>{t('status')} </td>
                  <td>{log.status}</td>
                </tr>
                <tr>
                  <td>{t('headers')} </td>
                  <td>
                    <pre className="prettyprint">{JSON.stringify(log.headers, undefined, 2)}</pre>
                  </td>
                </tr>
                <tr>
                  <td>{t('request')}</td>
                  <td>
                    <pre className="prettyprint">{JSON.stringify(log.request, undefined, 2)}</pre>
                  </td>
                </tr>
                <tr>
                  <td>{t('query')}</td>
                  <td>
                    <pre className="prettyprint">{JSON.stringify(log.query, undefined, 2)} </pre>
                  </td>
                </tr>
                <tr>
                  <td>{t('message')}</td>
                  <td>{log.message}</td>
                </tr>
                <tr>
                  <td>{t('params')}</td>
                  <td>
                    <pre className="prettyprint">{JSON.stringify(log.params, undefined, 2)} </pre>
                  </td>
                </tr>
                <tr>
                  <td>{t('number_of_runs')}</td>
                  <td>{log.count || 1}</td>
                </tr>
                <tr>
                  <td>{t('response')}</td>
                  <td>
                    <pre className="prettyprint">{JSON.stringify(log.data, undefined, 2)} </pre>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Transition appear show={isModalVisible} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={handleCloseModal}>
        <div className="min-h-screen px-4 text-center rtl">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-[#00000065]" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            {renderSelectedLogData()}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default HttpLogDetailsModal;
