import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import '../../css/style.css';
import Select from '../Forms/Select';
import { config } from '../../config/Config';

import { SRLWrapper } from 'simple-react-lightbox';
import ReCreate from '../Atoms/ReCreate';
import { handleMessage } from '../../helper/utils';

toast.configure();
const EditDriver = (props) => {
  let history = useHistory();
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [citcData, setCitcData] = useState(null);
  const [attachments, setAttachments] = useState(data?.attachment || []);
  const [identities, setIdentities] = useState([]);
  const [carsTypes, setCarsTypes] = useState([]);
  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);
  const inputElement = useRef(null);
  const { t } = useTranslation();

  const [companies, setCompanies] = useState([]);
  const getCompanyList = async () => {
    const { data } = await axios.get(config.API_URL.DELIVERY_COMPANY.LIST);
    if (data && data?.success) {
      const terraformData = await data?.data?.map((company) => {
        return {
          label: company?.name ?? '',
          value: company?._id ?? '',
        };
      });
      setCompanies(terraformData);
    }
  };
  const getDriver = async () => {
    const resp = await axios.get(config.API_URL.DRIVERS.EDIT_DRIVER + id, {
      headers: config.headers,
    });
    await setData(resp.data);
  };

  const getIdentityTypes = async () => {
    const response = await axios.get(config.API_URL.CITC_LOOKUP.IDENTITY_TYPES);
    const { data: result = [] } = response;
    const identityTypes = result.map((identityType) => {
      return {
        label: identityType?.nameAr ?? '',
        value: identityType?.id ?? '',
      };
    });
    setIdentities(identityTypes);
  };

  const getCarTypes = async () => {
    const response = await axios.get(config.API_URL.CITC_LOOKUP.CAR_TYPES);
    const { data: result = [] } = response;
    const carTypes = result.map((carType) => {
      return {
        label: carType?.nameAr ?? '',
        value: carType?.id ?? '',
      };
    });
    setCarsTypes(carTypes);
  };

  const getRegions = async () => {
    const response = await axios.get(config.API_URL.CITC_LOOKUP.REGIONS);
    const { data: result = [] } = response;

    const regions = result.map((region) => {
      return {
        label: region?.nameAr ?? '',
        value: region?.id ?? '',
      };
    });
    setRegions(regions);
  };

  const getCities = async (regionId) => {
    const response = await axios.get(config.API_URL.CITC_LOOKUP.CITIES, {
      params: { regionId },
    });
    const { data: result = [] } = response;
    const cities = result.map((city) => {
      return {
        label: city?.nameAr ?? '',
        value: city?.id ?? '',
      };
    });
    setCities(cities);
  };

  useEffect(() => {
    (async () => {
      await getDriver();
      getIdentityTypes();
      getRegions();
      getCarTypes();
      getCompanyList();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!data || identities.length <= 0) return;
    const identity = identities.find((identityType) => {
      return data.identity_type_id === identityType.value;
    });
    const selectIdentityType = {
      label: identity?.label ?? '',
      value: identity?.value ?? '',
      id: 1,
      model: 'IdentityTypeId',
    };
    selectHandler(selectIdentityType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, identities]);

  useEffect(() => {
    if (!data || carsTypes.length <= 0) return;
    const carType = carsTypes.find((carType) => {
      return data.car_type_id === carType.value;
    });
    const selectCarType = {
      label: carType?.label ?? '',
      value: carType?.value ?? '',
      id: 2,
      model: 'carType',
    };
    selectHandler(selectCarType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, carsTypes]);

  useEffect(() => {
    if (!data || regions.length <= 0) return;
    const region = regions.find((region) => {
      return data.region_id === region.value;
    });
    const selectRegion = {
      label: region?.label ?? '',
      value: region?.value ?? '',
      id: 3,
      model: 'region',
    };
    selectHandler(selectRegion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, regions]);

  useEffect(() => {
    if (!data || cities.length <= 0) return;
    const city = cities.find((city) => {
      return data.city_id === city.value;
    });
    const selectCity = {
      label: city?.label ?? '',
      value: city?.value ?? '',
      id: 4,
      model: 'city',
    };
    selectHandler(selectCity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, cities]);

  useEffect(() => {
    if (!data) return;
    updateInitial();
    const selectedData = [
      { label: data?.company_name, value: data?.from_company, id: 5, model: 'company' },
    ];

    // initialize select form
    selectedData.forEach((o, index) => {
      selectHandler(selectedData[index]);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const initialState = {
    name: '',
    firstName: '',
    secondName: '',
    thirdName: '',
    lastName: '',
    phone: '',
    idNumber: '',
    day: '',
    month: '',
    year: '',
    carNumber: '',
    image: '',
  };
  const updateInitial = async () => {
    let dateOfBith = data?.date_of_birth?.replaceAll('-', '/');
    dateOfBith = dateOfBith?.split('/');

    let medicalInsuranceDate = data?.medical_Insurance_date;
    medicalInsuranceDate = medicalInsuranceDate?.split('/');
    setAttachments(data.attachment || []);
    await setState({
      name: data?.name ?? '',
      firstName: data?.firstName ?? '',
      secondName: data?.secondName ?? '',
      thirdName: data?.thirdName ?? '',
      lastName: data?.lastName ?? '',
      phone: data?.phone ?? '',
      idNumber: data?.id_number ?? '',
      day: dateOfBith?.[0] || '',
      month: dateOfBith?.[1] || '',
      year: dateOfBith?.[2] || '',
      day_medical: medicalInsuranceDate?.[0] || '',
      month_medical: medicalInsuranceDate?.[1] || '',
      year_medical: medicalInsuranceDate?.[2] || '',
      carNumber: data?.car_number ?? '',
      image: data?.driver_image ?? '',
      vehicleSequenceNumber: data?.vehicle_sequence_number ?? '',
      number_of_received: data?.number_of_received ?? '',
      company: data?.from_company ?? '',
      stc_pay_number: data?.stc_pay_number ?? '',
    });
  };

  // States
  const [
    {
      name,
      firstName,
      secondName,
      thirdName,
      lastName,
      vehicleSequenceNumber,
      phone,
      idNumber,
      day,
      month,
      year,
      carNumber,
      image,
      day_medical,
      month_medical,
      year_medical,
      number_of_received,
      stc_pay_number,
    },
    setState,
  ] = useState(initialState);
  const [selectValues, setSelectValues] = useState([]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const selectHandler = (e) => {
    setSelectValues((prevValues) => [...prevValues.filter((i) => i.id !== e.id), e]);
    if (e.id === 3 && e.value) {
      getCities(e.value);
    }
  };

  const onSubmit = async () => {
    if (selectValues.length < 4) {
      toast.error(t('please_fill_in_all_fields'), { position: toast.POSITION.BOTTOM_LEFT });
      return;
    }
    let identity = selectValues.find((e) => e.model === 'IdentityTypeId');
    let car_type = selectValues.find((e) => e.model === 'carType');
    let region = selectValues.find((e) => e.model === 'region');
    let city = selectValues.find((e) => e.model === 'city');
    let company = selectValues.find((e) => e.model === 'company');

    let car_type_id = car_type?.value ?? '';
    let car_type_label = car_type?.label ?? '';
    let identity_type_id = identity?.value ?? '';
    let identity_type = identity?.label ?? '';
    let region_id = region?.value ?? '';
    let region_name = region?.label ?? '';
    let cityId = city?.value ?? '';
    let cityName = city?.label ?? '';

    const data = {
      firstName: firstName,
      secondName: secondName,
      thirdName: thirdName,
      lastName: lastName,
      phone: phone,
      stc_pay_number: stc_pay_number,
      id_number: idNumber,
      date_of_birth: day + '/' + month + '/' + year,
      medical_Insurance_date: day_medical + '/' + month_medical + '/' + year_medical,
      car_type: car_type_label,
      car_type_id: car_type_id,
      car_number: carNumber.trim(),
      identity_type: identity_type,
      identity_type_id: identity_type_id,
      region: region_name,
      region_id: region_id,
      city: cityName,
      city_id: cityId,
      driver_image: image,
      attachment: attachments,
      vehicle_sequence_number: vehicleSequenceNumber,
      number_of_received: number_of_received,
      from_company: company?.value,
      company_name: company?.label,
    };

    await axios
      .patch(config.API_URL.DRIVERS.UPDATE_DRIVER + id, data, {
        headers: config.headers,
      })
      .then((response) => {
        toast(t('data_updated_successfully'), { position: toast.POSITION.BOTTOM_LEFT });

        history.push('/drivers');
      })
      .catch((error) => {
        const errorList = error?.response?.data?.errors;
        if (errorList) {
          errorList.forEach((error) => handleMessage('error', error.msg));
        }
      });
  };

  const handleUpload = async (event) => {
    const file = event.target.files[0];
    if (file && file.length < 0) return;

    const formData = new FormData();
    formData.append('assets', file);

    const uploaded = await axios.post(config.API_URL.DRIVERS.ATTACHMENT, formData, {
      headers: config.headers,
    });
    const { response_code, response_data } = uploaded.data;
    if (response_code === 200) {
      const newAttachments = [...attachments, ...[response_data]];
      setAttachments(newAttachments);
    }
  };
  const handlerRemove = async (key) => {
    const newAttachments = attachments.filter((i, index) => key !== index);
    setAttachments(newAttachments);
  };

  const getCitcDriver = async () => {
    const resp = await axios.get(config.API_URL.DRIVERS.CITC_DRIVER + id, {
      headers: config.headers,
    });
    await setCitcData(resp.data);
  };

  const getS3 = () => {
    return attachments.map((i, index) => (
      <div key={index} className="upload-list-picture-card-container">
        <div className="upload-list-item upload-list-item-done upload-list-item-list-type-picture-card">
          <div className="upload-list-item-info">
            <span className="upload-span">
              <a
                className="upload-list-item-thumbnail"
                href={i.Location}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={i.Location} alt="" className="upload-list-item-image" />
              </a>
            </span>
            <span className="upload-list-item-actions">
              <button
                title="Remove file"
                type="button"
                className="btn remove-button"
                onClick={() => handlerRemove(index)}
              >
                <span role="img" aria-label="delete" tabIndex={-1} className="icon icon-delete">
                  <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="delete"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z" />
                  </svg>
                </span>
              </button>
            </span>
          </div>
        </div>
      </div>
    ));
  };
  return (
    <>
      {data && (
        <>
          <div className="new_driver_container">
            <h3>{t('edit_information')} </h3>
            {!!name && (
              <div className="row">
                <label>{t('driver_name')} </label>
                <input type="text" value={name || ''} disabled={true} />
              </div>
            )}
            <div className="row">
              <label>{t('firstName')} </label>
              <input type="text" value={firstName || ''} name="firstName" onChange={onChange} />
            </div>
            <div className="row">
              <label>{t('secondName')} </label>
              <input type="text" value={secondName || ''} name="secondName" onChange={onChange} />
            </div>
            <div className="row">
              <label>{t('thirdName')} </label>
              <input type="text" value={thirdName || ''} name="thirdName" onChange={onChange} />
            </div>
            <div className="row">
              <label>{t('lastName')} </label>
              <input type="text" value={lastName || ''} name="lastName" onChange={onChange} />
            </div>
            <div className="row">
              <label> {t('phone')}</label>
              <input
                type="text"
                className="center"
                value={phone || ''}
                name="phone"
                onChange={onChange}
              />
            </div>
            <div className="row">
              <label>{t('stc_pay_number')} </label>
              <input
                type="text"
                className="center"
                value={stc_pay_number}
                name="stc_pay_number"
                onChange={onChange}
              />
            </div>
            <div className="row">
              <label> {t('id_number')}</label>
              <Select
                id={1}
                model="IdentityTypeId"
                selectHandler={selectHandler}
                data={identities}
                value={data.identity_type_id}
                label={data.identity_type}
              />
            </div>
            <div className="row">
              <label> {t('national_id')}</label>
              <input
                type="text"
                className="center"
                value={idNumber || ''}
                name="idNumber"
                onChange={onChange}
              />
            </div>
            <div className="row">
              <label>{t('birth_day')} </label>
              <input
                type="text"
                className="input60"
                placeholder="DD"
                value={day || ''}
                name="day"
                onChange={onChange}
              />
              <input
                type="text"
                className="input60"
                placeholder="MM"
                value={month || ''}
                name="month"
                onChange={onChange}
              />
              <input
                type="text"
                className="input80"
                placeholder="YYYY"
                value={year || ''}
                name="year"
                onChange={onChange}
              />
            </div>
            <div className="row">
              <label>{t('company')} </label>
              <Select
                id={5}
                model="company"
                selectHandler={selectHandler}
                data={companies}
                value={data.company_id}
                label={data.company_name}
              />
            </div>
            <div className="row">
              <label>{t('type_of_the_vehicle')} </label>
              <Select
                id={2}
                model="carType"
                selectHandler={selectHandler}
                value={data.car_type_id}
                data={carsTypes}
                label={data.car_type}
              />
            </div>
            <div className="row">
              <label>{t('car_number')} </label>
              <input
                type="text"
                className="center"
                value={carNumber || ''}
                name="carNumber"
                onChange={onChange}
              />
            </div>
            <div className="row">
              <label>{t('region')}</label>
              <Select
                id={3}
                model="region"
                value={data.region_id}
                selectHandler={selectHandler}
                data={regions}
                label={data.region}
              />
            </div>
            <div className="row">
              <label>{t('city')} </label>
              <Select
                id={4}
                model="city"
                value={data.city_id}
                selectHandler={selectHandler}
                data={cities}
                label={data.city}
              />
            </div>
            <div className="row">
              <label>{t('vehicle_sequence_number')}</label>
              <input
                type="text"
                className="center"
                value={vehicleSequenceNumber || ''}
                name="vehicleSequenceNumber"
                onChange={onChange}
              />
            </div>
            <div className="row">
              <label>{t('medical_insurance_date')} </label>
              <input
                type="text"
                className="input60"
                placeholder="DD"
                value={day_medical || ''}
                name="day_medical"
                onChange={onChange}
              />
              <input
                type="text"
                className="input60"
                placeholder="MM"
                value={month_medical || ''}
                name="month_medical"
                onChange={onChange}
              />
              <input
                type="text"
                className="input80"
                placeholder="YYYY"
                value={year_medical || ''}
                name="year_medical"
                onChange={onChange}
              />
            </div>
            <div className="row">
              <label> {t('image')}</label>
              <input
                type="text"
                className="ltr"
                value={image || ''}
                name="image"
                onChange={onChange}
              />
            </div>
            <div className="row">
              <label> {t('number_of_received')}</label>
              <input
                type="text"
                className="ltr"
                value={number_of_received || ''}
                name="number_of_received"
                onChange={onChange}
              />
            </div>
            <div className="row">
              <label style={{ marginBottom: 20, marginTop: 20 }}>{t('document')} </label>

              <div className="flex">
                <div
                  className="upload upload-select upload-select-picture-card"
                  onClick={() => inputElement.current?.click()}
                >
                  <span tabIndex={0} className="upload" role="button">
                    <input
                      type="file"
                      accept=""
                      style={{ display: 'none' }}
                      ref={inputElement || ''}
                      onChange={handleUpload}
                    />
                    <div>
                      <span role="img" aria-label="plus" className="icon icon-plus">
                        <svg
                          viewBox="64 64 896 896"
                          focusable="false"
                          data-icon="plus"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <defs>
                            <style dangerouslySetInnerHTML={{ __html: '' }} />
                          </defs>
                          <path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z" />
                          <path d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z" />
                        </svg>
                      </span>
                    </div>
                  </span>
                </div>

                <div className="flex flex-wrap">
                  <SRLWrapper>{getS3()} </SRLWrapper>
                </div>
              </div>
            </div>
            <div className="flex flex-row py-6">
              <div className="px-4">
                <ReCreate driverId={id} />
              </div>
              <button className="registerBtn px-5 py-[7px] rounded-md" onClick={onSubmit}>
                {t('save')}
              </button>
            </div>
          </div>
        </>
      )}

      <div className="new_driver_container mx-5">
        <button className="refresh-button mt-2" onClick={getCitcDriver}>
          {t('get_citc')}
        </button>

        {citcData && (
          <>
            <td>{t('citc')}</td>
            <td>
              <pre className="prettyprint">{JSON.stringify(citcData, undefined, 2)} </pre>
            </td>
          </>
        )}
      </div>
    </>
  );
};
export default EditDriver;
