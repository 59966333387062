import React from 'react';

function Loading() {
  return (
    <>
      <div className="quarter-spinner"></div>
    </>
  );
}

export default Loading;
