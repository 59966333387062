const headerTransactionKey = 'x-ca-transaction-key';

export const guid = () => {
  let s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
};
export const TransactionKey = {
  cancel: 'cancel',
  assign: 'assign',
  execute: 'execute',
  reassign: 'reassign',
};
export const buildTransactionKey = (order_number, id_admin, type, request_number) => {
  // let request_number = guid();
  switch (type) {
    case TransactionKey.cancel:
      return {
        [headerTransactionKey]: `${order_number}::${id_admin}::CancelOrder::${request_number}`,
      };
    case TransactionKey.assign:
      return {
        [headerTransactionKey]: `${order_number}::${id_admin}::AssignOrder::${request_number}`,
      };

    case TransactionKey.execute:
      return {
        [headerTransactionKey]: `${order_number}::${id_admin}::ExecuteOrder::${request_number}`,
      };

    case TransactionKey.reassign:
      return {
        [headerTransactionKey]: `${order_number}::${id_admin}::ReassignOrder::${request_number}`,
      };

    default:
      return { [headerTransactionKey]: '' };
  }
};
