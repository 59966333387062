import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import axios from 'axios';
import produce from 'immer';
import { uniqueId } from 'lodash';
import moment from 'moment';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { toast } from 'react-toastify';
import LoadingButton from '../../commonComponents/LoadingButton/LoadingButton';
import { config } from '../../config/Config';
import { DataContext } from '../../context/DataContext';
import '../../css/citclog.css';
import { createDate } from '../../helper/date/createDateWithTimezone';
import { buildQueryString } from '../../helper/utils';
import useEventEnter from '../../hooks/useEventEnter';
import CustomSelectReact from '../CustomSelectReact';
import DropdownInput from '../DropdownInput';

import { parseQueryString } from '../Orders/Orders.js';
import SearchInput from '../SearchInput';
import AllSuccess from './AllSuccess';
import LogModal from './LogModal';
import Timeout from './Timeout';
import useFilterOptions from './useColumns';
toast.configure();

const LogsCitc = () => {
  const { methods, operations, status } = useFilterOptions();

  const { t } = useTranslation();

  const { search } = useLocation();
  let history = useHistory();
  const queryString = parseQueryString(search);

  const [logType, setLogType] = useState(queryString?.type || 'all');

  const [logs, setLogs] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isExport, setIsExport] = useState(false);
  const [limit, setLimit] = useState(queryString.limit || 100);
  const [filters, setFilters] = useState(queryString.filters || {});
  const [dateRange, setDateRange] = useState([null, null]);
  const [selectedLog, setSelectedLog] = useState(null);
  let [modalVisible, setModalVisible] = useState(false);
  let [firstTime, setFirstTime] = useState(true);
  const [newRender, setNewRender] = useState(uniqueId());

  const { currentTimezone } = useContext(DataContext);

  const inputElement = useRef(null);
  const onSetFilters = (newFilter) => {
    setFilters(newFilter);
  };
  const toggleModal = (visible) => {
    setModalVisible(visible);
  };

  useEffect(() => {
    if (queryString.type === 'timeout') {
      setLogType('timeout');
    }
    if (queryString.type === 'all') {
      setLogType('all');
    }
  }, [queryString]);

  const conditionalRowStyles = [
    {
      when: (row) => !row.isSuccess,
      style: {
        backgroundColor: '#F7DDDE',
      },
    },
    {
      when: (row) => row.isSuccess,
      style: {
        backgroundColor: '#D4E9D8',
      },
    },
  ];

  const toggleActivate = (id_number) => {
    const index = logs.findIndex((i) => i.id_number === id_number);
    const toggle = produce(logs, (draft) => {
      draft[index].isActive = !draft[index].isActive;
    });
    setLogs(toggle);
  };

  const getLogs = async (type, _currentPage) => {
    const filterList = filters;
    if (isLoaded) return;
    setIsLoaded(true);
    let fetchURL = '';

    if (type) {
      if (type === 'all') {
        fetchURL = config.API_URL.CITC_LOGS.LIST;
      }

      if (type === 'timeout') {
        fetchURL = config.API_URL.CITC_LOGS.TIMEOUT_LIST;
      }
    } else {
      if (logType === 'all') {
        fetchURL = config.API_URL.CITC_LOGS.LIST;
      }

      if (logType === 'timeout') {
        fetchURL = config.API_URL.CITC_LOGS.TIMEOUT_LIST;
      }
    }
    const finalizeFilterList = {
      methods: filterList?.methods || [],
      types: filterList?.types || [],
      status: filterList?.status,
      id: filterList?.id || '',
    };
    if (filterList?.fromDate) {
      finalizeFilterList.fromDate = new Date(
        createDate(+filterList?.fromDate, currentTimezone)
      ).getTime();
    }

    if (filterList?.toDate) {
      finalizeFilterList.toDate = new Date(
        createDate(+filterList?.toDate, currentTimezone)
      ).getTime();
    }

    const newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      `?${buildQueryString({ type: logType, ...filters })}`;

    window.history.pushState({ path: newurl }, '', newurl);
    try {
      const resp = await axios.get(fetchURL, {
        params: {
          page: _currentPage ? _currentPage : currentPage,
          limit,
          filters: finalizeFilterList,
        },
        headers: config.headers,
      });
      setLogs(resp.data?.data || []);
      setTotal(resp.data?.total || 0);
    } catch (e) {
    } finally {
      setIsLoaded(false);
    }
  };

  useEffect(() => {
    if (firstTime) {
      setFilters(queryString);
      setFirstTime(false);
    }
    if (!firstTime) {
      getLogs();
    }
  }, [newRender, limit, firstTime, logType]);

  const handlePageChange = async (page) => {
    await setCurrentPage(page);
    setNewRender(uniqueId());
  };

  const refresh = () => {
    getLogs();
  };

  const selectHandler = (e, key) => {
    const newFilters = {
      ...filters,
      [key]: e,
    };
    onSetFilters(newFilters);
  };

  const handleFilterStatus = (value) => {
    const newFilters = {
      ...filters,
      status: value.value,
    };
    onSetFilters(newFilters);
  };

  const handleFilterText = (value) => {
    const newFilters = {
      ...filters,
      id: value,
    };
    onSetFilters(newFilters);
  };

  const handleDateFilter = (value) => {
    if (value) {
      const newFilter = {
        ...filters,
        fromDate: new Date(value[0]).getTime(),
        toDate: new Date(value[1]).getTime(),
      };
      onSetFilters(newFilter);
    } else {
      const newFilter = {
        ...filters,
        fromDate: null,
        toDate: null,
      };
      onSetFilters(newFilter);
      setDateRange([null, null]);
    }
  };

  useEffect(() => {
    if (filters?.fromDate && filters?.toDate) {
      const dateValue = [new Date(+filters?.fromDate), new Date(+filters?.toDate)];
      setDateRange(dateValue);
    } else {
      setDateRange([null, null]);
    }
  }, [filters?.fromDate, filters?.toDate]);

  const resetFilters = () => {
    setFilters({
      methods: null,
      types: null,
      status: null,
    });
  };

  const handleTab = async (type) => {
    const newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      `?${buildQueryString({ type })}`;
    history.push({ path: newurl }, '', newurl);
    window.history.replaceState({ path: newurl }, '', newurl);
    if (isExport) {
      setIsExport(false);
    }
    await resetFilters();
    await setCurrentPage(1);
    await setLogType(type);
  };

  const exportExcel = (type) => {
    setIsExport(true);
    try {
      axios
        .post(
          `${
            type === 'timeout'
              ? config.API_URL.CITC_LOGS.EXPORT_TIMEOUT_LIST
              : config.API_URL.CITC_LOGS.EXPORT_ALl_LIST
          }`,
          {},
          {
            params: {
              filters: filters,
            },
            headers: {
              ...config.headers,
              'Content-Disposition': 'attachment; filename=template.xlsx',
              'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            responseType: 'arraybuffer',
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `${type === 'timeout' ? 'citc-timeout' : 'citc-success'}-${moment()
              .locale('en')
              .format('MM-DD-YYYY')}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
        });
    } catch (errors) {
      setIsExport(false);
    } finally {
    }
  };

  const handleUploadFile = async (event) => {
    const file = event.target.files[0];
    if (file && file.length < 0) return;
    const formData = new FormData();
    formData.append('fileExcel', file);
    try {
      const response = await axios.post(config.API_URL.CITC_LOGS.RECOVERY, formData, {
        headers: {
          ...config.headers,
        },
      });
      if (response.status === 200) {
        toast(t('upload_file_citc_success'), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } catch (errors) {
      toast.error(t('upload_file_citc_failed'), { position: toast.POSITION.BOTTOM_LEFT });
    }
  };

  const renderTab = useCallback(
    () => (
      <>
        <button
          className={`tab-btn ${logType === 'all' ? 'active-tab' : ''}`}
          onClick={() => handleTab('all')}
        >
          {t('CITC Success Logs')}
        </button>
        <button
          className={`tab-btn ${logType === 'timeout' ? 'active-tab' : ''}`}
          onClick={() => handleTab('timeout')}
        >
          {t('CITC Timeout Logs')}
        </button>
      </>
    ),
    [logType]
  );

  const onGetLogs = async () => {
    await setCurrentPage(1);
    getLogs(null, 1);
  };

  useEventEnter(onGetLogs);

  const renderActions = () => {
    return (
      <div className="flex flex-col mb-[35px] ">
        <div className="action-list">{renderTab()}</div>
        <div className="h-full flex items-center gap-x-4 mx-[10px]">
          {logType === 'timeout' && (
            <button className="refresh-button !mx-0" onClick={() => exportExcel('timeout')}>
              {t('exportExcels')}
            </button>
          )}

          {logType === 'timeout' && (
            <div className="flex">
              <div
                className="  upload-select-picture-card"
                onClick={() => inputElement.current?.click()}
              >
                <span tabIndex={0} role="button">
                  <input
                    type="file"
                    accept=""
                    style={{ display: 'none' }}
                    ref={inputElement}
                    onChange={handleUploadFile}
                  />
                  <button className="refresh-button !mx-0  ">{t('updatedFile')}</button>
                </span>
              </div>
            </div>
          )}
          <button className="refresh-button !mx-0" onClick={() => refresh()}>
            {t('refresh')}
          </button>

          {logType === 'all' && (
            <button className="refresh-button !mx-0" onClick={() => exportExcel('all')}>
              {t('exportExcels')}
            </button>
          )}
        </div>
        <div className="action-list">
          {logType === 'all' && (
            <div>
              <SearchInput
                disabled={isLoaded}
                placeholder={`${t('order_number')}/${t('driver_id')}`}
                handleSearch={handleFilterText}
                makeRerender={filters}
                value={filters?.id}
              />
            </div>
          )}
          <div className="input-select-wrapper">
            <CustomSelectReact
              isDisabled={isLoaded}
              placeholder={t('method')}
              className="input-select-input-wrapper !border-0 h-full max-w-max w-full"
              options={methods}
              value={filters.methods}
              onChange={(e) => selectHandler(e, 'methods')}
              isMulti
            />
          </div>
          <div className="input-select-wrapper">
            <CustomSelectReact
              isDisabled={isLoaded}
              placeholder={t('operation')}
              className="input-select-input-wrapper !border-0 h-full max-w-max w-full"
              options={operations}
              value={filters.types}
              onChange={(e) => selectHandler(e, 'types')}
              isMulti
            />
          </div>
          {logType === 'all' && (
            <div>
              <DropdownInput
                disable={isLoaded}
                textAlign="right"
                placeholder={t('status')}
                options={status}
                handleSelect={handleFilterStatus}
              />
            </div>
          )}

          <div className="input-select-wrapper" style={{ direction: 'ltr' }}>
            <DateTimeRangePicker
              disabled={isLoaded}
              className="input-select-input-wrapper !border-0"
              value={dateRange}
              onChange={handleDateFilter}
              disableClock
              format={'y-MM-dd'}
            />
          </div>
        </div>
        <div className="relative w-full mb-6 top-[-5px]">
          <div className="absolute right-0 px-[10px]">
            <LoadingButton onClick={onGetLogs} label={t('Apply Filter')} loading={isLoaded} />
          </div>
        </div>
      </div>
    );
  };

  const handleToggleModal = (id) => {
    toggleModal(true);
    setSelectedLog(id);
  };

  const renderLogType = () => {
    if (logType === 'all') {
      return (
        <AllSuccess
          paginationDefaultPage={+currentPage}
          handleToggleModal={handleToggleModal}
          logs={logs}
          conditionalRowStyles={conditionalRowStyles}
          handlePageChange={handlePageChange}
          toggleActivate={toggleActivate}
          isLoaded={isLoaded}
          total={total}
          setLimit={setLimit}
          limit={limit}
          logType={logType}
        />
      );
    }

    if (logType === 'timeout') {
      return (
        <Timeout
          paginationDefaultPage={+currentPage}
          handleToggleModal={handleToggleModal}
          logs={logs}
          conditionalRowStyles={conditionalRowStyles}
          handlePageChange={handlePageChange}
          toggleActivate={toggleActivate}
          isLoaded={isLoaded}
          total={total}
          setLimit={setLimit}
          limit={limit}
          logType={logType}
        />
      );
    }
  };

  return (
    <div className="">
      <div className="rtl">{renderActions()}</div>
      <div>{renderLogType()}</div>
      <LogModal
        logType={logType}
        id={selectedLog}
        visible={modalVisible}
        toggleModal={toggleModal}
      />
    </div>
  );
};

export default LogsCitc;
