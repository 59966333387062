/* eslint-disable react-hooks/exhaustive-deps */
import { Status, Wrapper } from '@googlemaps/react-wrapper';
import MapDom from './MapDom';
const mapRender = (status) => {
  if (status === Status.LOADING) return <p>Loading...</p>;
  if (status === Status.FAILURE) return <p>Error...</p>;
  return null;
};

MapDom.displayName = 'Map';

const MapComponent = ({
  apiKey,
  render = mapRender,
  children,
  onDblClick,
  onDragEnd,
  onDragStart,
  onMouseDown,
  onMouseMove,
  onMouseOut,
  onMouseOver,
  onMouseUp,
  onRightClick,
  onClick,
  onDrag,
  onLoad,
  onUnmount,
  boundsChange,
  ...props
}) => {
  return (
    <>
      <Wrapper apiKey={apiKey} render={render} libraries={['drawing']}>
        <MapDom
          {...props}
          onDblClick={onDblClick}
          onDragEnd={onDragEnd}
          onDragStart={onDragStart}
          onMouseDown={onMouseDown}
          onMouseMove={onMouseMove}
          onMouseOut={onMouseOut}
          onMouseOver={onMouseOver}
          onMouseUp={onMouseUp}
          onRightClick={onRightClick}
          onClick={onClick}
          onDrag={onDrag}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          {children}
        </MapDom>
      </Wrapper>
    </>
  );
};

export default MapComponent;
