import { useTranslation } from 'react-i18next';

const useFilterOption = () => {
  const { t } = useTranslation();

  const filterStatus = [
    {
      value: 'cancelled',
      label: t('cancelled'),
    },
    {
      value: 'deliveried',
      label: t('deliveried'),
    },
    {
      value: 'assigned',
      label: t('assigned'),
    },
    {
      value: 'new',
      label: t('new'),
    },
  ];

  const filterPayment = [
    {
      value: 'Card',
      label: t('card'),
    },
    {
      value: 'Cash',
      label: t('cash'),
    },
    {
      value: 'Point',
      label: t('point'),
    },
  ];

  const filterExpress = [
    {
      value: 'true',
      label: t('yes'),
    },
    {
      value: 'false',
      label: t('no'),
    },
  ];
  return {
    filterStatus,
    filterExpress,
    filterPayment,
  };
};
export default useFilterOption;
