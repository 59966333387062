import axios from 'axios';
import { isEmpty } from 'lodash';
import { uniqueId } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { config } from '../../config/Config';
import { DataContext } from '../../context/DataContext';
import { createDate } from '../../helper/date/createDateWithTimezone';
import { buildQueryString, parseQueryString } from '../../helper/utils';

import Filters from './filters/Filters';
import OdooLogDetail from './OdooLogDetail';
import useColumns from './useColumns';

const OdooLog = () => {
  const { search } = useLocation();
  const queryString = parseQueryString(search);
  const { t } = useTranslation();

  const [logs, setLogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [limit, setLimit] = useState(100);
  const [total, setTotal] = useState(0);
  const [selectedId, setSelectedId] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState([null, null]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedLog, setSelectedLog] = useState(null);
  const [parsingQuery, setParsingQuery] = useState(true);
  const { currentTimezone } = useContext(DataContext);
  const [newRender, setNewRender] = useState(uniqueId());

  const handlePageChange = async (page) => {
    await setCurrentPage(page);
    setNewRender(uniqueId());
  };

  const { columns } = useColumns({ logs });
  const handleDateFilter = (value) => {
    if (value) {
      const newFilter = {
        ...filters,
        fromDate: new Date(value[0]).getTime(),
        toDate: new Date(value[1]).getTime(),
      };
      setFilters(newFilter);
    } else {
      const newFilter = {
        ...filters,
        fromDate: null,
        toDate: null,
      };
      setFilters(newFilter);
      setDateRange([null, null]);
    }
  };

  useEffect(() => {
    if (filters?.fromDate && filters?.toDate) {
      const dateValue = [new Date(+filters?.fromDate), new Date(+filters?.toDate)];
      setDateRange(dateValue);
    } else {
      setDateRange([null, null]);
    }
  }, [filters?.fromDate, filters?.toDate]);

  const toggleModal = (visible) => {
    setModalVisible(visible);
    if (!visible) {
      setSelectedLog(null);
    }
  };

  const handleToggleModal = (id) => {
    toggleModal(true);
    setSelectedLog(id);
  };

  const getOdooLogs = async (_filter = {}) => {
    const newFilters = {
      filters: {
        ...filters,
        fromDate: filters.fromDate
          ? new Date(createDate(+filters.fromDate, currentTimezone)).getTime()
          : 'filters.fromDate',
        toDate: filters.toDate
          ? new Date(createDate(+filters?.toDate, currentTimezone)).getTime()
          : 'filters.toDate ',
      },
      sort: -1,
      limit: limit,
      page: currentPage,
    };
    const filter = isEmpty(_filter) ? newFilters : _filter;

    const newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      `?${buildQueryString({ ...filter })}`;

    window.history.pushState({ path: newurl }, '', newurl);
    const finalizeFilter = {
      status: [],
      types: [],
      methods: [],
      fromDate: null,
      toDate: null,
      order_number: '',
    };

    if (filter?.filters?.status && filter?.filters?.status?.length > 0) {
      filter?.filters?.status?.forEach((st) => {
        finalizeFilter.status?.push(st.value);
      });
    }

    if (filter?.filters?.types && filter?.filters?.types?.length > 0) {
      filter?.filters?.types?.forEach((st) => {
        finalizeFilter.types?.push(st.value);
      });
    }

    if (filter?.filters?.methods && filter?.filters?.methods?.length > 0) {
      filter?.filters?.methods?.forEach((st) => {
        finalizeFilter.methods?.push(st.value);
      });
    }

    if (filter?.filters?.fromDate) {
      finalizeFilter.fromDate = +filter?.filters?.fromDate;
    }

    if (filter?.filters?.toDate) {
      finalizeFilter.toDate = +filter?.filters?.toDate;
    }
    if (filter?.filters?.order_number) {
      finalizeFilter.order_number = filter?.filters?.order_number;
    }

    setLoading(true);
    const response = await axios.get(`${config.API_URL.ODOO_LOGS.LIST}`, {
      params: {
        ...filter,
        filters: finalizeFilter,
      },
      headers: config.headers,
    });

    const { data = {}, total } = response?.data;

    if (total >= 0) {
      setLogs(data);
      await setTotal(total);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!parsingQuery) {
      getOdooLogs();
    }
  }, [limit, newRender, parsingQuery]);

  useEffect(() => {
    if (queryString.page) {
      setCurrentPage(+queryString.page);
    }
    if (queryString.filters) {
      const filter = { ...queryString.filters };
      if (filter.fromDate) {
        filter.fromDate = +filter.fromDate;
      }
      if (filter.toDate) {
        filter.toDate = +filter.toDate;
      }
      setFilters(filter);
    }
    if (queryString.limit > 0) {
      setLimit(+queryString.limit);
    }
    setParsingQuery(false);
  }, []);

  const tableHeight = window.innerHeight - 330;

  const conditionalRowStyles = [
    {
      when: (row) => row?.response?.result?.state !== 'ready',
      style: {
        backgroundColor: '#F7DDDE',
      },
    },
    {
      when: (row) => row?.response?.result?.state === 'ready',
      style: {
        backgroundColor: '#D4E9D8',
      },
    },
  ];

  const selectHandler = (e, key) => {
    const newFilters = {
      ...filters,
      [key]: e,
    };
    setFilters(newFilters);
  };

  const handleFilterOrderNumber = (value) => {
    const newFilters = {
      ...filters,
      order_number: value,
    };
    setFilters(newFilters);
  };
  const onGetOdooLogs = async () => {
    await setCurrentPage(1);
    const newFilters = {
      filters: {
        ...filters,
        fromDate: filters.fromDate
          ? new Date(createDate(+filters.fromDate, currentTimezone)).getTime()
          : 'filters.fromDate',
        toDate: filters.toDate
          ? new Date(createDate(+filters?.toDate, currentTimezone)).getTime()
          : 'filters.toDate ',
      },
      sort: -1,
      limit: limit,
      page: 1,
    };
    getOdooLogs(newFilters);
  };
  return (
    <div className="rtl">
      <Filters
        dateRange={dateRange}
        filters={filters}
        getLogs={onGetOdooLogs}
        handleDateFilter={handleDateFilter}
        handleFilterOrderNumber={handleFilterOrderNumber}
        loading={loading}
        selectHandler={selectHandler}
      />
      <DataTable
        noDataComponent={<div style={{ padding: '24px' }}>{t('orders_no_records')}</div>}
        highlightOnHover
        columns={columns}
        data={logs}
        fixedHeader
        pagination
        contextMessage={{ singular: t('item'), plural: t('items'), message: t('selected') }}
        fixedHeaderScrollHeight={tableHeight.toString() + 'px'}
        paginationPerPage={limit}
        paginationRowsPerPageOptions={[100, 200, 400, 600]}
        onChangeRowsPerPage={(currentRowsPerPage) => setLimit(+currentRowsPerPage)}
        progressPending={loading}
        progressComponent={<div className="wait">{t('please_wait')}</div>}
        paginationTotalRows={total}
        paginationServer
        onChangePage={handlePageChange}
        paginationComponentOptions={{
          rowsPerPageText: t('total_record'),
          rangeSeparatorText: t('from'),
          noRowsPerPage: false,
          selectAllRowsItem: false,
        }}
        selectableRows
        selectableRowSelected={(row) => row.isSelected}
        onRowExpandToggled={(toggleState, row) => [
          toggleState
            ? setSelectedId((p) => [...p, row._id])
            : setSelectedId(selectedId.filter((i) => i !== row._id)),
        ]}
        conditionalRowStyles={conditionalRowStyles}
        onRowClicked={(row) => handleToggleModal(row?._id)}
        paginationDefaultPage={currentPage}
        selectableRowsVisibleOnly
        persistTableHead
        selectableRowsHighlight
        direction={'rtl'}
        customStyles={{
          headCells: {
            style: {
              fontWeight: 'bold',
              fontSize: 12,
              background: '#F9F9F9',
            },
          },
          rows: {
            style: {
              cursor: 'pointer',
              fontSize: 12,
            },
          },
          header: {
            style: {
              minHeight: 0,
            },
          },
        }}
      />
      <OdooLogDetail id={selectedLog} visible={modalVisible} toggleModal={toggleModal} />
    </div>
  );
};

export default OdooLog;
