import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FORMAT_DATE, parseDateWithGmt } from '../../helper/date/parseDateGmt';
import { useDate } from '../../hooks/useDate';

export default function useColumns() {
  const { t } = useTranslation();
  const { currentTimezone } = useDate();

  const columns = useMemo(
    () => [
      {
        name: t('id'),
        selector: '_id',
        sortable: true,
        minWidth: '200px',
      },
      {
        name: t('id_number'),
        selector: 'id_number',
        sortable: true,
        minWidth: '150px',
      },
      {
        name: t('name'),
        selector: (row) => row?.name || '',
        sortable: true,
        width: '200px',
        style: {
          padding: 0,
        },
      },
      {
        name: t('phone'),
        selector: 'phone',
        sortable: true,
        style: {
          padding: 0,
        },
      },
      {
        name: t('driver_registration_order.date_of_birth'),
        selector: 'date_of_birth',
        sortable: true,
        width: '150px',
        style: {
          padding: 0,
        },
      },
      {
        name: t('driver_registration_order.identity_type'),
        selector: 'identity_type',
        sortable: true,
        style: {
          padding: 0,
        },
      },
      {
        name: t('city'),
        selector: 'city',
        sortable: true,
        width: '150px',
        style: {
          padding: 0,
        },
      },
      {
        name: t('region'),
        selector: (row) => row?.region,
        sortable: true,
        width: '150px',
        style: {
          padding: 0,
        },
      },
      {
        name: t('driver_registration_order.car_type'),
        selector: 'car_type',
        sortable: true,
        width: '150px',
        style: {
          padding: 0,
        },
      },
      {
        name: t('car_number'),
        selector: 'car_number',
        sortable: true,
        width: '150px',
        style: {
          padding: 0,
        },
      },
      {
        name: t('vehicle_sequence_number'),
        selector: (row) => `${row?.vehicle_sequence_number || ''}`,
        sortable: true,
        width: '200px',
        style: {
          padding: 0,
        },
      },
      {
        name: t('company'),
        selector: (row) => `${row?.company_name || ''}`,
        sortable: true,
        width: '200px',
        style: {
          padding: 0,
        },
      },
      {
        name: t('stcPay'),
        selector: (row) => row?.stc_pay_number || '',
        sortable: true,
        width: '200px',
        style: {
          padding: 0,
        },
      },
      {
        name: t('status'),
        selector: (row) => row?.status || '',
        sortable: true,
        width: '200px',
        style: {
          padding: 0,
        },
      },
      {
        name: t('date_time'),
        selector: (row) =>
          parseDateWithGmt({
            date: row.created_at,
            timezone: currentTimezone?.timezone,
            format: FORMAT_DATE.ymdhms,
          }),
        sortable: true,
        width: '300px',
        wrap: true,
        style: {
          padding: 0,
        },
      },
    ],
    [currentTimezone?.timezone, t]
  );
  return { columns };
}
