import { Link } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import React, { useEffect, useRef, useState } from 'react';
import { CloseIcon, DropIcon, LogOutIcon, MenuIcon } from '../constants/Icon';

const BurgerButton = ({ active, setActive, path, t, showOpsManagement, logout, menuList }) => {
  const elRef = useRef();
  const [height, setHeight] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuNamesActivated, setMenuNamesActivated] = useState({});

  useEffect(() => {
    if (!elRef?.current?.clientHeight) {
      return;
    }
    setHeight(elRef?.current?.clientHeight);
  }, [elRef?.current?.clientHeight]);

  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  const handleStateChange = (state) => {
    setMenuOpen(state.isOpen);
  };

  // This can be used to close the menu, e.g. when a user clicks a menu item
  const closeMenu = () => {
    setMenuOpen(false);
  };

  const onClickItem = (menu) => {
    setActive(menu);
    closeMenu();
  };

  //
  const handleOnOpenLogList = (menuName) => {
    //expand menu
    let activeMenuData = {
      //allow multiple expand menu
      // ...menuNamesActivated,
      [menuName]: menuName,
    };
    //collapse menu
    if (menuNamesActivated[menuName]) {
      activeMenuData = {
        //allow multiple expand menu
        // ...menuNamesActivated,
        [menuName]: null,
      };
    }
    setMenuNamesActivated(activeMenuData);
  };

  const renderMenuItem = (menu) => {
    const dataLength = menu?.data?.length;
    if (menu?.allowExpand) {
      return (
        <ul className={`${menuNamesActivated[menu.name] ? 'bg-gray-50' : ''}`}>
          {/* /collapse/ */}
          <li
            className={`flex flex-row px-10 justify-between pt-2`}
            onClick={() => handleOnOpenLogList(menu.name)}
          >
            <span
              className={`flex flex-row  hover:text-[#be2448] ${
                active === menu.state || path === menu.path ? 'text-[#be2448] font-bold ' : ''
              } text-2xl pb-2 `}
            >
              <span className="pl-8 ">{menu.icon}</span>
              {t(menu.name)}
            </span>
            <span
              className={`mb-3 duration-500 ${
                !menuNamesActivated[menu.name] ? 'rotate-90 ' : 'rotate-180'
              }`}
            >
              <DropIcon />
            </span>
          </li>
          {/* /expand/*/}
          <div
            className={`overflow-hidden transition-all duration-500 ease-in-out  max-h-0`}
            style={{ maxHeight: menuNamesActivated[menu.name] ? height * dataLength : '' }}
          >
            {menu?.data.map((item, index) => {
              return (
                <li className={`py-2 px-16  hover:bg-gray-300`} key={index} ref={elRef}>
                  <Link
                    className={` hover:text-[#be2448] ${
                      active === item.state || path === item.path ? 'text-[#be2448] font-bold ' : ''
                    }`}
                    to={{
                      pathname: item.path,
                      state: {
                        load: true,
                      },
                    }}
                    key={item.id}
                  >
                    <li
                      className={`h-14 flex items-center px-14`}
                      onClick={() => onClickItem(item.state)}
                    >
                      <span className={`text-[14px]`}>{t(item.name)}</span>
                    </li>
                  </Link>
                </li>
              );
            })}
          </div>
        </ul>
      );
    }
    //
    return (
      <li className="h-14 flex items-center px-10" onClick={() => onClickItem(menu.state)}>
        <span className="pl-8">{menu.icon} </span>
        <span className="text-2xl">{t(menu.name)}</span>
      </li>
    );
  };

  const renderMenuList = () => {
    return menuList.map((menu) => {
      if (showOpsManagement || !menu.superAdminOnly) {
        return (
          <Link
            className={`${
              active === menu.state || path === menu.path ? 'text-[#be2448] font-bold' : ''
            }`}
            to={{
              pathname: menu.path,
              state: {
                load: true,
              },
            }}
            key={menu.id}
          >
            {renderMenuItem(menu)}
          </Link>
        );
      }
      //
      return null;
    });
  };

  return (
    <Menu
      right
      styles={styles}
      isOpen={menuOpen}
      onStateChange={(state) => handleStateChange(state)}
      customBurgerIcon={<MenuIcon />}
      customCrossIcon={<CloseIcon />}
    >
      <ul>
        {renderMenuList()}
        <li className="h-14 min-w-max flex items-center px-10" onClick={logout}>
          <span className="flex flex-row text-2xl cursor-pointer hover:text-[#be2448]">
            <span className="pl-8">{<LogOutIcon />}</span>
            {t('logout')}
          </span>
        </li>
      </ul>
    </Menu>
  );
};

export default BurgerButton;

const styles = {
  bmBurgerButton: {
    position: 'relative',
    color: 'white',
    cursor: 'pointer',
    padding: '8px',
    borderRadius: '6px',
    background: '#aa1135',
    zIndex: 3,
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
    top: '32px',
    right: '18px',
  },
  bmCross: {
    background: '#F2F2F2',
  },
  bmMenuWrap: {
    position: 'fixed',
    top: 0,
    right: 0,
    height: '100%',
  },
  bmMenu: {
    background: '#FFFFFF',
    padding: '48px 0',
  },
  bmMorphShape: {
    fill: '#373a47',
  },
  bmItemList: {
    padding: '16px 0',
  },
  bmItem: {
    color: '#2B2B35',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
    position: 'fixed',
    top: 0,
    right: 0,
    height: '100%',
    zIndex: 3,
  },
};
