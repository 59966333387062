import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckMark } from '../../constants/Icon';

export default function ModalResetPassword({ isShowModal, hideModal, password }) {
  const { t } = useTranslation();

  return (
    <Transition appear show={isShowModal} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={hideModal}>
        <div className="min-h-screen px-4 text-center rtl flex items-center justify-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-[#00000065]" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="min-h-screen flex items-center justify-center w-full  max-w-3xl  text-left align-middle transition-all duration-300 transform ">
              <div className="w-full  items-center  flex flex-col  bg-white shadow-xl rounded-2xl  py-12 px-12">
                <div className="font-semibold text-4xl">{t('generate_password')}</div>

                <div className="flex flex-row items-center mt-8">
                  <div className="w-12 h-12">
                    <CheckMark color="#32BF84" />
                  </div>
                  <span className="text-2xl mx-2 text-[#32BF84] font-medium">
                    {t('generate_password_success')}
                  </span>
                </div>
                <div className="flex flex-row mt-4">
                  <div className="text-2xl">{t('new_Password')}: </div>
                  <div className="px-2 text-2xl font-bold">{password}</div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
