import React, { useEffect, useState } from 'react';
import { useComponentVisible } from '../constants/customHooks';
import { IconDropdown } from '../constants/Icon';

const DropdownInput = ({
  handleSelect = () => {},
  textAlign = '',
  options = [],
  placeholder = 'Select...',
  title = '',
  hasDefault = true,
  className = '',
  wrapperClassName = '',
  defaultValue, //  = { value: '', label: 'All' }
  defaultSelectedValue = null,
  disable = false,
}) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible();

  const [selected, setSelected] = useState(null);
  const [optionList, setOptionList] = useState([]);

  useEffect(() => {
    if (hasDefault) {
      if (defaultValue) {
        setOptionList([defaultValue, ...options]);
      } else {
        setOptionList([{ value: '', label: 'All' }, ...options]);
      }
    } else {
      setOptionList([...options]);
    }
  }, [options, hasDefault, defaultValue]);

  useEffect(() => {
    if (defaultSelectedValue) {
      handleSelectOption(defaultSelectedValue);
    }
  }, [defaultSelectedValue]);

  const toggleVisible = () => {
    if (disable) return;
    setIsComponentVisible(!isComponentVisible);
  };

  const textAlignClass = () => {
    switch (textAlign) {
      case 'left': {
        return 'text-left';
      }
      case 'center': {
        return 'text-center';
      }
      case 'right': {
        return 'text-right';
      }
      default: {
        return 'text-left';
      }
    }
  };

  const handleSelectOption = (item) => {
    setSelected(item);
    handleSelect(item);
  };

  return (
    <div
      className={`input-select-wrapper ${wrapperClassName}} ${disable && 'select-disable-grey'} `}
    >
      {title?.length > 0 && <p className="input-select-title">{title}</p>}
      <div
        className={`${className ? className : ''} relative input-select-input-wrapper`}
        ref={ref}
        onClick={toggleVisible}
      >
        <div className="absolute" style={{ top: 15, right: 15, color: '#6f6f6f' }}>
          <IconDropdown />
        </div>
        <div className="input-select relative flex">
          {selected ? (
            <p className="input-select-current">{selected.label}</p>
          ) : (
            <p className={`input-select-placeholder ${textAlignClass()}`}>{placeholder}</p>
          )}
        </div>
        <div className={`input-select-options ${isComponentVisible ? 'visible' : ''}`}>
          {optionList.map((item) => (
            <button
              type="button"
              className={`option ${textAlignClass()}`}
              onClick={() => handleSelectOption(item)}
              key={item.value}
            >
              {item.label}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DropdownInput;
