import React, { memo } from 'react';
import { TabPanel } from 'react-headless-tabs';
import OrderCount from '../../../Atoms/OrderCount';
import ListOrders from '../ListOrders';

const TabContent = ({ selectedTab, handlerRefresh, apiVersion, loadingConfig }) => {
  return (
    <>
      <TabPanel hidden={selectedTab !== 'dashboard'}>
        <OrderCount />
      </TabPanel>
      <TabPanel hidden={selectedTab !== 'order_list'}>
        <ListOrders
          handlerRefresh={handlerRefresh}
          apiVersion={apiVersion}
          loadingConfig={loadingConfig}
        />
      </TabPanel>
    </>
  );
};

export default memo(TabContent);
