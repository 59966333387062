import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { config } from '../../config/Config';
import { CompareGmtAndCalcTimestamp, GetTimezone } from '../../helper/date/getLocalGmtWithTimezone';

import { buildQueryString, parseQueryString } from '../Orders/Orders.js';
import Filters from './filters/Filters';
import HttpLogsTable from './HttpLogsTable';
import HttpLogDetailsModal from './HttpLogModal/HttpLogDetailsModal.jsx';

const LogsHttp = () => {
  const { search } = useLocation();
  const queryString = parseQueryString(search);

  const { t } = useTranslation();

  const [logs, setLogs] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(queryString.page || 1);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedId, setSelectedId] = useState([]);
  const [selectedLogId, setSelectedLogId] = useState(null);
  const [curLimit, setCurLimit] = useState(queryString.limit || 100);
  const [filters, setFilters] = useState(queryString.filters || {});
  const [dateRange, setDateRange] = useState([null, null]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOpenModal = (recordId) => {
    setIsModalVisible(true);
    setSelectedLogId(recordId);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setSelectedLogId(null);
  };

  const onSetFilters = (newFilter) => {
    setFilters(newFilter);
  };

  const getLogs = useCallback(async (filters) => {
    if (isLoaded) return false;

    setIsLoaded(true);

    const newUrl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      `?${buildQueryString({ ...filters })}`;

    window.history.pushState({ path: newUrl }, '', newUrl);
    try {
      const resp = await axios.get(config.API_URL.HTTP_LOGS.LIST, {
        params: {
          ...filters,
        },
        headers: config.headers,
      });
      setLogs(resp.data?.data || []);
      setTotal(resp.data?.total || 0);
    } catch (e) {
    } finally {
      setIsLoaded(false);
    }
  }, []);

  useEffect(() => {
    const newFilters = {
      limit: curLimit,
      page: currentPage,
      filters,
    };

    getLogs(newFilters);
  }, [currentPage, curLimit, getLogs]);

  const handlerFilters = (e) => {
    const newFilters = {
      ...filters,
      [e.target.name]: e.target.value,
    };
    onSetFilters(newFilters);
  };

  const selectHandler = (e, key) => {
    const newFilters = {
      ...filters,
      [key]: e,
    };
    onSetFilters(newFilters);
  };

  const handleDateFilter = (value) => {
    if (value) {
      const newFilters = {
        ...filters,
        fromDate: new Date(value[0]).getTime(),
        toDate: new Date(value[1]).getTime(),
      };

      return onSetFilters(newFilters);
    }

    const newFilter = {
      ...filters,
      fromDate: null,
      toDate: null,
    };
    onSetFilters(newFilter);
  };

  const refresh = () => {
    const newFilters = {
      limit: curLimit,
      page: currentPage,
      filters,
    };

    getLogs(newFilters);
  };

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  useEffect(() => {
    if (filters?.fromDate && filters?.toDate) {
      const dateValue = [new Date(+filters?.fromDate), new Date(+filters?.toDate)];
      return setDateRange(dateValue);
    }

    return setDateRange([null, null]);
  }, [filters?.fromDate, filters?.toDate]);

  const RefreshData = () => {
    return (
      <div className="flex">
        <div className="h-full flex items-center gap-x-4" onClick={() => refresh()}>
          <button className="refresh-button">{t('refresh')} </button>
        </div>
      </div>
    );
  };

  const onApplyFilter = useCallback(async () => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }

    const { settingGmt, localGmt } = await GetTimezone();
    const newFilters = {
      limit: curLimit,
      page: currentPage,
      filters: {
        ...filters,
        fromDate: filters.fromDate
          ? CompareGmtAndCalcTimestamp(settingGmt, localGmt, filters.fromDate)
          : null,
        toDate: filters.toDate
          ? CompareGmtAndCalcTimestamp(settingGmt, localGmt, filters.toDate)
          : null,
      },
    };

    getLogs(newFilters);
  }, [currentPage, filters, curLimit, getLogs]);

  return (
    <div className="pt-4">
      <div className="px-4">
        <RefreshData />
        <Filters
          filters={filters}
          handlerFilters={handlerFilters}
          handleDateFilter={handleDateFilter}
          dateRange={dateRange}
          selectHandler={selectHandler}
          isLoaded={isLoaded}
          onApplyFilter={onApplyFilter}
          curLimit={curLimit}
          currentPage={currentPage}
        />
      </div>

      <HttpLogsTable
        curLimit={curLimit}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        isLoaded={isLoaded}
        logs={logs}
        selectedId={selectedId}
        setSelectedId={setSelectedId}
        setCurLimit={setCurLimit}
        setCurrentPage={setCurrentPage}
        total={total}
        handleOpenModal={handleOpenModal}
      />

      {isModalVisible && (
        <HttpLogDetailsModal
          logId={selectedLogId}
          isModalVisible={isModalVisible}
          handleCloseModal={handleCloseModal}
        />
      )}
    </div>
  );
};

export default LogsHttp;
