import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../../../../commonComponents/LoadingButton';
import useEventEnter from '../../../../hooks/useEventEnter';
import CustomSelectReact from '../../../CustomSelectReact';
import useFilterOptionOrder from './filterOption';
import useFilter from './useFilter';

function Filters(
  { isLoading = false, setCurrentPage, handlerGetOrder, buildFilterOrderWithRef, currentPage },
  refFilters
) {
  const { handlerFiltersInput, selectHandlerSelect, handleDateFilter, onApplyFilter } = useFilter({
    setCurrentPage,
    handlerGetOrder,
    refFilters,
    buildFilterOrderWithRef,
    currentPage,
  });
  const { t } = useTranslation();
  const {
    filterPayment,
    filterExpress,
    filterIsDiscount,
    filterStatus,
    filterCompany,
    filterDelivery,
  } = useFilterOptionOrder();
  useEventEnter(onApplyFilter);

  return (
    <div className="search-order flex flex-col mb-[35px] rtl items-start">
      <div className=" py-5 flex items-center gap-4 flex-wrap mb-4">
        <div className="flex">
          <input
            placeholder={t('customer')}
            className="filter-input"
            name="customer_name"
            onChange={handlerFiltersInput}
            ref={(ref) => (refFilters.current.customer_name = ref)}
          />
        </div>
        <div className="flex">
          <input
            placeholder={t('order_number')}
            className="filter-input"
            name="order_number"
            ref={(ref) => (refFilters.current.order_number = ref)}
            onChange={handlerFiltersInput}
          />
        </div>
        <div className="flex">
          <input
            placeholder={t('restaurant')}
            className="filter-input"
            name="restaurant"
            ref={(ref) => (refFilters.current.restaurant = ref)}
            onChange={handlerFiltersInput}
          />
        </div>
        <div className="flex">
          <input
            placeholder={t('phone')}
            className="filter-input"
            name="phone"
            ref={(ref) => (refFilters.current.phone = ref)}
            onChange={handlerFiltersInput}
          />
        </div>
        <div className="flex">
          <input
            placeholder={t('driver_name')}
            className="filter-input o"
            name="driver_name"
            ref={(ref) => (refFilters.current.driver_name = ref)}
            onChange={handlerFiltersInput}
          />
        </div>
        <div className="flex">
          <CustomSelectReact
            isDisabled={isLoading}
            placeholder={t('payment')}
            className="filter-select min-w-[200px]"
            options={filterPayment}
            value={refFilters?.current?.payment || []}
            onChange={(e) => selectHandlerSelect(e, 'payment')}
            isMulti
          />
        </div>
        <div className="flex">
          <CustomSelectReact
            isDisabled={isLoading}
            placeholder={t('isFreeExpress')}
            className="filter-select min-w-[200px]"
            options={filterExpress}
            value={refFilters?.current?.isFreeExpress || []}
            onChange={(e) => selectHandlerSelect(e, 'isFreeExpress')}
          />
        </div>
        <div className="flex">
          <CustomSelectReact
            isDisabled={isLoading}
            placeholder={t('is_show_discount')}
            className="filter-select min-w-[200px]"
            options={filterIsDiscount}
            value={refFilters?.current?.isDiscount || []}
            onChange={(e) => selectHandlerSelect(e, 'isDiscount')}
          />
        </div>
        <div className="flex">
          <CustomSelectReact
            isDisabled={isLoading}
            placeholder={t('status')}
            className="filter-select min-w-[200px]"
            options={filterStatus}
            value={refFilters?.current?.status || []}
            onChange={(e) => selectHandlerSelect(e, 'status')}
            isMulti
          />
        </div>
        <div className="flex">
          <CustomSelectReact
            isDisabled={isLoading}
            placeholder={t('company')}
            className="filter-select min-w-[200px]"
            options={filterCompany}
            // ref={(ref) => (refFilters.current.from_company = ref)}
            value={refFilters?.current?.from_company || []}
            onChange={(e) => selectHandlerSelect(e, 'from_company')}
            isMulti
          />
        </div>
        <div className="flex">
          <CustomSelectReact
            isDisabled={isLoading}
            placeholder={t('delivery_company_filter')}
            className="filter-select min-w-[200px]"
            options={filterDelivery}
            value={refFilters?.current?.driver_company || []}
            onChange={(e) => selectHandlerSelect(e, 'driver_company')}
            isMulti
          />
        </div>
        <div className="input-select-wrapper ltr">
          <DateTimeRangePicker
            className="input-select-input-wrapper !border-0 w-[200px]"
            disabled={isLoading}
            // value={newCreateRangeDate(refFilters.current?.date)}
            value={refFilters.current?.date}
            onChange={handleDateFilter}
            disableClock
            format={'y-MM-dd'}
          />
        </div>
        <div className="relative w-full">
          <div className="absolute right-0 ">
            <LoadingButton onClick={onApplyFilter} label={t('Apply Filter')} loading={isLoading} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default forwardRef(Filters);
