import axios from 'axios';
import moment from 'moment';
import { useState } from 'react';
import { config } from '../../../../config/Config';
import { handleMessage } from '../../../../helper/utils';

export default function useActionOrder({
  setSelectedTab,
  setCurrentPage,
  buildNewQueryStringWithFilter,
  buildFilterOrderWithRef,
}) {
  const [isExportStc, setIsExportStc] = useState(false);
  const [isExportExcel, setIsExportExcel] = useState(false);

  const onChangeTab = (tab) => {
    setCurrentPage(1);
    setSelectedTab(tab);
  };

  const exportSTCExcel = () => {
    setIsExportStc(true);
    console.log(
      'buildNewQueryStringWithFilter',
      buildNewQueryStringWithFilter(buildFilterOrderWithRef())
    );

    try {
      axios
        .post(config.API_URL.ORDERS.EXPORT_STC_EXCEL, null, {
          params: {
            ...buildNewQueryStringWithFilter(buildFilterOrderWithRef()),
          },
          headers: {
            ...config.headers,
            'Content-Disposition': 'attachment; filename=template.xlsx',
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
          responseType: 'arraybuffer',
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `order-STC-${moment().locale('en').format('MM-DD-YYYY')}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          setIsExportStc(false);
        });
    } catch (errors) {
      setIsExportStc(false);
    } finally {
    }
  };

  const exportExcel = () => {
    setIsExportExcel(true);
    try {
      axios
        .post(config.API_URL.ORDERS.EXPORT_EXCEL, null, {
          params: {
            ...buildNewQueryStringWithFilter(buildFilterOrderWithRef()),
          },
          headers: {
            ...config.headers,
            'Content-Disposition': 'attachment; filename=template.xlsx',
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
          responseType: 'arraybuffer',
        })
        .then((response) => {
           if (response?.status === 200) {
             handleMessage('success', 'Export excel Successfully! Check your email.');
           }
          setIsExportExcel(false);
          // const url = window.URL.createObjectURL(new Blob([response.data]));
          // const link = document.createElement('a');
          // link.href = url;
          // link.setAttribute('download', `order-${moment().locale('en').format('MM-DD-YYYY')}.xlsx`);
          // document.body.appendChild(link);
          // link.click();
        });
    } catch (errors) {
      handleMessage('error', 'Export excel failed! Please export excel again.');
      setIsExportExcel(false);
    } 
  };

  return {
    setSelectedTab,
    onChangeTab,
    exportSTCExcel,
    exportExcel,
    isExportStc,
    isExportExcel,
  };
}
