import React, { memo } from 'react';
import useMapTracking from '../../../../hooks/useMapTracking';
import DriverCard from '../../../Atoms/DriverCard';

const DriverCards = memo(({ selectedTab }) => {
  const { driverId, handlerSelectedDriver, drivers } = useMapTracking();

  return (
    <>
      <nav>
        <ul>
          {drivers.map((driver) => {
            return (
              <DriverCard
                status={selectedTab}
                key={driver._id}
                driver={driver}
                idDriverFocus={driverId}
                setSelectedDriver={handlerSelectedDriver}
              />
            );
          })}
        </ul>
      </nav>
    </>
  );
});

export default DriverCards;
