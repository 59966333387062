import { useTranslation } from 'react-i18next';
import { STATUS_MAPPING } from '../../helper/regexHelper';

export const FilterStatuses = () => {
  const { t } = useTranslation();

  return [
    {
      value: 'SUCCESS',
      label: t('SUCCESS'),
    },
    {
      value: 'FAILURE',
      label: t('FAILURE'),
    },
    {
      value: 'PENDING',
      label: t('PENDING'),
    },
  ];
};

export const fromApplication = [
  {
    value: 'EXPRESS',
    label: 'EXPRESS',
  },
  {
    value: 'RESTAURANT',
    label: 'RESTAURANT',
  },
  {
    value: 'SHUKAH_CLIENT',
    label: 'SHUKAH_CLIENT',
  },
  {
    value: 'LOCATE_CLIENT',
    label: 'LOCATE_CLIENT',
  },
  {
    value: 'CLIENT',
    label: 'CLIENT',
  },
];

export const conditionalRowStyles = [
  {
    when: (row) => row?.transaction_status === STATUS_MAPPING.FAILURE,
    style: {
      backgroundColor: '#F7DDDE',
    },
  },
  {
    when: (row) => row?.transaction_status === STATUS_MAPPING.SUCCESS,
    style: {
      backgroundColor: '#D4E9D8',
    },
  },
  {
    when: (row) => row?.transaction_status === STATUS_MAPPING.PENDING,
    style: {
      backgroundColor: '#FFFBDB',
    },
  },
];
