/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { config, SETTING_KEY } from '../../config/Config';
toast.configure();

const TimeExecute = ({ execute, setExcute }) => {
  const { t } = useTranslation();

  const getSetting = async () => {
    const resp = await axios.get(config.API_URL.SETTING.GET, {
      params: {
        key: SETTING_KEY.DRIVER_TIME_EXECUTE,
      },
      headers: config.headers,
    });
    const { data } = resp;
    const { setting } = data;
    setExcute({
      time: setting.time || 0,
      distance: setting.distance || 0,
      distance_send_notify: setting.distance_send_notify || 0,
    });
  };

  useEffect(() => {
    getSetting();
  }, []);

  const handlerChange = (e) => {
    const newValue = {
      ...execute,
      [e.target.name]: e.target.value,
    };
    setExcute(newValue);
  };

  return (
    <div className="setting">
      <h3>{t('flight_end_time')}</h3>
      <div className="row flex items-center gap-x-4">
        <label>{t('duration')}: </label>
        <input type="number" value={execute.time} name="time" onChange={handlerChange} />
        <label className="pr-2">{t('accurate')}</label>
      </div>

      <div className="row flex items-center gap-x-4">
        <label>{t('distance')}: </label>
        <input type="number" value={execute.distance} name="distance" onChange={handlerChange} />
        <label className="pr-2">{t('km')}</label>
      </div>
      <div className="row flex items-center gap-x-4">
        <label>{t('distance_send_notify')}: </label>
        <input
          type="number"
          value={execute.distance_send_notify}
          name="distance_send_notify"
          onChange={handlerChange}
        />
        <label className="pr-2">{t('km')}</label>
      </div>
    </div>
  );
};
export default TimeExecute;
