import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const TYPE_API = {
  /**Order Type*/
  ORDER_CREATE: 'CREATE ORDER',
  ORDER_CANCEL: 'CANCEL ORDER',
  ACCEPT_ORDER: 'ACCEPT ORDER',
  ORDER_ASSIGN: 'ASSIGN ORDER',
  ORDER_ACCEPT: 'ACCEPT ORDER',
  ORDER_EXECUTE: 'EXECUTE ORDER',
  DRIVER_EXECUTE_ORDER: 'EXECUTE ORDER',
  DRIVER_ASSIGN_DRIVER_TO_ORDER: 'ASSIGN ORDER TO DRIVER',
  DRIVER_ACCEPT_ORDER: 'DRIVER ACCEPT ORDER',
  /** Driver type */
  DRIVER_CREATE: 'DRIVER CREATE',
  DRIVER_EDIT: 'DRIVER EDIT',
  DRIVER_DEACTIVATE: 'DRIVER DEACTIVATE',
  DRIVER_GET: 'DRIVER GET',
  DRIVER_UPDATE: 'DRIVER UPDATE',
};

const useSchema = ({ logType }) => {
  const { t } = useTranslation();

  let schema = [];

  if (logType === 'all') {
    schema = [
      {
        name: t('url'),
        selector: 'url',
        sortable: true,
      },
      {
        name: t('method'),
        selector: 'method',
        sortable: true,
        width: '150px',
      },
      {
        name: t('driver_name'),
        selector: 'driver',
        sortable: true,
        cell: (row) => row?.driver?.name || '-',
      },
      {
        name: t('order_number'),
        selector: (row) => `${row.filters ? row.filters.id : ''}`,
        sortable: true,
        width: '200px',
      },
      {
        name: t('action'),
        selector: (row) => `${TYPE_API[row.type]}`,
        sortable: true,
        width: '200px',
      },
      {
        name: t('status'),
        selector: (row) => `${row.isSuccess ? t('success') : t('failure')}`,
        sortable: true,
        width: '150px',
        wrap: true,
      },
      {
        name: t('date_time'),
        selector: (row) =>
          `${
            row.created_at !== null
              ? moment(row.created_at).locale('ar').format('LL ~ hh:mm A')
              : '...'
          }`,
        sortable: true,
        width: '300px',
        wrap: true,
      },
    ];
  }

  if (logType === 'timeout') {
    schema = [
      {
        name: t('url'),
        selector: 'url',
        sortable: true,
      },
      {
        name: t('method'),
        selector: 'method',
        sortable: true,
      },
      {
        name: t('driver_name'),
        selector: 'driver',
        sortable: true,
        cell: (row) => row?.driver?.name || '-',
      },
      {
        name: t('action'),
        selector: (row) => `${TYPE_API[row.type]}`,
        sortable: true,
      },
      {
        name: t('date_time'),
        selector: (row) =>
          `${
            row.created_at !== null
              ? moment(row.created_at).locale('ar').format('LL ~ hh:mm A')
              : '...'
          }`,
        sortable: true,
        wrap: true,
      },
    ];
  }

  return schema;
};

export default useSchema;
