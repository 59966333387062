import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import TableComponents from '../../../commonComponents/TableComponents';
import useSchema from './Schema';

function DataTableDrivers({
  selectedTab,
  setSelectedItem,
  isLoading,
  limit,
  setLimit,
  currentPage,
  setCurrentPage,
  total,
  handlePageChange,
  onRowClicked,
  data = [],
  selectedId,
  height,
  ...rest
}) {
  const { t } = useTranslation();
  const schema = useSchema();
  const tableHeight = window.innerHeight - (height + 250);
  const conditionalRowStyles = [
    {
      when: (row) => selectedId.includes(row._id),
      style: {
        backgroundColor: '#C32E52',
        color: '#fff',
      },
    },
  ];
  return (
    <TableComponents
      noDataComponent={<div style={{ padding: '24px' }}>{t('orders_no_records')}</div>}
      columns={schema}
      data={data}
      contextMessage={{ singular: t('item'), plural: t('items'), message: t('selected') }}
      fixedHeader
      pagination
      paginationDefaultPage={currentPage}
      fixedHeaderScrollHeight={tableHeight.toString() + 'px'}
      paginationRowsPerPageOptions={[100, 200, 400, 600]}
      onChangeRowsPerPage={(currentRowsPerPage) => {
        setLimit(currentRowsPerPage);
        setCurrentPage(1);
      }}
      paginationPerPage={+limit}
      progressPending={isLoading}
      paginationTotalRows={total}
      progressComponent={<div className="wait">{t('please_wait')}</div>}
      paginationServer
      subHeader
      onChangePage={handlePageChange}
      conditionalRowStyles={conditionalRowStyles}
      paginationComponentOptions={{
        rowsPerPageText: t('total_record'),
        rangeSeparatorText: t('from'),
        noRowsPerPage: false,
        selectAllRowsItem: false,
      }}
      selectableRows
      selectableRowSelected={(row) => row.isSelected}
      onSelectedRowsChange={(row) => setSelectedItem(row.selectedRows)}
      selectableRowsVisibleOnly
      persistTableHead
      selectableRowsHighlight
      direction={'rtl'}
      expandableRows
      expandableRowsHideExpander
      expandOnRowClicked
      onRowClicked={onRowClicked}
      customStyles={customStylesTable}
      {...rest}
    />
  );
}
export default memo(DataTableDrivers);

const customStylesTable = {
  headCells: {
    style: {
      fontWeight: 'bold',
      fontSize: 12,
      background: '#F9F9F9',
      justifyContent: 'flex-start',
      padding: 0,
    },
  },
  rows: {
    style: {
      cursor: 'pointer',
      fontSize: 12,
    },
  },
  header: {
    style: {
      minHeight: 0,
    },
  },
  subHeader: {
    style: {
      minHeight: 0,
    },
  },
};
