import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Slide, toast } from 'react-toastify';
import { config } from '../../config/Config';

export default function ModalUpdatePassword({
  isOpenModalUpdatePassword,
  hideModal,
  userID,
  setUserId,
}) {
  const [password, setPassword] = useState('');
  const { t } = useTranslation();

  const handleMessage = (e) => {
    setPassword(e.target.value);
  };

  const onResetPassword = async () => {
    try {
      const response = await axios.put(
        `${config.API_URL.AUTH.PASSWORD}/${userID}/update-password`,
        {
          password,
        },
        { headers: config.headers }
      );
      if (response.status === 200) {
        toast.success(t('update_password_success'), {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          transition: Slide,
        });
        // hideModal;
      }
      console.log('response', response);
    } catch (error) {
      toast.error(t('update_password_failed'), {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide,
      });
    } finally {
      hideModal();
      setUserId('');
      setPassword('');
    }
  };

  return (
    <Transition appear show={isOpenModalUpdatePassword} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={hideModal}>
        <div className="min-h-screen px-4 text-center rtl flex items-center justify-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-[#00000065]" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="min-h-screen flex items-center justify-center w-full  max-w-3xl  text-left align-middle transition-all duration-300 transform ">
              <div className="w-full bg-white shadow-xl rounded-2xl  py-16 px-12">
                <div className="flex justify-center items-center mb-6">
                  <div className="font-semibold text-4xl px-2">{t('edit_password')}</div>

                  <div className="w-20 h-20">
                    <img src="/images/locate.png" alt="" />
                  </div>
                </div>

                <input
                  type="text"
                  name="message"
                  onChange={handleMessage}
                  value={password}
                  className=" px-3 border-2 border-gray-400 rounded-lg py-4 w-full mt-8"
                  placeholder={t('new_Password')}
                />
                {password.length > 0 && password.length < 8 && (
                  <div className="flex flex-row justify-start my-3 text-red-500">
                    New password minimum of 8 characters
                  </div>
                )}
                <div className="flex justify-center items-center pt-12 pb-4 ">
                  <button
                    type="button"
                    className={`toggle-btn bg-green-400 rounded-md ${
                      password.length < 8 ? 'opacity-50' : 'opacity-100'
                    } `}
                    onClick={onResetPassword}
                    disabled={password.length < 8 ? true : false}
                  >
                    <div className="text-gray-600">{t('edit_password')}</div>
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
