/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { debounce } from 'lodash';
import CsvDownload from 'react-json-to-csv';
import { useTranslation } from 'react-i18next';

import '../../../css/datatable.css';
import useSchema from './Schema.js';
import { config } from '../../../config/Config';
import produce from 'immer';
import Expandable from './Expandable';
import { useLocation, useParams } from 'react-router-dom';
import { parseQueryString } from '../../Orders/Orders';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import { handleToastMessage } from '../../../helper/toast';

const FilterComponent = ({ filters = {}, handleDateFilter, handlerFilters, t }) => (
  <div className="search-order flex flex-col mb-10 rtl">
    <div className="flex w-full gap-x-4">
      <div className="flex items-center gap-x-4 filter">
        <div className="flex items-center gap-x-4">
          <div className="flex filter-select min-w-[200px] ltr">
            <DateTimeRangePicker
              value={filters.date}
              onChange={handleDateFilter}
              disableClock
              format={'y-MM-dd'}
            />
          </div>
        </div>
      </div>

      <div className="flex items-center gap-x-4">
        <div className="flex">
          <input
            placeholder={t('order_number')}
            className="filter-input"
            name="order_number"
            value={filters.order_number}
            onChange={handlerFilters}
          />
        </div>
      </div>
    </div>
  </div>
);

const DriverTransactions = () => {
  const tableHeight = window.innerHeight - 400;

  const schema = useSchema();
  const { t } = useTranslation();

  const { search } = useLocation();
  const queryString = parseQueryString(search);

  const [isLoading, setIsLoading] = useState(true);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(queryString.page || 1);
  const [filters, setFilters] = useState(queryString.filters || {});
  const [limit, setLimit] = useState(queryString.limit || 100);

  const onSetFilters = (newFilter) => {
    setCurrentPage(1);
    setFilters(newFilter);
  };

  let { id } = useParams();

  const conditionalRowStyles = [
    {
      when: (row) => row.isFee !== null,
      style: {
        backgroundColor: '#f2f2f2',
      },
    },
    {
      when: (row) => row.isCharge,
      style: {
        backgroundColor: '#D4E9D8',
      },
    },
    {
      when: (row) => row.isGift,
      style: {
        backgroundColor: '#F7DDDE',
      },
    },
  ];

  async function getTransactions(filters = {}) {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${config.API_URL.TRANSACTIONS.GET_DRIVER_TRANSACTIONS}${id}/transactions`,
        {
          params: {
            ...filters,
          },
          headers: config.headers,
        }
      );
      const { data = {} } = response?.data;

      setData(data.results || []);
      setTotal(data.total || []);
    } catch (e) {
      console.log('Axios error: ', e);
    } finally {
      setIsLoading(false);
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handler = useCallback(
    debounce((filters) => getTransactions(filters), 500),
    []
  );

  const handleDateFilter = (value) => {
    const newFilter = {
      ...filters,
      date: value,
    };
    onSetFilters(newFilter);
  };

  const handlerFilters = (e) => {
    const newFilter = {
      ...filters,
      [e.target.name]: e.target.value,
    };
    onSetFilters(newFilter);
  };

  useEffect(() => {
    const newFilters = {
      filters: {
        ...filters,
      },
      sort: -1,
      limit: limit,
      page: currentPage,
    };
    handler(newFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handler, filters, currentPage, limit]);

  const refresh = () => {
    const newFilters = {
      filters: {
        ...filters,
      },
      sort: -1,
      limit: limit,
      page: currentPage,
    };
    getTransactions(newFilters);
  };

  const RefreshData = () => {
    return (
      <div className="flex justify-end">
        <div className="h-full flex items-center gap-x-4" onClick={() => refresh()}>
          <button className="refresh-button">{t('updating_data')}</button>
        </div>
      </div>
    );
  };

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
        placeholder={`${t('search')} ...`}
        handleDateFilter={handleDateFilter}
        handlerFilters={handlerFilters}
        filters={filters}
        t={t}
      />
    );
  }, [filterText, resetPaginationToggle, filters]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const toggleActivate = (id_number) => {
    const index = data.findIndex((i) => i.id_number === id_number);
    const toggle = produce(data, (draft) => {
      draft[index].isActive = !draft[index].isActive;
    });
    setData(toggle);
  };

  return (
    <div>
      {selected.length > 0 && (
        <CsvDownload
          filename="transactions.csv"
          data={selected}
          style={{
            position: 'absolute',
            top: 90,
            zIndex: 2,
            right: 30,
            borderWidth: 0,
            backgroundColor: '#fff',
            padding: 10,
            borderRadius: 5,
            cursor: 'pointer',
          }}
        >
          {t('loading_data')}
        </CsvDownload>
      )}
      <DataTable
        noDataComponent={<div style={{ padding: '24px' }}>{t('orders_no_records')}</div>}
        title={<RefreshData />}
        columns={schema}
        data={data}
        fixedHeader
        pagination
        contextMessage={{ singular: t('item'), plural: t('items'), message: t('selected') }}
        onSelectedRowsChange={(row) => setSelected(row.selectedRows)}
        fixedHeaderScrollHeight={tableHeight.toString() + 'px'}
        paginationPerPage={limit}
        paginationRowsPerPageOptions={[100, 200, 400, 600]}
        onChangeRowsPerPage={(currentRowsPerPage) => {
          setLimit(currentRowsPerPage);
          setCurrentPage(1);
        }}
        progressPending={isLoading}
        progressComponent={<div className="wait">{t('please_wait')}</div>}
        paginationResetDefaultPage={resetPaginationToggle}
        paginationTotalRows={total}
        paginationServer
        onChangePage={handlePageChange}
        subHeader
        conditionalRowStyles={conditionalRowStyles}
        paginationComponentOptions={{
          rowsPerPageText: t('total_record'),
          rangeSeparatorText: t('from'),
          noRowsPerPage: false,
          selectAllRowsItem: false,
        }}
        subHeaderComponent={subHeaderComponentMemo}
        selectableRows
        selectableRowSelected={(row) => row.isSelected}
        onRowExpandToggled={(toggleState, row) => [
          toggleState
            ? setSelectedId((p) => [...p, row._id])
            : setSelectedId(selectedId.filter((i) => i !== row._id)),
        ]}
        expandableRows
        expandOnRowClicked
        expandableRowsHideExpander
        expandableRowsComponent={<Expandable toggleActivate={toggleActivate} data={(row) => row} />}
        selectableRowsVisibleOnly
        persistTableHead
        selectableRowsHighlight
        direction={'rtl'}
        customStyles={{
          headCells: {
            style: {
              fontWeight: 'bold',
              fontSize: 12,
              background: '#F9F9F9',
              justifyContent: 'flex-start',

              padding: 0,
            },
          },
          rows: {
            style: {
              cursor: 'pointer',
              fontSize: 12,
            },
          },
        }}
      />
    </div>
  );
};

export default DriverTransactions;
