import axios from 'axios';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Slide, toast } from 'react-toastify';
import { object, string } from 'yup';
import { config } from '../../../config/Config';

toast.configure();
const NewPartner = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);

  const validationSchema = object().shape({
    first_name: string().required(t('delivery_company.partner.validate.required.first_name')),
    last_name: string().required(t('delivery_company.partner.validate.required.last_name')),
    phone: string().required(t('delivery_company.partner.validate.required.phone')),
    email: string()
      .required(t('delivery_company.partner.validate.required.email'))
      .email(t('delivery_company.partner.validate.type.email')),
    password: string()
      .required(t('delivery_company.partner.validate.required.password'))
      .min(6, t('delivery_company.partner.validate.type.password')),
  });

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      phone: '',
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validateOnChange: false,
  });

  const borderInputError = {
    border: '1px solid #E95F67',
  };

  const handleSubmit = async () => {
    setLoading(true);
    const finalizeData = {
      first_name: formik.values.first_name,
      last_name: formik.values.last_name,
      phone: formik.values.phone,
      email: formik.values.email,
      password: formik.values.password,
      company_id: id,
    };
    try {
      const { data } = await axios.post(
        config.API_URL.DELIVERY_COMPANY.PARTNER.CREATE,
        finalizeData,
        {
          headers: config.headers,
        }
      );
      if (data?.success) {
        handleMessage('success');
        window.history.back();
      } else {
        handleMessage('error', t('error'));
      }
      setLoading(false);
    } catch (error) {
      const errorList = error?.response?.data?.errors;
      if (errorList) {
        errorList.forEach((error) => handleMessage('error', error.msg));
      }
      setLoading(false);
    }
  };

  const handleMessage = (type = 'success', message = '') => {
    if (type === 'success') {
      return toast.success(t('delivery_company.partner.register.success'), {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide,
      });
    }
    return toast.error(message, {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      transition: Slide,
    });
  };

  return (
    <div className="add-ops-board">
      <h3 className="title">{t('delivery_company.partner.register.title')}</h3>
      <div className="ops-form">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit(e);
          }}
        >
          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <input
                id="first_name"
                name="first_name"
                type="text"
                style={formik.errors.first_name && borderInputError}
                className="center rounded-md"
                onChange={formik.handleChange}
                value={formik.values.first_name}
              />
              {formik.errors.first_name && (
                <span className="mt-2 text-red-600 rtl error-message">
                  {formik.errors.first_name}
                </span>
              )}
            </div>
            <span>{t('delivery_company.partner.register.first_name')}</span>
          </div>

          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <input
                id="last_name"
                name="last_name"
                type="text"
                style={formik.errors.last_name && borderInputError}
                className="center rounded-md"
                onChange={formik.handleChange}
                value={formik.values.last_name}
              />
              {formik.errors.last_name && (
                <span className="mt-2 text-red-600 rtl error-message">
                  {formik.errors.last_name}
                </span>
              )}
            </div>
            <span>{t('delivery_company.partner.register.last_name')}</span>
          </div>

          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <input
                id="phone"
                name="phone"
                type="text"
                style={formik.errors.phone && borderInputError}
                className="center rounded-md"
                onChange={formik.handleChange}
                value={formik.values.phone}
              />
              {formik.errors.phone && (
                <span className="mt-2 text-red-600 rtl error-message">{formik.errors.phone}</span>
              )}
            </div>
            <span>{t('delivery_company.partner.register.phone')}</span>
          </div>

          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <input
                id="email"
                name="email"
                type="text"
                style={formik.errors.email && borderInputError}
                className="center rounded-md"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              {formik.errors.email && (
                <span className="mt-2 text-red-600 rtl error-message">{formik.errors.email}</span>
              )}
            </div>
            <span>{t('delivery_company.partner.register.email')}</span>
          </div>

          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <input
                id="password"
                name="password"
                type="password"
                style={formik.errors.password && borderInputError}
                className="center rounded-md"
                onChange={formik.handleChange}
                value={formik.values.password}
              />
              {formik.errors.password && (
                <span className="mt-2 text-red-600 rtl error-message">
                  {formik.errors.password}
                </span>
              )}
            </div>
            <span>{t('delivery_company.partner.register.password')}</span>
          </div>

          <div className="">
            <button
              type="submit"
              className={`add-btn ${loading ? 'disabled' : ''}`}
              disabled={loading}
            >
              {t('delivery_company.submit')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewPartner;
