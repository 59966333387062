import moment from 'moment';
export const FORMAT_DATE = {
  full: 'DD-MM-YYYY HH:mm:ss ZZ',
  ymd: 'DD-MM-YYYY',
  hms: 'HH:mm:ss',
  ymdhms: 'ddd-DD-MM-YYYY / HH:mm:ss',
};
const getUtcOffset = (timezone) => {
  switch (timezone) {
    case 'Asia/Kuwait':
      return 180;
    case 'Asia/Ho_Chi_Minh':
      return 420;

    default:
      return 180;
  }
};
export const parseDateWithGmt = ({ date, format, timezone }) => {
  if (date) {
    const dateFormat = moment(date)
      .locale('en')
      .utcOffset(getUtcOffset(timezone))
      .format(format ? format : FORMAT_DATE.ymd);
    return dateFormat;
  }
  return '...';
};
