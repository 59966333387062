/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import { debounce } from 'lodash';
import { memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { config } from '../../../config/Config';
import { DataContext } from '../../../context/DataContext';
import {
  buildTransactionKey,
  guid,
  TransactionKey,
} from '../../../helper/order/buildTransactionKey';
import SearchDriverCard from '../SearchDriverCard';
toast.configure();

const AssignModal = memo(
  ({ order, assignModal, closeModal, assignToDriver, apiVersion, loadingConfig }) => {
    const { t } = useTranslation();
    const number_request = useRef(guid());

    const [driverAlready, setDriverAlready] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isRunningActionOrder, setIsRunningActionOrder] = useState(false);

    const { profileAdmin } = useContext(DataContext);

    const getDriverAlready = async (filters) => {
      try {
        setLoading(true);
        if (isRunningActionOrder) return;
        setIsRunningActionOrder(true);
        const resp = await axios.post(
          config.API_URL.LOCATION.DRIVER_ASSIGN,
          {
            coordinate: order.restaurant_coordinates,
            params: {
              filters,
            },
          },
          {
            headers: config.headers,
          }
        );
        setDriverAlready(resp.data?.drivers || []);
      } catch (errors) {
      } finally {
        setLoading(false);
        setIsRunningActionOrder(false);
        number_request.current = guid();
      }
    };

    const assign = async (driver_id) => {
      if (isRunningActionOrder) return;
      setIsRunningActionOrder(true);
      try {
        if (loadingConfig) return null;
        let apiConfig = null;
        if (apiVersion === 'v1') {
          apiConfig = config;
        } else {
          apiConfig = config;
        }
        await axios
          .patch(
            apiConfig.API_URL.ORDERS.ASSIGN_ORDER + order.order_number,
            { driver_id },
            {
              headers: {
                ...apiConfig.headers,
                ...buildTransactionKey(
                  order.order_number,
                  profileAdmin?._id,
                  TransactionKey.assign,
                  number_request.current
                ),
              },
            }
          )
          .then(async (response) => {
            if (response.data.status === 409) {
              toast.error(t('request_assigned_to_driver'), {
                position: toast.POSITION.BOTTOM_LEFT,
              });
            } else {
              toast(t('request_has_been_successfully_submitted'), {
                position: toast.POSITION.BOTTOM_LEFT,
              });
              assignToDriver();
            }
          })
          .finally(() => {
            setIsRunningActionOrder(false);
            number_request.current = guid();
          });
      } catch (err) {
        console.log(err);
      }
    };

    const handlerSearch = useCallback(
      debounce((filters) => getDriverAlready(filters), 500),
      []
    );

    const onChange = async (value) => {
      // await setKeyword(value);
      const filters = {
        keyword: value,
      };
      handlerSearch(filters);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
      getDriverAlready();
    }, []);

    return (
      <>
        {loading && ''}
        <div className={`assignModal cards ${assignModal ? 'show' : ''}`}>
          <div className="header-assign">
            <div className="button-back" onClick={closeModal}>
              <svg
                viewBox="0 0 24 24"
                width="24"
                height="24"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <polyline points="12 8 8 12 12 16"></polyline>
                <line x1="16" y1="12" x2="8" y2="12"></line>
              </svg>
            </div>
          </div>
          <div className="content driver-info ">
            <div className="map-search">
              <input
                type="text"
                name="search"
                placeholder={t('search')}
                className="input-search"
                autoComplete="off"
                onChange={(e) => onChange(e.target.value)}
              />
              <button type="submit" className="button-search">
                <svg
                  className="search-icon fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  version="1.1"
                  x="0px"
                  y="0px"
                  viewBox="0 0 56.966 56.966"
                  style={{ enableBackground: 'new 0 0 56.966 56.966' }}
                  xmlSpace="preserve"
                  width="512px"
                  height="512px"
                >
                  <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
                </svg>
              </button>
            </div>
            <nav>
              <ul>
                {driverAlready.map((driver) => {
                  return (
                    <SearchDriverCard
                      key={driver._id}
                      driver={driver}
                      goToDriver={() => assign(driver.driver_id)}
                    />
                  );
                })}
              </ul>
            </nav>
          </div>
        </div>
      </>
    );
  }
);

export default AssignModal;
