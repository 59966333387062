import React from 'react';

function LocatePolicy() {
  return (
    <div>
      <div className="translations-content-container" style={{ fontFamily: 'Roboto' }}>
        <div style={{ padding: '20px 20px' }}>
          <div className="container">
            <p>&nbsp;</p>
            <p
              style={{
                background: '#C51C43',
                color: '#fff',
                padding: '15px',
                textAlign: 'center',
                fontWeight: 'bold',
              }}
            >
              يعد استخدام المندوب للتطبيق قبول والتزام منه بالشروط والأحكام الواردة فيه، كما يحق
              للوكيت تعديل الشروط والأحكام وفقًا لتقديراتها.
            </p>
            <p style={{ color: '#C51C43', fontWeight: 'bold' }}>
              تقع المسؤولية كاملة على المستخدم / المندوب حين مخالفته لأي بند في هذه الوثيقة
            </p>
            <p />
            <p>&nbsp;</p>
            <p style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '24px' }}>إقرار</p>
            <p>
              في ظلّ الظروف الراهنة لتفشي جائحة فايروس كورونا، وحرصًا منا على سلامة الجميع من مزودي
              خدمة ومناديب وعملاء، وباعتبار أنكم -كمناديب- الواجهة الأمامية للتطبيق فإنه يجب علينا
              تنويهكم على التالي:
            </p>
            <ul>
              <li>يجب على كل مندوب عمل فحص طبي يبين خلوّه من الأمراض.</li>
              <li>
                يجب على المندوب طوال فترات عمله في توصيل الطلبات أن يراعي جميع احتياطات السلامة وأن
                يكون في حوزته التصريح الموحّد والبطاقة الالكترونية والفحص الطبي.
              </li>
              <li>
                يمنع استخدام الدراجات النارية في عملية التوصيل، كما يمنع استخدام سيارة أخرى غير
                المسجلة في التصريح لعمليات التوصيل.
              </li>
              <li>
                اشتراطات السلامة اليومية لعمليات التوصيل:
                <ul>
                  <li>فحص درجة الحرارة.</li>
                  <li>لبس الكمام والقفازة وتغييرها باستمرار.</li>
                  <li>حفظ الأكل/البضائع في حافظة الطعام.</li>
                  <li>تطهير اليدين قبل وبعد تسليم الطلب.</li>
                  <li>تسليم الأغراض على بعد مترين.</li>
                  <li>عدم المصافحة.</li>
                  <li>
                    عدم استلام طلبات الأطعمة من مشروبات مأكولات من محال المطاعم والكافيهات او ما في
                    حكمها في حال كانت غير محكمة الإغلاق وبشكل غير قابل للفتح
                  </li>
                </ul>
              </li>
              <li>
                يُمنع منعًا باتــًّا الخروج لاستلام الطلبات أو لأي غرض آخر، إذا كانت حالة المندوب في
                البطاقة الالكترونية "غير نشط".
              </li>
              <li>
                يُمنع منعًا باتــًّا استخدام التصريح في طلبات غير طلبات التوصيل المختصة بلوكيت جو.
              </li>
            </ul>
            <p>&nbsp;</p>
            <p style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '24px' }}>
              الشروط والأحكام
            </p>
            <p>الطرف الأول: شركة تحديد لنقل الطرود</p>
            <p>الطرف الثاني: مستخدم الخدمة / مندوب التوصيل</p>
            <p>
              الطرف الأول مؤسسة متخصصة في مجال التسويق الالكتروني، وتحتاج إلى مندوبي توصيل، وبما أن
              الطرف الثاني يملك المؤهلات المطلوبة لذلك، فقد قام الطرف الأول بناء على ذلك بتوقيع
              اتفاقية عمل مع الطرف الثاني. وفق الشروط التالية:
            </p>
            <p>
              - موقع لوكيت والتطبيقات المرتبطة به وما تحتويه من معلومات (يشار اليها جميعها أو بعضها
              في هذه الشروط والأحكام الخاصة بمناديب التوصيل بموقع لوكيت أو لوكيت) هي مملوكة بالكامل
              الى مؤسسة شركة تحديد لنقل الطرود (يشار اليها في هذه الشروط والأحكام بالمؤسسة)" وهي
              مؤسسة سعودية والمسجلة تحت رقم السجل التجاري (٢٠٥٠١٢٧٧٤٦).
            </p>
            <p>
              - أن تعاون مندوب التوصيل مع موقع لوكيت لأداء خدمة توصيل الطلبات للعملاء من قبل مقدمي
              الخدمات يخضع للشروط والأحكام المثبتة في هذه الوثيقة كما يعني تسجيل مندوب التوصيل في
              موقع لوكيت الموافقة الكاملة غير المشروطة والتزام مندوب التوصيل بالشروط والأحكام الخاصة
              بموقع لوكيت وكذلك ببيان الخصوصية وبيان ملفات تعريف الارتباط.
            </p>
            <p>
              - إن الشروط والأحكام المنصوص عليها هنا تخضع للتقييم بشكل دوري من قبل المختصين لذلك من
              الممكن أن تعدل من وقت لآخر بما يتناسب مع المستجدات والقوانين والأنظمة المعمول بها ,
              ويعني الدخول والولوج والتصفح والاستخدام لموقع لوكيت او تطبيقاتنا المرتبطة بها عبر أي
              وسيلة كانت بما يشمل على سبيل المثال لا الحصر (اجهزة الكمبيوتر , الهاتف النقال)
              الموافقة والإقرار الكامل دون قيد او شرط على قراءة وفهم والموافقة على جميع هذه الشروط
              والأحكام وعلى بيان الخصوصية وبيان ملفات تعريف الارتباط وعلى أي تحديثات أو تعديلات تتم
              في موقع لوكيت.
            </p>
            <p>
              - إن المعلومات المتوفرة على موقع لوكيت والتي يطلع عليها مندوب التوصيل هي معلومات سرية
              بالتالي فإن استخدام مندوب التوصيل لموقع لوكيت والتطبيقات المرتبطة به وما تحتويه من
              معلومات مقدمة من لعملاء ومقدمي الخدمات فقط لأغراض التوصيل للطلبات ولا يجوز لأي طرف اخر
              استخدامها بشكل تجاري دون الموافقة المكتوبة من قبل موقع لوكيت او المؤسسة كما يحظر
              تماماً على مندوب التوصيل استخدام أي بيانات خاصة لأي عميل أو مقدم خدمة لأغراض أخرى وعلى
              سبيل المثال لا الحصر، التواصل لأغراض لا علاقة لها بأداء خدمة التوصيل، الازعاج،
              الاستغلال، التهديد، التعارف وغيرها، وفي حال قام مندوب التوصيل بأي أعمال أو تصرفات غير
              نظامية أو قانونية في استخدامه لأي من البيانات المتاحة له عبر موقع لوكيت والتطبيقات
              المرتبطة به فإن مندوب التوصيل يتحمل كامل المسئولية الجنائية والمدنية دون ادني مسئولية
              على موقع لوكيت كما يلتزم بتعويض موقع لوكيت عن كل الاضرار التي تنشأ من أي اخلال يقوم به
              مندوب التوصيل.&nbsp;&nbsp;
            </p>
            <p>
              - تتم التعاملات المختلفة لاستلام وتوصيل الطلبات فقط عبر موقع لوكيت او التطبيقات
              المرتبطة به.
            </p>
            <p>
              - يقوم مندوب التوصيل بتقديم طلب الكتروني لتسجيل بياناته عبر موقع لوكيت وعلى مندوب
              التوصيل تعبئة جميع البيانات الالزامية وتقديم جميع المستندات والأوراق التي يطلبها موقع
              لوكيت سواء ان كانت متعلقة به شخصيا أو بالمركبات التي يستخدمها في عملية التوصيل.
            </p>
            <p>
              - تسجيل مندوب التوصيل عبر موقع لوكيت لا يعني اعتماده المباشر كمندوب توصيل للطلبات
              للعملاء ويحق لموقع لوكيت دون ابداء أي سبب رفض أي طلب وفقا لتقدير موقع لوكيت الخاص كما
              يحق لموقع لوكيت ايضا ايقاف مندوب التوصيل عن التعاون مع موقع لوكيت في أي وقت دون ابداء
              أي سبب وفقا لتقدير موقع لوكيت الخاص.
            </p>
            <p>
              - حيث أن عملية توصيل الطلبات من قبل مندوب التوصيل متعلقة بطعام ومشروبات ومواد غذائية
              يقر مندوب التوصيل بخلوه من الامراض المعدية أو المهنية او السارية والتي قد تنتقل عن
              طريق الملامسة للطلبات أو بأي طريقة أخرى مباشرة أو غير مباشرة كما يلتزم مندوب التوصيل
              وفقا لتقدير موقع لوكيت في أي وقت بالفحوص الطبية التي يطلبها موقع لوكيت على نفقة مندوب
              التوصيل.
            </p>
            <p>
              - عند ظهور أي طلب توصيل عبر موقع لوكيت وقبوله من قبل أي مندوب توصيل فإن تحويل نفس طلب
              التوصيل الى مندوب توصيل اخر هو حق اصيل لموقع لوكيت وفقا لتقديرها الخاص لظروف التوصيل
              وقرب مندوب التوصيل الاخر سواء من موقع مقدم الخدمة او من موقع العميل.
            </p>
            <p>
              - لا يوجد حد اقصى او أدنى لساعات العمل او عدد الطلبات ويخضع ذلك لتقديرات ومقدرة مندوب
              التوصيل ولذلك فإن قبول توصيل الطلبات من قبل مندوب التوصيل يخضع لتقدير مندوب التوصيل
              ولا يلزم موقع لوكيت أي مندوب توصيل بقبول عملية الاستلام والتسليم وفي حالة قبول مندوب
              توصيل للقيام بأداء الخدمة فعليه الالتزام الكامل بها دون ابطاء او تأخير.&nbsp;&nbsp;
            </p>
            <p>- يجب أن يكون لمندوب التوصيل رخصة قيادة سارية المفعول وان يمتلك سيارة خاصة.</p>
            <p>
              - يلتزم مندوب التوصيل بحسن السلوك والأخلاق والمظهر اثناء تقديم خدمة توصيل الطلبات وفي
              جميع الاوقات يلتزم بالأنظمة والأعراف والعادات والآداب المرعية في المملكة العربية
              السعودية وكذلك بالقواعد واللوائح والتعليمات ويتحمل كافة المستوليات الناتجة عن مخالفته
              لتلك الانظمة أو اللوائح او التعليمات.
            </p>
            <p>
              - يجب على مندوب التوصيل ألا يكون سبق إدانته بموجب حكم قطعي نهائي بأي جريمة متعلقة
              بالشرف والأمانة أو الاعتداء على النفس والمال في خلال العشر سنوات السابقة لتقديمه لطلب
              التسجيل في موقع لوكيت وفي حالة تلك الادانة يجب على مندوب التوصيل ان يقدم لموقع لوكيت
              ما يؤكد رد اعتباره من قبل السلطات المختصة.
            </p>
            <p>
              - يجب ان يكون مندوب التوصيل شخص يتسم بالجدية في العمل والالتزام بالمواعيد سواء في
              الاستلام أو التسليم.
            </p>
            <p>
              - بقر مندوب التوصيل بتعويض موقع لوكيت أو العميل أو مقدم الخدمة (بحسب الضرر الواقع على
              أي من المذكورين) في حال تأخيره في تسليم أي من الطلبات التي استلمها بما يزيد عن 20
              دقيقة من وقت التوصيل المحدد للطلب ورفض العميل استلام الطلب، وكذلك في حال ضياع أو اتلاف
              أي من الطلبات التي استلمها.
            </p>
            <p>
              - لا يحق لمندوب التوصيل الحصول على أي أموال إضافية من العملاء بخلاف التي تكون مسجلة في
              فاتورة الطلب.
            </p>
            <p>
              - يقوم مندوب التوصيل وعند تسجيل بيانه لدى موقع لوكيت بإيداع مبلغ لا يقل عن 500 ريال في
              المحفظة المخصصة لكل مندوب توصيل ويقوم موقع لوكيت بخصم الرسوم المستحقة لموقع لوكيت من
              قبل مندوب التوصيل عن كل عملية توصيل من حساب المحفظة الخاص بمندوب التوصيل.
            </p>
            <p>
              - لن يستطيع أي مندوب توصيل القيام بأداء خدمة التوصيل للطلبات في حال نقصت محفظته عن
              مبلغ 200 ريال والذي يعتبر هو الحد الأدنى وبالتالي فإن على مندوب التوصيل أن يقوم بتغذية
              محفظته على الدوام في حال اقترب المبلغ الموجود في محفظته من الحد الأدنى.&nbsp;
            </p>
            <p>
              - في حال رغب مندوب التوصيل في تصفية حسابه مع موقع لوكيت واسترجاع المبالغ المودعة في
              المحفظة الخاصة به فعلى مندوب التوصيل تقديم طلب إلى إدارة موقع لوكيت حيث سيتم تصفية
              حساب المحفظة المذكورة والحساب بين الطرفين خلال فترة لا تتجاوز شهر ميلادي من تاريخ
              تقديم الطلب بواسطة مندوب التوصيل.
            </p>
            <p>
              - في حال تحصل مندوب التوصيل على أي مبالغ تخص مقدمي الخدمات أو موقع لوكيت من قبل
              العملاء فيجب على مندوب التوصيل توريد المبالغ المتحصل عليها من قبل العميل فور استلامها
              ولا يحق له تأخير توريدها إلى اليوم الثاني إلا بموافقة مقدمي الخدمات أو موقع لوكيت وفي
              حال تأخير التوريد لما يتجاوز 24 ساعة يحق لموقع لوكيت أو مقدمي الخدمات اتخاذ الاجراءات
              النظامية الكفيلة بحفظ حقوق أي طرف.
            </p>
            <p>
              - يكون استلام طلبات العملاء من مقدمي الخدمة وتوصيلها إلى العملاء بموجب توقيع من قبل
              مندوب التوصيل أو بواسطة ادخال كود الطلب عن طريق الجوال أو بأي شكل اخر مقدم من قبل
              مقدمي الخدمات ويلتزم مندوب التوصيل بمطابقة الكميات مع الفاتورة دون أن يقوم بفتح عبوات
              وتغليف الطلبات وان يقوم بعملية التوصيل لطلبات العميل كما أستلمها من مقدم الخدمة.
            </p>
            <p>
              - يجب على مندوب التوصيل الاهتمام بنظافة وجاهزية المركبات المستخدمة في التوصيل وإجراء
              كل الخطوات الضرورية لتفقد تلك المركبات قبل استلام طلب التوصيل مع التأكد من المستندات
              الخاصة بها وكذلك التأكد من درجة الحرارة في المركبات ووضع طلب التوصيل بشكل امن بحيث لا
              يحدث أي تلف لطلبات العميل.
            </p>
            <p>
              - يحظر على مندوب التوصيل حمل أي حيوانات اليفة أو غير اليفة اثناء عملية التوصيل أو ركوب
              اشخاص اخرين معه اثناء عملية التوصيل أو السماح لأطراف اخرين بالتواصل مع العميل أو مقدم
              الخدمة اثناء عملية الاستلام والتوصيل وفي حال رفض العميل استلام أي من طلبات التوصيل
              لأسباب راجعة لمندوب التوصيل فإن مندوب التوصيل يلتزم بتعويض موقع لوكيت أو العميل أو
              مقدم الخدمة (بحسب الضرر الواقع على أي من المذكورين).
            </p>
            <p>
              - يتحمل مندوب التوصيل كافة المصروفات المتعلقة بالمركبات المستخدمة في عملية توصيل
              الطلبات وعلى سبيل المثال لا الحصر، الوقود والزيوت والصيانة الدورية أو المفاجئة أو
              الناتجة عن حوادث وخلافه ومنعا للشك فإن موقع دعوى ومقدمي الخدمة والعملاء لا يتحملون كل
              ما يتعلق بتلك المركبات.
            </p>
            <p>
              - يجب على مندوب التوصيل الحفاظ على الفواتير والإبلاغ الفوري لموقع لوكيت أو مقدم الخدمة
              في حال اكتشاف أي اختلاف بين طلب العميل والفواتير الخاصة بالطلب كما يلتزم مندوب التوصيل
              بتدوين وإرسال أي معلومات أو ملاحظات من قبل العميل سواء ان كانت متعلقة بنوع الخدمة أو
              التوصيل او السلوك.
            </p>
            <p>
              - يجب على مندوب التوصيل إعادة الطلبات المرتجعة أو الطلبات الغير مستلمة من العميل فوراً
              وعدم تأخيرها الى اليوم التالي وفي حال عدم التزام مندوب التوصيل بذلك يتحمل قيمتها
              الكاملة، كما لا يتحمل موقع لوكيت أي تعويضات لمندوب التوصيل عن عدم سداد العميل لأي رسوم
              توصيل حيث أن موقع لوكيت هو وسيط بين مقدم الخدمة والعميل ومندوب التوصيل وبالتالي يخضع
              ذلك التعويض لاتفاق خاص بين مقدم الخدمة ومندوب التوصيل. &nbsp;
            </p>
            <p>
              -&nbsp;يجب على مندوب التوصيل تقديم تقارير يومية الى موقع لوكيت عن طلبات التسليم التي
              تمت وطلبات التوصيل التي لم تكتمل وعن الاسباب والمشاكل التقنية أو النوعية أو المتعلقة
              بالطلبات وجميع المراسلات بين الطرفين تكون عبر موقع لوكيت أو عن طريق خدمات العملاء وعلي
              مندوب التوصيل الالتزام الكامل بالتوجيهات التي تصدر اليه من موقع لوكيت أو من خدمات
              العملاء فيما يتعلق بذلك.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocatePolicy;
