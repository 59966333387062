import React, { useCallback, useEffect, useMemo, useState } from 'react';
import '../../css/ops-management.css';
import '../../css/datatable.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { config } from '../../config/Config';
import DataTable from 'react-data-table-component';
import { debounce } from 'lodash';
import { buildQueryString } from '../Orders/Orders';
import { useTranslation } from 'react-i18next';
import produce from 'immer';
import { Slide, toast } from 'react-toastify';
import moment from 'moment';

toast.configure();

const ExpandableRow = ({ toggleActivate, data, refresh }) => {
  const { t } = useTranslation();

  const handleMessage = (type = 'success', message = '') => {
    if (type === 'success') {
      return toast.success(message, {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide,
      });
    }
    return toast.error(message, {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      transition: Slide,
    });
  };

  const activate = async (id_number) => {
    await axios
      .patch(
        `${config.API_URL.DELIVERY_COMPANY.LIST}${id_number}/active`,
        {},
        { headers: config.headers }
      )
      .then((response) => {
        if (response.data.status === 200) {
          handleMessage(
            'success',
            `${t('ops_management.action.notification.activate')} - ${data?.name}`
          );
          toggleActivate(id_number, 'true');
        }
      })
      .catch((error) => {
        const errorList = error?.response?.data?.errors;
        if (errorList) {
          errorList.forEach((error) => handleMessage('error', error?.msg));
        }
      });
  };
  const deactivate = async (id_number) => {
    if (!window.confirm('هل تريد ايقاف الحساب')) return;
    await axios
      .patch(
        `${config.API_URL.DELIVERY_COMPANY.LIST}${id_number}/deactive`,
        {},
        { headers: config.headers }
      )
      .then((response) => {
        if (response.data.status === 200) {
          handleMessage(
            'success',
            `${t('ops_management.action.notification.deactivate')} - ${data?.name}`
          );
          toggleActivate(id_number);
        }
      })
      .catch((error) => {
        const errorList = error?.response?.data?.errors;
        if (errorList) {
          errorList.forEach((error) => handleMessage('error', error?.msg));
        }
      });
  };

  return (
    <div className="flex items-center expand">
      <Link to={`/delivery-company/detail/${data?._id}`}>
        <button type="button" className="edit-btn bg-blue-500">
          {t('delivery_company.partner.company_detail')}
        </button>
      </Link>
      <Link to={`/delivery-company/edit/${data?._id}`}>
        <button type="button" className="edit-btn disable">
          {t('ops_management.action.edit.title')}
        </button>
      </Link>
      {!data.isActive ? (
        <button type="button" className="toggle-btn active" onClick={() => activate(data._id)}>
          {t('ops_management.action.activate')}
        </button>
      ) : (
        <button type="button" className="toggle-btn disable" onClick={() => deactivate(data._id)}>
          {t('ops_management.action.deactivate')}
        </button>
      )}
    </div>
  );
};

const DeliveryCompanyManagement = () => {
  const tableHeight = window.innerHeight - 260;
  const { t } = useTranslation();

  const [data, setData] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  // const [selected, setSelected] = useState([]);
  const [limit, setLimit] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [curFilters, setCurFilters] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const filter = {
      limit,
      page: currentPage,
    };
    setCurFilters(filter);
    getOpsList(filter);
  }, [limit, currentPage]);

  const getOpsList = async (filter) => {
    const newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      `?${buildQueryString({ ...filter })}`;
    window.history.replaceState({ path: newurl }, '', newurl);

    const { data } = await axios.get(config.API_URL.DELIVERY_COMPANY.LIST);
    setIsLoading(false);
    setData(data?.data);
    setTotal(data?.total);
  };

  const conditionalRowStyles = [
    {
      when: (row) => selectedId.includes(row._id),
      style: {
        backgroundColor: '#C32E52',
        color: '#fff',
      },
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handler = useCallback(() => debounce((filters) => getOpsList(filters), 500), []);

  const refresh = () => {
    const newFilters = {
      filters: {
        ...curFilters,
        // keyword: filterText,
      },
      limit: limit,
      page: currentPage,
    };
    getOpsList(newFilters);
  };

  const RefreshData = () => {
    return (
      <div className="flex justify-end my-4 mx-4">
        <Link to="/delivery-company/new">
          <button type="button" className="refresh-button">
            {t('ops_management.title')}
          </button>
        </Link>
        <div className="h-full flex items-center gap-x-4" onClick={() => refresh()}>
          <button className="refresh-button"> {t('refresh')} </button>
        </div>
      </div>
    );
  };

  const handlePageChange = (nextPage) => {
    if (isLoading) return false;
    if (Number(nextPage) === Number(currentPage)) return false;

    const newFilters = {
      // filters: { ...curFilters, keyword: filterText },
      limit: limit,
      page: nextPage,
    };
    const newUrl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      `?${buildQueryString({ ...newFilters })}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
    setCurrentPage(nextPage);
    handler(newFilters);
  };

  const toggleActivate = (_id) => {
    const index = data.findIndex((i) => i._id === _id);
    const toggle = produce(data, (draft) => {
      if (draft[index].isActive) {
        draft[index].isActive = false;
      } else {
        draft[index].isActive = true;
      }
    });
    setData(toggle);
  };

  const renderField = (value) => {
    if (typeof value === 'boolean') {
      if (value) {
        return t('active');
      }
      return t('inactive');
    } else {
      if (!value) return '-';
      return value;
    }
  };

  const schema = useMemo(
    () => [
      {
        name: t('delivery_company.id'),
        selector: '_id',
        cell: (row) => renderField(row?._id),
        minWidth: '220px',
      },
      {
        name: t('delivery_company.name'),
        selector: 'name',
        cell: (row) => renderField(row?.name),
        minWidth: '220px',
      },
      {
        name: t('delivery_company.phone'),
        selector: 'phone',
        cell: (row) => renderField(row?.phone),
        minWidth: '220px',
      },
      {
        name: t('delivery_company.cr_number'),
        selector: 'cr_number',
        cell: (row) => renderField(row?.cr_number),
        minWidth: '220px',
      },
      {
        name: t('delivery_company.contract'),
        selector: 'contract',
        cell: (row) => renderField(row?.contract),
        minWidth: '220px',
      },
      {
        name: t('delivery_company.iban'),
        selector: 'iban',
        cell: (row) => renderField(row?.iban),
        minWidth: '220px',
      },
      {
        name: t('delivery_company.status'),
        selector: 'isActive',
        cell: (row) => renderField(row?.isActive),
        minWidth: '120px',
      },
      {
        name: t('delivery_company.created_at'),
        selector: 'created_at',
        cell: (row) => renderField(moment(row.created_at).locale('ar').format('LL ~ hh:mm A')),
        minWidth: '220px',
      },
    ],
    [selectedId, t]
  );

  return (
    <div>
      <RefreshData />
      <DataTable
        noDataComponent={<div style={{ padding: '24px' }}>{t('orders_no_records')}</div>}
        noHeader
        columns={schema}
        data={data}
        fixedHeader
        pagination
        contextMessage={{ singular: t('item'), plural: t('items'), message: t('selected') }}
        fixedHeaderScrollHeight={tableHeight.toString() + 'px'}
        onSelectedRowsChange={(row) => setSelectedId(row.selectedRows)}
        paginationPerPage={Number(limit)}
        paginationRowsPerPageOptions={[100, 200, 400, 600]}
        onChangeRowsPerPage={(currentRowsPerPage) => {
          setLimit(currentRowsPerPage);
          setCurrentPage(1);
        }}
        progressPending={isLoading}
        paginationTotalRows={total}
        paginationServer
        paginationDefaultPage={currentPage}
        onChangePage={handlePageChange}
        progressComponent={<div className="wait">{t('please_wait')}</div>}
        onRowExpandToggled={(toggleState, row) => [
          toggleState
            ? setSelectedId((p) => [...p, row._id])
            : setSelectedId(selectedId.filter((i) => i !== row._id)),
        ]}
        conditionalRowStyles={conditionalRowStyles}
        paginationComponentOptions={{
          rowsPerPageText: t('total_record'),
          rangeSeparatorText: 'من',
          noRowsPerPage: false,
          selectAllRowsItem: false,
        }}
        selectableRows
        selectableRowSelected={(row) => row.isSelected}
        selectableRowsVisibleOnly
        persistTableHead
        selectableRowsHighlight
        expandableRowsComponent={
          <ExpandableRow toggleActivate={toggleActivate} data={(row) => row} refresh={refresh} />
        }
        expandableRows
        expandableRowsHideExpander
        expandOnRowClicked
        direction={'rtl'}
        customStyles={{
          headCells: {
            style: {
              fontWeight: 'bold',
              fontSize: 12,
              background: '#F9F9F9',
            },
          },
          rows: {
            style: {
              fontSize: 12,
            },
          },
        }}
      />
    </div>
  );
};

export default DeliveryCompanyManagement;
