import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useRouteMatch, useParams } from 'react-router-dom';
import { config } from '../../../config/Config';
import { buildQueryString, handleMessage, parseQueryString } from '../../../helper/utils';
import { DETAIL_TABS } from '../constants';

const Partner = ({ companyName }) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { id } = useParams();
  const queryString = parseQueryString(search);
  let match = useRouteMatch();

  const [partnerList, setPartnerList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(100);
  const [parsingQuery, setParsingQuery] = useState(true);
  const [loading, setLoading] = useState(true);

  const tableHeight = window.innerHeight - 445;

  useEffect(() => {
    if (queryString.page) {
      setCurrentPage(+queryString.page);
    }
    if (queryString.limit > 0) {
      setLimit(+queryString.limit);
    }
    setParsingQuery(false);
  }, []);

  const fetchPartnerList = async (filter) => {
    setLoading(true);
    const result = await axios.get(`${config.API_URL.DELIVERY_COMPANY.LIST}${id}/partners`, {
      headers: config.headers,
    });

    const { status } = result;
    const { data, total } = result.data;

    if (status === 200) {
      setTotal(total);
      setPartnerList(data);
    }
    setLoading(false);
  };

  const renderFields = (value) => {
    if (typeof value === 'boolean') return JSON.stringify(value);
    if (!value) return '-';
    return value;
  };

  const schema = useMemo(
    () => [
      {
        name: t('id'),
        selector: '_id',
        minWidth: '200px',
        cell: (row) => renderFields(row?._id),
      },
      {
        name: t('firstName'),
        selector: 'first_name',
        sortable: true,
        minWidth: '200px',
        cell: (row) => renderFields(row?.first_name),
      },
      {
        name: t('lastName'),
        selector: 'last_name',
        sortable: true,
        minWidth: '200px',
        cell: (row) => renderFields(row?.last_name),
      },
      {
        name: t('email'),
        selector: 'email',
        sortable: true,
        minWidth: '200px',
        cell: (row) => renderFields(row?.email),
      },
      {
        name: t('phone'),
        sortable: true,
        selector: 'phone',
        minWidth: '200px',
        cell: (row) => renderFields(row?.phone),
      },
      {
        name: t('active'),
        selector: 'is_active',
        sortable: true,
        cell: (row) => renderFields(row?.is_active),
      },
    ],
    [partnerList]
  );

  useEffect(() => {
    if (!parsingQuery) {
      const newFilters = {
        sort: -1,
        limit: limit,
        page: currentPage,
        tab: DETAIL_TABS.PARTNER,
      };

      const newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        `?${buildQueryString({ ...newFilters })}`;

      window.history.pushState({ path: newurl }, '', newurl);

      fetchPartnerList(newFilters);
    }
  }, [limit, currentPage, parsingQuery]);

  const refresh = () => {
    const newFilters = {
      limit: limit,
      page: currentPage,
    };
    fetchPartnerList(newFilters);
  };

  const toggleLive = () => {
    return (
      <div className="h-full flex items-center flex-row-reverse">
        <button className="refresh-button" onClick={() => refresh()}>
          {t('refresh')}
        </button>
        <Link to={`${match.url}/new`} className="refresh-button">
          {t('delivery_company.partner.create_new')}
        </Link>
      </div>
    );
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="ltr">
      <DataTable
        noDataComponent={<div style={{ padding: '24px' }}>{t('orders_no_records')}</div>}
        title={toggleLive()}
        columns={schema}
        data={partnerList}
        fixedHeader
        pagination
        paginationDefaultPage={+currentPage}
        fixedHeaderScrollHeight={tableHeight.toString() + 'px'}
        paginationRowsPerPageOptions={[100, 200, 400, 600]}
        onChangeRowsPerPage={(currentRowsPerPage) => {
          setLimit(+currentRowsPerPage);
          setCurrentPage(1);
        }}
        paginationPerPage={+limit}
        progressPending={loading}
        paginationTotalRows={total}
        progressComponent={<div className="wait">{t('please_wait')}</div>}
        paginationServer
        onChangePage={handlePageChange}
        paginationComponentOptions={{
          rowsPerPageText: t('total_record'),
          rangeSeparatorText: t('from'),
          noRowsPerPage: false,
          selectAllRowsItem: false,
        }}
        persistTableHead
        selectableRowSelected={(row) => row.isSelected}
        selectableRowsHighlight
        direction={'rtl'}
        expandableRows
        expandableRowsHideExpander
        expandOnRowClicked
        expandableRowsComponent={<PartnerActions data={(row) => row} refresh={refresh} />}
        customStyles={{
          headCells: {
            style: {
              fontWeight: 'bold',
              fontSize: 12,
              background: '#F9F9F9',
              justifyContent: 'flex-start',
            },
          },
          rows: {
            style: {
              cursor: 'pointer',
              fontSize: 12,
            },
          },
        }}
      />
    </div>
  );
};

const PartnerActions = ({ data, refresh }) => {
  const { t } = useTranslation();
  let match = useRouteMatch();

  const handleActivateAccount = async () => {
    try {
      const result = await axios.patch(
        `${config.API_URL.DELIVERY_COMPANY.PARTNER.UPDATE}${data?._id}/change-status`,
        { is_active: !data?.is_active },
        { headers: config.headers }
      );
      const { status } = result;
      if (status !== 200) {
        return handleMessage('error', t('delivery_company.partner.status.activate.failure'));
      }
      handleMessage('success', t('delivery_company.partner.status.activate.success'));
      return refresh();
    } catch (error) {
      return handleMessage('error', error.msg);
    }
  };

  return (
    <div className="p-7 bg-red-50 flex items-center gap-x-4">
      <Link to={`${match.url}/edit/${data?._id}`}>
        <button
          type="button"
          className="px-6 py-4 rounded-md bg-blue-500 hover:bg-blue-600 transition-all duration-300 text-white"
        >
          {t('delivery_company.partner.edit.action')}
        </button>
      </Link>
      <button
        type="button"
        className={`px-6 py-4 rounded-md transition-all duration-300 ${
          data?.is_active ? 'bg-red-500 hover:bg-red-600' : 'bg-green-500 hover:bg-green-600'
        } text-white`}
        onClick={handleActivateAccount}
      >
        {data?.is_active ? t('account_deactivate') : t('account_active')}
      </button>
    </div>
  );
};

export default Partner;
