/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import { slideInLeft } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import { X } from 'react-feather';
import '../../css/receipt.css';
import DriversList from './DriversList';
import { useTranslation } from 'react-i18next';
import { config } from '../../config/Config';
import axios from 'axios';

export default function Receipt(props) {
  const { apiVersion, loadingConfig } = props;
  const { id } = useParams();
  const [assignModal, setAssignModal] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState({});
  const history = useHistory();
  const [reasonsList, setReasonsList] = useState([]);

  const { t } = useTranslation();

  const getOrder = async () => {
    const resp = await axios.get(config.API_URL.ORDERS.GET_ORDER + id, {
      headers: config.headers,
    });
    setData(resp.data);
    setLoaded(true);
  };

  useEffect(() => {
    setLoaded(false);
    getOrder();
  }, []);

  const styles = {
    slideInLeft: {
      animation: 'x 0.4s',
      animationName: Radium.keyframes(slideInLeft, 'slideInLeft'),
    },
  };
  const closeModal = () => {
    document.body.classList.remove('modal-open');
    setAssignModal(false);
  };

  const openModal = () => {
    document.body.classList.add('modal-open');
    setAssignModal(true);
  };

  async function getReason() {
    try {
      const reasons = await axios.get(`${config.API_URL.CITC_LOOKUP.REASONS}`);
      setReasonsList(reasons?.data || []);
      return;
    } catch (e) {
      console.log('Axios error: ', e);
    }
  }

  useEffect(() => {
    getReason();
  }, []);

  const Recipt = () => {
    if (!data) return null;

    return (
      <div className="m-5 rtl">
        <div className="tree">
          <span onClick={() => history.push('/orders')}>{t('orders')}</span>
          <span> / </span>
          <span>{data.order_number}</span>
        </div>
        <div className="headContainer">
          <div className="rowRight">
            <div className="receiptHeadRow">
              <label>{t('order_number')}</label>
              <span className="ltr">{data.order_number}</span>
            </div>
            <div className="receiptHeadRow">
              <label>{t('restaurant_name')} </label>
              <span>{data.restaurant_name}</span>
            </div>
            <div className="receiptHeadRow">
              <label>{t('restaurant_number')} </label>
              <span>{data.restaurant_phone}</span>
            </div>
            <div className="receiptHeadRow">
              <label>{t('restaurant_city')} </label>
              <span>{data.restaurant_city}</span>
            </div>
          </div>
          <div className="rowLeft">
            <div className="receiptHeadRow">
              <label>{t('customer')}</label>
              <span>{data.customer_name}</span>
            </div>
            <div className="receiptHeadRow">
              <label>{t('customer_number')} </label>
              <span>{data.customer_phone}</span>
            </div>
            <div className="receiptHeadRow">
              <label>{t('ordered_at')} </label>
              <span>{moment(data.created_at).format('LLL')}</span>
            </div>
            <div className="receiptHeadRow">
              <label>{t('customer_city')} </label>
              <span>{data.customer_city}</span>
            </div>
            <div className="receiptHeadRow">
              <label>{t('customer_address')} </label>
              <span>{data.customer_address}</span>
            </div>
          </div>
        </div>
        {data?.cancel_reason && (
          <div className="mb-10">
            <p className="font-bold">Cancel reason</p>
            <div className="grid grid-cols-3 gap-x-6 max-w-3xl">
              <p className="col-span-1">Type</p>
              <p>
                {reasonsList.filter((reason) => reason.id === data?.cancel_reason)?.[0]?.nameAr}
              </p>
            </div>
            <div className="grid grid-cols-3 gap-x-6 max-w-3xl">
              <p className="col-span-1">Reason</p>
              <p>{data?.cancel_reason_txt}</p>
            </div>
          </div>
        )}
        <table
          id="dtBasicExample"
          className="table table-striped table-bordered"
          cellSpacing="0"
          style={{
            width: window.innerWidth - 60,
          }}
        >
          <thead>
            <tr>
              <th className="th-sm quantity">{t('quantity')}</th>
              <th className="th-sm item">{t('item')}</th>
              <th className="th-sm price">{t('price')}</th>
            </tr>
          </thead>
          <tbody>
            {data.items.map((r, index) => {
              return (
                <tr key={index}>
                  <td>{r.item_quantity}</td>
                  <td>{r.item_name}</td>
                  <td>{r.item_price}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div>
          <div className="receipt-checkout">
            <div className="receipt-row">
              <div className="receipt-column flex items-center gap-x-2">
                <p>{t('delivery_value')} </p>
                {data?.isFreeExpress && <p className="text-blue-500">Free express</p>}
              </div>
              <div className="receipt-column">
                {data.tax ? (data.delivery_cost / (1 + data.tax)).toFixed(2) : data.delivery_cost}
              </div>
            </div>
            {/* <div className="receipt-row">
              <div className="receipt-column">{t('delivery_vat')} </div>
              <div className="receipt-column">
                {data.tax
                  ? ((data.delivery_cost / (1 + data.tax)) * data.tax).toFixed(2)
                  : data.delivery_cost}
              </div>
            </div> */}
            <div className="receipt-row">
              <div className="receipt-column"> {t('order_cost')}</div>
              <div className="receipt-column">
                {data.sub_total ? (data.sub_total / (1 + data.tax)).toFixed(2) : data.sub_total}
              </div>
            </div>
            {/* <div className="receipt-row">
              <div className="receipt-column"> {t('order_vat')}</div>
              <div className="receipt-column">
                {data.tax
                  ? ((data.sub_total / (1 + data.tax)) * data.tax).toFixed(2)
                  : data.sub_total}
              </div>
            </div> */}
            <div className="receipt-row">
              <div className="receipt-column"> {t('total')}</div>
              <div className="receipt-column">
                {Number(data.sub_total) + Number(data.delivery_cost)}
              </div>
            </div>
            <div className="receipt-row">
              <div className="receipt-column"> {t('payment_method')}</div>
              <div className="receipt-column">{data.payment_method}</div>
            </div>
          </div>
        </div>
        <div className="assignBtn" onClick={openModal}>
          {t('assigning')}
        </div>
        <StyleRoot>
          {assignModal && (
            <div className="fixed driverListHolder z-10" style={styles.slideInLeft}>
              <X className="closeBtn" size={30} onClick={closeModal} />
              <DriversList
                order_number={data.order_number}
                closeModal={closeModal}
                apiVersion={apiVersion}
                loadingConfig={loadingConfig}
              />
            </div>
          )}
        </StyleRoot>
      </div>
    );
  };

  return loaded ? <Recipt /> : <div />;
}
