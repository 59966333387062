export const TRANSACTION_TYPE = {
  HOLDING: 'HOLDING', // Đặt cọc tiền
  CASH_BACK: 'CASH_BACK', // Trả lại tiền cọc
  FEE: 'FEE', // Phí dịch vụ cho maeda
  GIFT_ORDER: 'GIFT_ORDER', // Quà tặng cho driver khi cancel order
  TOP_UP: 'TOP_UP', // Nạp thẻ
  TOP_UP_PAYFORT_REFUND: 'TOP_UP_PAYFORT_REFUND', // Nạp thẻ
};

export const TRANSACTION_VER = {
  OLD: 'old',
  NEW: 'new',
};

export const CREATED_BY_TYPE = {
  DRIVER: 'DRIVER',
  ADMIN: 'ADMIN',
};
