/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { Link } from 'react-router-dom';
import useSocket from '../../socket/useSocket';
import produce from 'immer';
import '../../css/datatable.css';
import useSchema from './Schema.js';
import { config } from '../../config/Config';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
// import { parseQueryString } from './Orders';

const FilterComponent = ({ placeholder, filterText, onFilter, onClear }) => (
  <input
    id="search"
    type="text"
    placeholder={placeholder}
    aria-label="Search Input"
    value={filterText}
    onChange={onFilter}
    className="search"
  />
);

const LiveOrders = () => {
  const tableHieght = window.innerHeight - 320;

  const schema = useSchema();
  const { t } = useTranslation();

  // const { search } = useLocation();
  // const queryString = parseQueryString(search);

  const [isLoaded, setIsLoaded] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [data, setData] = useState([]);
  // const data = store.orders;
  // const setData = store.setOrders;
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [wsActive, setWsActive] = useState(true);
  // const [ expandedId, setExpandedId ] = useState(true);
  const [counter, setCounter] = useState(0);
  const countPerPage = 200;

  const [socket] = useSocket(config.SOCKET_URL, {
    autoConnect: false,
    transports: ['websocket'],
  });
  const filteredItems = data.filter(
    (item) =>
      item.customer_name.toLowerCase().includes(filterText.toLowerCase()) ||
      item.restaurant_name.toLowerCase().includes(filterText.toLowerCase()) ||
      item.order_number.toLowerCase().includes(filterText.toLowerCase()) ||
      item.payment_method.toLowerCase().includes(filterText.toLowerCase())
  );

  const conditionalRowStyles = [
    {
      when: (row) => row.driver_id !== null,
      style: {
        backgroundColor: '#F8EECC',
      },
    },
    {
      when: (row) => row.isCanceled,
      style: {
        backgroundColor: '#F7DDDE',
      },
    },
    {
      when: (row) => row.isDelivered,
      style: {
        backgroundColor: '#D4E9D8',
      },
    },
  ];
  async function getOrders() {
    setIsLoaded(false);
    try {
      await axios
        .get(
          `${config.API_URL.ORDERS.GET_ORDERS_PAGE}?page=${page}&limit=${countPerPage}&sort=-1`,
          {
            headers: config.headers,
          }
        )
        .then(async (response) => {
          const fetchData = produce(data, (draft) => {
            draft.push(...response.data.data.results);
          });
          setData(fetchData);
          if (response.data.data.results.length > 0) {
            setTotal(response.data.data.total);
          }
        });
      setIsLoaded(true);
      return;
    } catch (e) {
      setIsLoaded(true);
      console.log('Axios error: ', e);
    }
  }
  useEffect(() => {
    // Axios: fire the function
    getOrders();
    console.log(setWsActive);
  }, [page]);

  // WS: New order
  useEffect(() => {
    console.log('ws: new');
    socket.connect();
    socket.on('newOrder', (order) => {
      const newOrder = produce(data, (draft) => {
        if (wsActive) {
          draft.unshift(order);
        }
      });
      setData((currentData) => [...newOrder, ...currentData]);
    });
  }, [wsActive]);

  useEffect(() => {
    if (!data) return;
    console.log('ws: update');
    setCounter(counter + 1);
    // WS: Assign order to Driver.
    socket.once('update', (order) => {
      const updateFields = order.updatedFields;
      const orderId = order['_id'];
      if (updateFields.driver_name === null) {
        // to avoid mounting if call for reset
        return;
      }
      const orderIndex = data.findIndex((o) => o._id === orderId);
      const updatedData = produce(data, (draft) => {
        if (orderIndex !== -1) {
          if (updateFields.driver_id) {
            draft[orderIndex].driver_id = updateFields.driver_id;
          }
          if (updateFields.driver_name) {
            draft[orderIndex].driver_name = updateFields.driver_name;
          }
          if (updateFields.isDelivered) {
            draft[orderIndex].isDelivered = updateFields.isDelivered;
          }
          if (updateFields.isCanceled) {
            draft[orderIndex].isCanceled = updateFields.isCanceled;
          }
          if (updateFields.delivery_time) {
            draft[orderIndex].delivery_time = updateFields.delivery_time;
          }
          if (updateFields.assigned_at) {
            draft[orderIndex].assigned_at = updateFields.assigned_at;
          }
          if (updateFields?.driver && !isEmpty(updateFields?.driver)) {
            draft[orderIndex].driver = updateFields.driver;
          }
        }
      });
      setData(updatedData);
    });
  }, [data, wsActive]);
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
        placeholder={`${t('search')} ...`}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const toggleLive = () => {
    return (
      <div className="flex items-center justify-between">
        <div className="displayHolder">
          <div className="toggleLiveHolder">
            <div className="live selectedDisplay">مباشر</div>
            <Link to="/orders" className="live">
              <div className="offline">{t('hypothetical')}</div>
            </Link>
          </div>
          <span>{t('view_orders')}</span>
        </div>
        <div className="flex items-center h-full gap-x-4" onClick={() => getOrders()}>
          <button className="refresh-button">{t('refresh')} </button>
        </div>
      </div>
    );
  };

  if (!isLoaded) {
    return <div className="wait">{t('please_wait')}</div>;
  }

  return (
    <DataTable
      noDataComponent={<div style={{ padding: '24px' }}>{t('orders_no_records')}</div>}
      title={toggleLive()}
      columns={schema}
      data={filteredItems}
      progressPending={!isLoaded}
      progressComponent={<div className="wait">{t('please_wait')}</div>}
      fixedHeader
      pagination
      contextMessage={{ singular: t('item'), plural: t('items'), message: t('selected') }}
      fixedHeaderScrollHeight={tableHieght.toString() + 'px'}
      paginationRowsPerPageOptions={[50, 100]}
      paginationResetDefaultPage={resetPaginationToggle}
      subHeader
      conditionalRowStyles={conditionalRowStyles}
      paginationComponentOptions={{
        rowsPerPageText: t('total_record'),
        rangeSeparatorText: t('from'),
        noRowsPerPage: true,
        selectAllRowsItem: false,
      }}
      subHeaderComponent={subHeaderComponentMemo}
      selectableRows
      selectableRowSelected={(row) => row.isSelected}
      selectableRowsVisibleOnly
      selectableRowsComponent={() => <div />}
      persistTableHead
      selectableRowsHighlight
      direction={'rtl'}
      paginationServer
      paginationTotalRows={total}
      paginationPerPage={countPerPage}
      onChangePage={(page) => setPage(page)}
      customStyles={{
        headCells: {
          style: {
            fontWeight: 'bold',
            fontSize: 12,
            background: '#F9F9F9',
            justifyContent: 'flex-start',

            padding: 0,
          },
        },
        rows: {
          style: {
            cursor: 'pointer',
            fontSize: 12,
          },
        },
      }}
    />
  );
};

export default LiveOrders;
