import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import axios from 'axios';
import produce from 'immer';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import CsvDownload from 'react-json-to-csv';
import { useLocation, useParams } from 'react-router-dom';

import { config } from '../../../config/Config';
import { buildQueryString, parseQueryString } from '../../../helper/utils';
import CustomSelectReact from '../../CustomSelectReact';
import DriverDetails from '../../Drivers/DriverDetails';
import { DETAIL_TABS } from '../constants';
import LoadingButton from '../../../commonComponents/LoadingButton/LoadingButton';
import useEventEnter from '../../../hooks/useEventEnter';
import { createDate } from '../../../helper/date/createDateWithTimezone';
import { DataContext } from '../../../context/DataContext';
import {
  CompareGmtAndCalcTimestamp,
  GetTimezone,
} from '../../../helper/date/getLocalGmtWithTimezone';
import { isEmpty, uniqueId } from 'lodash';

const Driver = ({ apiVersion, loadingConfig, companyName }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { search } = useLocation();
  const queryString = parseQueryString(search);

  const [driverList, setDriverList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(100);
  const [selected, setSelected] = useState([]);
  const [filterText, setFilterText] = useState('');

  const [filters, setFilters] = useState({});
  const [oldFilters, setOldFilters] = useState({});

  const [parsingQuery, setParsingQuery] = useState(true);
  const [loading, setLoading] = useState(true);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);
  const tableHeight = window.innerHeight - 445;

  const { currentTimezone } = useContext(DataContext);

  const [isExportExcel, setIsExportExcel] = useState(false);

  const [date, setDate] = useState({ startDate: '', endDate: '', value: [] });
  const [newRender, setNewRender] = useState(uniqueId());
  const [loadingDriver,setLoadingDriver] = useState(false)

  const onSetFilters = (newFilter) => {
    setCurrentPage(1);
    setFilters(newFilter);
  };

  const getRegions = async () => {
    try {
      setLoading(true);
      const response = await axios.get(config.API_URL.CITC_LOOKUP.REGIONS);
      const { data = [] } = response;
      const res_regions = data.map((region) => {
        return {
          label: region.nameAr,
          value: region.id,
        };
      });
      setRegions(res_regions);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('[getRegions] ~ error->', error);
    }
  };

  const getCities = async (regionId) => {
    try {
      setLoading(true);
      const response = await axios.get(config.API_URL.CITC_LOOKUP.CITIES, {
        params: { regionId },
      });
      const { data } = response;
      const res_cities = data.map((city) => {
        return {
          label: city.nameAr,
          value: city.id,
        };
      });
      setCities(res_cities);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(' [getCities] ~ error:', error);
    }
  };

  const getDrivers = async (_filter = {}) => {
    try {
      setLoadingDriver(true)
      const newFilters = {
        filters: {
          ...filters,
          fromDate: date?.startDate
            ? new Date(createDate(+date.startDate, currentTimezone)).getTime()
            : '',
          toDate: date?.toDate
            ? new Date(createDate(+date.endDate, currentTimezone)).getTime()
            : '',
          date: date.value || '',
        },
        sort: -1,
        limit: limit,
        page: currentPage,
      };
      const filter = isEmpty(_filter) ? newFilters : _filter;
      setLoading(true);
      const result = await axios.get(`${config.API_URL.DELIVERY_COMPANY.LIST}${id}/driver`, {
        params: {
          ...filter,
        },
        headers: config.headers,
      });

      const { status } = result;
      const { results, total } = result.data.data;
      if (status === 200) {
        setTotal(total);
        setDriverList(results);
      }
      setLoading(false);
      setLoadingDriver(false)
    } catch (error) {
      setLoading(false);
      setLoadingDriver(false)
      console.log(' [getDriver] error-->', error);
    }
  };

  useEffect(() => {
    if (queryString.page) {
      setCurrentPage(+queryString.page);
    }
    if (queryString.filters) {
      const filter = { ...queryString.filters };
      if (filter.fromDate) {
        filter.fromDate = +filter.fromDate;
      }
      if (filter.toDate) {
        filter.toDate = +filter.toDate;
      }
      setFilters(filter);
      setOldFilters({ filters: filter });
    }
    if (queryString.limit > 0) {
      setLimit(+queryString.limit);
    }
    setParsingQuery(false);
    getRegions();
    getCities('rAy9UhMUw6Y=');
  }, []);

  const handleGetDataByFilter = async () => {
    if (!parsingQuery) {
      setCurrentPage(1);
      const { settingGmt, localGmt } = await GetTimezone();

      const bodyRequest = {
        filters: {
          ...filters,
          fromDate: date.fromDate
            ? CompareGmtAndCalcTimestamp(
                settingGmt,
                localGmt,
                new Date(createDate(+date?.fromDate, currentTimezone)).getTime()
              )
            : null,
          toDate: date.toDate
            ? CompareGmtAndCalcTimestamp(
                settingGmt,
                localGmt,
                new Date(createDate(+date?.toDate, currentTimezone)).getTime()
              )
            : null,
          date: isEmpty(date.value) ? '' : date.value,
        },
        sort: -1,
        limit: limit,
        page: currentPage,
        tab: DETAIL_TABS.DRIVER,
      };
      //
      const newUrl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        `?${buildQueryString({ ...bodyRequest })}`;

      window.history.pushState({ path: newUrl }, '', newUrl);

      onSetFilters(bodyRequest.filters);
      setOldFilters(bodyRequest);
      getDrivers(bodyRequest);
    }
  };

  const exportExcel = async () => {
    try {
      const newFilters = {
        filters: {
          ...oldFilters?.filters,
          date: filters.date,
        },
        sort: -1,
        limit: limit,
        page: currentPage,
      };
      setIsExportExcel(true);
      const result = await axios.post(
        `${config.API_URL.DELIVERY_COMPANY.LIST}${id}/drivers/excel`,
        {},
        {
          params: newFilters,
          headers: {
            ...config.headers,
            'Content-Disposition': 'attachment; filename=template.xlsx',
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
          responseType: 'arraybuffer',
        }
      );
      if (result?.status === 200) {
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `${companyName}-drivers-${moment().locale('en').format('MM-DD-YYYY')}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      }
      setIsExportExcel(false);
    } catch (error) {
      console.log('error:', error);
      setIsExportExcel(false);
    }
  };

  useEventEnter(handleGetDataByFilter);

  useEffect(() => {
    if (!parsingQuery) {
      const { settingGmt, localGmt } =  GetTimezone();
      const bodyRequest = {
        filters: {
          ...filters,
          fromDate: date.fromDate
            ? CompareGmtAndCalcTimestamp(
                settingGmt,
                localGmt,
                new Date(createDate(+date?.fromDate, currentTimezone)).getTime()
              )
            : null,
          toDate: date.toDate
            ? CompareGmtAndCalcTimestamp(
                settingGmt,
                localGmt,
                new Date(createDate(+date?.toDate, currentTimezone)).getTime()
              )
            : null,
          date: isEmpty(date.value) ? '' : date.value,
        },
        sort: -1,
        limit: limit,
        page: currentPage,
        tab: DETAIL_TABS.DRIVER,
      };
      
      const newUrl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        `?${buildQueryString({ ...bodyRequest })}`;

      window.history.pushState({ path: newUrl }, '', newUrl);

      getDrivers(bodyRequest);
    }
  }, [limit, parsingQuery,newRender]);

  const refresh = () => {
    const newFilters = {
      keyword: filterText,
      limit: limit,
      page: currentPage,
      filters,
    };
    getDrivers(newFilters);
  };

  const toggleLive = () => {
    return (
      <div className="h-full flex items-center flex-row-reverse">
        <button className="refresh-button" onClick={() => refresh()}>
          {t('refresh')}
        </button>
        <button
          type="button"
          onClick={exportExcel}
          disabled={isExportExcel}
          className={`refresh-button  ${
            isExportExcel ? 'opacity-40 border-red-200' : 'opacity-100 '
          } `}
        >
          {t('export_excel_all_by_filter')}
        </button>
      </div>
    );
  };

  const handlerFilters = (e) => {
    const newFilter = {
      ...filters,
      [e.target.name]: e.target.value,
    };
    onSetFilters(newFilter);
  };

  const handleStatusFilter = (event) => {
    const newFilter = {
      ...filters,
      status: event,
    };
    onSetFilters(newFilter);
  };

  const handleCompanyFilter = (values) => {
    const newFilter = {
      ...filters,
      company: values.map((value) => value.id),
    };
    onSetFilters(newFilter);
  };

  const handleDateFilter = (value) => {
    setDate({
      startDate: value?.[0] || '',
      endDate: value?.[1] || '',
      value: value || '',
    });
  };

  const selectHandler = (e, key) => {
    const newFilters = {
      ...filters,
      [key]: e,
    };
    onSetFilters(newFilters);
  };

  const selectHandlerCity = (e) => {
    const newFilters = {
      ...filters,
      cities: e,
    };
    onSetFilters(newFilters);
  };
  const contextActions = useMemo(() => {
    if (selected.length === 0) return null;
    return (
      <CsvDownload
        filename="orders.csv"
        data={selected}
        style={{
          zIndex: 2,
          borderWidth: 0,
          backgroundColor: '#fff',
          padding: 10,
          borderRadius: 5,
          cursor: 'pointer',
        }}
      >
        {t('loading_data')}
      </CsvDownload>
    );
  }, [selected, driverList]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setNewRender(uniqueId())
  };

  const toggleActivate = (id_number) => {
    const index = driverList.findIndex((i) => i.id_number === id_number);
    const toggle = produce(driverList, (draft) => {
      draft[index].isActive = !draft[index].isActive;
    });
    setDriverList(toggle);
  };

  const toggleReady = (id_number) => {
    const index = driverList.findIndex((i) => i.id_number === id_number);
    const ready = produce(driverList, (draft) => {
      draft[index].isReady = !draft[index].isReady;
    });
    setDriverList(ready);
  };

  const filterStatus = [
    {
      value: 'active',
      label: t('active'),
    },
    {
      value: 'deactivate',
      label: t('inactive'),
    },
  ];

  const translateText = {
    phone: t('phone'),
    lastName: t('lastName'),
    firstName: t('firstName'),
    company: t('company'),
    region: t('region'),
    city: t('city'),
    id_number: t('id_number'),
    status: t('status'),
  };

  const schema = useMemo(
    () => [
      {
        name: t('CODE'),
        selector: (row) => row.passKey?.toUpperCase(),
        style: {
          with: 100,
        },
      },
      {
        name: t('company'),
        selector: 'company_name',
        sortable: true,
      },
      {
        name: t('name'),
        selector: 'name',
        sortable: true,
      },
      {
        name: t('civil_registry'),
        selector: 'id_number',
      },
      {
        name: t('cell_phone'),
        selector: 'phone',
      },
      {
        name: t('driver_city'),
        selector: 'city',
        sortable: true,
      },
      {
        name: t('balance'),
        selector: (row) => `${row.balance ? row.balance?.toFixed(2) : '0.00'}`,
        sortable: false,
      },
      {
        name: t('gift'),
        selector: (row) => `${row.gift ? row.gift?.toFixed(2) : '0.00'}`,
        sortable: true,
        style: {
          justifyContent: 'center',
        },
      },
      {
        name: t('subscription_date'),
        selector: (row) => `${moment(row.registration_date).calendar('LL ~ hh:mm A')}`,
        sortable: true,
      },
      {
        name: t('status'),
        selector: (row) => `${row.isActive ? t('active') : t('inactive')}`,
        sortable: true,
      },
    ],
    [driverList]
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
        placeholder={`${t('search')} ...`}
        filterStatus={filterStatus}
        translateText={translateText}
        filters={filters}
        handlerFilters={handlerFilters}
        handleStatusFilter={handleStatusFilter}
        handleCompanyFilter={handleCompanyFilter}
        regions={regions}
        cities={cities}
        handleDateFilter={handleDateFilter}
        selectHandler={selectHandler}
        selectHandlerCity={selectHandlerCity}
        loading={loading}
        handleGetDataByFilter={handleGetDataByFilter}
        date={date}
      />
    );
  }, [filterText, filterStatus, translateText, filters, regions, cities, loading, date]);

  return (
    <div className="ltr">
      <DataTable  
        noDataComponent={!loadingDriver && <div style={{ padding: '24px' }}>{t('orders_no_records')}</div>}
        title={toggleLive()}
        columns={schema}
        data={driverList}
        fixedHeader
        pagination
        paginationDefaultPage={+currentPage}
        contextActions={contextActions}
        contextMessage={{ singular: t('item'), plural: t('items'), message: t('selected') }}
        fixedHeaderScrollHeight={tableHeight.toString() + 'px'}
        paginationRowsPerPageOptions={[100, 200, 400, 600]}
        onChangeRowsPerPage={(currentRowsPerPage) => {
          setLimit(+currentRowsPerPage);
          setCurrentPage(1);
        }}
        paginationPerPage={+limit}
        progressPending={loading}
        paginationTotalRows={total}
        progressComponent={<div className="wait">{t('please_wait')}</div>}
        paginationServer
        onChangePage={handlePageChange}
        paginationComponentOptions={{
          rowsPerPageText: t('total_record'),
          rangeSeparatorText: t('from'),
          noRowsPerPage: false,
          selectAllRowsItem: false,
        }}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        selectableRows
        selectableRowSelected={(row) => row.isSelected}
        onSelectedRowsChange={(row) => setSelected(row.selectedRows)}
        selectableRowsVisibleOnly
        persistTableHead
        selectableRowsHighlight
        direction={'rtl'}
        expandableRows
        expandableRowsHideExpander
        expandOnRowClicked
        expandableRowsComponent={
          <DriverDetails
            toggleActivate={toggleActivate}
            data={(row) => row}
            apiVersion={apiVersion}
            loadingConfig={loadingConfig}
            toggleReady={toggleReady}
          />
        }
        customStyles={{
          headCells: {
            style: {
              fontWeight: 'bold',
              fontSize: 12,
              background: '#F9F9F9',
              justifyContent: 'flex-start',
            },
          },
          rows: {
            style: {
              cursor: 'pointer',
              fontSize: 12,
            },
          },
        }}
      />
    </div>
  );
};

export default Driver;

const FilterComponent = ({
  filterText,
  onFilter,
  onClear,
  placeholder,
  filterStatus,
  translateText,
  filters = {},
  handlerFilters,
  handleStatusFilter,
  filterCompany,
  handleCompanyFilter,
  handleDateFilter,
  cities,
  selectHandlerCity,
  t,
  loading,
  handleGetDataByFilter,
  date,
}) => (
  <div className="search-order flex flex-col mb-10 rtl">
    <div className="flex w-full items-center filter gap-x-4">
      <div className="flex items-center gap-x-4">
        <div className="flex items-center gap-x-4">
          <div className="flex">
            <input
              className="filter-input"
              placeholder={translateText.firstName}
              name="firstName"
              value={filters.firstName}
              onChange={handlerFilters}
              disabled={loading}
            />
          </div>
          <div className="flex">
            <input
              className="filter-input"
              placeholder={translateText.lastName}
              name="lastName"
              value={filters.lastName}
              onChange={handlerFilters}
              disabled={loading}
            />
          </div>
          <div className="flex">
            <input
              className="filter-input"
              placeholder={translateText.phone}
              name="phone"
              value={filters.phone}
              onChange={handlerFilters}
              disabled={loading}
            />
          </div>
          <div className="flex">
            <input
              className="filter-input"
              placeholder={translateText.id_number}
              name="id_number"
              value={filters.id_number}
              onChange={handlerFilters}
              disabled={loading}
            />
          </div>
        </div>
      </div>
    </div>
    <div className="flex w-full items-center filter gap-x-4">
      <div className="flex items-center gap-x-4">
        <div className="flex ">
          <CustomSelectReact
            placeholder={translateText.city}
            onChange={selectHandlerCity}
            className="filter-select min-w-[200px]"
            options={cities}
            value={filters.cities}
            isDisabled={loading}
            isMulti
            key="cities"
          />
        </div>
      </div>

      <div className="flex items-center gap-x-4">
        <div className="flex">
          <CustomSelectReact
            placeholder={translateText.status}
            className="filter-select min-w-[200px]"
            options={filterStatus}
            onChange={handleStatusFilter}
            value={filters.status}
            isMulti
            isDisabled={loading}
          />
        </div>
      </div>
      <div className="flex items-center gap-x-4">
        <div className="flex filter-select min-w-[200px] ltr">
          <DateTimeRangePicker
            value={date?.value?.length == 0 ? undefined : date?.value}
            onChange={handleDateFilter}
            disableClock
            format={'y-MM-dd'}
            disabled={loading}
          />
        </div>
      </div>
    </div>
    <div className="relative w-full  my-8">
      <div className="absolute right-0 ">
        <LoadingButton onClick={handleGetDataByFilter} label={'Apply Filter'} loading={loading} />
      </div>
    </div>
  </div>
);
