import { useTranslation } from 'react-i18next';

const useFiltersOption = () => {
  const { t } = useTranslation();

  const status = [
    {
      value: false,
      label: t('failure'),
    },
    {
      value: true,
      label: t('success'),
    },
  ];
  const methods = [
    {
      value: 'GET',
      label: 'GET',
    },
    {
      value: 'POST',
      label: 'POST',
    },
    {
      value: 'PUT',
      label: 'PUT',
    },
    {
      value: 'PATCH',
      label: 'PATCH',
    },
    {
      value: 'DELETE',
      label: 'DELETE',
    },
  ];

  const types = [
    {
      value: 'DRIVER_CREATE',
      label: 'DRIVER CREATE',
    },
    {
      value: 'DRIVER_ASSIGN_DRIVER_TO_ORDER',
      label: 'DRIVER ASSIGN DRIVER TO ORDER',
    },
    {
      value: 'CREATE_ORDER',
      label: 'CREATE_ORDER',
    },
    {
      value: 'DRIVER_EXECUTE_ORDER',
      label: 'DRIVER EXECUTE ORDER',
    },
    {
      value: 'ORDER_CANCEL',
      label: 'ORDER CANCEL',
    },
  ];

  return { methods, types, status };
};

export default useFiltersOption;
