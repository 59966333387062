import axios from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { config, SETTING_KEY } from '../../config/Config';
import { handleMessage } from '../../helper/utils';
import SelectTimeZone from './components/SelectTimeZone';
import ConfigAPIVersionMobile from './ConfigAPIVersionMobile';
import Fee from './Fee';
import LimitDisplayOrder from './Limit';
import Received from './received';
import TimeExecute from './TimeExecute';
import TimeoutCITC from './TimeoutCITC';
import TimeSetting from './TimeSetting';
import ToggleCITC from './ToggleCITC';
import ToggleMaeda from './ToggleMaeda';
import ToggleHyperpay from './ToogleHyperpay';
import ToogleODOO from './ToogleODOO';
// import ToogleShukah from './ToogleShukah';
import WhatsAppPhone from './WhatsAppPhone';
import ToggleSocket from './ToggleSocket';
import useEventEnter from '../../hooks/useEventEnter';

export default function TabSetting({ isSuperAdmin }) {
  const { t } = useTranslation();
  const [haveSetting, setHaveSetting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState(false);
  const [error, setError] = useState(false);
  const [execute, setExecute] = useState({});
  const [received, setReceived] = useState({});
  const [whatsAppPhone, setWhatsAppPhone] = useState({});
  const [fee, setFee] = useState({});
  const [timeSetting, setTimeSetting] = useState({});
  const [citc, setCitc] = useState({});
  const [hyperpay, setHyperpay] = useState({});
  const [odoo, setOdoo] = useState({});

  const [limitOrder, setLimitOrder] = useState({});
  const [timeoutCITC, setTimeoutCITC] = useState({});

  // const [apiVerPortal, setApiVerPortal] = useState({});
  const [apiVerMobile, setApiVerMobile] = useState({});
  const [shukah, setShukah] = useState({});
  const [enableOrderSocket, setEnableOrderSocket] = useState({});

  const [errors, setErrors] = useState({});

  const onSubmit = async () => {
    if (Object.values(errors).length > 0) {
      return false;
    }
    if (loading) return false;
    setLoading(true);
    try {
      const settingData = [
        {
          key: SETTING_KEY.TOGGLE_ODOO,
          content: {
            ...odoo,
          },
        },
        {
          key: SETTING_KEY.TIME_TRACKING,
          content: {
            ...timeSetting,
          },
        },
        {
          key: SETTING_KEY.TOGGLE_CITC,
          content: {
            ...citc,
          },
        },
        {
          key: SETTING_KEY.DRIVER_TIME_EXECUTE,
          content: {
            ...execute,
          },
        },
        {
          key: SETTING_KEY.RECEIVED,
          content: {
            ...received,
          },
        },
        {
          key: SETTING_KEY.WHATSAPP,
          content: {
            ...whatsAppPhone,
          },
        },
        {
          key: SETTING_KEY.LIMIT_ORDER,
          content: {
            ...limitOrder,
          },
        },
        {
          key: SETTING_KEY.HYPERPAY,
          content: {
            ...hyperpay,
          },
        },
        {
          key: SETTING_KEY.TIMEOUT_CITC,
          content: {
            ...timeoutCITC,
          },
        },
        {
          key: SETTING_KEY.TOGGLE_SHUKAH,
          content: {
            ...shukah,
          },
        },
        {
          key: SETTING_KEY.ENABLE_ORDER_SOCKET,
          content: {
            ...enableOrderSocket,
          },
        },
      ];
      if (isSuperAdmin) {
        settingData.push(
          {
            key: SETTING_KEY.FEE_TAX,
            content: {
              ...fee,
            },
          },

          {
            key: SETTING_KEY.API_VER_MOBILE,
            content: {
              ...apiVerMobile,
            },
          }
        );
      }
      const resp = await axios.post(
        config.API_URL.SETTING.CREATE,
        {
          data: settingData,
        },
        {
          headers: config.headers,
        }
      );
      const { data } = resp;

      if (data.status) {
        toast(t('data_saved_successfully'), { position: toast.POSITION.BOTTOM_LEFT });
        return;
      }
      toast.error(t('request_assigned_to_driver'), { position: toast.POSITION.BOTTOM_LEFT });
      return;
    } catch (errors) {
    } finally {
      setLoading(false);
    }
  };

  const handlerSetting = () => {
    if (password === process.env.REACT_APP_PASSWORD_SETTING) {
      setHaveSetting(true);
    }

    setError(true);
  };

  useEventEnter(handlerSetting);
  
  const handlerChange = (e) => {
    setPassword(e.target.value);
  };

  const createFakeOrder = async () => {
    if (isSuperAdmin) {
      try {
        const res = await axios.post(
          config.API_URL.ORDERS.CREATE_FAKE_ORDER,
          {},
          {
            headers: config.headers,
          }
        );

        if (res.data.status === 200) {
          handleMessage('success', t('create_fake_order_success'));
        } else {
          handleMessage('error', t('create_fake_order_failed'));
        }
      } catch (error) {
        handleMessage('error', t('data_error'));
      }
    }
  };
  return (
    <>
      {!haveSetting && (
        <div className="flex flex-col justify-end">
          <div className="setting">
            <p>{t('enter_password_login_to_setting')}</p>
            <div className="row flex items-center gap-x-4">
              <label>{t('password')}</label>
              <input type="password" value={execute.time} onChange={handlerChange} />
            </div>
            {error && (
              <p style={{ color: 'rgba(244, 91, 105, 1)' }}>{t('password_is_not_match')}</p>
            )}
          </div>

          <div className="setting">
            <button className="saveButton" onClick={handlerSetting}>
              {t('login')}
            </button>
          </div>
        </div>
      )}
      {haveSetting && (
        <div className="flex flex-col justify-end">
          <div>
            <h1 className="text-4xl px-12 py-8 rtl">{t('الإعداد للوحة القيادة Locate Go')}</h1>
            {isSuperAdmin && (
              <div className="setting">
                <button
                  className="refresh-button create-fake-order-button"
                  onClick={createFakeOrder}
                >
                  {t('create_fake_order')}
                </button>
              </div>
            )}
            <TimeExecute setExcute={setExecute} execute={execute} />

            {isSuperAdmin && (
              <Fee fee={fee} setFee={setFee} setErrors={setErrors} errors={errors} />
            )}

            <TimeSetting timeSetting={timeSetting} setTimeSetting={setTimeSetting} />
            <Received setReceived={setReceived} received={received} />
            <LimitDisplayOrder setLimitOrder={setLimitOrder} limitOrder={limitOrder} />
            <WhatsAppPhone setWhatsAppPhone={setWhatsAppPhone} whatsAppPhone={whatsAppPhone} />
            <ToggleCITC setCitc={setCitc} citc={citc} />
            <ToggleHyperpay setHyperpay={setHyperpay} hyperpay={hyperpay} />
            {/* <ToogleShukah shukah={shukah} setShukah={setShukah} /> */}
            <ToogleODOO setOdoo={setOdoo} odoo={odoo} />
            <ToggleSocket
              enableOrderSocket={enableOrderSocket}
              setEnableOrderSocket={setEnableOrderSocket}
            />

            <TimeoutCITC setTimeoutCITC={setTimeoutCITC} timeoutCITC={timeoutCITC} />
            {isSuperAdmin && (
              <div style={{ backgroundColor: 'red' }}>
                {/* <ConfigAPIVersionPortal
                  apiVerPortal={apiVerPortal}
                  setApiVerPortal={setApiVerPortal}
                /> */}
                <ConfigAPIVersionMobile
                  apiVerMobile={apiVerMobile}
                  setApiVerMobile={setApiVerMobile}
                />
              </div>
            )}
            <SelectTimeZone />

            <div className="setting">
              <button className="saveButton" onClick={onSubmit} disabled={loading}>
                {t('save')}
              </button>
            </div>
          </div>
          <hr />
          <div>
            <h1 className="text-4xl px-12 py-8 rtl">{t('تفعيل / تعطيل حالة عمل Locate Go ')}</h1>
            <ToggleMaeda />
          </div>
        </div>
      )}
    </>
  );
}
