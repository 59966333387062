import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../../../commonComponents/LoadingButton';
import CustomSelectReact from '../../CustomSelectReact';
import useFilterOption from './useFilterOption';
import useEventEnter from '../../../hooks/useEventEnter';
import SearchInput from '../../SearchInput';

export default function Filters({
  loading,
  filters,
  selectHandler,
  dateRange,
  handleDateFilter,
  getShukahLogs,
}) {
  const { t } = useTranslation();
  const { payment_methods, status, types } = useFilterOption();
  useEventEnter(getShukahLogs);

  return (
    <div className="px-8 py-5 flex items-center gap-4  mb-[35px] flex-wrap">
      <div>
        <SearchInput
          className="input-select-input-wrapper !border-0 h-full max-w-max w-full"
          disabled={loading}
          placeholder={t('order_number')}
          handleSearch={(e) => selectHandler(e, 'order_number')}
          makeRerender={filters}
          value={filters?.order_number}
        />
      </div>

      <div>
        <SearchInput
          className="input-select-input-wrapper !border-0 h-full max-w-max w-full"
          disabled={loading}
          placeholder={t('driver_id')}
          handleSearch={(e) => selectHandler(e, 'driver_id')}
          makeRerender={filters}
          value={filters?.driver_id}
        />
      </div>

      <div>
        <SearchInput
          className="input-select-input-wrapper !border-0 h-full max-w-max w-full"
          disabled={loading}
          placeholder={t('merchant_name')}
          handleSearch={(e) => selectHandler(e, 'merchant_name')}
          makeRerender={filters}
          value={filters?.merchant_name}
        />
      </div>

      <div>
        <SearchInput
          className="input-select-input-wrapper !border-0 h-full max-w-max w-full"
          disabled={loading}
          placeholder={t('client_phone')}
          handleSearch={(e) => selectHandler(e, 'client_phone')}
          makeRerender={filters}
          value={filters?.client_phone}
        />
      </div>

      <div className="input-select-wrapper">
        <CustomSelectReact
          isClearable
          isDisabled={loading}
          placeholder={t('payment_method')}
          className="input-select-input-wrapper !border-0 h-full max-w-max w-full"
          options={payment_methods}
          value={filters.payment_method}
          onChange={(e) => selectHandler(e, 'payment_method')}
        />
      </div>

      <div className="input-select-wrapper">
        <CustomSelectReact
          isDisabled={loading}
          placeholder={t('types')}
          className="input-select-input-wrapper !border-0 h-full max-w-max w-full"
          options={types}
          value={filters.types}
          onChange={(e) => selectHandler(e, 'types')}
          isMulti
        />
      </div>

      <div className="input-select-wrapper">
        <CustomSelectReact
          isDisabled={loading}
          placeholder={t('status')}
          className="input-select-input-wrapper !border-0 h-full max-w-max w-full"
          options={status}
          value={filters.status}
          onChange={(e) => selectHandler(e, 'status')}
          isMulti
        />
      </div>
      <div className="input-select-wrapper" style={{ direction: 'ltr' }}>
        <DateTimeRangePicker
          disabled={loading}
          className="input-select-input-wrapper !border-0 w-[200px]"
          value={dateRange}
          onChange={handleDateFilter}
          disableClock
          format={'y-MM-dd'}
        />
      </div>
      <div className="relative w-full mb-4">
        <div className="absolute right-0 ">
          <LoadingButton onClick={getShukahLogs} label={t('Apply Filter')} loading={loading} />
        </div>
      </div>
    </div>
  );
}
