/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useTabs, TabPanel } from 'react-headless-tabs';
import { useTranslation } from 'react-i18next';
import ListOrder from '../Atoms/ListOrders';
import OrderCount from '../Atoms/OrderCount';

const OrderCard = ({
  setSelectedOrder,
  orders,
  goToOrder,
  refreshList,
  handleStatusFilter,
  handleCityFilter,
  apiVersion,
  loadingConfig,
}) => {
  const [selectedTab, setSelectedTab] = useTabs(['order_list', 'dashboard']);
  const { t } = useTranslation();

  return (
    <>
      <div className="flex tab-header order-tab">
        <button
          className={`tab-button ${selectedTab === 'order_list' ? 'tab-active' : ''}`}
          isActive={selectedTab === 'order_list'}
          onClick={() => setSelectedTab('order_list')}
        >
          {t('new_orders')}
        </button>
        <button
          className={`tab-button ${selectedTab === 'dashboard' ? 'tab-active' : ''}`}
          isActive={selectedTab === 'dashboard'}
          onClick={() => setSelectedTab('dashboard')}
        >
          {t('dashboard')}
        </button>
      </div>
      <div>
        <TabPanel hidden={selectedTab !== 'dashboard'}>
          <OrderCount />
        </TabPanel>
        <TabPanel hidden={selectedTab !== 'order_list'}>
          <ListOrder
            orders={orders}
            setSelectedOrder={setSelectedOrder}
            refreshList={refreshList}
            handleStatusFilter={handleStatusFilter}
            handleCityFilter={handleCityFilter}
            apiVersion={apiVersion}
            loadingConfig={loadingConfig}
          />
        </TabPanel>
      </div>
    </>
  );
};

export default OrderCard;
