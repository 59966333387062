import { Listbox, Transition } from '@headlessui/react';
import axios from 'axios';
import { Fragment, useContext } from 'react';
import { toast } from 'react-toastify';
import { config, SETTING_KEY } from '../../../../config/Config';
import { CheckMarkOutLine, Chevron } from '../../../../constants/Icon';
import { DataContext } from '../../../../context/DataContext';
export const optionTimezone = [
  { id: 1, name: '(UTC+07:00) Hanoi, VietNam', value: '+07:00', timezone: 'Asia/Ho_Chi_Minh' },
  { id: 2, name: '(UTC+03:00) Kuwait, Riyadh', value: '+03:00', timezone: 'Asia/Kuwait' },
];
export default function SelectTimeZone() {
  // const [currentTimezone, setCurrentTimezone] = useState();
  const { currentTimezone, onGetSettingTimeZone } = useContext(DataContext);

  const updateSetting = async (name, gmt, timezone) => {
    try {
      await axios.post(
        config.API_URL.SETTING.CREATE,
        {
          data: [
            {
              key: SETTING_KEY.TIME_ZONE,
              content: {
                gmt,
                name,
                timezone,
              },
            },
          ],
        },
        {
          headers: config.headers,
        }
      );
      toast('Change Timezone success', { position: toast.POSITION.BOTTOM_LEFT });
      onGetSettingTimeZone();
    } catch (error) {
    } finally {
    }
  };

  // const getSetting = async () => {
  //   const resp = await axios.get(config.API_URL.SETTING.GET, {
  //     params: {
  //       key: SETTING_KEY.TIME_ZONE,
  //     },
  //     headers: config.headers,
  //   });
  //   const { data } = resp;
  //   const { setting } = data;
  //   setCurrentTimezone(setting);
  // };
  // useEffect(() => {
  //   getSetting();
  // }, []);
  return (
    <div className="setting">
      <div className="row flex items-center gap-x-4">
        <div className="w-[200px]">
          <h3>TimeZone</h3>
        </div>
        <div className="flex justify-between pl-0.5 mb-4">
          <div className="w-9/12">
            <div className="w-[230px]">
              <Listbox
                value={currentTimezone}
                onChange={(time) => {
                  updateSetting(time?.name, time?.value, time?.timezone);
                }}
              >
                <div className="relative mt-1">
                  <Listbox.Button className="flex justify-between items-center w-full cursor-default rounded-lg bg-white py-1 pl-3 pr-10 text-left shadow-md border border-gray-100 focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm ">
                    <div className="block truncate ">
                      {currentTimezone?.name || 'Select Timezone'}
                    </div>
                    <div className="pointer-events-none  inset-y-0   pr-2 w-10 h-10 flex items-center justify-center">
                      <Chevron color="grey" aria-hidden="true" />
                    </div>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {optionTimezone.map((person, personIdx) => (
                        <Listbox.Option
                          key={personIdx}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                              active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                            } py-4 `
                          }
                          value={person}
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? 'font-medium' : 'font-normal'
                                } `}
                              >
                                {person.name}
                              </span>
                              {selected ? (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600 w-12 h-12">
                                  <CheckMarkOutLine color="#3BCE63" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
