import moment from 'moment';
import { useTranslation } from 'react-i18next';
import colBalance from '../../Transactions/colBalance';

const useSchema = () => {
  const { t } = useTranslation();
  const schema = [
    {
      name: t('order_number'),
      selector: 'order_number',
      sortable: true,
      style: {
        padding: 0,
      },
    },
    {
      name: t('by'),
      selector: (row) => row?.created_by?.name ?? '',

      sortable: true,
      style: {
        padding: 0,
      },
    },
    {
      name: t('delegate'),
      selector: 'driver_name',
      sortable: true,
      style: {
        padding: 0,
      },
    },
    {
      name: t('value'),
      selector: (row) => `${row.amount.toFixed(2)}`,
      sortable: true,
      width: '200px',
      style: {
        padding: 0,
      },
    },
    {
      name: t('transaction.previous_balance'),
      minWidth: '200px',
      cell: (row) => colBalance(row, 'previous'),
    },
    {
      name: t('transaction.remaining_balance'),
      minWidth: '200px',
      cell: (row) => colBalance(row, 'remaining'),
    },
    {
      name: t('operation'),
      selector: (row) =>
        `${row.isFee ? t('table_fee') : row.isCharge ? t('shipping_value') : t('not_charge')}`,
      sortable: true,
      wrap: true,
      width: '200px',
      style: {
        padding: 0,
      },
    },

    {
      name: t('date'),
      selector: (row) =>
        `${row.created_at !== null ? moment(row.created_at).locale('ar').format('LL') : '...'}`,
      sortable: true,
      wrap: true,
      width: '300px',
      style: {
        padding: 0,
      },
    },

    {
      name: t('date_time'),
      selector: (row) =>
        `${
          row.created_at !== null ? moment(row.created_at).locale('ar').format(' hh:mm A') : '...'
        }`,
      sortable: true,
      wrap: true,
      width: '300px',
      style: {
        padding: 0,
      },
    },
  ];
  return schema;
};

export default useSchema;
