import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FORMAT_DATE, parseDateWithGmt } from '../../helper/date/parseDateGmt';
import { useDate } from '../../hooks/useDate';
const renderFields = (text) => {
  if (!text) return '-';
  return text;
};

export default function useColumns({ logs }) {
  const { t } = useTranslation();
  const { currentTimezone } = useDate();
  const columns = useMemo(
    () => [
      {
        name: t('order_number'),
        selector: 'order.order_number',
        sortable: true,
        minWidth: '200px',
        cell: (row) => renderFields(row?.order?.order_number),
      },
      {
        name: t('shukah.driver.id'),
        selector: 'driver.id',
        sortable: true,
        minWidth: '200px',
        cell: (row) => renderFields(row?.driver?.id_number),
      },
      {
        name: t('shukah.restaurant.name'),
        selector: 'order.restaurant_name',
        sortable: true,
        minWidth: '200px',
        cell: (row) => renderFields(row?.order?.restaurant_name),
      },
      {
        name: t('shukah.customer.phone'),
        selector: 'order.customer_phone',
        sortable: true,
        minWidth: '200px',
        cell: (row) => <span className="ltr">{renderFields(row?.order?.customer_phone)}</span>,
      },
      {
        name: t('payment'),
        selector: 'order.payment_method',
        sortable: true,
        minWidth: '140px',
        cell: (row) => renderFields(row?.order?.payment_method),
      },
      {
        name: t('status'),
        selector: 'order.status',
        sortable: true,
        minWidth: '140px',
        cell: (row) => renderFields(row?.status),
      },
      {
        name: t('type'),
        selector: 'type',
        sortable: true,
        minWidth: '260px',
        cell: (row) => renderFields(row?.type),
      },
      {
        name: t('date'),

        selector: (row) =>
          parseDateWithGmt({
            date: row.created_at,
            timezone: currentTimezone?.timezone,
          }),
        sortable: true,
        wrap: true,
        minWidth: '120px',
      },
      {
        name: t('date_time'),
        selector: (row) =>
          parseDateWithGmt({
            date: row.created_at,
            timezone: currentTimezone?.timezone,
            format: FORMAT_DATE.hms,
          }),
        sortable: true,
        wrap: true,
        minWidth: '140px',
      },
    ],
    [logs]
  );
  return { columns };
}
