import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FORMAT_DATE, parseDateWithGmt } from '../../helper/date/parseDateGmt';
import { useDate } from '../../hooks/useDate';

export default function useColumns() {
  const { t } = useTranslation();
  const { currentTimezone } = useDate();

  const columns = useMemo(
    () => [
      {
        name: t('id'),
        selector: 'id',
        sortable: true,
        minWidth: '200px',
      },
      {
        name: t('transaction_id'),
        selector: (row) => row?.transaction_id || '',
        sortable: true,
        width: '200px',
        style: {
          padding: 0,
        },
      },
      {
        name: t('fort_id'),
        selector: 'fort_id',
        sortable: true,
        style: {
          padding: 0,
        },
      },
      {
        name: t('from_application'),
        selector: 'from_application',
        sortable: true,
        width: '150px',
        style: {
          padding: 0,
        },
      },
      {
        name: t('digital_wallet'),
        selector: (row) => row?.digital_wallet ?? row?.payment_log?.digital_wallet,
        sortable: true,
        width: '150px',
        style: {
          padding: 0,
        },
      },
      {
        name: t('response_code'),
        selector: 'response_code',
        sortable: true,
        width: '150px',
        style: {
          padding: 0,
        },
      },
      {
        name: t('response_message'),
        selector: 'response_message',
        sortable: true,
        width: '150px',
        style: {
          padding: 0,
        },
      },
      {
        name: t('transaction_status'),
        selector: (row) => `${row?.transaction_status || ''}`,
        sortable: true,
        width: '200px',
        style: {
          padding: 0,
        },
      },
      {
        name: t('sdk_token'),
        selector: (row) => `${row?.sdk_token || ''}`,
        sortable: true,
        width: '200px',
        style: {
          padding: 0,
        },
      },
      {
        name: t('service_command'),
        selector: (row) => row?.service_command || '',
        sortable: true,
        width: '200px',
        style: {
          padding: 0,
        },
      },
      {
        name: t('signature'),
        selector: (row) => row?.signature || '',
        sortable: true,
        width: '200px',
        style: {
          padding: 0,
        },
      },

      {
        name: t('date_time'),
        selector: (row) =>
          parseDateWithGmt({
            date: row.created_at,
            timezone: currentTimezone?.timezone,
            format: FORMAT_DATE.ymdhms,
          }),
        sortable: true,
        width: '300px',
        wrap: true,
        style: {
          padding: 0,
        },
      },
    ],
    [currentTimezone?.timezone, t]
  );
  return { columns };
}
