import axios from 'axios';
import GoogleMap from 'google-map-react';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { config } from '../../config/Config';
import Loading from '../Atoms/Loading';
import OrderCard from './OrderCard';

import DriverCards from '../Atoms/DriverCards';

import DriverMarker from '../Atoms/Marker/DriverMarker';
import OrderMarker from '../Atoms/Marker/OrderMarker';
import RestaurantMaker from '../Atoms/Marker/RestaurantMarker';

import { isEmpty } from 'lodash';
import { useTabs } from 'react-headless-tabs';
import '../../css/map.css';
import { CONST_STATUS } from '../../constants/status';
import { findCenter } from '../../helper/utils';

const apiMaps = 'AIzaSyA6H47tcACDHEIIVhLKkuQx0lesf5fxvDE';
// có hasOrder là đang nhận đơn

const options = {
  mapTypeControl: true,
  mapTypeId: 'roadmap',
  mapTypeControlOptions: {
    mapTypeIds: ['roadmap', 'satellite'],
  },
  styles: [
    { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
    { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
    { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
    {
      featureType: 'administrative.locality',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#d59563' }],
    },
    {
      featureType: 'poi',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'transit',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'road',
      stylers: [{ color: '#38414e' }],
    },
    {
      featureType: 'road.arterial',
      stylers: [{ visibility: 'off' }],
    },

    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ color: '#17263c' }],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#515c6d' }],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.stroke',
      stylers: [{ color: '#17263c' }],
    },
  ],
};
const coordinateDefault = {
  lat: 26.4910430908203,
  lng: 50.0308636515505,
};

const LocationDrivers = ({ ...props }) => {
  const { apiVersion, loadingConfig } = props;

  const [coordinate, setCoordinate] = useState(coordinateDefault);
  const [loading, setLoading] = useState(true);
  const [drivers, setDrivers] = useState([]);
  const [orders, setOrders] = useState([]);
  const [orderStatus, setOrderStatus] = useState([]);
  const [city, setCity] = useState('');

  const [boundaries, setBoundaries] = useState({});
  const [selectedDriver, setSelectedDriver] = useState(null);

  const [selectedOrder, setSelectedOrder] = useState(null);
  const [zoom, setZoom] = useState(13);
  const [idDriverFocus, setIdDriverFocus] = useState('');

  const [tooltipRes, setTooltipRes] = useState(null);
  const [tooltipDriver, setTooltipDriver] = useState(null);
  const [tooltipCus, setTooltipCus] = useState(null);

  // const [selectedTab, setSelectedTab] = useTabs(['online', 'offline']);
  const [selectedTab, setSelectedTab] = useTabs([CONST_STATUS.ONLINE, CONST_STATUS.OFFLINE]);

  const getDriver = async (bounds, filter = {}) => {
    try {
      setLoading(true);
      const resp = await axios.post(
        config.API_URL.LOCATION.DRIVER,
        {
          boundaries: bounds,
          filter: { ...filter },
        },
        {
          headers: config.headers,
        }
      );
      setDrivers(resp.data?.drivers || []);
    } catch (errors) {
    } finally {
      setLoading(false);
    }
  };

  const getOrder = async (bounds, filter = {}) => {
    try {
      setLoading(true);
      const resp = await axios.post(
        config.API_URL.LOCATION.ORDER,
        { boundaries: bounds, filter },
        {
          headers: config.headers,
        }
      );
      setOrders(resp.data?.orders || []);
    } catch (errors) {
    } finally {
      setLoading(false);
    }
  };

  const handleStatusFilter = (status) => {
    const filter_order = {
      order_id: selectedOrder || null,
      status: status,
      city: city,
    };
    setOrderStatus(status);
    getOrder(boundaries, filter_order);
  };

  const handleCityFilter = (city) => {
    const filter_order = {
      order_id: selectedOrder || null,
      city: city,
      status: orderStatus,
    };
    setCity(city);
    getOrder(boundaries, filter_order);
  };

  const refreshListDriver = async () => {
    await setSelectedDriver(null);
    const filter = {
      driver_id: null,
    };
    const filter_order = {
      order_id: selectedOrder || null,
    };
    getDriver(boundaries, filter);
    getOrder(boundaries, filter_order);
    setZoom(13);
  };

  const refreshListOrder = async () => {
    await setSelectedOrder(null);
    const filter = {
      driver_id: selectedDriver || null,
    };
    const filter_order = {
      order_id: null,
    };

    getDriver(boundaries, filter);
    getOrder(boundaries, filter_order);
  };

  const getDriverAndOrder = (boundaries) => {
    const filter = {
      driver_id: selectedDriver || null,
    };
    const filter_order = {
      order_id: selectedOrder || null,
      status: orderStatus,
      city: city,
    };

    getDriver(boundaries, filter);
    getOrder(boundaries, filter_order);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handler = useCallback(
    debounce((boundaries) => getDriverAndOrder(boundaries), 500),
    []
  );

  const onBoundsChange = ({ center, zoom, bounds, marginBounds }) => {
    if (idDriverFocus.length > 0) {
      return;
    }
    setCoordinate(center);
    const northEast = bounds.ne;
    const southWest = bounds.sw;
    const boundaries = {
      northLatitude: northEast.lat,
      eastLongitude: northEast.lng,
      southLatitude: southWest.lat,
      westLongitude: southWest.lng,
    };
    setBoundaries(boundaries);
    handler(boundaries);
  };

  const goToDriver = (driver) => {
    setSelectedDriver(driver.driver_id);
    const coordinateDriver = {
      lat: driver.latitude,
      lng: driver.longitude,
    };
    setCoordinate(coordinateDriver);
  };

  useEffect(() => {
    const interval = window.setInterval(async () => {
      const bounds = {
        northLatitude: boundaries?.northLatitude,
        eastLongitude: boundaries?.eastLongitude,
        southLatitude: boundaries?.southLatitude,
        westLongitude: boundaries?.westLongitude,
      };
      const filter = {
        driver_id: selectedDriver || null,
      };
      const filter_order = {
        order_id: selectedOrder || null,
        status: orderStatus,
        city: city,
      };

      getDriver(bounds, filter);
      getOrder(bounds, filter_order);
    }, 300000);
    return () => window.clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boundaries, selectedOrder, selectedDriver]);

  const handlerSelectDriver = (driver) => {
    if (idDriverFocus.length > 0) {
      setIdDriverFocus('');
    } else {
      setIdDriverFocus(driver.driver_id);
    }

    const orderByDriverFocus = orders.filter((item) => driver.driver_id === item?.driver_id);
    if (typeof orderByDriverFocus !== 'undefined' && !isEmpty(orderByDriverFocus)) {
      const listLocation = [];
      // location driver
      listLocation.push({ lat: driver.latitude, lng: driver.longitude });
      const listLocationRestaurantAndCustomer = orderByDriverFocus.reduce(
        (previousValue, currentValue) => {
          previousValue.push({
            lat: currentValue.restaurant_location.coordinates[1],
            lng: currentValue.restaurant_location.coordinates[0],
          });
          previousValue.push({
            lat: currentValue.customer_location.coordinates[1],
            lng: currentValue.customer_location.coordinates[0],
          });
          return previousValue;
        },
        []
      );
      listLocation.push(...listLocationRestaurantAndCustomer);
      const centerLocation = findCenter(listLocation);
      setCoordinate(centerLocation);
    } else {
      setCoordinate({ lat: driver.latitude, lng: driver.longitude });
    }

    if (zoom < 14) {
      setZoom(14);
    } else {
      setZoom(13);
    }
    const filter = {
      driver_id: driver.driver_id,
    };

    getOrder({}, filter);
    getDriver({}, filter);
    setSelectedDriver(driver);
  };

  const handlerSelectOrder = (order) => {
    if (!order) {
      const bounds = {
        northLatitude: boundaries?.northLatitude,
        eastLongitude: boundaries?.eastLongitude,
        southLatitude: boundaries?.southLatitude,
        westLongitude: boundaries?.westLongitude,
      };
      getDriver(bounds);
      return;
    }
    const filter = {
      driver_id: order.driver?.id || null,
    };
    const filter_order = {
      order_id: order._id || null,
    };
    getDriver({}, filter);
    getOrder({}, filter_order);
    setSelectedOrder(order);
  };

  const _onClick = (value) => {
    setTooltipRes(null);
    setTooltipDriver(null);
    setTooltipCus(null);
  };

  const onSetSelectedTab = (tab) => {
    setSelectedTab(tab);
    const filter = {
      driver_id: null,
      status: tab === CONST_STATUS.ONLINE ? CONST_STATUS.ONLINE : CONST_STATUS.OFFLINE,
    };
    setSelectedDriver(null);
    if (idDriverFocus.length > 0) {
      setIdDriverFocus('');
    }
    getDriver(boundaries, filter);
    setZoom(13);
  };

  const DriverMarkers = drivers.map((driver, index) => {
    const lat = driver.latitude;
    const long = driver.longitude;
    return (
      <DriverMarker
        selectedDriver={selectedDriver}
        id={driver.driver_id}
        key={driver.driver_id}
        lat={lat}
        lng={long}
        driver={driver}
        setTooltipDriver={setTooltipDriver}
        tooltipDriver={tooltipDriver}
      />
    );
  });

  const OrderMarkers = orders.map((order, index) => {
    const lat = order.customer_location?.coordinates?.[1];
    const long = order.customer_location?.coordinates?.[0];

    return (
      <OrderMarker
        selectedOrder={selectedOrder}
        id={order._id}
        key={'cus_' + order._id}
        lat={lat}
        lng={long}
        order={order}
        selectedDriver={selectedDriver}
        setTooltipCus={setTooltipCus}
        tooltipCus={tooltipCus}
      />
    );
  });

  const RestaurantMarkers = orders.map((order, index) => {
    const lat = order.restaurant_location?.coordinates?.[1];
    const long = order.restaurant_location?.coordinates?.[0];
    return (
      <RestaurantMaker
        selectedOrder={selectedOrder}
        id={order._id}
        key={'res_' + order._id}
        lat={lat}
        lng={long}
        order={order}
        selectedDriver={selectedDriver}
        setTooltipDriver={setTooltipDriver}
        setTooltipRes={setTooltipRes}
        tooltipRes={tooltipRes}
      />
    );
  });
  return (
    <div className="tracking-driver">
      <div className="card-drivers cards">
        <DriverCards
          drivers={drivers}
          setSelectedDriver={handlerSelectDriver}
          goToDriver={goToDriver}
          refreshList={refreshListDriver}
          idDriverFocus={idDriverFocus}
          selectedTab={selectedTab}
          setSelectedTab={onSetSelectedTab}
        />
      </div>
      <div className="maps">
        {loading && <Loading />}
        <GoogleMap
          bootstrapURLKeys={{ key: apiMaps }}
          zoom={zoom}
          onChange={onBoundsChange}
          center={coordinate}
          onClick={_onClick}
          options={{ options }}
        >
          {DriverMarkers}
          {OrderMarkers}
          {RestaurantMarkers}
        </GoogleMap>
      </div>
      <div className="card-orders cards">
        <OrderCard
          orders={orders}
          setSelectedOrder={handlerSelectOrder}
          refreshList={refreshListOrder}
          selectedDriver={selectedDriver}
          handleStatusFilter={handleStatusFilter}
          handleCityFilter={handleCityFilter}
          apiVersion={apiVersion}
          loadingConfig={loadingConfig}
        />
      </div>
    </div>
  );
};

export default LocationDrivers;
