import moment from 'moment';
import { useTranslation } from 'react-i18next';
const restaurant_name = (row) => <span title={row.restaurant_city}>{row.restaurant_name}</span>;
const customer_name = (row) => <span title={row.customer_city}>{row.customer_name}</span>;

const useSchema = (showFakeOrderColumn = false) => {
  const { t } = useTranslation();
  const payments = [
    {
      cash: {
        title: t('cash'),
      },
      card: {
        title: t('card'),
      },
      point: {
        title: t('table_point'),
      },
    },
  ];

  const statusOptions = [
    {
      success: t('successful'),
      canceled: t('canceled_orders'),
      waiting: t('waiting'),
      ongoing: t('ongoing'),
    },
  ];
  const status = statusOptions[0];
  const schema = [
    {
      name: t('order'),
      selector: 'order_number',
      sortable: true,
      style: {
        padding: 0,
      },
    },
    ...(showFakeOrderColumn
      ? [
          {
            name: t('fake_order'),
            selector: (row) => `${row.isFake ? t('fake') : ''}`,
            sortable: true,
            with: '50px',
            style: {
              padding: 0,
              color: 'green',
            },
          },
        ]
      : []),

    {
      name: t('delegate'),
      selector: (row) =>
        `${row.driver_name !== null ? row.driver_name : t('waiting_for_acceptance')}`,
      sortable: true,
      width: '200px',
      style: {
        padding: 0,
      },
    },
    {
      name: t('payment_method'),
      width: '130px',
      selector: (row) => {
        return `${
          row.payment_method === 'Cash'
            ? payments[0].cash.title
            : row.payment_method === 'Card' || row.payment_method === 'online'
            ? payments[0].card.title
            : row.payment_method === 'Point' && payments[0].point.title
        }`;
      },
      sortable: true,
      style: {
        padding: 0,
      },
    },
    {
      name: t('delivery_value'),
      selector: 'delivery_cost',
      sortable: true,
      width: '130px',
      style: {
        padding: 0,
      },
    },

    {
      name: t('free'),
      selector: (row) => `${row.isFreeExpress === true ? t('yes') : t('no')}`,
      sortable: true,
      width: '50px',
      style: {
        padding: 0,
      },
    },
    {
      name: t('company'),
      width: '130px',
      selector: 'from_company',
      sortable: true,
      wrap: true,
      style: {
        direction: 'ltr',
        justifyContent: 'flex-end',
        padding: 0,
      },
    },
    {
      name: t('restaurant'),
      selector: 'restaurant_name',
      sortable: true,
      wrap: true,
      width: '200px',
      style: {
        padding: 0,
      },
      cell: restaurant_name,
    },
    {
      name: t('customer'),
      selector: 'customer_name',
      sortable: true,
      wrap: true,
      width: '200px',
      style: {
        padding: 0,
      },
      cell: customer_name,
    },
    {
      name: t('phone_number'),
      selector: 'customer_phone',
      sortable: true,
      wrap: true,
      width: '130px',
      style: {
        padding: 0,
        direction: 'ltr',
        justifyContent: 'flex-end',
      },
    },
    {
      name: t('ordered_at'),
      selector: (row) => `${moment(row.created_at).calendar('LL')}`,
      sortable: true,
      wrap: true,
      minWidth: '200px',
      style: {
        padding: 0,
      },
    },
    {
      name: t('order_time'),
      selector: (row) => `${moment(row.created_at).format('hh:mm A')}`,
      sortable: true,
      wrap: true,
      minWidth: '150px',
      style: {
        direction: 'ltr',
        justifyContent: 'flex-end',
        padding: 0,
      },
    },

    {
      name: t('acceptance_time'),
      minWidth: '100px',
      selector: (row) =>
        `${row.assigned_at !== null ? moment(row.assigned_at).format('hh:mm A') : '...'}`,
      sortable: true,
      wrap: true,
      style: {
        direction: 'ltr',
        justifyContent: 'flex-end',
        padding: 0,
      },
    },

    {
      name: t('finish_time'),
      minWidth: '150px',
      selector: (row) =>
        `${row.delivery_time !== null ? moment(row.delivery_time).format('hh:mm A') : '...'}`,
      sortable: true,
      wrap: true,
      style: {
        direction: 'ltr',
        justifyContent: 'flex-end',
        padding: 0,
      },
    },

    {
      name: t('status'),
      selector: (row) =>
        `${
          row.isDelivered
            ? status.success
            : row.isCanceled
            ? status.canceled
            : row.driver_name
            ? status.ongoing
            : status.waiting
        }`,
      sortable: true,
      width: '200px',
      style: {
        padding: 0,
      },
    },
  ];
  return schema;
};

export default useSchema;
