import axios from 'axios';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from '../../../config/Config';

const DriverCount = memo(({ refreshList }) => {
  const { t } = useTranslation();
  const [data, setData] = useState({});

  const getAnalysis = async () => {
    const response = await axios.get(config.API_URL.ANALYSIS.DRIVER, { headers: config.headers });

    if (response.data?.status) {
      const resp = response.data?.data;
      setData(resp);
    }
  };

  useEffect(() => {
    getAnalysis();
  }, [refreshList]);

  return (
    <>
      <div className="driver-dashboard content">
        <div className="card card--oil">
          <div className="card__count-container">
            <div className="card__count-text">
              <span className="card__count-text--big total">{data?.count_delivering || 0}</span>
              <span>{t('delivering')}</span>
            </div>
          </div>
        </div>
        <div className="card card--oil">
          <div className="card__count-container">
            <div className="card__count-text">
              <span className="card__count-text--big">{data?.count_drivers || 0}</span>
              {t('drivers_already')}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default DriverCount;
