import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Slide, toast } from 'react-toastify';
import * as Yup from 'yup';
import { config } from '../../config/Config';

toast.configure();
const EditCompany = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('delivery_company.validate.required.name')),
    phone: Yup.string().required(t('delivery_company.validate.required.phone')),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      cr_number: '',
      contract: '',
      iban: '',
      distance_radius: '',
      company_contract_price: '',
    },
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validateOnChange: false,
  });

  const getDetailCompany = async () => {
    try {
      const { data, status } = await axios.get(`${config.API_URL.DELIVERY_COMPANY.LIST}${id}`);
      if (status !== 200) return;
      // const { data } = data;

      await formik.setValues({
        name: data?.data?.name,
        phone: data?.data?.phone,
        cr_number: data?.data?.cr_number,
        iban: data?.data?.iban,
        contract: data?.data?.contract,
        distance_radius: data?.data?.company_distance_receive,
        company_contract_price: data?.data?.company_contract_price,
      });

      setLoading(false);
    } catch (error) {
      console.log('error when fetching data =====', error);
    }
  };

  useEffect(() => {
    getDetailCompany();
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    const finalizeData = {
      name: formik.values.name,
      phone: formik.values.phone,
      cr_number: formik.values.cr_number,
      contract: formik.values.contract,
      iban: formik.values.iban,
      company_distance_receive: formik.values.distance_radius,
      company_contract_price: formik.values.company_contract_price,
    };
    try {
      const { data } = await axios.put(
        `${config.API_URL.DELIVERY_COMPANY.UPDATE}${id}`,
        finalizeData,
        {
          headers: config.headers,
        }
      );
      if (data?.success) {
        handleMessage('success');
        window.history.back();
      } else {
        handleMessage('error', t('error'));
      }
      setLoading(false);
    } catch (error) {
      console.log('[handleSubmit]  error->', { error });
      setLoading(false);
      const errorMessage = error?.response?.data?.message;
      if (error?.response?.status) {
        handleMessage('error', errorMessage);
      }
    }
  };

  const handleMessage = (type = 'success', message = '') => {
    if (type === 'success') {
      return toast.success(t('data_updated_successfully'), {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide,
      });
    }
    return toast.error(message, {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      transition: Slide,
    });
  };

  return (
    <div className="add-ops-board">
      <h3 className="title">{t('delivery_company.register.title')}</h3>
      <div className="ops-form">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit(e);
          }}
        >
          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <input
                id="name"
                name="name"
                type="text"
                className={`center rounded-md ${formik.errors.name && 'border border-red-600'}`}
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              {formik.errors.name && (
                <span className="mt-2 text-red-600 rtl error-message">{formik.errors.name}</span>
              )}
            </div>
            <span>{t('delivery_company.name')}</span>
          </div>

          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <input
                id="phone"
                name="phone"
                type="text"
                className={`center rounded-md ${formik.errors.phone && 'border border-red-600'}`}
                onChange={formik.handleChange}
                value={formik.values.phone}
              />
              {formik.errors.phone && (
                <span className="mt-2 text-red-600 rtl error-message">{formik.errors.phone}</span>
              )}
            </div>
            <span>{t('delivery_company.phone')}</span>
          </div>

          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <input
                id="cr_number"
                name="cr_number"
                type="text"
                className={`center rounded-md ${
                  formik.errors.cr_number && 'border border-red-600'
                }`}
                onChange={formik.handleChange}
                value={formik.values.cr_number}
              />
              {formik.errors.cr_number && (
                <span className="mt-2 text-red-600 rtl error-message">
                  {formik.errors.cr_number}
                </span>
              )}
            </div>
            <span>{t('delivery_company.cr_number')}</span>
          </div>

          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <input
                id="contract"
                name="contract"
                type="text"
                className={`center rounded-md ${formik.errors.contract && 'border border-red-600'}`}
                onChange={formik.handleChange}
                value={formik.values.contract}
              />
              {formik.errors.contract && (
                <span className="mt-2 text-red-600 rtl error-message">
                  {formik.errors.contract}
                </span>
              )}
            </div>
            <span>{t('delivery_company.contract')}</span>
          </div>

          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <input
                id="iban"
                name="iban"
                type="text"
                className={`center rounded-md ${formik.errors.iban && 'border border-red-600'}`}
                onChange={formik.handleChange}
                value={formik.values.iban}
              />
              {formik.errors.iban && (
                <span className="mt-2 text-red-600 rtl error-message">{formik.errors.iban}</span>
              )}
            </div>
            <span>{t('delivery_company.iban')}</span>
          </div>

          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <input
                id="distance_radius"
                name="distance_radius"
                type="text"
                className="center rounded-md"
                onChange={formik.handleChange}
                value={formik.values.distance_radius}
              />
              {formik.errors.distance_radius && (
                <span className="mt-2 text-red-600 rtl error-message">
                  {formik.errors.distance_radius}
                </span>
              )}
            </div>
            <span>{t('delivery_company.distance_radius')}</span>
          </div>

          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <input
                id="company_contract_price"
                name="company_contract_price"
                type="text"
                className="center rounded-md"
                onChange={formik.handleChange}
                value={formik.values.company_contract_price}
              />
              {formik.errors.company_contract_price && (
                <span className="mt-2 text-red-600 rtl error-message">
                  {formik.errors.company_contract_price}
                </span>
              )}
            </div>
            <span>{t('delivery_company.company_contract_price')}</span>
          </div>

          <div className="">
            <button
              type="submit"
              className={`add-btn ${loading ? 'disabled' : ''}`}
              disabled={loading}
            >
              {t('delivery_company.submit')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditCompany;
