const { default: axios } = require('axios');
const { config } = require('../../config/Config');

export const getDriverRegistrationOrderDetailAPI = (id) => {
  return axios.get(config.API_URL.DRIVER_REGISTRATION.GET_DETAIL(id), {
    headers: config.headers,
  });
};

export const REGISTRATION_DRIVER_STATUS = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  UNAVAILABLE: 'UNAVAILABLE',
};
export const X_ACTION_DRIVER_REGISTRATION_ORDER_STATUS = { ACCEPT: 'ACCEPT', REJECT: 'REJECT' };

export const patchUpdateDriverRegistrationOrderStatusAPI = (id, status) => {
  const defaultHeaders = { ...config.headers, 'x-action': status };
  return axios.patch(config.API_URL.DRIVER_REGISTRATION.PATCH_CHANGE_STATUS_SUPPLIER(id), null, {
    headers: defaultHeaders,
  });
};
