import debounce from 'lodash/debounce';
import React, { useEffect, useMemo, useState } from 'react';
import { IconSearch } from '../constants/Icon';

const SearchInput = ({
  placeholder = 'Search...',
  handleSearch = () => {},
  search = '',
  title = '',
  makeRerender = false,
  disabled = false,
  value = '',
}) => {
  const [query, setQuery] = useState('');

  const debouncedEventHandler = useMemo(
    () => debounce((value) => handleSearch(value), 150),
    [makeRerender]
  );

  useEffect(() => {
    if (value && value !== query) {
      setQuery(value);
    }
  }, [value]);

  const handleQueryChange = (e) => {
    const { value } = e.target;
    setQuery(value);
    debouncedEventHandler(value);
  };

  return (
    <div className="input-search-wrapper">
      {title?.length > 0 && <p className="input-search-title">{title}</p>}
      <div className="input-search-input-wrapper relative">
        <div className="absolute" style={{ color: '#6f6f6f', top: 8, right: 10 }}>
          <IconSearch />
        </div>
        <input
          disabled={disabled}
          type="text"
          className="input-search"
          placeholder={placeholder}
          onChange={handleQueryChange}
          value={query}
        />
      </div>
    </div>
  );
};

export default SearchInput;
