import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../../../commonComponents/LoadingButton/LoadingButton';
import useEventEnter from '../../../hooks/useEventEnter';
import CustomSelectReact from '../../CustomSelectReact';
import useFilterOption from './useFilterOption';

export default function Filters({
  filters,
  handlerFilters,
  handleDateFilter,
  dateRange,
  selectHandler,
  isLoaded,
  onApplyFilter,
}) {
  const { t } = useTranslation();
  const { filterMethods, filterStatutes, filterTypes } = useFilterOption();
  useEventEnter(onApplyFilter);

  return (
    <div className="search-order flex flex-col mb-[35px] rtl">
      <div className="py-5 flex items-center gap-4 flex-wrap  w-full">
        <div className="flex">
          <input
            className="filter-input"
            name="id_number"
            value={filters.id_number}
            placeholder={t('id_number')}
            onChange={handlerFilters}
          />
        </div>
        <div className="flex">
          <input
            className="filter-input"
            placeholder={t('order_number')}
            name="order_id"
            value={filters.order_id}
            onChange={handlerFilters}
          />
        </div>
        <CustomSelectReact
          isDisabled={isLoaded}
          placeholder={t('status')}
          className="checkbox-order w-[200px] filter-select"
          options={filterStatutes}
          value={filters.statutes}
          onChange={(e) => selectHandler(e, 'statutes')}
          isMulti
        />
        <CustomSelectReact
          isDisabled={isLoaded}
          placeholder={t('type')}
          className="checkbox-order w-[200px] filter-select"
          options={filterTypes}
          value={filters.types}
          onChange={(e) => selectHandler(e, 'types')}
          isMulti
        />
        <CustomSelectReact
          isDisabled={isLoaded}
          placeholder={t('method')}
          className="checkbox-order w-[200px] filter-select"
          options={filterMethods}
          value={filters.methods}
          onChange={(e) => selectHandler(e, 'methods')}
          isMulti
        />
        <div className="input-select-wrapper" style={{ direction: 'ltr' }}>
          <DateTimeRangePicker
            name="date_range"
            disabled={isLoaded}
            className="input-select-input-wrapper !border-0 w-[200px]"
            value={dateRange}
            onChange={handleDateFilter}
            disableClock
            format={'y-MM-dd'}
          />
        </div>
      </div>
      <div className="relative w-full mb-6">
        <div className="absolute right-0 ">
          <LoadingButton onClick={onApplyFilter} label={t('Apply Filter')} loading={isLoaded} />
        </div>
      </div>
    </div>
  );
}
