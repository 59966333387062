const { default: axios } = require('axios');
const { config, PERMISSIONS } = require('../../config/Config');

export const getProfile = async () => {
  const { data } = await axios.get(config.API_URL.AUTH.PROFILE, {
    headers: config.headers,
  });
  if (data?.role === PERMISSIONS.SUPER_ADMIN) {
    return data;
  }
};
