import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../../../commonComponents/LoadingButton';
import useEventEnter from '../../../hooks/useEventEnter';
import CustomSelectReact from '../../CustomSelectReact';
import SearchInput from '../../SearchInput';
import useFiltersOption from './useFiltersOption';

export default function Filters({
  loading,
  filters,
  selectHandler,
  handleFilterOrderNumber,
  dateRange,
  handleDateFilter,
  getLogs,
}) {
  const { methods, status, types } = useFiltersOption();
  const { t } = useTranslation();
  useEventEnter(getLogs);

  return (
    <div className="px-8 py-5 flex items-center gap-4 mb-[35px] flex-wrap relative">
      <div>
        <SearchInput
          disabled={loading}
          placeholder={t('order_number')}
          handleSearch={handleFilterOrderNumber}
          makeRerender={filters}
          value={filters?.order_number}
        />
      </div>
      <div className="input-select-wrapper">
        <CustomSelectReact
          isDisabled={loading}
          placeholder={t('method')}
          className="input-select-input-wrapper !border-0 h-full max-w-max w-full"
          options={methods}
          value={filters.methods}
          onChange={(e) => selectHandler(e, 'methods')}
          isMulti
        />
      </div>
      <div className="input-select-wrapper">
        <CustomSelectReact
          isDisabled={loading}
          placeholder={t('types')}
          className="input-select-input-wrapper !border-0 h-full max-w-max w-full"
          options={types}
          value={filters.types}
          onChange={(e) => selectHandler(e, 'types')}
          isMulti
        />
      </div>
      <div className="input-select-wrapper">
        <CustomSelectReact
          isDisabled={loading}
          placeholder={t('status')}
          className="input-select-input-wrapper !border-0 h-full max-w-max w-full"
          options={status}
          value={filters.status}
          onChange={(e) => selectHandler(e, 'status')}
          isMulti
        />
      </div>
      <div className="input-select-wrapper" style={{ direction: 'ltr' }}>
        <DateTimeRangePicker
          disabled={loading}
          className="input-select-input-wrapper !border-0 w-[200px]"
          value={dateRange}
          onChange={handleDateFilter}
          disableClock
          format={'y-MM-dd'}
        />
      </div>
      <div className="relative w-full mb-4">
        <div className="absolute right-0 ">
          <LoadingButton onClick={getLogs} label={t('Apply Filter')} loading={loading} />
        </div>
      </div>
    </div>
  );
}
