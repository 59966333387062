import { useTranslation } from 'react-i18next';

export default function useFilterOption() {
  const { t } = useTranslation();
  const filterTypes = [
    {
      value: 'ORDER_CREATE',
      label: 'ORDER_CREATE',
    },
    {
      value: 'ORDER_ASSIGN',
      label: 'ORDER_ASSIGN',
    },
    {
      value: 'ORDER_EXECUTE',
      label: 'ORDER_EXECUTE',
    },
    {
      value: 'ORDER_CANCEL',
      label: 'ORDER_CANCEL',
    },
  ];
  const filterStatutes = [
    {
      value: '200',
      label: t('200'),
    },
    {
      value: '500',
      label: t('500'),
    },
    {
      value: '422',
      label: t('422'),
    },
    {
      value: '400',
      label: t('400'),
    },
    {
      value: '409',
      label: t('409'),
    },
  ];
  const filterMethods = [
    {
      value: 'GET',
      label: t('GET'),
    },
    {
      value: 'POST',
      label: t('POST'),
    },
    {
      value: 'PUT',
      label: t('PUT'),
    },
    {
      value: 'PATCH',
      label: t('PATCH'),
    },
    {
      value: 'DELETE',
      label: t('DELETE'),
    },
  ];
  return { filterTypes, filterStatutes, filterMethods };
}
