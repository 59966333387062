import React from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import useMapTracking from '../../../../hooks/useMapTracking';

const FilterOrders = memo(() => {
  const { t } = useTranslation();
  const filterStatus = [
    {
      key: 'new',
      name: t('new'),
    },
    {
      key: 'assigned',
      name: t('assigned'),
    },
  ];

  const { onChangeFieldFilterOrder, filtersOrder } = useMapTracking();

  const orderStatus = filtersOrder?.status;
  const handleStatus = (event) => {
    const isChecked = event.target.checked;
    const newStatus = { ...orderStatus, [event.target.value]: isChecked };
    onChangeFieldFilterOrder('status', newStatus);
  };

  return (
    <>
      <div className="flex filter flex-col px-6 gap-y-2">
        {filterStatus.map((constStatus) => {
          const { key, name } = constStatus;
          return (
            <label key={key} className="checkbox-order">
              <input
                type="checkbox"
                value={key}
                onChange={handleStatus}
                checked={orderStatus[key]}
              />
              <span className="checkmark" />
              {name}
            </label>
          );
        })}
      </div>
    </>
  );
});

export default FilterOrders;
