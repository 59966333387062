import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import { isArray } from 'lodash';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../../../../commonComponents/LoadingButton';
import useEventEnter from '../../../../hooks/useEventEnter';
import DropdownInput from '../../../DropdownInput';
import SearchInput from '../../../SearchInput';
import { TRANSACTION_TYPE } from '../../constants';
import { useFilterOptions } from './useFilterOption';

function FilterComponents({
  filters,
  handleFilters,
  loading,
  dateRange,
  handleDateFilter,
  onGetTransactions,
}) {
  const { t } = useTranslation();

  const loadPlaceholderDropInput = (value, defaultValue) => {
    if (value) {
      return value;
    } else {
      return defaultValue;
    }
  };
  const getLabelCompany = (id) => {
    return filterCompany?.find((item) => item?.value === id);
  };

  const { transactionType, createdByType, filterOrderStatus, filterTopUpStatus, filterCompany } =
    useFilterOptions();

  useEventEnter(onGetTransactions);

  return (
    <div className="px-8 py-5 flex items-center gap-4 flex-wrap  mb-[35px]">
      <DropdownInput
        textAlign="right"
        placeholder={loadPlaceholderDropInput(filters?.type, t('status'))}
        options={transactionType}
        handleSelect={(value) => handleFilters(value, 'type', 'DropdownInput')}
        className="w-[200px]"
        defaultValue={{ value: '', label: 'Overview' }}
        disable={loading}
      />
      {filters?.type === TRANSACTION_TYPE.TOP_UP && (
        <DropdownInput
          textAlign="right"
          placeholder={loadPlaceholderDropInput(filters?.statusTransaction, t('top_up_status'))}
          options={filterTopUpStatus}
          handleSelect={(value) => handleFilters(value, 'statusTransaction', 'DropdownInput')}
          className="w-[200px]"
          defaultValue={{ value: '', label: 'Overview' }}
          disable={loading}
        />
      )}

      <DropdownInput
        textAlign="right"
        placeholder={loadPlaceholderDropInput(filters?.created_by, t('createBy'))}
        options={createdByType}
        handleSelect={(value) => handleFilters(value, 'created_by', 'DropdownInput')}
        className="w-[200px]"
        defaultValue={{ value: '', label: 'Overview' }}
        disable={loading}
      />
      <DropdownInput
        textAlign="right"
        placeholder={loadPlaceholderDropInput(
          isArray(filters?.status) && filters?.status[0]?.label,
          t('status')
        )}
        options={filterOrderStatus}
        handleSelect={(value) => handleFilters(value, 'status', 'DropdownInput')}
        className="w-[200px]"
        disable={loading}
        defaultValue={{ value: '', label: 'Overview' }}
      />
      <div className="input-select-wrapper" style={{ direction: 'ltr' }}>
        <DateTimeRangePicker
          disabled={loading}
          className="input-select-input-wrapper !border-0 w-[200px]"
          value={dateRange}
          onChange={handleDateFilter}
          disableClock
          format={'y-MM-dd'}
        />
      </div>
      <div>
        <SearchInput
          disabled={loading}
          placeholder={t('order_number')}
          handleSearch={(value) => handleFilters(value, 'order_number', 'SearchInput')}
          makeRerender={filters}
          value={filters?.order_number}
        />
      </div>

      <div>
        <SearchInput
          disabled={loading}
          placeholder={t('driver_id')}
          handleSearch={(value) => handleFilters(value, 'id_number', 'SearchInput')}
          makeRerender={filters}
          value={filters?.id_number}
        />
      </div>
      <div>
        <SearchInput
          disabled={loading}
          placeholder={t('driver_name')}
          handleSearch={(value) => handleFilters(value, 'driver_name', 'SearchInput')}
          makeRerender={filters}
          value={filters?.driver_name}
        />
      </div>
      <div>
        <SearchInput
          disabled={loading}
          placeholder={t('created_at_driver_name')}
          handleSearch={(value) => handleFilters(value, 'created_by_driver_name', 'SearchInput')}
          makeRerender={filters}
          value={filters?.created_by_driver_name}
        />
      </div>
      <div>
        <SearchInput
          disabled={loading}
          placeholder={t('created_at_admin_name')}
          handleSearch={(value) => handleFilters(value, 'created_by_admin_name', 'SearchInput')}
          makeRerender={filters}
          value={filters?.created_by_admin_name}
        />
      </div>
      <div>
        <DropdownInput
          textAlign="right"
          placeholder={loadPlaceholderDropInput(
            filters?.company_type && getLabelCompany(filters?.company_type)?.label,
            "driver's company"
          )}
          options={filterCompany}
          handleSelect={(value) => handleFilters(value, 'company_type', 'DropdownInput')}
          className="w-[200px]"
          defaultValue={{ value: '', label: 'Overview' }}
          disable={loading}
        />
      </div>
      <div className="relative w-full mb-4">
        <div className="absolute right-0 ">
          <LoadingButton onClick={onGetTransactions} label={t('Apply Filter')} loading={loading} />
        </div>
      </div>
    </div>
  );
}

export default memo(FilterComponents);
