import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Slide, toast } from 'react-toastify';
import * as Yup from 'yup';
import { config } from '../../config/Config';
import CustomSelectReact from '../CustomSelectReact';
import DropdownInput from '../DropdownInput';

toast.configure();
const NewOperator = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);

  const getRegionList = async () => {
    const response = await axios.get(config.API_URL.CITC_LOOKUP.REGIONS);
    if (response?.status === 200) {
      const regionList = response?.data;
      const formattedRegionList = [];
      regionList.forEach((region) => {
        formattedRegionList.push({
          value: region.id,
          label: region.nameAr,
        });
      });
      setRegions(formattedRegionList);
    }
    setLoading(false);
  };

  const getCities = async () => {
    const response = await axios.get(
      `${config.API_URL.CITC_LOOKUP.CITIES}?regionId=${formik.values?.region_id}`
    );
    if (response?.status === 200) {
      const citiesList = response?.data;
      const formattedCitiesList = [];
      citiesList.forEach((region) => {
        formattedCitiesList.push({
          value: region.id,
          label: region.nameAr,
        });
      });
      setCities(formattedCitiesList);
    }
    setLoading(false);
  };

  useEffect(() => {
    getRegionList();
  }, []);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('ops_management.validate.type.email'))
      .required(t('ops_management.validate.required.email')),
    password: Yup.string()
      .min(8, t('ops_management.validate.length.password'))
      .required(t('ops_management.validate.required.password')),
    firstName: Yup.string().required(t('ops_management.validate.required.firstName')),
    lastName: Yup.string().required(t('ops_management.validate.required.lastName')),
    nationalID: Yup.string().required(t('ops_management.validate.required.nationalID')),
    phone: Yup.string().required(t('ops_management.validate.required.phone')),
    opsId: Yup.string().required(t('ops_management.validate.required.opsId')),
    region_id: Yup.string().required(t('ops_management.validate.required.region_id')),
    cities: Yup.array().min(1, t('ops_management.validate.required.cities')),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      nationalID: '',
      address: '',
      phone: '',
      dobDay: '',
      dobMonth: '',
      dobYear: '',
      opsId: '',
      region_id: '',
      cities: [],
    },
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validateOnChange: false,
  });

  const borderInputError = {
    border: '1px solid #E95F67',
  };

  useEffect(() => {
    if (formik.values?.region_id && formik.values?.region_id?.length > 0) {
      getCities();
    }
  }, [formik.values.region_id]);

  const handleSelectRegion = (select) => {
    formik.setValues({ ...formik.values, region_id: select.value });
  };

  const handleSelectCities = (select) => {
    formik.setValues({ ...formik.values, cities: select });
  };

  const handleSubmit = async () => {
    setLoading(true);
    const finalizeData = {
      firstname: formik.values.firstName,
      lastname: formik.values.lastName,
      code: formik.values.opsId,
      phone: formik.values.phone,
      nationality_id: formik.values.nationalID,
      email: formik.values.email,
      password: formik.values.password,
      address: formik.values.address,
      region_id: formik.values.region_id,
      city_id: formik.values.cities.map((city) => city.value),
    };
    if (formik.values.dobYear && formik.values.dobDay && formik.values.dobMonth) {
      finalizeData.date_of_birth = `${formik.values.dobYear}/${formik.values.dobMonth}/${formik.values.dobDay}`;
    }
    try {
      const { data } = await axios.post(config.API_URL.AUTH.REGISTER_OPERATORS, finalizeData, {
        headers: config.headers,
      });
      if (data?.success) {
        handleMessage('success');
        window.history.back();
      } else {
        handleMessage('error', t('error'));
      }
      setLoading(false);
    } catch (error) {
      const errorList = error?.response?.data?.errors;
      if (errorList) {
        errorList.forEach((error) => handleMessage('error', error.msg));
      }
      setLoading(false);
    }
  };

  const handleMessage = (type = 'success', message = '') => {
    if (type === 'success') {
      return toast.success(t('ops_management.register.success'), {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide,
      });
    }
    return toast.error(message, {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      transition: Slide,
    });
  };

  return (
    <div className="add-ops-board">
      <h3 className="title">{t('ops_management.register.title')}</h3>
      <div className="ops-form">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit(e);
          }}
        >
          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <DropdownInput
                id="region_id"
                name="region_id"
                textAlign="center"
                placeholder={t('region')}
                options={regions}
                handleSelect={handleSelectRegion}
                hasDefault={false}
                className={`!w-[200px] !min-w-[200px] ${
                  formik.errors.region_id ? '!border-red-500' : ''
                }`}
                wrapperClassName="rtl"
              />
              {formik.errors.region_id && (
                <span className="mt-2 text-red rtl error-message">{formik.errors.region_id}</span>
              )}
            </div>
            <span>{t('region')}</span>
          </div>

          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <CustomSelectReact
                id="cities"
                name="cities"
                placeholder={t('city')}
                onChange={handleSelectCities}
                className="filter-select w-[200px]"
                options={cities}
                value={formik.values.cities}
                isMulti
              />
              {formik.errors.region_id && (
                <span className="mt-2 text-red rtl error-message">{formik.errors.region_id}</span>
              )}
            </div>
            <span>{t('region')}</span>
          </div>

          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <input
                id="opsId"
                name="opsId"
                type="text"
                style={formik.errors.opsId && borderInputError}
                className="center rounded-md"
                onChange={formik.handleChange}
                value={formik.values.opsId}
              />
              {formik.errors.opsId && (
                <span className="mt-2 text-red rtl error-message">{formik.errors.opsId}</span>
              )}
            </div>
            <span>{t('ops_management.register.opsId')}</span>
          </div>

          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <input
                id="email"
                name="email"
                type="text"
                style={formik.errors.email && borderInputError}
                className="center rounded-md"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              {formik.errors.email && (
                <span className="mt-2 text-red rtl error-message">{formik.errors.email}</span>
              )}
            </div>
            <span>{t('ops_management.register.email')}</span>
          </div>

          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <input
                id="password"
                name="password"
                type="password"
                style={formik.errors.password && borderInputError}
                className="center rounded-md"
                onChange={formik.handleChange}
                value={formik.values.password}
              />
              {formik.errors.password && (
                <span className="mt-2 text-red rtl error-message">{formik.errors.password}</span>
              )}
            </div>
            <span>{t('ops_management.register.password')}</span>
          </div>

          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <input
                id="firstName"
                name="firstName"
                type="text"
                style={formik.errors.firstName && borderInputError}
                className="center rounded-md"
                onChange={formik.handleChange}
                value={formik.values.firstName}
              />
              {formik.errors.firstName && (
                <span className="mt-2 text-red rtl error-message">{formik.errors.firstName}</span>
              )}
            </div>
            <span>{t('ops_management.register.firstName')}</span>
          </div>

          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <input
                id="lastName"
                name="lastName"
                type="text"
                style={formik.errors.lastName && borderInputError}
                className="center rounded-md"
                onChange={formik.handleChange}
                value={formik.values.lastName}
              />
              {formik.errors.lastName && (
                <span className="mt-2 text-red rtl error-message">{formik.errors.lastName}</span>
              )}
            </div>
            <span>{t('ops_management.register.lastName')}</span>
          </div>

          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <input
                id="nationalID"
                name="nationalID"
                type="text"
                style={formik.errors.nationalID && borderInputError}
                className="center rounded-md"
                onChange={formik.handleChange}
                value={formik.values.nationalID}
              />
              {formik.errors.nationalID && (
                <span className="mt-2 text-red rtl error-message">{formik.errors.nationalID}</span>
              )}
            </div>
            <span>{t('ops_management.register.nationalID')}</span>
          </div>

          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <input
                id="address"
                name="address"
                type="text"
                style={formik.errors.address && borderInputError}
                className="center rounded-md"
                onChange={formik.handleChange}
                value={formik.values.address}
              />
            </div>
            <span>{t('ops_management.register.address')}</span>
          </div>

          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <div>
                <input
                  id="dobYear"
                  name="dobYear"
                  type="text"
                  style={formik.errors.dobYear && borderInputError}
                  className="input80 rounded-l-md"
                  onChange={formik.handleChange}
                  placeholder="YYYY"
                  value={formik.values.dobYear}
                />
                <input
                  id="dobMonth"
                  name="dobMonth"
                  type="text"
                  style={formik.errors.dobMonth && borderInputError}
                  className="input60"
                  onChange={formik.handleChange}
                  placeholder="MM"
                  value={formik.values.dobMonth}
                />
                <input
                  id="dobDay"
                  name="dobDay"
                  type="text"
                  style={formik.errors.dobDay && borderInputError}
                  className="input60 rounded-r-md"
                  onChange={formik.handleChange}
                  placeholder="DD"
                  value={formik.values.dobDay}
                />
              </div>
            </div>
            <span>{t('ops_management.register.dob')}</span>
          </div>

          <div className="flex items-center gap-x-4 justify-between row">
            <div className="flex flex-col items-end">
              <input
                id="phone"
                name="phone"
                type="text"
                style={formik.errors.phone && borderInputError}
                className="center rounded-md"
                onChange={formik.handleChange}
                value={formik.values.phone}
              />
              {formik.errors.phone && (
                <span className="mt-2 text-red rtl error-message">{formik.errors.phone}</span>
              )}
            </div>
            <span>{t('ops_management.register.phone')}</span>
          </div>
          <div className="">
            <button
              type="submit"
              className={`add-btn ${loading ? 'disabled' : ''}`}
              disabled={loading}
            >
              {t('ops_management.register.submit')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewOperator;
