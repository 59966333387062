/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

function OrderMarker({ order, ...props }) {
  const { id, tooltipCus } = props;
  const { customer_name, driver = {} } = order;
  const { id: driverId } = driver;
  return (
    <>
      <div className="marker order w-6 h-6" onClick={() => props.setTooltipCus('cus_' + id)}>
        <div className="pin" title={customer_name}>
          <img
            src={`${driver && driverId ? '/images/yellow_flag.png' : '/images/white_flag.png'}`}
            alt=""
          />
        </div>
        <div className={`driver-tooltip ${'cus_' + id === tooltipCus ? 'show' : 'hide'}`}>
          <div className="map-marker-hint--title">
            <strong>{customer_name}</strong>
          </div>
        </div>
      </div>
    </>
  );
}
export default OrderMarker;
