import React, { memo, useState } from 'react';
import useMapTracking from '../../../../hooks/useMapTracking';
import AssignModal from '../../../Atoms/AssignModal';
import OrderCard from './OrderCard';

const OrderCards = memo(({ handlerRefresh, apiVersion, loadingConfig }) => {
  const [assignModal, setAssignModal] = useState(false);
  const [currentOrder, setCurrentOrder] = useState({});

  const assignToDriver = async () => {
    setAssignModal(false);
    handlerRefresh();
  };
  const { orders } = useMapTracking();

  const handlerAssign = async (event, order) => {
    event.preventDefault();
    await setCurrentOrder(order);
    setAssignModal(true);
  };

  return (
    <>
      <div className="card-order">
        <nav>
          <ul>
            {orders?.map((order) => {
              return <OrderCard key={order._id} order={order} handlerAssign={handlerAssign} />;
            })}
          </ul>
        </nav>
        {assignModal && (
          <AssignModal
            order={currentOrder}
            assignModal={assignModal}
            closeModal={() => setAssignModal(false)}
            assignToDriver={assignToDriver}
            apiVersion={apiVersion}
            loadingConfig={loadingConfig}
          />
        )}
      </div>
    </>
  );
});

export default OrderCards;
