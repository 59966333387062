/* eslint-disable react-hooks/exhaustive-deps */
const { useState, useEffect, useContext } = require('react');
const { default: MapContext } = require('../../../../context/MapContext');

const Polygon = ({
  options,
  draggable,
  editable,
  visible,
  path,
  onDblClick,
  onDragEnd,
  onDragStart,
  onMouseDown,
  onMouseMove,
  onMouseOut,
  onMouseOver,
  onMouseUp,
  onRightClick,
  onClick,
  onDrag,
  onLoad,
  onUnmount,
}) => {
  const map = useContext(MapContext);
  const [instance, setInstance] = useState(null);

  const [dblclickListener, setDblclickListener] = useState(null);
  const [dragendListener, setDragendListener] = useState(null);
  const [dragstartListener, setDragstartListener] = useState(null);
  const [mousedownListener, setMousedownListener] = useState(null);
  const [mousemoveListener, setMousemoveListener] = useState(null);
  const [mouseoutListener, setMouseoutListener] = useState(null);
  const [mouseoverListener, setMouseoverListener] = useState(null);
  const [mouseupListener, setMouseupListener] = useState(null);
  const [rightclickListener, setRightclickListener] = useState(null);
  const [clickListener, setClickListener] = useState(null);
  const [dragListener, setDragListener] = useState(null);

  // Order does matter
  useEffect(() => {
    if (instance !== null) {
      instance.setMap(map);
    }
  }, [instance, map]);

  useEffect(() => {
    if (typeof options !== 'undefined' && instance !== null) {
      instance.setOptions(options);
    }
  }, [instance, options]);

  useEffect(() => {
    if (typeof draggable !== 'undefined' && instance !== null) {
      instance.setDraggable(draggable);
    }
  }, [instance, draggable]);

  useEffect(() => {
    if (typeof editable !== 'undefined' && instance !== null) {
      instance.setEditable(editable);
    }
  }, [instance, editable]);

  useEffect(() => {
    if (typeof visible !== 'undefined' && instance !== null) {
      instance.setVisible(visible);
    }
  }, [instance, visible]);

  useEffect(() => {
    if (typeof path !== 'undefined' && instance !== null) {
      instance.setPath(path);
    }
  }, [instance, path]);

  useEffect(() => {
    if (instance && onDblClick) {
      if (dblclickListener !== null) {
        window.google.maps.event.removeListener(dblclickListener);
      }

      setDblclickListener(window.google.maps.event.addListener(instance, 'dblclick', onDblClick));
    }
  }, [onDblClick]);

  useEffect(() => {
    if (instance && onDragEnd) {
      if (dragendListener !== null) {
        window.google.maps.event.removeListener(dragendListener);
      }

      setDragendListener(window.google.maps.event.addListener(instance, 'dragend', onDragEnd));
    }
  }, [onDragEnd]);

  useEffect(() => {
    if (instance && onDragStart) {
      if (dragstartListener !== null) {
        window.google.maps.event.removeListener(dragstartListener);
      }

      setDragstartListener(
        window.google.maps.event.addListener(instance, 'dragstart', onDragStart)
      );
    }
  }, [onDragStart]);

  useEffect(() => {
    if (instance && onMouseDown) {
      if (mousedownListener !== null) {
        window.google.maps.event.removeListener(mousedownListener);
      }

      setMousedownListener(
        window.google.maps.event.addListener(instance, 'mousedown', onMouseDown)
      );
    }
  }, [onMouseDown]);

  useEffect(() => {
    if (instance && onMouseMove) {
      if (mousemoveListener !== null) {
        window.google.maps.event.removeListener(mousemoveListener);
      }

      setMousemoveListener(
        window.google.maps.event.addListener(instance, 'mousemove', onMouseMove)
      );
    }
  }, [onMouseMove]);

  useEffect(() => {
    if (instance && onMouseOut) {
      if (mouseoutListener !== null) {
        window.google.maps.event.removeListener(mouseoutListener);
      }

      setMouseoutListener(window.google.maps.event.addListener(instance, 'mouseout', onMouseOut));
    }
  }, [onMouseOut]);

  useEffect(() => {
    if (instance && onMouseOver) {
      if (mouseoverListener !== null) {
        window.google.maps.event.removeListener(mouseoverListener);
      }

      setMouseoverListener(
        window.google.maps.event.addListener(instance, 'mouseover', onMouseOver)
      );
    }
  }, [onMouseOver]);

  useEffect(() => {
    if (instance && onMouseUp) {
      if (mouseupListener !== null) {
        window.google.maps.event.removeListener(mouseupListener);
      }

      setMouseupListener(window.google.maps.event.addListener(instance, 'mouseup', onMouseUp));
    }
  }, [onMouseUp]);

  useEffect(() => {
    if (instance && onRightClick) {
      if (rightclickListener !== null) {
        window.google.maps.event.removeListener(rightclickListener);
      }

      setRightclickListener(
        window.google.maps.event.addListener(instance, 'rightclick', onRightClick)
      );
    }
  }, [onRightClick]);

  useEffect(() => {
    if (instance && onClick) {
      if (clickListener !== null) {
        window.google.maps.event.removeListener(clickListener);
      }

      setClickListener(window.google.maps.event.addListener(instance, 'click', onClick));
    }
  }, [onClick]);

  useEffect(() => {
    if (instance && onDrag) {
      if (dragListener !== null) {
        window.google.maps.event.removeListener(dragListener);
      }

      setDragListener(window.google.maps.event.addListener(instance, 'drag', onDrag));
    }
  }, [onDrag]);

  useEffect(() => {
    const polygon = new window.google.maps.Polygon({
      ...(options || {}),
      map,
    });

    if (path) {
      polygon.setPath(path);
    }

    if (typeof visible !== 'undefined') {
      polygon.setVisible(visible);
    }

    if (typeof editable !== 'undefined') {
      polygon.setEditable(editable);
    }

    if (typeof draggable !== 'undefined') {
      polygon.setDraggable(draggable);
    }

    if (onDblClick) {
      setDblclickListener(window.google.maps.event.addListener(polygon, 'dblclick', onDblClick));
    }

    if (onDragEnd) {
      setDragendListener(window.google.maps.event.addListener(polygon, 'dragend', onDragEnd));
    }

    if (onDragStart) {
      setDragstartListener(window.google.maps.event.addListener(polygon, 'dragstart', onDragStart));
    }

    if (onMouseDown) {
      setMousedownListener(window.google.maps.event.addListener(polygon, 'mousedown', onMouseDown));
    }

    if (onMouseMove) {
      setMousemoveListener(window.google.maps.event.addListener(polygon, 'mousemove', onMouseMove));
    }

    if (onMouseOut) {
      setMouseoutListener(window.google.maps.event.addListener(polygon, 'mouseout', onMouseOut));
    }

    if (onMouseOver) {
      setMouseoverListener(window.google.maps.event.addListener(polygon, 'mouseover', onMouseOver));
    }

    if (onMouseUp) {
      setMouseupListener(window.google.maps.event.addListener(polygon, 'mouseup', onMouseUp));
    }

    if (onRightClick) {
      setRightclickListener(
        window.google.maps.event.addListener(polygon, 'rightclick', onRightClick)
      );
    }

    if (onClick) {
      setClickListener(window.google.maps.event.addListener(polygon, 'click', onClick));
    }

    if (onDrag) {
      setDragListener(window.google.maps.event.addListener(polygon, 'drag', onDrag));
    }

    setInstance(polygon);

    if (onLoad) {
      onLoad(polygon);
    }

    return () => {
      if (dblclickListener !== null) {
        window.google.maps.event.removeListener(dblclickListener);
      }

      if (dragendListener !== null) {
        window.google.maps.event.removeListener(dragendListener);
      }

      if (dragstartListener !== null) {
        window.google.maps.event.removeListener(dragstartListener);
      }

      if (mousedownListener !== null) {
        window.google.maps.event.removeListener(mousedownListener);
      }

      if (mousemoveListener !== null) {
        window.google.maps.event.removeListener(mousemoveListener);
      }

      if (mouseoutListener !== null) {
        window.google.maps.event.removeListener(mouseoutListener);
      }

      if (mouseoverListener !== null) {
        window.google.maps.event.removeListener(mouseoverListener);
      }

      if (mouseupListener !== null) {
        window.google.maps.event.removeListener(mouseupListener);
      }

      if (rightclickListener !== null) {
        window.google.maps.event.removeListener(rightclickListener);
      }

      if (clickListener !== null) {
        window.google.maps.event.removeListener(clickListener);
      }

      if (onUnmount) {
        onUnmount(polygon);
      }

      polygon.setMap(null);
    };
  }, []);

  return null;
};

export default Polygon;
