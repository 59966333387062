import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { config, PERMISSIONS, SETTING_KEY } from '../../config/Config';
import Navbar from '../Navbar';

const LayoutPrivate = ({ children }) => {
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [apiVersion, setApiVersion] = useState('v1');
  const [loadingConfig, setLoadingConfig] = useState(true);

  const getPermission = async () => {
    const { status, data } = await axios.get(config.API_URL.AUTH.PROFILE, {
      headers: config.headers,
    });
    if (+status !== 200) return setIsSuperAdmin(false);
    if (data?.role === PERMISSIONS.SUPER_ADMIN) {
      return setIsSuperAdmin(true);
    }
    return setIsSuperAdmin(false);
  };

  const getAPIPortalVersion = async () => {
    const resp = await axios.get(config.API_URL.SETTING.GET, {
      params: {
        key: SETTING_KEY.API_VER_PORTAL,
      },
      headers: config.headers,
    });
    const { data } = resp;
    const { setting } = data;
    if (setting) {
      setApiVersion(setting.version);
    }
  };

  const getConfig = async () => {
    getPermission();
    await getAPIPortalVersion();
    setLoadingConfig(false);
  };

  useEffect(() => {
    getConfig();
  }, []);

  return (
    <div>
      <Navbar isSuperAdmin={isSuperAdmin} />
      {React.cloneElement(children, { isSuperAdmin, apiVersion, loadingConfig })}
    </div>
  );
};

export default LayoutPrivate;
