import React from 'react';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import TransactionDetail from '../TransactionDetail';

export default function TableTransaction({
  modalVisible,
  columns,
  transactionLog,
  limit,
  setLimit,
  setCurrentPage,
  loading,
  total,
  handlePageChange,
  setSelectedId,
  selectedId,
  handleToggleModal,
  currentPage,
  conditionalRowStylesTable,
  selectedTransaction,
  toggleModal,
  setting,
}) {
  const tableHeight = window.innerHeight - 420;
  const { t } = useTranslation();

  return (
    <div className="flex flex-row overflow-hidden  min-h-[calc(100vh-320px)] ">
      <div
        className={`${
          modalVisible ? 'translate-x-[500px] pr-[500px]' : 'translate-x-[0px]'
        } flex-1 duration-300	 ease-in-out w-full overflow-scroll`}
      >
        <DataTable
          noHeader
          noDataComponent={<div style={{ padding: '24px' }}>{t('orders_no_records')}</div>}
          highlightOnHover
          columns={columns}
          data={transactionLog}
          fixedHeader
          pagination
          contextMessage={{ singular: t('item'), plural: t('items'), message: t('selected') }}
          fixedHeaderScrollHeight={tableHeight.toString() + 'px'}
          paginationPerPage={limit}
          paginationRowsPerPageOptions={[100, 200, 400, 600]}
          onChangeRowsPerPage={(currentRowsPerPage) => {
            setLimit(+currentRowsPerPage);
            setCurrentPage(1);
          }}
          progressPending={loading}
          progressComponent={<div className="wait">{t('please_wait')}</div>}
          paginationTotalRows={total}
          paginationServer
          onChangePage={handlePageChange}
          paginationComponentOptions={{
            rowsPerPageText: t('total_record'),
            rangeSeparatorText: t('from'),
            noRowsPerPage: false,
            selectAllRowsItem: false,
          }}
          selectableRows
          onRowExpandToggled={(toggleState, row) => [
            toggleState
              ? setSelectedId((p) => [...p, row._id])
              : setSelectedId(selectedId.filter((i) => i !== row._id)),
          ]}
          onRowClicked={(row) => handleToggleModal(row?._id)}
          paginationDefaultPage={currentPage}
          selectableRowsVisibleOnly
          persistTableHead
          selectableRowsHighlight
          direction={'rtl'}
          conditionalRowStyles={conditionalRowStylesTable}
          customStyles={{
            headCells: {
              style: {
                fontWeight: 'bold',
                fontSize: 12,
                background: '#F9F9F9',
                textAlign: 'right',
              },
            },
            rows: {
              style: {
                cursor: 'pointer',
                fontSize: 12,
              },
            },
          }}
        />
      </div>
      <div className="relative">
        <TransactionDetail
          id={selectedTransaction}
          visible={modalVisible}
          toggleModal={toggleModal}
          tax={setting?.tax}
        />
      </div>
    </div>
  );
}
