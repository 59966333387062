import { getSettingTimezone } from '../../hooks/useDate';

const calcGmt = (timezoneOffset) => {
  return (timezoneOffset < 10 ? '0' : '') + timezoneOffset;
};

const convertHoursDiffToTimestamp = () => {
  // compare between GMT+3 and GMT+7, number of hours different is 4
  // formula: default_value(60k) * minutesInHour * hoursDifferent
  return Number(60000 * (60 * 4));
};

const GetLocalGmtWithTimezone = () => {
  var offset = new Date().getTimezoneOffset();
  var sign = offset < 0 ? '+' : '-';
  offset = Math.abs(offset);
  return sign + calcGmt(offset / 60) + ':' + calcGmt(offset % 60);
};

export const CompareGmtAndCalcTimestamp = (settingGmt, localGmt, timestamp = 0) => {
  const isEqualGmt = settingGmt === localGmt;

  if (isEqualGmt) {
    return Number(timestamp);
  }

  //convert timestamp follow timezone
  if (settingGmt < localGmt && timestamp) {
    return Number(timestamp + convertHoursDiffToTimestamp());
  } else {
    return Number(timestamp - convertHoursDiffToTimestamp());
  }
};

export const GetTimezone = async () => {
  const settingTimezone = await getSettingTimezone();
  const settingGmt = settingTimezone.gmt;
  const localGmt = GetLocalGmtWithTimezone();

  return { settingGmt, localGmt };
};
