import moment from 'moment';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default function useColumns({ logs }) {
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        name: t('id'),
        selector: '_id',
        sortable: true,
        minWidth: '200px',
      },
      {
        name: t('type'),
        selector: 'type',
        sortable: true,
        minWidth: '260px',
      },
      {
        name: t('url'),
        selector: 'url',
        sortable: true,
        minWidth: '500px',
      },
      {
        name: t('method'),
        selector: 'method',
        sortable: true,
      },
      {
        name: t('date'),
        selector: (row) =>
          `${row.created_at !== null ? moment(row.created_at).locale('ar').format('LL') : '...'}`,
        sortable: true,
        wrap: true,
        minWidth: '120px',
      },
      {
        name: t('date_time'),
        selector: (row) =>
          `${
            row.created_at !== null ? moment(row.created_at).locale('ar').format(' hh:mm A') : '...'
          }`,
        sortable: true,
        wrap: true,
        minWidth: '140px',
      },
    ],
    [logs]
  );
  return { columns };
}
