import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from '../../config/Config';
import { IconSpin } from '../../constants/Icon';

export default function LogModal({ visible, id, logType, toggleModal }) {
  let [isOpen, setIsOpen] = useState(false);
  const [log, setLog] = useState({});
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  const getDetailLog = async () => {
    setIsLoading(true);
    try {
      if (logType === 'all') {
        const resp = await axios.get(`${config.API_URL.CITC_LOGS.DETAIL}${id}`, {
          headers: config.headers,
        });
        console.log(resp.data);
        setLog(resp.data.data);
      }
      if (logType === 'timeout') {
        const resp = await axios.get(`${config.API_URL.CITC_LOGS.TIMEOUT_LIST}${id}`, {
          headers: config.headers,
        });
        console.log(resp.data);
        setLog(resp.data.data);
      }
    } catch (e) {
      console.log('error', e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAction = async () => {
    try {
      const resp = await axios.post(
        `${config.API_URL.CITC_LOGS.REACTION}${id}`,
        {},
        {
          headers: config.headers,
        }
      );
      if (resp.data) {
        window.location.reload();
      }
    } catch (errors) {
      console.log(errors);
    }
  };

  useEffect(() => {
    if (id) {
      getDetailLog();
    }
  }, [id]);

  function closeModal() {
    setIsOpen(false);
    toggleModal(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  useEffect(() => {
    if (visible) {
      openModal();
    }
  }, [visible]);

  const renderLogType = () => {
    if (isLoading) {
      return (
        <div className="inline-block w-full max-w-7xl h-[calc(100vh-200px)] overflow-x-hidden overflow-y-auto px-6 py-10 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
          <div className="flex items-center justify-center h-full w-full space-x-4">
            <p>{t('loading')}</p>
            <IconSpin />
          </div>
        </div>
      );
    }
    if (logType === 'all') {
      return (
        <div className="inline-block w-full max-w-7xl h-[calc(100vh-200px)] overflow-x-hidden overflow-y-auto px-6 py-10 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
          <Dialog.Title as="h1" className="font-bold text-2xl leading-6 text-gray-900 text-right">
            {id}
          </Dialog.Title>
          <div className="mt-2">
            <div className="rtl">
              <table id="table" className="w-full">
                <tbody>
                  <tr>
                    <td>{t('route')}</td>
                    <td>{log.url}</td>
                  </tr>
                  <tr>
                    <td>{t('method')}</td>
                    <td>{log.method}</td>
                  </tr>
                  <tr>
                    <td>{t('type')} </td>
                    <td>{log.type}</td>
                  </tr>
                  <tr>
                    <td>{t('request')}</td>
                    <td>
                      <pre className="prettyprint">{JSON.stringify(log.request, undefined, 2)}</pre>
                    </td>
                  </tr>
                  <tr>
                    <td>{t('status')}</td>
                    <td>{log.isSuccess ? t('success') : t('failure')}</td>
                  </tr>
                  <tr>
                    <td>{t('number_of_runs')}</td>
                    <td>{log.count || 1}</td>
                  </tr>
                  <tr>
                    <td>{t('response')}</td>
                    <td>
                      <pre className="prettyprint">{JSON.stringify(log.data, undefined, 2)} </pre>
                    </td>
                  </tr>
                </tbody>
              </table>
              {!log.isSuccess && (
                <button
                  type="button"
                  onClick={handleAction}
                  className="btn btn-primary mt-20 px-4 py-6 flex flex-end"
                >
                  {t('try_again')}
                </button>
              )}
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="inline-block w-full max-w-7xl h-[calc(100vh-200px)] overflow-x-hidden overflow-y-auto px-6 py-10 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
        <Dialog.Title as="h1" className="font-bold text-2xl leading-6 text-gray-900 text-right">
          {id}
        </Dialog.Title>
        <div className="mt-2">
          <div className="rtl">
            <table id="table" className="w-full">
              <tbody>
                <tr>
                  <td>{t('route')}</td>
                  <td>{log.url}</td>
                </tr>
                <tr>
                  <td>{t('method')}</td>
                  <td>{log.method}</td>
                </tr>
                <tr>
                  <td>{t('type')} </td>
                  <td>{log.type}</td>
                </tr>
                <tr>
                  <td>{t('request')}</td>
                  <td>
                    <pre className="prettyprint">{JSON.stringify(log.request, undefined, 2)}</pre>
                  </td>
                </tr>
                <tr>
                  <td>{t('time')} </td>
                  <td>{log.created_at}</td>
                </tr>
                <tr>
                  <td>{t('status')}</td>
                  <td>{log.isSuccess ? t('success') : t('failure')}</td>
                </tr>
                <tr>
                  <td>{t('number_of_runs')}</td>
                  <td>{log.count || 1}</td>
                </tr>
                <tr>
                  <td>{t('response')}</td>
                  <td>
                    <pre className="prettyprint">{JSON.stringify(log.data, undefined, 2)} </pre>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-[#00000065]" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {renderLogType()}
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
