import moment from 'moment';

const { useMemo } = require('react');

const renderFields = (text) => {
  if (!text) return '-';
  return text;
};
const useColumn = () => {
  const columns = useMemo(
    () => [
      {
        name: 'Type',
        selector: 'type',
        sortable: true,
        minWidth: '200px',
        cell: (row) => renderFields(row?.type),
      },
      {
        name: 'Is recovery',
        selector: 'is_recovery',
        sortable: true,
        minWidth: '200px',
        cell: (row) => (row?.is_recovery ? 'True' : 'False'),
      },
      {
        name: 'Url',
        selector: 'url',
        sortable: true,
        minWidth: '200px',

        cell: (row) => (
          <a className="text-blue-500 hover:text-blue-600 truncate-text" href={row?.url}>
            {row?.url?.split('/')[row?.url?.split('/').length - 1] || row?.url}
          </a>
        ),
      },

      {
        name: 'Create at',
        selector: 'created_at',
        sortable: true,
        minWidth: '200px',
        maxWidth: '400px',

        cell: (row) => moment(row.created_at).locale('ar').format('DD-MM-YYYY / HH:mm'),
      },
    ],
    []
  );
  return { columns };
};

export { useColumn };
