import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

const ButtonRefresh = memo(({ handlerRefresh }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex justify-end">
        <button onClick={handlerRefresh} className="refresh-button mt-2">
          {t('refresh')}
        </button>
      </div>
    </>
  );
});

export default ButtonRefresh;
