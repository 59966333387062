import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CsvDownload from 'react-json-to-csv';
import { useDataTable } from '../../../hooks/useDataTable';
import useLayout from '../../../hooks/useLayout';
import ActionOrder from './ActionOrder/ActionOrder';
import DataTableOrder from './DataTableOrder/DataTableOrder';
import Filters from './Filters/Filters';
import useNewOrder from './useNewOrder';

export default function NewOrder() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const {
    data = [],
    setData,
    limit,
    setLimit,
    currentPage,
    setCurrentPage,
    total,
    setTotal,
  } = useDataTable({ isLoading, setIsLoading });

  const {
    selectedTab,
    setSelectedTab,
    selectedItem,
    setSelectedItem,
    onRowClicked,
    handlePageChange,
    handlerGetOrder,
    filtersRef,
    buildFilterOrderWithRef,
    handleLimitChange,
    refresh,
    buildNewQueryStringWithFilter,
    unAssignOrder,
  } = useNewOrder({
    isLoading,
    setIsLoading,
    currentPage,
    setCurrentPage,
    setData,
    setTotal,
    limit,
    setLimit,
    data,
  });
  const { height, refHeight } = useLayout();
  return (
    <div>
      <div className="px-[20px] " ref={refHeight}>
        <div className="pt-4 pb-8">
          <ActionOrder
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            refresh={refresh}
            setCurrentPage={setCurrentPage}
            buildNewQueryStringWithFilter={buildNewQueryStringWithFilter}
            buildFilterOrderWithRef={buildFilterOrderWithRef}
          />
        </div>
        {selectedItem.length > 0 && (
          <CsvDownload
            filename="orders.csv"
            data={selectedItem}
            style={{
              position: 'absolute',
              top: 90,
              zIndex: 2,
              right: 30,
              borderWidth: 0,
              backgroundColor: '#fff',
              padding: 10,
              borderRadius: 5,
              cursor: 'pointer',
            }}
          >
            {t('loading_data')}
          </CsvDownload>
        )}
        <Filters
          isLoading={isLoading}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          handlerGetOrder={handlerGetOrder}
          ref={filtersRef}
          buildFilterOrderWithRef={buildFilterOrderWithRef}
        />
      </div>
      <DataTableOrder
        height={height}
        selectedTab={selectedTab}
        setSelectedItem={setSelectedItem}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        data={data}
        unAssignOrder={unAssignOrder}
        limit={limit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        total={total}
        handlePageChange={handlePageChange}
        onRowClicked={onRowClicked}
        setLimit={handleLimitChange}
      />
    </div>
  );
}
