import moment from 'moment';
import { useTranslation } from 'react-i18next';
import colBalance from '../Transactions/colBalance';

const useSchema = () => {
  const { t } = useTranslation();
  const schema = [
    {
      name: t('CODE'),
      selector: (row) => row.passKey?.toUpperCase(),
      style: {
        with: 100,
      },
    },
    {
      name: t('transaction.previous_balance'),
      minWidth: '200px',
      cell: (row) => colBalance(row?.last_transaction, 'previous'),
    },
    {
      name: t('transaction.remaining_balance'),
      minWidth: '200px',
      cell: (row) => colBalance(row?.last_transaction, 'remaining'),
    },
    {
      name: t('company'),
      selector: 'company_name',
      sortable: true,
    },
    {
      name: t('name'),
      selector: 'name',
      sortable: true,
    },
    {
      name: t('civil_registry'),
      selector: 'id_number',
    },
    {
      name: t('cell_phone'),
      selector: 'phone',
    },
    {
      name: t('driver_city'),
      selector: 'city',
      sortable: true,
    },
    {
      name: t('balance'),
      selector: (row) =>
        `${
          row.balance
            ? row.balance?.toFixed(2)
            : row?.driver?.new_balance
            ? row?.driver?.new_balance.toFixed(2)
            : 0
        }`,
      sortable: true,
    },
    {
      name: t('gift'),
      selector: (row) => `${row.subBalance ? row.subBalance?.toFixed(2) : 0}`,
      sortable: true,
    },
    {
      name: t('stcPay'),
      selector: 'stcPay',
      sortable: true,
    },
    {
      name: t('subscription_date'),
      selector: (row) => `${moment(row.registration_date).calendar('LL ~ hh:mm A')}`,
      sortable: true,
    },
    {
      name: t('status'),
      selector: (row) => `${row.isActive ? t('active') : t('inactive')}`,
      sortable: true,
    },
  ];

  return schema;
};
export default useSchema;
