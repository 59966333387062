import { debounce } from 'lodash';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IconSearchInputOrders } from '../../../../constants/Icon';
import useMapTracking from '../../../../hooks/useMapTracking';

function InputSearchOrder() {
  const { t } = useTranslation();

  const { onChangeFieldFilterOrder } = useMapTracking();
  const handlerSearch = useCallback(
    debounce((search) => onChangeFieldFilterOrder('keyword', search), 500),
    []
  );
  const { refKeywordSearch } = useMapTracking();

  const onChange = (value) => {
    handlerSearch(value);
  };

  return (
    <div className="map-search">
      <input
        type="text"
        name="search"
        placeholder={t('search')}
        className="input-search"
        autoComplete="off"
        onChange={(e) => onChange(e.target.value)}
        ref={refKeywordSearch}
      />
      <button type="submit" className="button-search">
        <IconSearchInputOrders />
      </button>
    </div>
  );
}
export default InputSearchOrder;
