import React, { memo } from 'react';

const Tabs = memo(({ selectedTab, setSelectedTab, title, isActive }) => {
  const handlerTabs = () => {
    if (!isActive) {
      setSelectedTab(selectedTab);
    }
  };

  return (
    <>
      <span
        className={`tab-button cursor-pointer ${isActive ? 'tab-active' : ''}`}
        onClick={handlerTabs}
      >
        {title}
      </span>
    </>
  );
});

export default Tabs;
