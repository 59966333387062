/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

function RestaurantMaker({ ...props }) {
  const { order } = props;
  return (
    <>
      <div className={`marker restaurant ${props.id === props.showToolTip ? 'marker-active' : ''}`}>
        <div className="pin" title={order.restaurant_name}>
          <img
            src="/images/restaurant_pin.png"
            alt=""
            onClick={() => props.setTooltipRes('res_' + props.id)}
          />
        </div>
        <div className="pulse" />
        <div
          className={`driver-tooltip ${'res_' + props.id === props.tooltipRes ? 'show' : 'hide'}`}
        >
          <div className="map-marker-hint--title">
            <strong>{order.restaurant_name}</strong>
          </div>
          <div className="map-marker-hint-address"></div>
        </div>
      </div>
    </>
  );
}

export default RestaurantMaker;
