import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import axios from 'axios';
import { uniqueId } from 'lodash';
import { isEmpty } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import LoadingButton from '../../commonComponents/LoadingButton';
import { config } from '../../config/Config';
import { DataContext } from '../../context/DataContext';
import { createDate } from '../../helper/date/createDateWithTimezone';
import { checkResultStatus, STATUS_MAPPING } from '../../helper/regexHelper';
import { buildQueryString, parseQueryString } from '../../helper/utils';
import useEventEnter from '../../hooks/useEventEnter';

import SearchInput from '../SearchInput';
import HyperpayLogDetail from './HyperpayLogDetail';
import useColumn from './useColumn';
import moment from 'moment';

const HyperpayLog = () => {
  const { search } = useLocation();
  const queryString = parseQueryString(search);
  const { t } = useTranslation();

  const [logs, setLogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState(null);
  const [oldFilters, setOldFilters] = useState({});
  const [limit, setLimit] = useState(100);
  const [total, setTotal] = useState(0);
  const [selectedId, setSelectedId] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedLog, setSelectedLog] = useState(null);
  const [parsingQuery, setParsingQuery] = useState(true);
  const { columns } = useColumn({ logs });
  const [newRender, setNewRender] = useState(uniqueId());
  const [isExportExcel, setIsExportExcel] = useState(false);

  const { currentTimezone } = useContext(DataContext);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const handlePageChange = async (page) => {
    await setCurrentPage(page);
    setNewRender(uniqueId());
  };

  const onSetFilters = (newFilter) => {
    setCurrentPage(1);
    setFilters(newFilter);
  };
  const handleDateFilter = (value) => {
    if (value) {
      const newFilter = {
        ...filters,
        fromDate: new Date(value[0]).getTime(),
        toDate: new Date(value[1]).getTime(),
      };
      onSetFilters(newFilter);
    } else {
      const newFilter = {
        ...filters,
        fromDate: null,
        toDate: null,
      };
      onSetFilters(newFilter);
      setDateRange([null, null]);
    }
  };

  useEffect(() => {
    if (filters?.fromDate && filters?.toDate) {
      const dateValue = [new Date(+filters?.fromDate), new Date(+filters?.toDate)];
      setDateRange(dateValue);
    } else {
      setDateRange([null, null]);
    }
  }, [filters?.fromDate, filters?.toDate]);

  const toggleModal = (visible) => {
    setModalVisible(visible);
    if (!visible) {
      setSelectedLog(null);
    }
  };

  const handleToggleModal = (id) => {
    toggleModal(true);
    setSelectedLog(id);
  };

  const getHyperpayLogs = async (_filter = {}) => {
    const newFilters = {
      filters: {
        ...filters,
        fromDate: filters?.fromDate
          ? new Date(createDate(+filters?.fromDate, currentTimezone)).getTime()
          : '',
        toDate: filters?.toDate
          ? new Date(createDate(+filters?.toDate, currentTimezone)).getTime()
          : '',
      },
      sort: -1,
      limit: limit,
      page: currentPage,
    };
    const filter = isEmpty(_filter) ? newFilters : _filter;
    if (loading) return;
    setLoading(true);
    const newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      `?${buildQueryString({ ...filter })}`;

    window.history.pushState({ path: newurl }, '', newurl);

    try {
      const response = await axios.get(`${config.API_URL.HYPERPAY_LOGS.LIST}`, {
        params: {
          ...filter,
        },
        headers: config.headers,
      });

      const { data = {}, total } = response?.data;

      setLogs(data);
      await setTotal(total);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!parsingQuery) {
      getHyperpayLogs();
    }
  }, [limit, newRender, parsingQuery]);

  useEffect(() => {
    if (queryString.page) {
      setCurrentPage(+queryString.page);
    }
    if (queryString.filters) {
      const filter = { ...queryString.filters };
      if (filter.fromDate) {
        filter.fromDate = +filter.fromDate;
      }
      if (filter.toDate) {
        filter.toDate = +filter.toDate;
      }
      setFilters(filter);
      setOldFilters({ filters: filter });
    }
    if (queryString.limit > 0) {
      setLimit(+queryString.limit);
    }
    setParsingQuery(false);
  }, []);

  const tableHeight = window.innerHeight - 330;

  const conditionalRowStyles = [
    {
      when: (row) => checkResultStatus(row?.response?.result?.code) === STATUS_MAPPING.FAILURE,
      style: {
        backgroundColor: '#F7DDDE',
      },
    },
    {
      when: (row) => {
        return checkResultStatus(row?.response?.result?.code) === STATUS_MAPPING.SUCCESS;
      },
      style: {
        backgroundColor: '#D4E9D8',
      },
    },
    {
      when: (row) => checkResultStatus(row?.response?.result?.code) === STATUS_MAPPING.PENDING,
      style: {
        backgroundColor: '#FFFBDB',
      },
    },
  ];
  const handleFilterDriverId = (value) => {
    const newFilters = {
      ...filters,
      id_number: value,
    };
    onSetFilters(newFilters);
  };

  const handleFilterDriverName = (value) => {
    const newFilters = {
      ...filters,
      driver_name: value,
    };
    onSetFilters(newFilters);
  };

  const onGetHyperpayLogs = async () => {
    await setCurrentPage(1);
    const newFilters = {
      filters: {
        ...filters,
        fromDate: filters?.fromDate
          ? new Date(createDate(+filters?.fromDate, currentTimezone)).getTime()
          : '',
        toDate: filters?.toDate
          ? new Date(createDate(+filters?.toDate, currentTimezone)).getTime()
          : '',
      },
      sort: -1,
      limit: limit,
      page: currentPage,
    };
    setStartDate(newFilters?.filters?.fromDate);
    setEndDate(newFilters?.filters?.toDate);
    setOldFilters(newFilters);
    getHyperpayLogs(newFilters);
  };

  useEventEnter(onGetHyperpayLogs);

  const exportExcel = async () => {
    setIsExportExcel(true);

    const newFilters = {
      filters: {
        ...oldFilters?.filters,
        fromDate: startDate,
        toDate: endDate,
      },
      sort: -1,
      limit: limit,
      page: currentPage,
    };
    try {
      await axios
        .post(`${config.API_URL.HYPERPAY_LOGS.EXPORT_EXCEL}`, null, {
          params: newFilters,
          headers: {
            ...config.headers,
            'Content-Disposition': 'attachment; filename=template.xlsx',
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
          responseType: 'arraybuffer',
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `hyperpayLogs-${moment().locale('en').format('MM-DD-YYYY')}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
        });
    } catch (errors) {
      console.log('[exportExcel] errors-->', errors);
    } finally {
      setIsExportExcel(false);
    }
  };

  return (
    <div className="rtl">
      <div className="px-8 py-5 flex items-center gap-4 mb-[35px] flex-wrap">
        <div>
          <SearchInput
            disabled={loading}
            placeholder={t('driver_id')}
            handleSearch={handleFilterDriverId}
            makeRerender={filters}
            value={filters?.id_number}
          />
        </div>
        <div>
          <SearchInput
            disabled={loading}
            placeholder={t('driver_name')}
            handleSearch={handleFilterDriverName}
            makeRerender={filters}
            value={filters?.driver_name}
          />
        </div>
        <div className="input-select-wrapper" style={{ direction: 'ltr' }}>
          <DateTimeRangePicker
            disabled={loading}
            className="input-select-input-wrapper !border-0 w-[200px]"
            value={dateRange}
            onChange={handleDateFilter}
            disableClock
            format={'y-MM-dd'}
          />
        </div>
        <div className="relative w-full mb-4">
          <div className="flex right-0 ">
            <LoadingButton
              onClick={onGetHyperpayLogs}
              label={t('Apply Filter')}
              loading={loading}
            />
            <button
              className={`refresh-button  ${
                isExportExcel ? 'opacity-40 border-red-200' : 'opacity-100 '
              } `}
              disabled={isExportExcel}
              onClick={exportExcel}
            >
              {t('export_excel_all_by_filter')}
            </button>
          </div>
        </div>
      </div>
      <DataTable
        noDataComponent={<div style={{ padding: '24px' }}>{t('orders_no_records')}</div>}
        highlightOnHover
        columns={columns}
        data={logs}
        fixedHeader
        pagination
        contextMessage={{ singular: t('item'), plural: t('items'), message: t('selected') }}
        fixedHeaderScrollHeight={tableHeight.toString() + 'px'}
        paginationPerPage={limit}
        paginationRowsPerPageOptions={[100, 200, 400, 600]}
        onChangeRowsPerPage={(currentRowsPerPage) => {
          setLimit(+currentRowsPerPage);
          setCurrentPage(1);
        }}
        progressPending={loading}
        progressComponent={<div className="wait">{t('please_wait')}</div>}
        paginationTotalRows={total}
        paginationServer
        onChangePage={handlePageChange}
        paginationComponentOptions={{
          rowsPerPageText: t('total_record'),
          rangeSeparatorText: t('from'),
          noRowsPerPage: false,
          selectAllRowsItem: false,
        }}
        selectableRows
        selectableRowSelected={(row) => row.isSelected}
        onRowExpandToggled={(toggleState, row) => [
          toggleState
            ? setSelectedId((p) => [...p, row._id])
            : setSelectedId(selectedId.filter((i) => i !== row._id)),
        ]}
        conditionalRowStyles={conditionalRowStyles}
        onRowClicked={(row) => handleToggleModal(row?._id)}
        paginationDefaultPage={currentPage}
        selectableRowsVisibleOnly
        persistTableHead
        selectableRowsHighlight
        direction={'rtl'}
        customStyles={{
          headCells: {
            style: {
              fontWeight: 'bold',
              fontSize: 12,
              background: '#F9F9F9',
            },
          },
          rows: {
            style: {
              cursor: 'pointer',
              fontSize: 12,
            },
          },
          header: {
            style: {
              minHeight: 0,
            },
          },
        }}
      />
      <HyperpayLogDetail id={selectedLog} visible={modalVisible} toggleModal={toggleModal} />
    </div>
  );
};

export default HyperpayLog;
