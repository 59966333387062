/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { config, SETTING_KEY } from '../../config/Config';
toast.configure();

const ToggleMaeda = memo(() => {
  const { t } = useTranslation();

  const [express, setExpress] = useState(true);

  const getStatus = async () => {
    const resp = await axios.get(
      'https://maeda.com.sa/express_connections/v2/status.php?key=maeda_express'
    );

    if (resp?.data?.isClosed) {
      setExpress(false);
    } else {
      setExpress(true);
    }
  };

  useEffect(async () => {
    getStatus();
  }, []);

  const sendToSlack = async (status) => {
    const message = {
      text: `Express delivery in by app have ${status ? 'closed' : 'opened'}`,
      type: 'mrkdwn',
      attachments: [
        {
          color: '#E01E5A',
          fields: [
            {
              title: 'Environment',
              value: 'Production',
              short: true,
            },
            {
              title: 'Driver',
              value: 'ALL DRIVER',
              short: true,
            },
          ],
        },
      ],
    };
    await axios.post(
      `${config.API_URL.DRIVERS.SEND_MESSAGE}sendToSlack`,
      {
        message,
      },
      {
        headers: config.headers,
      }
    );
  };

  const changeStatus = async () => {
    if (express) {
      await axios.get('https://maeda.com.sa/express_connections/v2/close.php?key=maeda_express');
      await getStatus();
    } else {
      await axios.get('https://maeda.com.sa/express_connections/v2/open.php?key=maeda_express');
      await getStatus();
    }
    sendToSlack(express);
  };

  return (
    <>
      <div className="setting">
        <h3>{t('Enable express')}:</h3>
        <div className="row flex items-center gap-x-4">
          <label className="switch">
            <input
              checked={express}
              type="checkbox"
              name="isEnable"
              onChange={changeStatus}
            />
            <span className="slider round"></span>
          </label>
        </div>
      </div>
    </>
  );
});

export default ToggleMaeda;
