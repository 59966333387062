const driver = ({ driver }) => {
  return (
    <>
      <div className="map-marker-hint--title">
        <strong>{driver.driver_name}</strong>
      </div>
    </>
  );
};
export default driver;
