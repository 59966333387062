import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import TableComponents from '../../../../commonComponents/TableComponents';
import useSchema from '../../Schema';

function DataTableOrderDiscount({
  isLoading,
  limit,
  setLimit,
  currentPage,
  setCurrentPage,
  total,
  handlePageChange,
  data = [],
}) {
  const { t } = useTranslation();
  const schema = useSchema();
  const tableHeight = window.innerHeight - 420;
  return (
    <TableComponents
      noDataComponent={<div style={{ padding: '24px' }}>{t('orders_no_records')}</div>}
      columns={schema}
      data={data}
      fixedHeader
      pagination
      paginationDefaultPage={currentPage}
      contextMessage={{ singular: t('item'), plural: t('items'), message: t('selected') }}
      fixedHeaderScrollHeight={tableHeight.toString() + 'px'}
      paginationRowsPerPageOptions={[100, 200, 400, 600]}
      onChangeRowsPerPage={(currentRowsPerPage) => {
        setLimit(currentRowsPerPage);
        setCurrentPage(1);
      }}
      paginationPerPage={+limit}
      progressPending={isLoading}
      paginationTotalRows={total}
      progressComponent={<div className="wait">{t('please_wait')}</div>}
      paginationServer
      subHeader
      onChangePage={handlePageChange}
      conditionalRowStyles={conditionalRowStyles}
      paginationComponentOptions={{
        rowsPerPageText: t('total_record'),
        rangeSeparatorText: t('from'),
        noRowsPerPage: false,
        selectAllRowsItem: false,
      }}
      selectableRows
      selectableRowSelected={(row) => row.isSelected}
      selectableRowsVisibleOnly
      persistTableHead
      selectableRowsHighlight
      direction={'rtl'}
      expandableRowsHideExpander
      customStyles={customStylesTable}
    />
  );
}
export default memo(DataTableOrderDiscount);
const conditionalRowStyles = [
  {
    when: (row) => row.driver_id !== null,
    style: {
      backgroundColor: '#F8EECC',
    },
  },
  {
    when: (row) => row.isCanceled,
    style: {
      backgroundColor: '#F7DDDE',
    },
  },
  {
    when: (row) => row.isDelivered,
    style: {
      backgroundColor: '#D4E9D8',
    },
  },
];
const customStylesTable = {
  headCells: {
    style: {
      fontWeight: 'bold',
      fontSize: 12,
      background: '#F9F9F9',
      justifyContent: 'flex-start',
      padding: 0,
    },
  },
  rows: {
    style: {
      cursor: 'pointer',
      fontSize: 12,
    },
  },
  header: {
    style: {
      minHeight: 0,
    },
  },
  subHeader: {
    style: {
      minHeight: 0,
    },
  },
};
