import React, { memo } from 'react';

import { useTranslation } from 'react-i18next';
import Tabs from '../../../Atoms/Tabs';

const TabHeaderOrders = memo(({ selectedTab, setSelectedTab }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex tab-header order-tab">
        <Tabs
          title={t('new_orders')}
          isActive={selectedTab === 'order_list'}
          setSelectedTab={setSelectedTab}
          selectedTab={'order_list'}
        />
        <Tabs
          title={t('dashboard')}
          isActive={selectedTab === 'dashboard'}
          setSelectedTab={setSelectedTab}
          selectedTab={'dashboard'}
        />
      </div>
    </>
  );
});

export default TabHeaderOrders;
