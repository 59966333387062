/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import CsvDownload from 'react-json-to-csv';
import { Link, useLocation, useParams } from 'react-router-dom';
import { config } from '../../../config/Config';
import '../../../css/datatable.css';
import OrderActions from './OrderActions';
import useSchema from './Schema.js';

import moment from 'moment';
import { buildQueryString, parseQueryString } from '../../Orders/Orders';
import FilterComponent from './FilterComponent';

const DriverOrders = () => {
  const tableHeight = window.innerHeight - 400;
  const { id } = useParams();
  const schema = useSchema();
  const { t } = useTranslation();

  const { search } = useLocation();
  const queryString = parseQueryString(search);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [reasonsList, setReasonsList] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [firstTime, setFirstTime] = useState(true);
  const [total, setTotal] = useState(0);
  const [selected, setSelected] = useState([]);
  const [limit, setLimit] = useState(queryString.limit || 100);

  const [filters, setFilters] = useState({});

  const onSetFilters = (newFilter) => {
    setCurrentPage(1);
    setFilters(newFilter);
  };

  const handleDateFilter = (value) => {
    const newFilter = {
      ...filters,
      date: value,
    };
    onSetFilters(newFilter);
  };

  const selectHandler = (e, key) => {
    const newFilters = {
      ...filters,
      [key]: e,
    };
    onSetFilters(newFilters);
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.driver_id !== null,
      style: {
        backgroundColor: '#F8EECC',
      },
    },
    {
      when: (row) => row.isCanceled,
      style: {
        backgroundColor: '#F7DDDE',
      },
    },
    {
      when: (row) => row.isDelivered,
      style: {
        backgroundColor: '#D4E9D8',
      },
    },
  ];

  async function getOrders(filters = {}) {
    if (isLoading) return false;
    setIsLoading(true);
    try {
      const newFilters = {
        limit: limit,
        page: currentPage,
        ...filters,
      };

      const newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        `?${buildQueryString({ ...newFilters })}`;
      window.history.replaceState({ path: newurl }, '', newurl);

      await axios
        .get(`${config.API_URL.DRIVERS.GET_ALL_DRIVERS_v1}${id}/orders`, {
          params: {
            ...filters,
          },
          headers: config.headers,
        })
        .then(async (response) => {
          setData(response.data.data.results);
          if (response.data.data.results.length > 0) {
            setTotal(response.data.data.total);
          }
        });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);

      console.log('Axios error: ', e);
    } finally {
    }
  }

  async function getReason() {
    try {
      const reasons = await axios.get(`${config.API_URL.CITC_LOOKUP.REASONS}`);
      setReasonsList(reasons?.data || []);
      return;
    } catch (e) {
      console.log('Axios error: ', e);
    }
  }

  const handler = useCallback(
    debounce((filters) => getOrders(filters), 500),
    []
  );

  const handlePageChange = (nextPage) => {
    if (isLoading) return false;
    if (Number(nextPage) === Number(currentPage)) return false;
    const newFilters = {
      limit: limit,
      page: nextPage,
      filters,
    };

    setCurrentPage(nextPage);
    handler(newFilters, limit);
  };

  useEffect(async () => {
    if (firstTime) {
      const newFilters = parseQueryString(window.location.search);
      const { filters, limit = 100, page = 1 } = newFilters;
      setFilters({
        ...filters,
      });
      setFirstTime(false);
      setLimit(limit || 100);
      setCurrentPage(page || 1);

      const newFilter = {
        limit: limit,
        page: currentPage,
        filters,
      };
      handler(newFilter);
    } else {
      const newFilter = {
        limit: limit,
        page: currentPage,
        filters,
      };
      handler(newFilter);
    }
  }, [limit, currentPage]);

  useEffect(() => {
    getReason();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlerFilters = (e) => {
    const newFilter = {
      ...filters,
      [e.target.name]: e.target.value,
    };
    onSetFilters(newFilter);
  };

  const exportExcel = () => {
    // setIsExport(true);
    try {
      axios
        .post(
          `${config.API_URL.ORDERS.EXPORT_EXCEL}?id_number=${id}&${buildQueryString({
            filters: filters,
          })}`,
          {},
          {
            headers: {
              ...config.headers,
              'Content-Disposition': 'attachment; filename=template.xlsx',
              'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            responseType: 'arraybuffer',
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `order-${moment().locale('en').format('MM-DD-YYYY')}.xlsx`);
          document.body.appendChild(link);
          link.click();
        });
    } catch (errors) {
    } finally {
      // setIsExport(false);
    }
  };

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <FilterComponent
        filters={filters}
        handleDateFilter={handleDateFilter}
        handlerFilters={handlerFilters}
        selectHandler={selectHandler}
        isLoading={isLoading}
        handler={handler}
        limit={limit}
      />
    );
  }, [filters, isLoading, handler]);

  const refresh = () => {
    const newFilters = {
      limit: limit,
      page: currentPage,
      filters,
    };
    getOrders(newFilters);
  };

  const toggleLive = () => {
    return (
      <div className="flex justify-between items-center">
        <div className="displayHolder">
          <div className="toggleLiveHolder">
            <Link to="/orders/live" className="live">
              <div>{t('live')}</div>
            </Link>
            <div className="offline selectedDisplay">{t('hypothetical')}</div>
          </div>
          <span>{t('view_orders')}</span>
        </div>
        <div className="h-full flex items-center gap-x-4">
          <button className="refresh-button" onClick={() => refresh()}>
            {t('refresh')}
          </button>
          <button className="refresh-button" onClick={exportExcel}>
            {t('export_excel_all_by_filter')}
          </button>
        </div>
      </div>
    );
  };

  return (
    <div>
      {selected.length > 0 && (
        <CsvDownload
          filename="orders.csv"
          data={selected}
          style={{
            position: 'absolute',
            top: 90,
            zIndex: 2,
            right: 30,
            borderWidth: 0,
            backgroundColor: '#fff',
            padding: 10,
            borderRadius: 5,
            cursor: 'pointer',
          }}
        >
          {t('loading_data')}
        </CsvDownload>
      )}

      <DataTable
        noDataComponent={<div style={{ padding: '24px' }}>{t('orders_no_records')}</div>}
        title={toggleLive()}
        columns={schema}
        data={data}
        fixedHeader
        pagination
        paginationDefaultPage={+currentPage}
        contextMessage={{ singular: t('item'), plural: t('items'), message: t('selected') }}
        fixedHeaderScrollHeight={tableHeight.toString() + 'px'}
        paginationRowsPerPageOptions={[100, 200, 400, 600]}
        onChangeRowsPerPage={(currentRowsPerPage) => {
          setLimit(currentRowsPerPage);
          setCurrentPage(1);
        }}
        paginationPerPage={+limit}
        progressPending={isLoading}
        paginationTotalRows={total}
        progressComponent={<div className="wait">{t('please_wait')}</div>}
        paginationServer
        subHeader
        onChangePage={handlePageChange}
        conditionalRowStyles={conditionalRowStyles}
        paginationComponentOptions={{
          rowsPerPageText: t('total_record'),
          rangeSeparatorText: t('from'),
          noRowsPerPage: false,
          selectAllRowsItem: false,
        }}
        subHeaderComponent={subHeaderComponentMemo}
        selectableRows
        selectableRowSelected={(row) => row.isSelected}
        onSelectedRowsChange={(row) => setSelected(row.selectedRows)}
        selectableRowsVisibleOnly
        persistTableHead
        selectableRowsHighlight
        direction={'rtl'}
        expandableRows
        expandableRowsHideExpander
        expandOnRowClicked
        expandableRowsComponent={<OrderActions data={(row) => row} reasonsList={reasonsList} />}
        customStyles={{
          headCells: {
            style: {
              fontWeight: 'bold',
              fontSize: 12,
              background: '#F9F9F9',
              justifyContent: 'flex-start',

              padding: 0,
            },
          },
          rows: {
            style: {
              cursor: 'pointer',
              fontSize: 12,
            },
          },
        }}
      />
    </div>
  );
};

export default DriverOrders;
