import axios from 'axios';
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from '../../../config/Config';
import { handleMessage } from '../../../helper/utils';

const ReCreate = memo(({ driverId }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handlerReCreate = async () => {
    setLoading(true);
    try {
      const resp = await axios.post(
        config.API_URL.DRIVERS.RE_CREATE,
        {
          driver_id: driverId,
        },
        {
          headers: config.headers,
        }
      );
      if (resp.data?.success) {
        handleMessage('success');
      } else {
        handleMessage('error', t('error'));
      }
    } catch (error) {
      const message = error?.response?.data?.message;
      handleMessage('error', message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <button
      disabled={loading}
      onClick={handlerReCreate}
      className={`w-[80px] text-white px-5 py-[7px] border border-yellow-700  rounded-md ${
        loading ? 'bg-yellow-600' : 'bg-yellow-700'
      } `}
    >
      {t('reCreate')}
    </button>
  );
});

export default ReCreate;
