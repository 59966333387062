import React from 'react';

const SearchDriverCard = ({ driver, goToDriver }) => {
  return (
    <>
      <li key={driver._id} onClick={() => goToDriver(driver)}>
        <div className="content flex">
          <div className="driver-info ">
            <h1>{driver.driver_name}</h1>
            <div className="flex items-center gap-x-4 driver-location">
              <span className="items-center icon-location">
                <svg
                  viewBox="0 0 24 24"
                  width="16"
                  height="16"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                  <circle cx="12" cy="10" r="3"></circle>
                </svg>
              </span>
              <span>
                ({driver.latitude}, {driver.longitude})
              </span>
            </div>
          </div>
          <div className="flex items-center gap-x-4">
            <svg
              viewBox="0 0 24 24"
              width="24"
              height="24"
              stroke="currentColor"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <polyline points="12 16 16 12 12 8"></polyline>
              <line x1="8" y1="12" x2="16" y2="12"></line>
            </svg>
          </div>
        </div>
      </li>
    </>
  );
};

export default SearchDriverCard;
