import React, { useEffect, useRef } from 'react';

import useMapTracking from '../../hooks/useMapTracking';
import { customerInfo, driverInfo, restaurantInfo } from '../Atoms/InfoContent';
import { Information, MapComponent, MarkerItem } from '../Atoms/Map';
import MapDrivers from './Drivers';
import MapOrders from './Orders/Orders';

const apiKey = 'AIzaSyA6H47tcACDHEIIVhLKkuQx0lesf5fxvDE';
const MapTracking = ({ apiVersion, loadingConfig }) => {
  const refFirstRender = useRef(false);
  const { drivers, orders, isRenderNewMaps } = useMapTracking();

  useEffect(() => {
    if (refFirstRender.current === false && isRenderNewMaps) {
      refFirstRender.current = true;
    }
  }, [isRenderNewMaps]);

  return (
    <>
      <div className="tracking-driver map-tracking">
        <MapDrivers />
        {refFirstRender && (
          <MapComponent apiKey={apiKey}>
            {drivers.map((driver, index) => {
              const { latitude: lat, longitude: lng, hasOrder } = driver;
              return (
                <MarkerItem
                  position={{
                    lat,
                    lng,
                  }}
                  key={driver._id}
                  icon={{
                    url: hasOrder ? '/images/car-blue.png' : '/images/car-green.png',
                    scaledSize: {
                      height: 24,
                      width: 24,
                    },
                  }}
                >
                  <Information content={driverInfo({ driver })} />
                </MarkerItem>
              );
            })}
            {orders.map((order, index) => {
              const lat = order.customer_location?.coordinates?.[1];
              const lng = order.customer_location?.coordinates?.[0];
              const { driver = {} } = order;

              const driverId = driver?.id;
              return (
                <MarkerItem
                  position={{
                    lat,
                    lng,
                  }}
                  lat={lat}
                  lng={lng}
                  key={order._id}
                  icon={{
                    url: driverId ? '/images/yellow_flag.png' : '/images/white_flag.png',
                    scaledSize: {
                      height: 24,
                      width: 24,
                    },
                  }}
                >
                  <Information content={customerInfo({ order })} />
                </MarkerItem>
              );
            })}
            {orders.map((order, index) => {
              const lat = order.restaurant_location?.coordinates?.[1];
              const lng = order.restaurant_location?.coordinates?.[0];
              return (
                <MarkerItem
                  position={{
                    lat,
                    lng,
                  }}
                  lat={lat}
                  lng={lng}
                  key={order._id}
                  icon={{
                    url: '/images/restaurant_pin.png',
                    scaledSize: {
                      height: 24,
                      width: 24,
                    },
                  }}
                >
                  <Information content={restaurantInfo({ order })} />
                </MarkerItem>
              );
            })}
          </MapComponent>
        )}

        <MapOrders apiVersion={apiVersion} loadingConfig={loadingConfig} />
      </div>
    </>
  );
};

export default MapTracking;
