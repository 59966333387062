/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { config, SETTING_KEY } from '../../config/Config';
toast.configure();

const ToogleODOO = memo(({ setOdoo, odoo }) => {
  const { t } = useTranslation();

  const getSetting = async () => {
    const resp = await axios.get(config.API_URL.SETTING.GET, {
      params: {
        key: SETTING_KEY.TOGGLE_ODOO,
      },
      headers: config.headers,
    });
    const { data } = resp;
    const { setting } = data;
    setOdoo({
      isDisable: setting.isDisable || false,
    });
  };

  useEffect(() => {
    getSetting();
  }, []);

  const handlerChange = (e) => {
    const newValue = {
      ...odoo,
      [e.target.name]: !e.target.checked,
    };
    setOdoo(newValue);
  };

  return (
    <>
      <div className="setting">
        <h3>{t('disable_odoo')}:</h3>

        <div className="row flex items-center gap-x-4">
          <label className="switch">
            <input
              checked={!odoo.isDisable}
              type="checkbox"
              name="isDisable"
              onChange={handlerChange}
            />
            <span className="slider round"></span>
          </label>
        </div>
      </div>
    </>
  );
});

export default ToogleODOO;
