import { useEffect, useRef, useState } from 'react';

export default function useLayout() {
  const [height, setHeight] = useState(0);
  const refHeight = useRef(null);

  useEffect(() => {
    setHeight(refHeight.current.clientHeight);
  }, []);
  return { height, refHeight };
}
