import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from '../../../../config/Config';

const useFilterOptionOrder = () => {
  const { t } = useTranslation();

  const [filterDelivery, setFilterDelivery] = useState([]);

  const getOpsList = async () => {
    const { data } = await axios.get(config.API_URL.DELIVERY_COMPANY.LIST);
    const dataFilterDelivery = data?.data.map((item) => {
      return { value: item?._id, label: item?.name };
    });

    setFilterDelivery(dataFilterDelivery);
  };
  useEffect(() => {
    getOpsList();
  }, []);

  const filterPayment = [
    {
      value: 'online',
      label: t('online'),
    },
    {
      value: 'Cash',
      label: t('cash'),
    },
    {
      value: 'Point',
      label: t('point'),
    },
  ];

  const filterExpress = [
    {
      value: 'true',
      label: t('yes'),
    },
    {
      value: 'false',
      label: t('no'),
    },
  ];

  const filterStatus = [
    {
      value: 'cancelled',
      label: t('cancelled'),
    },
    {
      value: 'deliveried',
      label: t('deliveried'),
    },
    {
      value: 'assigned',
      label: t('assigned'),
    },
    {
      value: 'new',
      label: t('new'),
    },
  ];

  const filterCompany = [
    {
      value: 'maeda',
      label: t('Maeda'),
    },
    {
      value: 'shukah',
      label: t('Shukah'),
    },
  ];

  return {
    filterPayment,
    filterExpress,
    filterStatus,
    filterCompany,
    filterDelivery,
  };
};
export default useFilterOptionOrder;
