import React from 'react';
import { renderBalanceValue } from '../../helper/Transaction/renderAddedValue';

export default function colBalance(rowData, type = 'previous' | 'remaining') {
  const { previousBalance, remainingBalance } = renderBalanceValue(
    rowData?.transactionBalanceMain,
    rowData?.transactionBalanceSub,
    type
  );
  return (
    <div className="flex flex-row-reverse ">
      <div className="text-red-500 balance-value">{previousBalance}</div>
      <div className="px-2">/</div>
      <div className="text-green-500 balance-value">{remainingBalance}</div>
    </div>
  );
}
