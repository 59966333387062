import axios from 'axios';
import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import LoadingButton from '../../commonComponents/LoadingButton';

import { config } from '../../config/Config';
import { buildQueryString, parseQueryString } from '../../helper/utils';
import useEventEnter from '../../hooks/useEventEnter';
import SearchInput from '../SearchInput';
import useColumnsOtp from './useColumsOtp';

const Opt = () => {
  const { search } = useLocation();
  const queryString = parseQueryString(search);
  const { t } = useTranslation();

  const [otpList, setOtpList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [limit, setLimit] = useState(100);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [parsingQuery, setParsingQuery] = useState(true);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getOtpList = async (filter) => {
    try {
      setLoading(true);
      const newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        `?${buildQueryString({ ...filter })}`;

      window.history.pushState({ path: newurl }, '', newurl);
      const response = await axios.get(`${config.API_URL.DRIVERS.OTP}`, {
        params: {
          ...filter,
        },
        headers: config.headers,
      });

      const { data = {}, count } = response?.data;

      if (count >= 0) {
        setOtpList(data?.results ?? []);
        await setTotal(count);
      }
      setLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    if (!parsingQuery) {
      const newFilters = {
        filters: {
          ...filters,
        },
        sort: -1,
        limit: limit,
        page: currentPage,
      };

      getOtpList(newFilters);
    }
  }, [limit, currentPage, parsingQuery]);

  useEffect(() => {
    if (queryString.page) {
      setCurrentPage(+queryString.page);
    }
    if (queryString.filters) {
      const filter = { ...queryString.filters };
      setFilters(filter);
    }
    if (queryString.limit > 0) {
      setLimit(+queryString.limit);
    }
    setParsingQuery(false);
  }, []);

  const tableHeight = window.innerHeight - 330;

  const columns = useColumnsOtp();

  const handleSearchPhoneNumber = (value) => {
    const newFilters = {
      ...filters,
      phone: value,
    };
    setFilters(newFilters);
  };
  const onApplyFilter = () => {
    const newFilters = {
      filters: {
        ...filters,
      },
      sort: -1,
      limit: limit,
      page: 1,
    };
    setCurrentPage(1);
    getOtpList(newFilters);
  };

  useEventEnter(onApplyFilter);

  return (
    <div className="search-order flex flex-col mb-[35px] ltr items-start">
      <div className="px-8 py-5 flex items-end gap-4 flex-wrap flex-col w-full">
        <SearchInput
          handleSearch={handleSearchPhoneNumber}
          disabled={loading}
          makeRerender={filters}
          value={filters?.phone}
          placeholder="Phone number"
        />
        <div className="flex mb-4">
          <LoadingButton onClick={onApplyFilter} label={t('Apply Filter')} loading={loading} />
        </div>
      </div>
      <DataTable
        noDataComponent={<div style={{ padding: '24px' }}>{t('orders_no_records')}</div>}
        highlightOnHover
        columns={columns}
        data={otpList}
        fixedHeader
        pagination
        contextMessage={{ singular: t('item'), plural: t('items'), message: t('selected') }}
        fixedHeaderScrollHeight={tableHeight.toString() + 'px'}
        paginationPerPage={limit}
        paginationRowsPerPageOptions={[100, 200, 400, 600]}
        onChangeRowsPerPage={(currentRowsPerPage) => setLimit(+currentRowsPerPage)}
        progressPending={loading}
        progressComponent={<div className="wait">{t('please_wait')}</div>}
        paginationTotalRows={total}
        paginationServer
        onChangePage={handlePageChange}
        paginationComponentOptions={{
          rowsPerPageText: t('total_record'),
          rangeSeparatorText: t('from'),
          noRowsPerPage: false,
          selectAllRowsItem: false,
        }}
        selectableRows
        selectableRowSelected={(row) => row.isSelected}
        paginationDefaultPage={currentPage}
        selectableRowsVisibleOnly
        persistTableHead
        selectableRowsHighlight
        direction={'rtl'}
        customStyles={{
          headCells: {
            style: {
              fontWeight: 'bold',
              fontSize: 12,
              background: '#F9F9F9',
            },
          },
          rows: {
            style: {
              cursor: 'default',
              fontSize: 12,
            },
          },
          header: {
            style: {
              minHeight: 0,
            },
          },
          subHeader: {
            style: {
              minHeight: 0,
            },
          },
        }}
      />
    </div>
  );
};

export default Opt;
