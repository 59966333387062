import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { CONST_STATUS } from '../../../../constants/status';

const DriverStatus = memo(({ currentStatus, setCurrentStatus, numberDriver, showDriverNumber }) => {
  const { t } = useTranslation();

  const statusDriver = [
    { key: CONST_STATUS.ONLINE, name: t('online') },
    {
      key: CONST_STATUS.OFFLINE,
      name: t('offline'),
    },
  ];

  return (
    <>
      <div className="flex tab-header">
        <div className="flex items-center gap-x-4">
          {statusDriver.map((status) => {
            const handleSelect = () => {
              setCurrentStatus(status.key);
            };

            return (
              <button
                onClick={handleSelect}
                key={status.key}
                className={`tab-btn ${currentStatus === status.key ? 'active-tab' : ''}`}
              >
                {status.name} {currentStatus === status.key && numberDriver}
              </button>
            );
          })}
        </div>
      </div>
    </>
  );
});

export default DriverStatus;
