import React, { useState } from 'react';
import '../../css/offers.css';
import { useTranslation } from 'react-i18next';
const Offers = () => {
  const { t } = useTranslation();
  const [tab, setTab] = useState('all');
  return (
    <div className="">
      <div className="offerHeader">
        <div
          className="offerTab"
          onClick={() => setTab('all')}
          style={{ backgroundColor: tab === 'all' ? '#fff' : null }}
        >
          {t('offers')}
        </div>
        <div
          className="offerTab-2"
          onClick={() => setTab('new')}
          style={{ backgroundColor: tab === 'new' ? '#fff' : null }}
        >
          {t('add_offer')}
        </div>
      </div>
    </div>
  );
};

export default Offers;
