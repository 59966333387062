import axios from 'axios';
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { config } from '../../../config/Config';
import { IconLocation, IconWhatsapp } from '../../../constants/Icon';
import { CONST_STATUS } from '../../../constants/status';

const DriverCard = memo(({ driver, setSelectedDriver, idDriverFocus, status }) => {
  const { t } = useTranslation();

  const { driver_id, hasOrder } = driver;
  const handlerSelectDriver = () => {
    setSelectedDriver(driver);
  };
  const driverFocus = idDriverFocus === driver?.driver_id;
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  const handleMessage = (e) => {
    setMessage(e.target.value);
  };

  const sendMessage = async () => {
    if (message.length < 5) {
      alert(t('notification_must_have_minimum_5_letters'));
      return;
    }

    setShowMessage(false);
    setMessage('');
    await axios
      .post(
        `${config.API_URL.DRIVERS.SEND_MESSAGE}`,
        {
          title: t('admin'),
          message: message,
          driver_id,
        },
        { headers: config.headers }
      )
      .then(() =>
        toast(t('notification_has_been_successfully_sent'), {
          position: toast.POSITION.BOTTOM_LEFT,
        })
      );
  };
  const toggleViewSendNoti = () => {
    setShowMessage(!showMessage);
  };
  return (
    <>
      <li key={driver_id}>
        <div className="content">
          <div className="driver-info" onClick={handlerSelectDriver}>
            {status === CONST_STATUS.ONLINE ? (
              <span className={`label ${hasOrder ? 'inprogress' : 'already'}`}>
                {hasOrder ? t('in_progress') : t('already')}
              </span>
            ) : (
              <span className={`label offline`}>{t('driverOffline')}</span>
            )}

            <h1>{driver.driver_name}</h1>
            <div className="flex items-center gap-x-4 count-att">
              <div className="driver-icon-location">
                <IconLocation size={26} color={driverFocus ? '#3ed57c' : '#b3b3b3'} />
              </div>

              <span className="flex items-center gap-x-4 item-count">
                <span> {driver.delivering || 0}</span>
                <svg
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="none"
                >
                  <circle cx="12" cy="12" r="10"></circle>
                  <polyline points="16 12 12 8 8 12"></polyline>
                  <line x1="12" y1="16" x2="12" y2="8"></line>
                </svg>
              </span>
              <span className="flex items-center gap-x-4 item-count">
                <span> {driver.canceled_orders || 0}</span>
                <svg
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="none"
                >
                  <circle cx="12" cy="12" r="10"></circle>
                  <line x1="15" y1="9" x2="9" y2="15"></line>
                  <line x1="9" y1="9" x2="15" y2="15"></line>
                </svg>
              </span>

              <span className="flex items-center gap-x-4 item-count">
                <span> {driver.executed_orders || 0}</span>
                <svg
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="none"
                >
                  <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                  <polyline points="22 4 12 14.01 9 11.01"></polyline>
                </svg>
              </span>
              <span className="flex items-center gap-x-4 item-count">
                <span>{driver.assigned_orders || 0}</span>
                <span>:{t('total_order')} </span>
              </span>
            </div>
          </div>
          {driverFocus && (
            <div>
              <div className="justify-end items-center flex-row wrapBtnWhatsapp ">
                <div className="flex justify-end">
                  {/* <button className="phoneNumberDriver notifyDriver px-4">Notify Driver</button> */}
                  <div className="phoneNumberDriver mx-4 px-4">{driver?.driver_phone}</div>
                </div>
                <div className="flex justify-end items-center flex-row btnWhatsappLg">
                  <a
                    href={`https://wa.me/${driver?.driver_phone}`}
                    target="_blank"
                    rel="noreferrer noopener"
                    className={`rounded-md   ${
                      driver?.driver_phone
                        ? 'bg-[#45d354] hover:bg-[#39b045]'
                        : 'pointer-events-none cursor-not-allowed '
                    }`}
                  >
                    <div className="btnWhatsapp ">
                      <IconWhatsapp className="hover:bg-green-100" size={26} />
                    </div>
                  </a>
                </div>
              </div>
              <>
                <div className="mt-6 flex justify-end mx-4">
                  <button onClick={toggleViewSendNoti} className="driverCardMessages">
                    {t('send_alert')}
                  </button>
                </div>
                {showMessage && (
                  <div className="flex  flex-row justify-end mx-4 mt-5">
                    <input
                      type="text"
                      name="message"
                      onChange={handleMessage}
                      value={message}
                      className="driverInputMessages w-full"
                      placeholder={`${t('message')}...`}
                    />
                    <button
                      // style={styles.send}
                      className="driverSendNoti"
                      onClick={sendMessage}
                    >
                      {t('send')}
                    </button>
                  </div>
                )}
              </>
            </div>
          )}
        </div>
      </li>
    </>
  );
});

export default DriverCard;
