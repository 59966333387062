/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import { debounce } from 'lodash';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from '../../../config/Config';
import { CONST_STATUS } from '../../../constants/status';
import DriverCard from '../DriverCard';
import DriverCount from '../DriverCount';
import SearchDriverCard from '../SearchDriverCard';

const DriverCards = ({
  setSelectedDriver,
  drivers,
  goToDriver,
  refreshList,
  idDriverFocus,
  selectedTab,
  setSelectedTab,
}) => {
  const { t } = useTranslation();
  const [driverSearch, setDriverSearch] = useState([]);

  const getDriver = async ({ search, city }) => {
    const resp = await axios.post(
      config.API_URL.LOCATION.DRIVER,
      { keyword: search, city },
      {
        headers: config.headers,
      }
    );
    setDriverSearch(resp.data?.drivers || []);
  };

  const handlerSearch = useCallback(
    debounce((search) => getDriver(search), 500),
    []
  );

  const onChange = (value) => {
    handlerSearch({
      search: value,
    });
    setDriverSearch([]);
  };

  const refreshListSearch = () => {
    refreshList();
  };
  // const [selectedTab, setSelectedTab] = useTabs(['order_list', 'dashboard']);

  return (
    <>
      <div>
        <DriverCount />
      </div>
      <div className=" w-full">
        <div className="flex tab-header order-tab">
          <button
            className={`tab-button ${selectedTab === CONST_STATUS.ONLINE ? 'tab-active' : ''}`}
            isActive={selectedTab === CONST_STATUS.ONLINE}
            onClick={() => setSelectedTab(CONST_STATUS.ONLINE)}
          >
            {t('online')} {selectedTab === CONST_STATUS.ONLINE && drivers?.length}
          </button>
          <button
            className={`tab-button ${selectedTab === CONST_STATUS.OFFLINE ? 'tab-active' : ''}`}
            isActive={selectedTab === CONST_STATUS.OFFLINE}
            onClick={() => setSelectedTab(CONST_STATUS.OFFLINE)}
          >
            {t('offline')} {selectedTab === CONST_STATUS.OFFLINE && drivers?.length}
          </button>
        </div>
      </div>
      <div className="map-search">
        <input
          type="text"
          name="search"
          placeholder={t('search')}
          className="input-search"
          autoComplete="off"
          onChange={(e) => onChange(e.target.value)}
        />
        <button type="submit" className="button-search">
          <svg
            className="search-icon fill-current"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            version="1.1"
            x="0px"
            y="0px"
            viewBox="0 0 56.966 56.966"
            style={{ enableBackground: 'new 0 0 56.966 56.966' }}
            xmlSpace="preserve"
            width="512px"
            height="512px"
          >
            <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
          </svg>
        </button>
      </div>

      <div className="flex justify-end">
        <button onClick={refreshListSearch} className="refresh-button mt-2">
          {t('refresh')}
        </button>
      </div>

      <nav>
        <ul>
          {driverSearch &&
            driverSearch.length > 0 &&
            driverSearch.map((driver) => {
              return <SearchDriverCard driver={driver} goToDriver={goToDriver} />;
            })}
          {driverSearch &&
            driverSearch.length <= 0 &&
            drivers.map((driver) => {
              return (
                <DriverCard
                  status={selectedTab}
                  idDriverFocus={idDriverFocus}
                  key={driver._id}
                  driver={driver}
                  setSelectedDriver={setSelectedDriver}
                />
              );
            })}
        </ul>
      </nav>
    </>
  );
};

export default DriverCards;
